import { Group, Member, Role } from 'types/generated/graphql'

export const getOwnerList = (group: Group) => {
  return group.members.filter((member: Member) => {
    return member.role === 'OWNER'
  })
}

export const isOwnerUser = (userId: string, group: Group) => {
  return group.members.some(
    (member) => member.user.id === userId && member.role === Role.Owner
  )
}
