import { useEffect, useState } from 'react'
import { Box, useBreakpointValue } from '@chakra-ui/react'
import { QuizDesignType } from 'types/generated/graphql'

import { Header } from 'components/Common/Header'
import { Sidebar } from 'components/Sidebar'
import { UserProfileForm } from 'components/UserProfileForm/Create'
import { AlertModal } from 'components/Modal/AlertModal'
import { useAuthContext, useUserProfileContext } from 'state'

const smVariant = {
  sidebar: 'drawer',
  sidebarButton: true,
}
const mdVariant = {
  sidebar: 'sidebar',
  sidebarButton: false,
}

export interface LayoutProps {
  pageTitle: string
  design?: QuizDesignType
}

export const Layout: React.FC<LayoutProps> = ({
  pageTitle,
  design,
  children,
}) => {
  const { userProfile } = useUserProfileContext()
  const [isSidebarOpen, setSidebarOpen] = useState(false)
  const variants = useBreakpointValue({ base: smVariant, md: mdVariant })
  const mainBoxMlNum = variants?.sidebarButton ? 0 : 200
  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen)
  useEffect(() => {
    document.title = pageTitle
  })
  const auth = useAuthContext()

  const mainArea = (
    <Box ml={mainBoxMlNum}>
      <Header
        showSidebarButton={variants?.sidebarButton}
        onShowSidebar={toggleSidebar}
        pageTitle={pageTitle}
      />
      <Box minH={'100vh'} bg={`${design}.common.layerBackground`}>
        {children}
      </Box>
    </Box>
  )
  return (
    <>
      <Sidebar
        variant={variants?.sidebar!}
        isOpen={isSidebarOpen}
        onClose={toggleSidebar}
      />
      {userProfile?.user.organizationName === '' ? (
        <AlertModal
          modalBody={
            '組織情報が取得できませんでした。管理者にお問い合わせください。'
          }
          isOpen={true}
          onClose={() => auth.signOut()}
        />
      ) : (
        <UserProfileForm userProfile={userProfile} />
      )}
      {mainArea}
    </>
  )
}
