import React from 'react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'
import { GroupForm } from 'v2components/CreateGroup/GroupForm'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const CreateGroupModal: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size="xl">
        <ModalOverlay bg="rgba(0, 0, 0, 0.8)" />
        <ModalContent
          backgroundColor="#fff"
          borderRadius="16px"
          w="900px"
          minW="600px"
          maxH="90%"
          maxW="90%"
          position="fixed"
        >
          <ModalBody p="24px" fontSize="20" overflowY="auto">
            <GroupForm onCloseCreateModal={onClose} />
          </ModalBody>
          <ModalCloseButton right={3} left="auto" size="lg" color="#222222" />
        </ModalContent>
      </Modal>
    </>
  )
}
