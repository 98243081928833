import { object, string, TypeOf } from 'yup'

export const groupSchema = object({
  name: string()
    .required('グループネームは必須です。')
    .max(
      190,
      'グループネームにセットできる文字数は190字が上限です。文字数を190字以内にしてください'
    )
    .default(''),
  icon: string().ensure().default(''),
  description: string()
    .ensure()
    .max(
      190,
      'グループの説明にセットできる文字数は190字が上限です。文字数を190字以内にしてください'
    )
    .default(''),
}).defined()

export type GroupFormValues = TypeOf<typeof groupSchema>
