const componentsStyles = {
  components: {
    Button: {
      // 1. We can update the base styles
      baseStyle: {
        fontWeight: '', // Normally, it is "semibold"
      },
      // 2. We can add a new button size or extend existing
      sizes: {
        xl: {
          h: '',
          fontSize: '',
          px: '',
        },
      },
      // 3. We can add a new visual variant
      variants: {
        'with-shadow': {
          //   bg: 'red.400',
          //   boxShadow: '0 0 2px 2px #efdfde',
        },
        'primary-btn': {
          fontWeight: '500',
          bg: 'buttonPrimary',
          width: '160px',
          height: '40px',
          borderRadius: '40px',
          color: '#fff',
          _hover: {
            bg: 'buttonPrimaryHover',
            _disabled: {
              bg: 'buttonPrimaryHover',
            },
          },
        },
        'basic-btn': {
          bg: 'buttonBasic',
          width: '160px',
          height: '40px',
          fontWeight: '500',
          borderRadius: '40px',
          _hover: {
            bg: 'buttonBasicHover',
          },
        },
        'tool-btns': {
          bg: '#FFD000',
          color: '#2B5080',
          borderRadius: '50%',
          w: '40px',
          h: '40px',
          _hover: {
            color: '#fff',
            bg: '#2B5080',
          },
        },
        // 4. We can override existing variants
        solid: (props: any) => ({
          //   bg: props.colorMode === 'dark' ? 'red.300' : 'red.500',
        }),
      },
    },
    Tooltip: {
      variants: {
        'quzilla-default': {
          bg: '#CCEAFC',
          color: '#222',
          borderRadius: '4px',
          fontSize: '11px',
          _after: {
            bg: '#CCEAFC',
          },
        },
      },
    },
    Tabs: {
      variants: {
        'info-area': {
          mx: '-16px',
          tablist: {
            borderBottom: '4px solid #007BC2',
            gap: '4px 4px',
            mx: '-16px',
            px: '16px',
          },
          tab: {
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '1',
            backgroundColor: '#DADFE6',
            borderRadius: '10px 10px 0px 0px',
            cursor: 'pointer',
            p: '8px',
            w: '100%',
            _selected: {
              color: '#fff',
              bg: 'v2Primary',
            },
          },
          tabpanel: {
            px: '0',
          },
        },
      },
    },
  },
}

export default componentsStyles
