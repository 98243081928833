import React, { useState, useCallback, useMemo } from 'react'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { useQuery } from '@apollo/client'
import { css } from '@emotion/react'
import {
  Container,
  Box,
  Heading,
  Stack,
  VStack,
  HStack,
  Center,
  Text,
  Image,
  IconButton,
  Flex,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useColorModeValue,
  Tooltip,
} from '@chakra-ui/react'
import { CalendarIcon, RepeatClockIcon } from '@chakra-ui/icons'
import dayjs from 'dayjs'
import { MdContentCopy, MdRemoveRedEye, MdModeEdit } from 'react-icons/md'
import { IoArrowBack } from 'react-icons/io5'
import { ImEmbed } from 'react-icons/im'
import { VscPreview } from 'react-icons/vsc'

import { Layout } from 'components/Common/Layout'
import { DeleteModal } from 'components/Modal/DeleteModal'
import { Loading } from 'components/Common/Loading'
import { Error } from 'components/Common/Error'
import {
  PLAYLIST,
  PlaylistData,
  PlaylistVars,
} from 'graphql/apollo/query/playlist'
import { useDeleteQuizPlaylist } from 'hooks/useDeleteQuizPlaylist'
import { useUserProfileContext } from 'state'
import { isOwned } from 'utils/isOwned'
import { Unauthorized } from 'components/Common/Unauthorized'

const ImageWrapper = css`
  padding-top: 100%;
  position: relative;
  height: 100%;
`
const ImageBox = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #f3b0ae;
`

type urlProps = RouteComponentProps<{ quizPlaylistId: string }>

export const QuizPlayListCheck: React.FC<urlProps> = ({ quizPlaylistId }) => {
  const navigate = useNavigate()
  const [copySuccess, setCopySuccess] = useState('')
  const publishedColor = useColorModeValue('primaryLight', 'secondaryLight')
  const unpublishedColor = useColorModeValue('primaryDark', 'secondaryDark')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { currentGroup, userProfile } = useUserProfileContext()

  const handleCopy = useCallback(() => {
    const { origin } = new URL(window.location.href)
    const shareUrl = `${origin}/quizplaylist/${quizPlaylistId}/play`
    onOpen()
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        setCopySuccess('プレイリストURLがコピーされました😆')
      })
      .catch(() => {
        setCopySuccess('プレイリストURLのコピーに失敗しました…😢')
      })
  }, [onOpen, quizPlaylistId])

  const handleEmbedCodeCopy = useCallback(() => {
    const { origin } = new URL(window.location.href)
    const code = `<script>window.addEventListener('message', function (e) {if (e.origin !== '${origin}') {return}; const iframe = document.getElementById('${quizPlaylistId}'); iframe.height = e.data[0] + 'px'}, false);</script><iframe id="${quizPlaylistId}" src="${origin}/quizplaylist/${quizPlaylistId}/embed/play"  width="100%" scrolling="no" frameBorder="no"></iframe>`

    onOpen()
    navigator.clipboard
      .writeText(code)
      .then(() => {
        setCopySuccess('埋め込み用コードがコピーされました😆')
      })
      .catch(() => {
        setCopySuccess('埋め込み用コードのコピーに失敗しました…😢')
      })
  }, [onOpen, quizPlaylistId])

  const handleSubmit = useDeleteQuizPlaylist(quizPlaylistId!, onClose)

  const { loading, error, data, refetch } = useQuery<
    PlaylistData,
    PlaylistVars
  >(PLAYLIST, {
    variables: {
      id: quizPlaylistId!,
    },
  })
  refetch({ id: quizPlaylistId })

  const isOwnedQuizPlayList = useMemo(() => {
    return isOwned(data?.playlist, currentGroup, userProfile)
  }, [currentGroup, data, userProfile])

  const isAllPrivateQuizzes = useMemo(() => {
    if (!data || !data.playlist) {
      return false
    }
    const privateCnt = data.playlist.quizzes.filter(
      (quiz) => !quiz.published
    ).length
    return data.playlist.quizzes.length === privateCnt
  }, [data])

  if (loading || !quizPlaylistId) {
    return <Loading />
  }
  if (error || !data) {
    return <Error />
  }
  if (!isOwnedQuizPlayList) {
    return (
      <Layout pageTitle="チェックページ">
        <Unauthorized />
      </Layout>
    )
  }

  const { playlist } = data

  return (
    <Layout pageTitle="チェックページ">
      <Container maxW="container.md" p={0}>
        <VStack py={2}>
          <Flex w="100%">
            <IconButton
              _focus={{ boxShadow: 'none' }}
              variant="outline"
              aria-label="Return QuizPlaylist"
              color="#4D3B3E"
              onClick={() => navigate(`/quizplaylist`)}
              icon={<IoArrowBack />}
            />
            <Spacer />
            <DeleteModal handleSubmit={handleSubmit} />
          </Flex>
          <VStack w="100%" borderWidth="1px" spacing="0">
            {playlist.published ? (
              <Center w="100%" bg={publishedColor}>
                <Text fontWeight="bold">公開中</Text>
              </Center>
            ) : (
              <Center w="100%" bg={unpublishedColor}>
                <Text fontWeight="bold">非公開</Text>
              </Center>
            )}
            <Box display={{ sm: 'flex' }} w="100%">
              {playlist.imageUrl ? (
                <Box flexShrink={0} w={{ base: '100%', sm: '30%' }}>
                  <Box css={ImageWrapper}>
                    <Image
                      css={ImageBox}
                      src={playlist.imageUrl}
                      fallbackSrc={'/images/whiteImage.png'}
                      alt="リスト画像"
                    />
                  </Box>
                </Box>
              ) : (
                <Center w={{ base: '100%', sm: '30%' }} h="200px">
                  <Text>no image</Text>
                </Center>
              )}
              <VStack p={{ base: 1, sm: 2 }} w="100%">
                <HStack w="100%">
                  <Text fontSize="md" mr={2}>
                    <CalendarIcon boxSize="16px" mr={1} mb={1} />
                    {dayjs(playlist.createdAt).format('YYYY-MM-DD')}
                  </Text>
                  <Text fontSize="md" mr={2}>
                    <RepeatClockIcon boxSize="16px" mr={1} mb={1} />
                    {dayjs(playlist.updatedAt).format('YYYY-MM-DD')}
                  </Text>
                </HStack>

                <Stack w="100%">
                  <Heading fontSize="2xl" fontWeight="bold">
                    {playlist.name}
                  </Heading>
                  <Text fontSize="lg" pb="1rem">
                    {playlist.description}
                  </Text>
                </Stack>
                <Spacer />
                <HStack w="100%">
                  <Text fontSize="2xl" fontWeight="bold">
                    全{playlist.quizzes.length}問
                  </Text>
                  <Text fontSize="sm" as="sub">
                    うちクイズ
                  </Text>
                  <Text fontSize="sm" fontWeight="bold" as="sub">
                    公開
                    {
                      playlist.quizzes.filter(({ published }) => published)
                        .length
                    }
                    問
                  </Text>
                  <Text fontSize="sm" fontWeight="bold" as="sub">
                    非公開
                    {
                      playlist.quizzes.filter(({ published }) => !published)
                        .length
                    }
                    問
                  </Text>
                </HStack>
                <HStack w="100%">
                  <Text fontSize="lg" fontWeight="bold">
                    デザイン:
                  </Text>
                  <Text fontSize="sm" as="sub">
                    {playlist.design === 'ORIGINAL'
                      ? `オリジナル`
                      : playlist.design === 'SIMPLE'
                      ? 'シンプル'
                      : playlist.design === 'SERIOUS'
                      ? 'シリアス'
                      : '未選択'}
                  </Text>
                </HStack>
                <Spacer />
              </VStack>
            </Box>
          </VStack>
          <HStack pt={3}>
            <Tooltip hasArrow label="URLコピー" bg="red.600">
              <IconButton
                colorScheme="red"
                aria-label="URLコピー"
                isRound
                icon={<MdContentCopy />}
                onClick={handleCopy}
              />
            </Tooltip>
            <Spacer />
            <Tooltip
              hasArrow
              label={
                playlist.published && !isAllPrivateQuizzes
                  ? '埋め込み用コードコピー'
                  : '非公開のためコピーできません'
              }
              bg="red.600"
              shouldWrapChildren
            >
              <IconButton
                colorScheme="red"
                aria-label="埋め込み用コードコピー"
                isRound
                icon={<ImEmbed />}
                onClick={handleEmbedCodeCopy}
                disabled={!(playlist.published && !isAllPrivateQuizzes)}
              />
            </Tooltip>
            <Spacer />
            <Tooltip hasArrow label="プレビュー" bg="red.600">
              <IconButton
                colorScheme="red"
                aria-label="プレビュー"
                isRound
                icon={<MdRemoveRedEye />}
                onClick={() =>
                  navigate(`/quizplaylist/${quizPlaylistId}/preview`)
                }
              />
            </Tooltip>
            <Spacer />
            <Tooltip hasArrow label="埋め込み用プレビュー" bg="red.600">
              <IconButton
                colorScheme="red"
                aria-label="埋め込み用プレビュー"
                isRound
                icon={<VscPreview />}
                onClick={() =>
                  navigate(`/quizplaylist/${quizPlaylistId}/embed/preview`)
                }
              />
            </Tooltip>
            <Spacer />
            <Tooltip hasArrow label="編集" bg="red.600">
              <IconButton
                colorScheme="red"
                aria-label="編集"
                isRound
                icon={<MdModeEdit />}
                onClick={() => navigate(`/quizplaylist/${quizPlaylistId}/edit`)}
              />
            </Tooltip>
          </HStack>
          <Text fontSize="xs" color="blackAlpha.700" pt={3}>
            ID : {quizPlaylistId}
          </Text>
        </VStack>
        <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton left={3} right="auto" size="lg" />
            <ModalBody textAlign="center" p="3rem" fontSize="20">
              {copySuccess}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Container>
    </Layout>
  )
}
