import React, { useMemo } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import { useQuery } from '@apollo/client'
import {
  Stack,
  HStack,
  Box,
  Spacer,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  IconButton,
  Heading,
} from '@chakra-ui/react'
import { MdSettings } from 'react-icons/md'

import { Layout } from 'components/Common/Layout'
import { Loading } from 'components/Common/Loading'
import { MemberDrower } from 'components/MemberDrawer'
import { QuizCardList } from 'components/QuizCardList'
import { QuizPlaylistCardList } from 'components/QuizPlaylistCardList'
import { EnqueteCardList } from 'components/EnqueteCardList/index'
import { GROUP, GroupData, GroupVars } from 'graphql/apollo/query/group'
import { useUserProfileContext } from 'state'
import { isOwnerUser } from 'utils/getGroupRole'
import { QUIZZES, QuizzesData, QuizzesVars } from 'graphql/apollo/query/quizzes'
import {
  ENQUETES,
  EnquetesData,
  EnquetesVars,
} from 'graphql/apollo/query/enquetes'
import { Error } from 'components/Common/Error'
import {
  PLAYLISTS,
  PlaylistsData,
  PlaylistsVars,
} from 'graphql/apollo/query/playlists'

type Props = RouteComponentProps<{
  id: string
}>

export const GroupPage: React.FC<Props> = ({ id }) => {
  const { userProfile, currentGroup } = useUserProfileContext()

  const isEditableQuiz = useMemo(() => {
    // 選択グループがあり、選択グループグループページであるとき
    return id === currentGroup?.id
  }, [currentGroup, id])

  const isEditableGroup = useMemo(() => {
    // 選択グループがあり、選択グループグループページであり、選択グループのオーナーであるとき
    if (!currentGroup) return false
    if (!userProfile) return false
    if (id !== currentGroup.id) return false
    if (!isOwnerUser(userProfile.user.id, currentGroup)) return false
    return true
  }, [currentGroup, userProfile, id])

  const {
    loading: groupLoading,
    error: groupError,
    data: groupData,
  } = useQuery<GroupData, GroupVars>(GROUP, {
    variables: {
      id: id!,
    },
    skip: !id,
  })

  const {
    loading: quizzesLoading,
    error: quizzesError,
    data: quizzesData,
  } = useQuery<QuizzesData, QuizzesVars>(QUIZZES, {
    variables: {
      filter: {
        ownerGroupId: id!,
      },
    },
  })

  const {
    loading: playlistsLoading,
    error: playlistsError,
    data: playlistsData,
  } = useQuery<PlaylistsData, PlaylistsVars>(PLAYLISTS, {
    variables: {
      filter: {
        ownerGroupId: id!,
      },
    },
    fetchPolicy: 'network-only',
  })

  const {
    loading: enquetesLoading,
    error: enquetesError,
    data: enquetesData,
  } = useQuery<EnquetesData, EnquetesVars>(ENQUETES, {
    variables: {
      groupId: id!,
    },
  })

  if (!userProfile || groupLoading)
    return (
      <Layout pageTitle="グループ">
        <Loading />
      </Layout>
    )
  if (groupError)
    return (
      <Layout pageTitle="グループ">
        <div>グループデータが取得できません。</div>
      </Layout>
    )
  if (groupData === undefined)
    return (
      <Layout pageTitle="グループ">
        <div>現在グループ未設定状態です。</div>
      </Layout>
    )

  if (quizzesLoading || playlistsLoading)
    return (
      <Layout pageTitle="グループ">
        <Loading />
      </Layout>
    )

  if (quizzesError || playlistsError)
    return (
      <Layout pageTitle="グループ">
        <Error />
      </Layout>
    )

  if (enquetesLoading || enquetesError)
    return (
      <Layout pageTitle="グループ">
        <Error />
      </Layout>
    )

  return (
    <Layout pageTitle="グループ">
      <Box w="100%" position="relative">
        <Stack p={3}>
          <HStack pb={5}>
            <Stack>
              <Heading fontSize="3xl">{groupData.group.name}</Heading>
              <Text as="sub">@{groupData.group.id}</Text>
            </Stack>
            <Spacer />
            {isEditableGroup && (
              <IconButton
                colorScheme="red"
                aria-label="グループ設定ボタン"
                isRound
                icon={<MdSettings />}
                onClick={() => navigate('/group/edit')}
              />
            )}
          </HStack>
          <MemberDrower
            userProfile={userProfile}
            isEditableGroup={isEditableGroup}
            selectedGroup={groupData.group}
          />
        </Stack>
      </Box>
      <Tabs isFitted colorScheme="red">
        <TabList>
          <Tab>アンケート</Tab>
          <Tab>クイズ</Tab>
          <Tab>クイズプレイリスト</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <EnqueteCardList
              enquetes={enquetesData?.enquetes}
              isEditable={isEditableQuiz}
            />
          </TabPanel>
          <TabPanel>
            <QuizCardList
              quizzes={quizzesData?.quizzes}
              isEditableQuiz={isEditableQuiz}
            />
          </TabPanel>
          <TabPanel>
            <QuizPlaylistCardList
              playlists={playlistsData?.playlists}
              isEditableQuiz={isEditableQuiz}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Layout>
  )
}
