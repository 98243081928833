import { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import {
  DeleteMemberData,
  DeleteMemberVar,
  DELETE_MEMBER,
} from 'graphql/apollo/mutation/group/deleteMember'
import { GROUP } from 'graphql/apollo/query/group'
import { USER } from 'graphql/apollo/query/user'

import { User, Group } from 'types/generated/graphql'

export const useDeleteGroupMember = () => {
  const [deleteMember, { loading }] = useMutation<
    DeleteMemberData,
    DeleteMemberVar
  >(DELETE_MEMBER)
  const handleDeleteGroupMember = useCallback(
    async (group: Group, user: User) => {
      const variables = {
        groupId: group.id,
        userId: user.id,
      }
      return await deleteMember({
        variables: variables,
        refetchQueries: [GROUP, USER],
        awaitRefetchQueries: true,
      })
    },
    [deleteMember]
  )
  return { handleDeleteGroupMember, loading }
}
