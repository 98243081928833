import { object, string, TypeOf } from 'yup'

export const userProfileFormSchema = object({
  aliasId: string()
    .required('ユーザーIDは必須です。')
    .matches(/^[0-9a-zA-Z]+$/, '半角英数字のみ')
    .max(
      190,
      'ユーザーIDにセットできる文字数は190字が上限です。文字数を190字以内にしてください'
    )
    .default(''),
  name: string()
    .required('ユーザーネームは必須です。')
    .max(
      190,
      'ユーザーネームにセットできる文字数は190字が上限です。文字数を190字以内にしてください'
    )
    .default(''),
  icon: string().ensure().default(''),
  coverImage: string().ensure().default(''),
  comment: string()
    .ensure()
    .max(
      190,
      'コメントにセットできる文字数は190字が上限です。文字数を190字以内にしてください'
    )
    .default(''),
}).defined()

export type UserProfileFormValues = TypeOf<typeof userProfileFormSchema>
