import React from 'react'
import { Image, Center, VStack, Text } from '@chakra-ui/react'
import { StatusIcon } from 'v2components/ImageUpload/StatusIcon'
import { DropzoneRootProps } from 'react-dropzone'

interface Props {
  imageUrl: string
  style: DropzoneRootProps
  isDragAccept: boolean
  isDragReject: boolean
}

export const ImageComponent: React.FC<Props> = ({
  imageUrl,
  style,
  isDragAccept,
  isDragReject,
}) => {
  return (
    <Center
      {...style}
      position="relative"
      w="100%"
      h="240px"
      borderRadius="10px"
      border="1px dashed #2B5080"
      bgColor="#EAF7FF"
      overflow="hidden"
    >
      <Image
        position={'absolute'}
        objectFit="cover"
        w="100%"
        h="100%"
        src={imageUrl}
        fallbackSrc={'/images/whiteImage.png'}
        alt="prifile-icon"
      />
      <Center
        w="100%"
        h="100%"
        position={'absolute'}
        pr={1}
        pb={2}
        bg={imageUrl ? 'blackAlpha.300' : '#EAF7FF'}
        _hover={{ bg: imageUrl ? 'blackAlpha.500' : '#EAF7FF', opacity: 0.8 }}
        cursor="pointer"
      >
        <VStack>
          <StatusIcon
            imageUrl={imageUrl}
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
          />
          <Text
            color={imageUrl ? 'whiteAlpha.700' : '#2B5080'}
            fontSize="16px"
            mt="16px"
            lineHeight="1"
            textAlign="center"
          >
            <Text as="span" fontWeight="bold" display="inline-block" mr="8px">
              画像をここにドロップ
            </Text>
            または
            <Text as="span" fontWeight="bold" display="inline-block" ml="8px">
              <Text as="span" textDecoration="underline">
                クリック
              </Text>
              してアップロード
            </Text>
          </Text>
        </VStack>
      </Center>
    </Center>
  )
}
