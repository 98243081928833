import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { Auth, CognitoUser } from '@aws-amplify/auth'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API_ENDPOINT,
})

const authLink = setContext(async (_, { headers, noAuthorizationHeader }) => {
  // Authorizationヘッダを送らない場合はクエリ実行時に
  // context経由でnoAuthorizationHeaderフラグを渡す(PlayQuizなど)
  if (noAuthorizationHeader) {
    return headers
  }

  try {
    const user: CognitoUser = await Auth.currentAuthenticatedUser()
    const accessToken = user
      .getSignInUserSession()
      ?.getAccessToken()
      ?.getJwtToken()
    return {
      headers: {
        ...headers,
        authorization: accessToken,
      },
    }
  } catch (e) {
    // unauthenticated user
    return {
      headers,
    }
  }
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  },
})
