import { Router, Redirect } from '@reach/router'
import { useAuth } from 'hooks/useAuth'
import { AuthProvider, UserProfileProvider } from 'state'
import { useUserProfile } from 'hooks/useUserProfile'
import { SignIn } from 'pages/SignIn'
import { QuizCheck } from 'pages/QuizCheck'
import { QuizPlaylists } from 'pages/QuizPlaylists'
import { QuizPlayListCheck } from 'pages/QuizPlaylistCheck'
import { NotFound } from 'pages/NotFound'
import { PlayQuizPreview } from 'pages/PlayQuizPreview'
import { PlayQuizOpenArea } from 'pages/PlayQuizOpenArea'
import { PlayQuizPlaylistPreview } from 'pages/PlayQuizPlaylistPreview'
import { PlayQuizPlaylistOpenArea } from 'pages/PlayQuizPlaylistOpenArea'
import { Quizzes } from 'pages/Quizzes'
import { CreateQuiz } from 'pages/CreateQuiz'
import { EditQuiz } from 'pages/EditQuiz'
import { CreateQuizPlaylist } from 'pages/CreateQuizPlaylist'
import { EditQuizPlaylist } from 'pages/EditQuizPlaylist'
import { MyPage } from 'pages/MyPage'
import { EditUser } from 'pages/EditUser'
import { GroupPage } from 'pages/GroupPage'
import { CreateGroup } from 'pages/CreateGroup'
import { EditGroup } from 'pages/EditGroup'
import { Enquetes } from 'pages/Enquetes'
import { CreateEnquete } from 'pages/CreateEnquete'
import { EnqueteCheck } from 'pages/EnqueteCheck'
import { EditEnquete } from 'pages/EditEnquete'
import { AnswerEnqueteOpenArea } from 'pages/AnswerEnqueteOpenArea'
import { AnswerEnquetePreview } from 'pages/AnswerEnquetePreview'
import { EmbedAnswerEnqueteOpenArea } from 'pages/EmbedAnswerEnqueteOpenArea'
import { EmbedAnswerEnquetePreview } from 'pages/EmbedAnswerEnquetePreview'
import { EmbedPlayQuizOpenArea } from 'pages/EmbedPlayQuizOpenArea'
import { EmbedPlayQuizPreview } from 'pages/EmbedPlayQuizPreview'
import { EmbedPlayQuizPlaylistOpenArea } from 'pages/EmbedPlayQuizPlaylistOpenArea'
import { EmbedPlayQuizPlaylistPreview } from 'pages/EmbedPlayQuizPlaylistPreview'
import { Terms } from 'pages/Terms'
import { Loading } from 'components/Common/Loading'
import { ChakraProvider } from '@chakra-ui/react'
import theme from 'v2theme'
import TagManager from 'react-gtm-module'
import { useEffect } from 'react'
import { Quizzes as QuizzesV2 } from 'v2pages/Quizzes'
import { Enquetes as EnquetesV2 } from 'v2pages/Enquetes'
import { QuizLists as QuizListsV2 } from 'v2pages/QuizLists'
import { CreateQuiz as CreateQuizV2 } from 'v2pages/CreateQuiz'
import { CreateEnquete as CreateEnqueteV2 } from 'v2pages/CreateEnquete'
import { CreateQuizList as CreateQuizListV2 } from 'v2pages/CreateQuizList'
import { CreateGroup as CreateGroupV2 } from 'v2pages/CreateGroup'
import { EditQuiz as EditQuizV2 } from 'v2pages/EditQuiz'
import { EditEnquete as EditEnqueteV2 } from 'v2pages/EditEnquete'
import { EditQuizList as EditQuizListV2 } from 'v2pages/EditQuizList'
import { EditGroup as EditGroupV2 } from 'v2pages/EditGroup'
import { EditUser as EditUserV2 } from 'v2pages/EditUser'
import { MyPage as MyPageV2 } from 'v2pages/MyPage'

export const App: React.FC = () => {
  const auth = useAuth()
  const userProfileContext = useUserProfile(auth.user)

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-TDQT69RR' })
  }, [])

  return (
    <ChakraProvider theme={theme}>
      <div className="App">
        {(() => {
          switch (auth.signInStatus) {
            case 'unconfirmed':
              return <Loading />
            case 'authenticated':
              return (
                <AuthProvider value={auth}>
                  <UserProfileProvider value={userProfileContext}>
                    <Router>
                      <NotFound default />
                      <NotFound path="/404NotFound" />
                      <Redirect from="/login" to="/" noThrow={true} />
                      <Quizzes path="/" />
                      <Quizzes path="/quiz" />
                      <MyPage path="/mypage" />
                      <EditUser path="/user/edit" />
                      <GroupPage path="/group/:id" />
                      <CreateGroup path="/group/create" />
                      <EditGroup path="/group/edit" />
                      <QuizCheck path="/quiz/:quizId/check" />
                      <PlayQuizPreview path="/quiz/:quizId/preview" />
                      <PlayQuizOpenArea path="/quiz/:quizId/play" />
                      <CreateQuiz path="/quiz/create" />
                      <EditQuiz path="/quiz/:quizId/edit" />
                      <QuizPlaylists path="/quizplaylist" />
                      <QuizPlayListCheck path="/quizplaylist/:quizPlaylistId/check" />
                      <PlayQuizPlaylistPreview path="/quizplaylist/:quizPlaylistId/preview" />
                      <PlayQuizPlaylistOpenArea path="/quizplaylist/:quizPlaylistId/play" />
                      <CreateQuizPlaylist path="/quizplaylist/create" />
                      <EditQuizPlaylist path="/quizplaylist/:quizPlaylistId/edit" />
                      <Enquetes path="/enquetes" />
                      <CreateEnquete path="/enquete/create" />
                      <EnqueteCheck path="/enquete/:enqueteId/check" />
                      <EditEnquete path="/enquete/:enqueteId/edit" />
                      <AnswerEnqueteOpenArea path="/enquete/:enqueteId/answer" />
                      <AnswerEnquetePreview path="/enquete/:enqueteId/preview" />
                      <EmbedAnswerEnqueteOpenArea path="/enquete/:enqueteId/embed/answer" />
                      <EmbedAnswerEnquetePreview path="/enquete/:enqueteId/embed/preview" />
                      <EmbedPlayQuizOpenArea path="/quiz/:quizId/embed/play" />
                      <EmbedPlayQuizPreview path="/quiz/:quizId/embed/preview" />
                      <EmbedPlayQuizPlaylistOpenArea path="/quizplaylist/:quizPlaylistId/embed/play" />
                      <EmbedPlayQuizPlaylistPreview path="/quizplaylist/:quizPlaylistId/embed/preview" />
                      <Terms path="/terms" />
                      <QuizzesV2 path="/v2/quiz" />
                      <EnquetesV2 path="/v2/enquetes" />
                      <QuizListsV2 path="/v2/quizlist" />
                      <CreateQuizV2 path="/v2/create/quiz" />
                      <EditQuizV2 path="/v2/edit/quiz/:quizId" />
                      <CreateEnqueteV2 path="/v2/create/enquete" />
                      <EditEnqueteV2 path="/v2/edit/enquete/:enqueteId" />
                      <CreateQuizListV2 path="/v2/create/quizlist" />
                      <EditQuizListV2 path="/v2/edit/quizlist/:quizlistId" />
                      <CreateGroupV2 path="/v2/create/group" />
                      <EditGroupV2 path="/v2/edit/group" />
                      <EditUserV2 path="/v2/edit/user/:userId" />
                      <MyPageV2 path="/v2/mypage" />
                    </Router>
                  </UserProfileProvider>
                </AuthProvider>
              )
            case 'unauthenticated':
              return (
                <Router>
                  <NotFound default />
                  <NotFound path="/404NotFound" />
                  <SignIn path="/login" onSignIn={auth.signIn} />
                  <PlayQuizOpenArea path="/quiz/:quizId/play" />
                  <PlayQuizPlaylistOpenArea path="/quizplaylist/:quizPlaylistId/play" />
                  <AnswerEnqueteOpenArea path="/enquete/:enqueteId/answer" />
                  <EmbedAnswerEnqueteOpenArea path="/enquete/:enqueteId/embed/answer" />
                  <EmbedPlayQuizOpenArea path="/quiz/:quizId/embed/play" />
                  <EmbedPlayQuizPlaylistOpenArea path="/quizplaylist/:quizPlaylistId/embed/play" />
                  <Terms path="/terms" />
                </Router>
              )
          }
        })()}
      </div>
    </ChakraProvider>
  )
}
