import React from 'react'
import { css } from '@emotion/react'
import { RouteComponentProps } from '@reach/router'
import { Box, Text, Center, VStack } from '@chakra-ui/react'

import { IoEggOutline } from 'react-icons/io5'

type Props = RouteComponentProps

const Wrapper = css`
  width: 100vw;
  height: 100vh;
`
const LineCover = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  div {
    width: 100%;
    height: 25%;
    background-color: #ffffff;
    transform: translateX(-100%);
    :nth-of-type(1) {
      animation: slide-right 0.5s ease 1.6s forwards;
    }
    :nth-of-type(2) {
      animation: slide-right 0.5s ease 1.7s forwards;
    }
    :nth-of-type(3) {
      animation: slide-right 0.5s ease 1.8s forwards;
    }
    :nth-of-type(4) {
      animation: slide-right 0.5s ease 1.9s forwards;
    }
  }
  @keyframes slide-right {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0%);
    }
  }
`

export const NotFound: React.FC<Props> = () => {
  const nothing = () => {
    return (
      <Center fontSize="9xl" h="100%">
        <Text>n</Text>
        <IoEggOutline />
        <Text>thing</Text>
      </Center>
    )
  }
  const lineCoverBox = () => {
    return (
      <Box css={LineCover}>
        <Box></Box>
        <Box>
          <VStack>
            <Center fontSize="9xl" mb="5rem">
              <Text>4</Text>
              <IoEggOutline />
              <Text>4</Text>
            </Center>
            <Text>お探しのページは見つかりませんでした。</Text>
          </VStack>
        </Box>
        <Box></Box>
        <Box></Box>
      </Box>
    )
  }
  return (
    <Box css={Wrapper} bg="#E18F8C">
      {nothing()}
      {lineCoverBox()}
    </Box>
  )
}
