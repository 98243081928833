import { Input, FormControl, FormLabel } from '@chakra-ui/react'
import { Field, FieldProps } from 'formik'
import React, { useContext } from 'react'
import { FormDateContext } from '../../EnqueteForm/index'

export interface FormInputProps {
  name: string
  label: string
  isRequired?: boolean
}

export const FormDateTime: React.FC<FormInputProps> = ({
  name,
  label,
  isRequired = false,
}) => {
  const { answerPeriodFlg, deadlineFlg, setDeadlineFlg } =
    useContext(FormDateContext)

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const inputValue = event.target.value
          if (field.name === 'deadline' && !inputValue) {
            setDeadlineFlg(true)
          } else {
            setDeadlineFlg(false)
          }
        }
        return (
          <FormControl
            // name={name}
            isInvalid={!!(form.errors[name] && form.touched[name])}
            isRequired={isRequired}
            mb={3}
            w="40%"
            onChange={handleChange}
          >
            <FormLabel
              fontSize="xs"
              htmlFor={name}
              color={
                (form.errors.deadline && name === 'deadline') ||
                (form.errors.startDate && name === 'startDate') ||
                (answerPeriodFlg && deadlineFlg && name === 'deadline')
                  ? 'red'
                  : ''
              }
            >
              {label}
            </FormLabel>
            <Input {...field} type="datetime-local" width="auto" />
          </FormControl>
        )
      }}
    </Field>
  )
}
