import React from 'react'
import {
  Center,
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react'
import { useNavigate } from '@reach/router'
import { Scalars } from 'types/generated/graphql'

interface Props {
  modalBody: string
  isOpen: boolean
  onClose: () => void
  isMutationSucceeded: boolean
  groupId: Scalars['ID']
}

export const GroupResultModal: React.FC<Props> = ({
  modalBody,
  isOpen,
  onClose,
  isMutationSucceeded,
  groupId,
}) => {
  const navigate = useNavigate()
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>　</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Center>
            <Text fontSize="lg" m={10}>
              {modalBody}
            </Text>
          </Center>
        </ModalBody>
        <ModalFooter>
          {isMutationSucceeded && (
            <Button
              size="md"
              backgroundColor="#F3B0AE"
              color="#4D3B3E"
              mr={3}
              onClick={() => {
                navigate(`/group/${groupId}`)
              }}
            >
              グループページへ行く
            </Button>
          )}

          <Button
            size="md"
            backgroundColor="#F3B0AE"
            color="#4D3B3E"
            mr={3}
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
