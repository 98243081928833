import { navigate, RouteComponentProps } from '@reach/router'
import {
  Stack,
  HStack,
  Box,
  Center,
  Spacer,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  Image,
  IconButton,
} from '@chakra-ui/react'
import { MdSettings } from 'react-icons/md'
import { useQuery } from '@apollo/client'
import { Layout } from 'components/Common/Layout'
import { Loading } from 'components/Common/Loading'
import { QuizCardList } from 'components/QuizCardList'
import { QuizPlaylistCardList } from 'components/QuizPlaylistCardList'
import { EnqueteCardList } from 'components/EnqueteCardList/index'
import { USER, UserData, UserVars } from 'graphql/apollo/query/user'
import { useUserProfileContext } from 'state'
import { Error } from 'components/Common/Error'
import { MYPAGE, MyPageData, MyPageVars } from 'graphql/apollo/query/mypage'

type Props = RouteComponentProps

const isSameUser = (idA: string, idB: string) => {
  return idA === idB
}

export const MyPage: React.FC<Props> = () => {
  const { userProfile, currentGroup } = useUserProfileContext()
  const { loading, error, data } = useQuery<UserData, UserVars>(USER, {
    variables: {
      id: userProfile?.user.id!,
    },
  })
  const {
    loading: myPageLoading,
    error: myPageError,
    data: MyPageData,
  } = useQuery<MyPageData, MyPageVars>(MYPAGE, {
    variables: {
      groupId: currentGroup?.id!,
    },
    fetchPolicy: 'network-only',
  })

  if (loading || myPageLoading)
    return (
      <Layout pageTitle="ユーザー">
        <Loading />
      </Layout>
    )
  if (myPageError)
    return (
      <Layout pageTitle="ユーザー">
        <Error />
      </Layout>
    )

  return (
    <Layout pageTitle="ユーザー">
      {error || !data || !userProfile ? (
        <div>ユーザーは存在しません。</div>
      ) : (
        <>
          <Box w="100%" position="relative">
            <Image
              src={data!.user.coverImage || '/images/quiz_bg--header.png'}
              fallbackSrc={'/images/quiz_bg--header.png'}
              alt="profile-background"
              objectFit="none"
              pt={5}
              w="100%"
              h="150px"
            />
            <Image
              position="absolute"
              top="50px"
              left="25px"
              borderRadius="full"
              boxSize="150px"
              src={data!.user.icon || '/images/quiz_bg.png'}
              fallbackSrc={'/images/quiz_bg.png'}
              alt="prifile-icon"
              border="1px"
              borderColor="gray.200"
            />
            <Center h="50px" w="100%">
              <Spacer />
              {!isSameUser(data?.user.id, userProfile?.user.id) || (
                <IconButton
                  colorScheme="red"
                  aria-label="User情報編集ボタン"
                  isRound
                  icon={<MdSettings />}
                  mr={2}
                  onClick={() => navigate('/user/edit')}
                />
              )}
            </Center>
            <Stack p={3}>
              <HStack>
                <Text fontSize="md">{data!.user.name}</Text>
                <Text fontSize="sm">@{data!.user.aliasId}</Text>
              </HStack>
              <Text fontSize="sm">{data!.user.email}</Text>
              <Text fontSize="sm">{data!.user.comment}</Text>
            </Stack>
          </Box>
          <Tabs isFitted colorScheme="red">
            <TabList>
              <Tab>アンケート</Tab>
              <Tab>クイズ</Tab>
              <Tab>クイズプレイリスト</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <EnqueteCardList
                  enquetes={MyPageData?.myPage.enquetes}
                  isEditable={true}
                />
              </TabPanel>
              <TabPanel>
                <QuizCardList
                  quizzes={MyPageData?.myPage.quizzes}
                  isEditableQuiz={true}
                />
              </TabPanel>
              <TabPanel>
                <QuizPlaylistCardList
                  playlists={MyPageData?.myPage.playlists}
                  isEditableQuiz={true}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      )}
    </Layout>
  )
}
