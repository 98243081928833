import {
  Center,
  Stack,
  Heading,
  Image,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useColorModeValue,
} from '@chakra-ui/react'
import { FormInput } from 'components/Common/InputPageComponent/FormInput'

import logo from 'assets/images/logo192.png'

interface Props {
  name: string
  aliasId: string
  handleNext: () => void
  isValid: boolean
}

export const Input: React.FC<Props> = ({
  name,
  aliasId,
  handleNext,
  isValid,
}) => {
  const buttonNextColor = useColorModeValue('buttonNextLight', 'buttonNextDark')
  return (
    <Stack>
      <ModalHeader>
        <Center>
          <Image src={logo} w="3rem" h="3rem" alt="logo" />
          <Heading size="lg" pl="0.5rem">
            Step 1/2
          </Heading>
        </Center>
      </ModalHeader>
      <ModalBody>
        <Center>
          <Heading size="lg" py={5}>
            ユーザー登録
          </Heading>
        </Center>
        <FormInput name="name" label="ユーザーネーム" isRequired={true} />
        <FormInput name="aliasId" label="ID" isRequired={true} />
      </ModalBody>
      <ModalFooter>
        <Button
          bg={buttonNextColor}
          marginTop="auto"
          w="100%"
          variant="outline"
          aria-label="Delete Quiz"
          onClick={handleNext}
          disabled={!name || !aliasId || !isValid}
        >
          確認
        </Button>
      </ModalFooter>
    </Stack>
  )
}
