import { Box } from '@chakra-ui/react'
import { RouteComponentProps } from '@reach/router'
import { Layout } from 'components/Common/Layout'
import { EnqueteCardList } from 'components/EnqueteCardList'
import { useUserProfileContext } from 'state'
import { useQuery } from '@apollo/client'
import {
  ENQUETES,
  EnquetesData,
  EnquetesVars,
} from 'graphql/apollo/query/enquetes'
import { Loading } from 'components/Common/Loading'
import { Error } from 'components/Common/Error'

type Props = RouteComponentProps

export const Enquetes: React.FC<Props> = () => {
  const { currentGroup } = useUserProfileContext()
  const {
    loading: enquetesLoading,
    error: enquetesError,
    data: enquetesData,
  } = useQuery<EnquetesData, EnquetesVars>(ENQUETES, {
    variables: {
      groupId: currentGroup?.id,
    },
  })

  if (enquetesLoading)
    return (
      <Layout pageTitle="アンケートページ">
        <Loading />
      </Layout>
    )

  if (enquetesError)
    return (
      <Layout pageTitle="アンケートページ">
        <Error />
      </Layout>
    )

  return (
    <Layout pageTitle="アンケートページ">
      <Box p="3">
        <EnqueteCardList enquetes={enquetesData?.enquetes} isEditable={true} />
      </Box>
    </Layout>
  )
}
