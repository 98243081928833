import { useMutation } from '@apollo/client'
import {
  DeleteQuizData,
  DeleteQuizVars,
  DELETE_QUIZ,
} from 'graphql/apollo/mutation/quiz/deleteQuiz'
import { QUIZZES } from 'graphql/apollo/query/quizzes'

export const useDeleteQuiz = (quizId: string, onClose: () => void) => {
  const [deleteQuiz, { loading, error }] = useMutation<
    DeleteQuizData,
    DeleteQuizVars
  >(DELETE_QUIZ)

  const handleDeleteQuiz = async () => {
    await deleteQuiz({
      variables: {
        id: quizId,
      },
      refetchQueries: [{ query: QUIZZES }, 'Quizzes'],
    })
    if (loading) console.log('loading now')
    if (error) alert(`${quizId}の削除において、エラー発生が発生しました`)
    onClose()
  }
  return handleDeleteQuiz
}
