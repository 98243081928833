import { gql } from '@apollo/client'
import { Scalars, Playlist } from 'types/generated/graphql'

export type PlaylistData = {
  playlist: Playlist
}

export type PlaylistVars = {
  id: Scalars['ID']
  onlyPublishedQuizzes?: boolean
}

export const PLAYLIST = gql`
  query Playlist($id: ID!, $onlyPublishedQuizzes: Boolean = false) {
    playlist(id: $id, onlyPublishedQuizzes: $onlyPublishedQuizzes) {
      id
      name
      published
      ownerGroup {
        id
        name
      }
      ownerUser {
        id
        name
      }
      snsInfo {
        shareText
        ogpImageUrl
        destinationLink
        destinationLinkText
      }
      quizzes {
        id
        title
        published
        content {
          question
          correct
          wrongs
          imageUrl
          answerImageUrl
          description
        }
        snsInfo {
          destinationLink
        }
        design
      }
      passingScore
      passText
      failText
      passLink
      failLink
      passLinkText
      failLinkText
      imageUrl
      description
      design
    }
  }
`
