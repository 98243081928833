import React from 'react'
import { Field, FieldProps } from 'formik'
import {
  Stack,
  HStack,
  Box,
  Button,
  FormControl,
  Checkbox,
} from '@chakra-ui/react'
import { navigate } from '@reach/router'

type Props = {
  submitButtonLabel: string
  isSubmitting: boolean
  isValid: boolean
}

export const SaveButtons: React.FC<Props> = ({
  submitButtonLabel,
  isSubmitting,
  isValid,
}) => {
  return (
    <Stack direction="column" spacing="24px">
      <Box bgColor="#EAF7FF" p="24px" borderRadius="10px" w="100%">
        <Field name="published">
          {({ form }: FieldProps) => (
            <FormControl
              //   name="published"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb="24px"
            >
              <Checkbox
                name="published"
                id="published"
                colorScheme="blue"
                defaultChecked={form.values.published}
                onChange={() => {
                  form.setFieldValue('published', !form.values.published)
                }}
              >
                公開する
              </Checkbox>
            </FormControl>
          )}
        </Field>

        <HStack direction="row" spacing={4} justifyContent="center">
          <Button
            bg="buttonBasic"
            width="160px"
            height="40px"
            fontWeight="500"
            borderRadius="40px"
            onClick={() => {
              navigate('/v2/quizlist')
            }}
            _hover={{
              bg: 'buttonBasicHover',
            }}
          >
            キャンセル
          </Button>
          <Button
            type="submit"
            m="auto"
            fontWeight="500"
            isLoading={isSubmitting}
            disabled={!isValid}
            bg="buttonPrimary"
            width="160px"
            height="40px"
            borderRadius="40px"
            color="#fff"
            _hover={{
              bg: 'buttonPrimaryHover',
            }}
          >
            {submitButtonLabel}
          </Button>
        </HStack>
      </Box>
    </Stack>
  )
}
