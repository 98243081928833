import { useEffect, useState } from 'react'
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'

import { CreateForm } from 'v2components/UserCreateModal/CreateForm'
import { Loading } from 'v2components/Common/Loading'

import { useCreateUser } from 'hooks/useCreateUser'

import { userProfileFormSchema } from 'services/validators/UserProfileFormSchema'
import { UserData } from 'graphql/apollo/query/user'

interface Props {
  userProfile?: UserData
}

export const UserProfileForm: React.FC<Props> = ({ userProfile }) => {
  const { handleCreateUser } = useCreateUser({
    userProfile: userProfile,
  })
  const openModalStatus = userProfile?.user.aliasId === ''
  const [isOpen, setIsOpen] = useState<boolean>(openModalStatus)
  useEffect(() => {
    setIsOpen(openModalStatus)
  }, [openModalStatus])
  const { onClose } = useDisclosure({
    defaultIsOpen: openModalStatus,
  })

  return (
    <>
      {!userProfile ?? <Loading />}
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent>
          <Formik
            initialValues={userProfileFormSchema.cast(
              userProfileFormSchema.getDefault(),
              {
                stripUnknown: true,
              }
            )}
            validationSchema={userProfileFormSchema}
            onSubmit={async (values) => {
              await handleCreateUser(values)
              onClose()
            }}
          >
            {({ values, isSubmitting, isValid }) => {
              return (
                <Form>
                  <CreateForm
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    values={values}
                  />
                </Form>
              )
            }}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  )
}
