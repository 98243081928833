import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { useUploadFile } from 'hooks/useUploadFile'
import { useUserProfileContext } from 'state'
import { EnqueteFormValues } from '../services/validators/EnqueteFormSchema'
import dayjs from 'dayjs'
import { ENQUETES } from 'graphql/apollo/query/enquetes'
import {
  CREATE_ENQUETE,
  CreateEnqueteData,
  CreateEnqueteVars,
} from 'graphql/apollo/mutation/enquete/createEnquete'

export const useCreateEnquete = () => {
  const { currentGroup } = useUserProfileContext()
  const { handleUploadDataUrlImage } = useUploadFile()

  const [createEnquete] = useMutation<CreateEnqueteData, CreateEnqueteVars>(
    CREATE_ENQUETE
  )

  const handleCreateEnquete = useCallback(
    async (values: EnqueteFormValues) => {
      const s3ImageUrl = await handleUploadDataUrlImage(
        values.imageUrl,
        'enquete'
      )

      await createEnquete({
        variables: {
          input: {
            title: values.title,
            question: values.question,
            optionsCsv: values.optionsCsv.join(','),
            imageUrl: s3ImageUrl,
            published: values.published,
            ownerGroupId: currentGroup?.id,
            periodDisplayFlag: values.periodDisplayFlag,
            numberOfVoteDisplayFlag: values.numberOfVoteDisplayFlag,
            showResult: values.showResult,
            resultText: values.resultText,
            allowDuplicateAnswer: values.allowDuplicateAnswer,
            startDate: values.startDate
              ? new Date(dayjs(values.startDate).format('YYYY/MM/DD HH:mm'))
              : undefined,
            deadline: values.deadline
              ? new Date(dayjs(values.deadline).format('YYYY/MM/DD HH:mm'))
              : undefined,
            displayUnit: values.displayUnit,
          },
        },
        refetchQueries: [{ query: ENQUETES }, 'Enquetes'],
      })
    },
    [currentGroup?.id, createEnquete, handleUploadDataUrlImage]
  )

  return { handleCreateEnquete }
}
