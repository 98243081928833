import React from 'react'
import { Box, HStack } from '@chakra-ui/react'
import {
  DisplayUnit,
  Enquete,
  StatsPerSelection,
} from 'types/generated/graphql'

interface Props {
  enquete: Enquete
  stats: StatsPerSelection
  onSelectedChoice: (selectedChoice: string) => void
  answered?: string
  canAnswer: boolean
}

export const HorizontalLine: React.FC<Props> = ({
  enquete,
  stats,
  onSelectedChoice,
  answered,
  canAnswer,
}) => {
  const percent = canAnswer ? `0%` : `${stats.percent}`

  return (
    <Box
      marginTop="4"
      color="blackAlpha.800"
      w="100%"
      padding="2"
      cursor={canAnswer ? 'pointer' : 'default'}
      position="relative"
      bg="#edf2f7"
      onClick={() => {
        if (canAnswer) {
          onSelectedChoice(stats.selection)
        }
      }}
      zIndex="0"
    >
      <Box
        top="0"
        bottom="0"
        left="0"
        width={percent}
        bg={answered === stats.selection ? '#f3b0ae' : 'gray.300'}
        transition="width 1s ease-in-out"
        position="absolute"
        zIndex="-1"
      ></Box>
      <HStack w="100%">
        <Box w="80%">{stats.selection}</Box>
        <Box w="20%" textAlign="right" whiteSpace="pre-line">
          {!canAnswer &&
            (enquete.displayUnit === DisplayUnit.Percentage
              ? stats.percent
              : enquete.displayUnit === DisplayUnit.Vote
              ? `${stats.count}票`
              : `${stats.percent}${`\n`} ${stats.count}票`)}
        </Box>
      </HStack>
    </Box>
  )
}
