import React from 'react'
import { useQuery } from '@apollo/client'
import { navigate } from '@reach/router'
import {
  Center,
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Button,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { QuizForm } from 'components/QuizForm'
import { AlertModal } from 'components/Modal/AlertModal'
import { Loading } from 'components/Common/Loading'
import { NoData } from 'components/Common/NoData'
import { Error } from 'components/Common/Error'
import { useUpdateQuiz } from 'hooks/useUpdateQuiz'
import { Quiz } from 'types/generated/graphql'
import {
  QUIZSTATS,
  QuizStatsData,
  QuizStatsVars,
} from 'graphql/apollo/query/quizStats'

export interface QuizCardProps {
  quiz: Quiz
}

export const EditRoot: React.FC<QuizCardProps> = ({ quiz }) => {
  const completeDisclosure = useDisclosure()
  const alertDisclosure = useDisclosure()
  const { handleUpdateQuiz } = useUpdateQuiz({
    id: quiz?.id,
  })
  const {
    loading: quizStatsLoading,
    error: quizStatsError,
    data: quizStatsData,
  } = useQuery<QuizStatsData, QuizStatsVars>(QUIZSTATS, {
    variables: {
      quizId: quiz?.id!,
    },
    fetchPolicy: 'network-only',
  })
  if (quizStatsLoading) return <Loading />
  if (quizStatsError) return <Error />
  if (!quizStatsData) return <NoData />

  return (
    <>
      <QuizForm
        initialValues={quiz}
        quizStats={quizStatsData.quizStats}
        submitButtonLabel="更新"
        onSubmit={async (values) => {
          try {
            await handleUpdateQuiz(values, quiz)
            completeDisclosure.onOpen()
          } catch (error) {
            alertDisclosure.onOpen()
          }
        }}
      />
      <Modal
        isOpen={completeDisclosure.isOpen}
        onClose={completeDisclosure.onClose}
        isCentered={true}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>　</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Text fontSize="2xl" m={10}>
                クイズを更新しました
              </Text>
            </Center>
          </ModalBody>
          <ModalFooter>
            <Button
              backgroundColor="#F3B0AE"
              color="#4D3B3E"
              mr={3}
              onClick={() => {
                navigate(`/quiz/${quiz.id}/check`)
              }}
            >
              戻る
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertModal
        modalBody={'選択クイズの登録に失敗しました。'}
        isOpen={alertDisclosure.isOpen}
        onClose={alertDisclosure.onClose}
      />
    </>
  )
}
