import { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import { GroupFormValues } from 'services/validators/groupSchema'

import {
  UpdateGroupData,
  UpdateGroupVars,
  UPDATE_GROUP,
} from 'graphql/apollo/mutation/group/updateGroup'
import { USER } from 'graphql/apollo/query/user'
import { Group } from 'types/generated/graphql'

type Props = {
  group?: Group
}
export const useEditGroup = ({ group }: Props) => {
  const [updateGroupData] = useMutation<UpdateGroupData, UpdateGroupVars>(
    UPDATE_GROUP
  )
  const handleEditGroup = useCallback(
    async (values: GroupFormValues) => {
      if (!group) return new Error('Missing Group ID.')
      const variables = {
        input: {
          id: group.id,
          name: values.name,
        },
      }
      return await updateGroupData({
        variables: variables,
        refetchQueries: [USER],
      })
    },
    [updateGroupData, group]
  )
  return { handleEditGroup }
}
