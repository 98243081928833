const splitByMeasureWidth = (str: string, maxWidth: number, context: any) => {
  const lines = []
  let line = ''
  str.split('').forEach((char) => {
    line += char
    if (context.measureText(line).width > maxWidth) {
      lines.push(line.slice(0, -1))
      line = line.slice(-1)
    }
  })
  lines.push(line)
  return lines
}

type ImageParams = {
  width: number
  height: number
  x: number
  y: number
}

const imageLoader = (
  imageParams: ImageParams,
  context: CanvasRenderingContext2D | null,
  file: string
) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = file
    image.crossOrigin = 'anonymous'
    image.onload = () => {
      context?.drawImage(
        image,
        imageParams.x,
        imageParams.y,
        imageParams.width,
        imageParams.height
      )
      resolve(image)
    }
    image.onerror = (err) => {
      reject(err)
    }
  })
}

export const createOgp = async (text: string, image: string) => {
  const CANVAS_WIDTH = 1200
  const CANVAS_HEIGHT = 630
  const TEXT_BOX_WIDTH = 580
  let TEXT_BOX_HEIGHT = 120
  const BACKGROUND_IMAGE_PATH = `/images/background.png`
  const ICON_IMAGE_PATH = `/images/logo.png`
  const DEFAULT_QUIZ_IMAGE_PATH = `/images/defaultQuizImage.png`
  const TEXT_COLOR = '#4D3B3E'
  const TEXT_SIZE = 28
  const TEXT_MAX_LINE = 4
  const TEXT_LINE_MARGIN_SIZE = 16
  const TEXT_MARGIN_X = 18
  const FONT_FAMILY = 'rounded-mplus-1p-medium'

  const canvas = document.createElement('canvas')
  canvas.width = CANVAS_WIDTH
  canvas.height = CANVAS_HEIGHT
  const context = canvas.getContext('2d')

  // Draw background
  const backgroundImageParams = {
    width: CANVAS_WIDTH,
    height: CANVAS_HEIGHT,
    x: 0,
    y: 0,
  }
  await imageLoader(backgroundImageParams, context, BACKGROUND_IMAGE_PATH)

  // Draw title at center
  context!.font = `bold ${TEXT_SIZE}px ${FONT_FAMILY}`
  context!.fillStyle = TEXT_COLOR
  const titleLines = splitByMeasureWidth(
    text,
    TEXT_BOX_WIDTH - TEXT_MARGIN_X,
    context
  )
  // 文字列の描画範囲をMAX4行確保する
  const renderingTextLine = Math.min(titleLines.length, TEXT_MAX_LINE)
  let lineY =
    CANVAS_HEIGHT / 2 -
    ((TEXT_SIZE + TEXT_LINE_MARGIN_SIZE) / 2) * (renderingTextLine - 1)
  TEXT_BOX_HEIGHT = renderingTextLine * (18 + 20)
  context!.rect(
    310,
    CANVAS_HEIGHT / 2 - TEXT_BOX_HEIGHT / 2 - 7,
    TEXT_BOX_WIDTH,
    TEXT_BOX_HEIGHT
  )
  context!.fillStyle = 'rgba(250,232,232,0.8)'
  context!.fill()
  context!.strokeStyle = 'rgba(250,232,232,0.8)'
  context!.lineWidth = 0
  context!.stroke()
  // 5行目以降のテキストは省略し、4行目の最後の文字を省略記号に変換する
  if (titleLines.length > TEXT_MAX_LINE) {
    titleLines.splice(TEXT_MAX_LINE)
    const truncatedText = titleLines.pop()
    titleLines.push(truncatedText!.replace(/.$/, '…'))
  }
  titleLines.forEach((line) => {
    const textWidth = context!.measureText(line).width
    context!.fillStyle = TEXT_COLOR
    context!.fillText(line, (CANVAS_WIDTH - textWidth) / 2, lineY)
    lineY += TEXT_SIZE + TEXT_LINE_MARGIN_SIZE
  })

  return canvas.toDataURL()
}
