import { useMemo } from 'react'
import { usePlayQuizPlaylist } from 'hooks/usePlayQuizPlaylist'
import { useUserProfileContext } from 'state'
import { isOwned } from 'utils/isOwned'

export const usePlayQuizPlaylistPreview = (quizPlaylistId: string) => {
  const playPlaylistProps = usePlayQuizPlaylist(quizPlaylistId, true)
  const { currentGroup, userProfile } = useUserProfileContext()

  // プレビュー時のみ所有ステータスを確認
  const isOwnedQuizPlaylist = useMemo(() => {
    return isOwned(playPlaylistProps.playlist, currentGroup, userProfile)
  }, [playPlaylistProps.playlist, currentGroup, userProfile])

  return {
    ...playPlaylistProps,
    isOwnedQuizPlaylist,
  }
}
