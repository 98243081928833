import { extendTheme, ThemeConfig } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'
// コンポーネントスタイル
import componentsStyles from './components'
import styles from './styles'

const breakpoints = createBreakpoints({
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
})

const colorModeConfig: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: true,
}

const overrides = {
  styles,
  colors: {
    background: '#F9F1F0',
    primary: '#ef9a9a',
    primaryLight: '#ffcccb',
    primaryDark: '#ba6b6c',
    secondary: '#513030',
    secondaryLight: '#7e5a59',
    secondaryDark: '#280907',
    borderLight: '#160000',
    borderDark: '#ffcccb',
    textLight: '#1c1c1c',
    textDark: '#FFFFFF',
    buttonLight: '#382121',
    buttonDark: '#ef9a9a',
    button2Light: '#ffcccb',
    button2Dark: '#ba6b6c',
    buttonNextLight: '#ef9a9a',
    buttonNextDark: '#ba6b6c',
    buttonBackLight: '#7e5a59',
    buttonBackDark: '#513030',
    choiceButton: 'rgba(254, 254, 255, 0.082)',
    v2Primary: '#007BC2',
    v2Attention: '#EB5757',
    v2AccentY: '#FFD800',
    buttonPrimary: '#007BC2',
    buttonPrimaryHover: '#0092E7',
    buttonBasic: '#DADFE6',
    buttonBasicHover: '#CCEAFC',
  },
  fonts: {
    body: '"Noto Sans JP", sans-serif',
    heading: '"Noto Sans JP", Georgia, serif',
    mono: 'Menlo, monospace',
  },
  semanticTokens: {
    colors: {
      error: 'red.500',
      link: {
        default: 'purple.700',
        _dark: 'purple.100',
      },
      buttonDEsuyo9: {
        default: '#ef9a9a',
        _dark: 'purple.100',
      },
      wordBox: {
        default: 'purple.700',
        _dark: 'purple.100',
      },
    },
  },
  breakpoints,
  colorModeConfig,
  components: componentsStyles.components,
}

const designTheme = extendTheme({
  colors: {
    ORIGINAL: {
      common: {
        subText: 'blackAlpha.700',
      },
      start: {
        button: {
          background: '#ef9a9a',
          afterBackground: '#E2E8F0',
        },
      },
      question: {
        choiceQuestion: {
          background: '#EDF2F7',
          afterBackground: '#E2E8F0',
        },
      },
      answered: {
        choiceQuestion: {
          background: '#EDF2F7',
        },
        result: {
          correctLabel: 'red',
          incorrectLabel: '#4169e1',
        },
        button: {
          background: '#ef9a9a',
          afterBackground: '#E2E8F0',
        },
      },
      result: {
        result: {
          correctLabel: 'red',
          incorrectLabel: '#4169e1',
        },
      },
    },
    SIMPLE: {
      common: {
        layerBackground: '#f8f8f8',
        screenBackground: '#FFFFFF',
        subText: 'blackAlpha.700',
      },
      start: {
        button: {
          background: '#FFFFFF',
          label: '#1e1e17',
          borderColor: '#d2d2cb',
          afterLabel: '#cd0000',
          afterBorder: '#b90000',
          afterBackground: '#f8f8f8',
        },
      },
      question: {
        choiceQuestion: {
          background: '#FFFFFF',
          label: '#0a0a03',
          afterBackground: '#F7FAFC',
          borderColor: '#444',
        },
      },
      answered: {
        choiceAnswered: {
          background: '#FFFFFF',
          label: '#0a0a03',
          borderColor: '#d2d2cb',
          afterLabel: '#cd0000',
          afterBorder: '#b90000',
          afterBackground: '#f8f8f8',
        },
        result: {
          correctLabel: '#cd0000',
          correctBorder: '#cd0000',
          incorrectLabel: '#11219e',
          incorrectBorder: '#11219e',
        },
        button: {
          background: '#FFFFFF',
          label: '#1e1e17',
          borderColor: '#d2d2cb',
          afterLabel: '#cd0000',
          afterBorder: '#b90000',
          afterBackground: '#f8f8f8',
        },
      },
      result: {
        result: {
          correctLabel: '#cd0000',
          incorrectLabel: '#11219e',
        },
      },
    },
    SERIOUS: {
      common: {
        layerBackground: '#12192F',
        screenBackground: '#222741',
        mainText: '#F1F1F1',
        subText: '#B4B4B4',
      },
      start: {
        button: {
          background: '#34A4BC',
          label: '#222741',
          afterLabel: '#222741',
          afterBackground: '#A4A8C0',
        },
      },
      question: {
        choiceQuestion: {
          background: '#DCE0F6',
          label: '#222741',
          afterBackground: '#A4A8C0',
        },
      },
      answered: {
        choiceAnswered: {
          background: '#DCE0F6',
          label: '#0a0a03',
          afterBackground: '#f8f8f8',
        },
        result: {
          correctLabel: '#F85853',
          incorrectLabel: '#3A94FC',
        },
        button: {
          background: '#34A4BC',
          label: '#222741',
          afterLabel: '#222741',
          afterBackground: '#A4A8C0',
        },
      },
      result: {
        result: {
          correctLabel: '#F85853',
          incorrectLabel: '#3A94FC',
        },
      },
    },
  },
})

export default extendTheme(overrides, designTheme)
