import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useFormikContext } from 'formik'
import dayjs from 'dayjs'
import { Box, Flex, Image, Text, VStack } from '@chakra-ui/react'
import { CalendarIcon } from '@chakra-ui/icons'
import { EnqueteStatsData } from 'graphql/apollo/query/enqueteStats'
import { Enquete } from 'types/generated/graphql'
import { getEmbedHeight } from 'utils/answeredEnquete'
import { EmbedHorizontalLine } from 'components/AnswerEnquete/EmbedHorizontalLine'
import { EnqueteFormSchema } from 'services/validators/EnqueteFormSchema'
import { ToolButtonsPre } from 'v2components/Common/ToolButtonsPre'

export const LivePreviewEnquete: React.FC = () => {
  const { values } = useFormikContext()
  const castValues = EnqueteFormSchema.cast(values, { stripUnknown: true })
  const generatedEnqueteData: Enquete = {
    ...castValues,
    id: 'dummyID',
    createdAt: '',
    updatedAt: '',
    selectMin: 1,
    selectMax: 1,
    optionsCsv: castValues.optionsCsv.join(','),
    startDate:
      castValues.startDate === 'Invalid Date' ? '' : castValues.startDate,
  }
  const generatedStats = castValues.optionsCsv.map((option) => {
    return {
      count: 0,
      percent: '0%',
      selection: option,
    }
  })
  const dummyStats: EnqueteStatsData = {
    enqueteStats: {
      enqueteId: '',
      stats: generatedStats,
      total: 0,
    },
  }
  const elm = useRef<HTMLInputElement>(null)
  const [answered, setAnswered] = useState<string>(
    useMemo(() => {
      return ''
    }, [])
  )
  const [isSkippedAnswer, setIsSkippedAnswer] = useState(false)
  const handleSelectedChoice = useCallback((selectedChoice) => {
    setAnswered(selectedChoice)
  }, [])
  const isExpired = useMemo(() => {
    if (!generatedEnqueteData.deadline) {
      return false
    }
    return new Date() >= new Date(generatedEnqueteData.deadline)
  }, [generatedEnqueteData.deadline])

  const canAnswer = !isExpired && !answered && !isSkippedAnswer

  const setEmbedWindowHeight = () => {
    if (!elm.current) {
      return
    }
    const { clientHeight } = elm.current
    // iframe埋込時に表示される枠の高さを最後にプラスする
    window.parent.postMessage([clientHeight + 21], '*')
  }

  const handleResetPreview = () => {
    setAnswered('')
    setIsSkippedAnswer(false)
  }

  return (
    <VStack
      w="100%"
      overflowY="auto"
      maxH="100%"
      onLoad={setEmbedWindowHeight}
      ref={elm}
      spacing="20px"
    >
      <Box w="100%">
        <Box h="100%" position="relative" borderRadius="16px" overflow="hidden">
          <Image
            position="absolute"
            w="100%"
            h="100%"
            objectFit="cover"
            filter="brightness(65%)"
            src={generatedEnqueteData.imageUrl || `/images/whiteImage.png`}
            alt={generatedEnqueteData.question}
          />
          <VStack
            w="100%"
            h="100%"
            minHeight={getEmbedHeight(generatedEnqueteData)}
            spacing={4}
            justify="center"
            position="relative"
            color="white"
            p={{ base: '10px 15px', sm: '15px 30px' }}
          >
            <Text
              fontSize={{ base: '18px', sm: '22px' }}
              mt="16px"
              mb=".4rem"
              display="flex"
              fontWeight="700"
              textShadow="0 0 5px rgba(0, 0, 0, 0.5)"
              _before={{
                content: '"Q"',
                textShadow: 'none',
                w: '1.8em',
                minWidth: '1.8em',
                h: '1.8em',
                borderRadius: '50%',
                fontSize: '70%',
                fontWeight: '800',
                mr: '.3em',
                mt: '.25em',
                bg: '#ffaf00',
                color: '#fff',
                textAlign: 'center',
              }}
            >
              {generatedEnqueteData.question}
            </Text>

            {dummyStats.enqueteStats.stats.map((stats, idx) => (
              <EmbedHorizontalLine
                key={idx}
                enquete={generatedEnqueteData}
                stats={stats}
                onSelectedChoice={handleSelectedChoice}
                canAnswer={canAnswer}
                answered={answered}
              />
            ))}
            <Flex w="100%" flexDirection="column">
              <Flex
                justifyContent="flex-end"
                alignItems="center"
                textShadow="0 0 5px rgba(0, 0, 0, 0.5)"
              >
                {generatedEnqueteData.periodDisplayFlag && (
                  <>
                    <CalendarIcon mr={1} h="12px" color={'whiteAlpha.800'} />
                    <Text fontSize="xs" color={'whiteAlpha.800'} mr={1}>
                      {generatedEnqueteData.startDate
                        ? dayjs(generatedEnqueteData.startDate).format(
                            'YYYY-MM-DD'
                          )
                        : ''}
                    </Text>
                    <Text mr={1} color={'whiteAlpha.800'}>
                      ~
                    </Text>
                    <Text fontSize="xs" color={'whiteAlpha.800'}>
                      {dayjs(generatedEnqueteData.deadline).format(
                        'YYYY-MM-DD'
                      )}
                    </Text>
                  </>
                )}
              </Flex>
              <Flex justifyContent="flex-end">
                <Text
                  textAlign="left"
                  fontSize="sm"
                  onClick={() => {
                    setIsSkippedAnswer(true)
                  }}
                  textDecoration="underline"
                  cursor="pointer"
                  textShadow="0 0 5px rgba(0, 0, 0, 0.5)"
                  display={canAnswer ? 'block' : 'none'}
                >
                  投票せずに結果を見る
                </Text>
                {!canAnswer && generatedEnqueteData.numberOfVoteDisplayFlag && (
                  <Text
                    textAlign="left"
                    fontSize="sm"
                    textShadow="0 0 5px rgba(0, 0, 0, 0.5)"
                  >
                    総投票数: {dummyStats.enqueteStats.total}
                  </Text>
                )}
              </Flex>
            </Flex>
          </VStack>
        </Box>
      </Box>

      <ToolButtonsPre handleResetPreview={handleResetPreview} />
    </VStack>
  )
}
