import { Button, Center, Flex, Text } from '@chakra-ui/react'
import { useNavigate } from '@reach/router'
import { BiSelectMultiple } from 'react-icons/bi'

export const CreateQuiz: React.FC = () => {
  const navigate = useNavigate()
  return (
    <Button
      colorScheme="red"
      backgroundColor="#F3B0AE"
      height={160}
      onClick={() => navigate('/quiz/create')}
    >
      <Flex flexDirection="column">
        <Center mb={3}>
          <BiSelectMultiple color="#4D3B3E" size={80} />
        </Center>
        <Center>
          <Text color="#4D3B3E">選択クイズ</Text>
        </Center>
      </Flex>
    </Button>
  )
}
