import {
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Box,
} from '@chakra-ui/react'
import { Field, FieldProps } from 'formik'

export interface FormInputProps {
  name: string
  label: string
  annotation?: string
  placeholder?: string
  isRequired?: boolean
}

export const FormInput: React.FC<FormInputProps> = ({
  name,
  label,
  annotation,
  placeholder = label,
  isRequired = false,
}) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        return (
          <FormControl
            isInvalid={!!(form.errors[name] && form.touched[name])}
            isRequired={isRequired}
            mb={3}
          >
            {annotation ? (
              <Box>
                <FormLabel fontSize="xs" htmlFor={name} mb={0}>
                  {label}
                </FormLabel>
                <Text fontSize="1px" color="blackAlpha.500">
                  {annotation}
                </Text>
              </Box>
            ) : (
              <FormLabel
                color="#222"
                fontSize="14px"
                fontWeight="500"
                lineHeight="1.7142857143"
                letterSpacing="0.04em"
                alignItems="center"
                mb="8px"
                htmlFor={name}
              >
                {label}
              </FormLabel>
            )}
            <Input
              borderColor="#C0C0C0"
              borderRadius="8px 8px"
              borderStyle="solid"
              borderWidth="1px"
              boxSizing="border-box"
              p="8px 16px"
              {...field}
              placeholder={placeholder}
            />
            <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
          </FormControl>
        )
      }}
    </Field>
  )
}
