import React from 'react'
import {
  Box,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useUserProfileContext } from 'state'
import { isOwnerUser } from 'utils/getGroupRole'
import { navigate } from '@reach/router'
import { CreateGroupModal } from '../CreateGroupModal'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const SelectGroupModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { handleCurrentGroup, currentGroup, userProfile } =
    useUserProfileContext()
  const {
    isOpen: isOpenCreateGroupModal,
    onOpen: onOpenCreateGroupModal,
    onClose: onCloseCreateGroupModal,
  } = useDisclosure()
  const userData = userProfile?.user
  const myGroups = userData?.groups
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size="xl">
        <ModalOverlay bg="rgba(0, 0, 0, 0.8)" />
        <ModalContent
          backgroundColor="#007BC2"
          borderRadius="16px"
          w="930px"
          minW="600px"
          maxH="90%"
          maxW="90%"
          position="fixed"
        >
          <ModalBody p="24px" fontSize="20" overflowY="auto">
            <Box
              textAlign="left"
              mb="16px"
              color="#fff"
              fontSize="20px"
              fontWeight={500}
              lineHeight={1.6}
              letterSpacing="0.04em"
            >
              グループ選択
            </Box>
            <Grid gap="16px 16px" gridTemplateColumns="repeat(auto-fit, 273px)">
              <Flex
                backgroundColor="#fff"
                borderRadius="8px"
                boxShadow="0px 0px 20px 0px rgba(0, 0, 0, 0.25)"
                flexDirection="column"
                justifyContent="center"
                p="24px"
                position="relative"
                border="none"
                cursor="pointer"
                onClick={() => {
                  handleCurrentGroup(undefined)
                  navigate(`/v2/enquetes`)
                }}
              >
                <Box
                  color="#2B5080"
                  fontSize="14px"
                  fontWeight={700}
                  letterSpacing="0.04em"
                  textAlign="center"
                  whiteSpace="nowrap"
                  textOverflow="elipsis"
                  overflow="hidden"
                  alignSelf="stretch"
                  cursor="pointer"
                >
                  グループなし
                </Box>
              </Flex>
              {myGroups?.map((myGroup) => {
                return (
                  <Box
                    backgroundColor="#fff"
                    borderRadius="8px"
                    boxShadow="0px 0px 20px 0px rgba(0, 0, 0, 0.25)"
                    display={[
                      '-webkit-box',
                      '-webkit-flex',
                      '-ms-flexbox',
                      'flex',
                    ]}
                    flexDirection="column"
                    justifyContent="center"
                    p="24px"
                    position="relative"
                    cursor="pointer"
                    onClick={() => {
                      handleCurrentGroup(myGroup)
                    }}
                  >
                    {myGroup.id === currentGroup?.id ? (
                      <Box
                        color="#fff"
                        textAlign="center"
                        fontSize="10px"
                        lineHeight={1.2}
                        letterSpacing="0.04em"
                        backgroundColor="#2B5080"
                        borderRadius="7px 0px"
                        pt="3px"
                        pb="3px"
                        pl="10px"
                        pr="9px"
                        position="absolute"
                        top="0"
                        left="0"
                      >
                        選択中
                      </Box>
                    ) : (
                      <></>
                    )}
                    <Flex
                      display={[
                        '-webkit-box',
                        '-webkit-flex',
                        '-ms-flexbox',
                        'flex',
                      ]}
                      gap="8px 8px"
                      marginBottom="8px"
                    >
                      {isOwnerUser(userData!.id, myGroup) ? (
                        <Box
                          color="#222"
                          textAlign="center"
                          fontSize="12px"
                          fontWeight={500}
                          lineHeight={1.1666666667}
                          backgroundColor="#FFD000"
                          borderRadius="10px"
                          boxSizing="border-box"
                          pt="3px"
                          pb="3px"
                          pl="10px"
                          pr="10px"
                          width="83px"
                        >
                          OWNER
                        </Box>
                      ) : (
                        <Box
                          color="#222"
                          textAlign="center"
                          fontSize="12px"
                          fontWeight={500}
                          lineHeight={1.1666666667}
                          backgroundColor="#A6DEFF"
                          borderRadius="10px"
                          boxSizing="border-box"
                          pt="3px"
                          pb="3px"
                          pl="10px"
                          pr="10px"
                          width="83px"
                        >
                          MEMBER
                        </Box>
                      )}
                      <Box
                        color="#222"
                        fontSize="12px"
                        fontWeight={500}
                        lineHeight={1.6666666667}
                        letterSpacing="0.04em"
                      >
                        {myGroup.members.length}人のメンバー
                      </Box>
                    </Flex>
                    <Text
                      color="#2B5080"
                      fontSize="14px"
                      fontWeight={700}
                      lineHeight={1.7142857143}
                      letterSpacing="0.04em"
                      textAlign="center"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      alignSelf="stretch"
                    >
                      {myGroup.name}
                    </Text>
                    <Text
                      color="#222"
                      fontSize="12px"
                      lineHeight={1.6666666667}
                      letterSpacing="0.04em"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      alignSelf="stretch"
                      marginTop="16px"
                    >
                      オーナー：
                      {myGroup.members.map((member, index) => {
                        if (index === myGroup.members.length - 1) {
                          return member.user.name
                        } else {
                          return member.user.name + ','
                        }
                      })}
                    </Text>
                  </Box>
                )
              })}
              <Flex
                backgroundColor="#FFD000"
                borderRadius="8px"
                boxShadow="0px 0px 20px 0px rgba(0, 0, 0, 0.25)"
                flexDirection="column"
                justifyContent="center"
                p="24px"
                position="relative"
                border="none"
                cursor="pointer"
                onClick={() => {
                  onOpenCreateGroupModal()
                }}
              >
                <Box
                  color="#2B5080"
                  fontSize="14px"
                  fontWeight={700}
                  letterSpacing="0.04em"
                  textAlign="center"
                  whiteSpace="nowrap"
                  textOverflow="elipsis"
                  overflow="hidden"
                  alignSelf="stretch"
                  cursor="pointer"
                >
                  グループ作成
                </Box>
              </Flex>
            </Grid>
          </ModalBody>
          <ModalCloseButton right={3} left="auto" size="lg" color="#fff" />
        </ModalContent>
      </Modal>
      <CreateGroupModal
        isOpen={isOpenCreateGroupModal}
        onClose={onCloseCreateGroupModal}
      />
    </>
  )
}
