import React, { useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'
import { USERS, UsersData, UsersVars } from 'graphql/apollo/query/users'
import { Group, Member } from 'types/generated/graphql'
import { MdAddCircle } from 'react-icons/md'
import { useAddUserToGroup } from 'v2hooks/useAddUserToGroup'
import { ConfirmModal } from './ConfirmModal'

type Props = {
  isOpen: boolean
  onClose: () => void
  group: Group
  groupMembers: Member[]
}

export const AddUserModal: React.FC<Props> = ({
  isOpen,
  onClose,
  group,
  groupMembers,
}) => {
  const { data } = useQuery<UsersData, UsersVars>(USERS)
  const [addUserId, setAddUserId] = useState('')
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)
  const users = useMemo(() => {
    if (!data) return []
    else return data.users
  }, [data])
  const displayUsers = users.filter(
    (user) => !groupMembers.some((member) => member.user.id === user.id)
  )
  const addUser = useAddUserToGroup(group, addUserId)
  const HandleAddUser = async () => {
    await addUser()
  }

  const onOpenConfirmModal = () => setIsOpenConfirmModal(true)
  const onCloseConfirmModal = () => setIsOpenConfirmModal(false)

  const handleOpenConfirmModal = (userId: string) => {
    setAddUserId(userId)
    onOpenConfirmModal()
  }
  const handleCloseConfirmModal = () => {
    setAddUserId('')
    onCloseConfirmModal()
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size="xl">
        <ModalOverlay bg="rgba(0, 0, 0, 0.8)" />
        <ModalContent
          backgroundColor="#007BC2"
          borderRadius="16px"
          w="900px"
          minW="600px"
          maxH="90%"
          maxW="90%"
          position="fixed"
        >
          <ModalBody p="24px" fontSize="20" overflowY="auto">
            <Box
              textAlign="left"
              mb="16px"
              color="#fff"
              fontSize="20px"
              fontWeight={500}
              lineHeight={1.6}
              letterSpacing="0.04em"
            >
              メンバーを追加
            </Box>
            <Flex
              overflow="hidden"
              flexGrow={1}
              flexShrink={1}
              flexDirection="column"
              gap="16px 16px"
            >
              <Box />
              <Box overflowY="auto" flexGrow={1} flexShrink={1}>
                <Box w="100%" position="relative">
                  <Box position="sticky" top={0} left={0} zIndex={1}>
                    <Flex>
                      <Flex
                        alignItems="center"
                        color="#fff"
                        backgroundColor="#007BC2"
                        flexBasis="160px"
                        pl="16px"
                        pr="12px"
                        flexGrow={0}
                        flexShrink={0}
                        fontWeight={500}
                        borderBottom="1px solid #C0C0C0"
                        pt="8px"
                        pb="7px"
                        fontSize="14px"
                        lineHeight="1.7142857143"
                      >
                        NAME
                      </Flex>
                      <Box
                        color="#fff"
                        backgroundColor="#007BC2"
                        flexBasis="200px"
                        pl="12px"
                        pr="12px"
                        flexGrow={0}
                        flexShrink={0}
                        fontWeight={500}
                        borderBottom="1px solid #C0C0C0"
                        pt="8px"
                        pb="7px"
                        fontSize="14px"
                        lineHeight={1.7142857143}
                        display="block"
                      >
                        EMAIL
                      </Box>
                      <Box
                        color="#fff"
                        backgroundColor="#007BC2"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        flexGrow={1}
                        flexShrink={1}
                        flexBasis="240px"
                        pl="12px"
                        pr="12px"
                        fontWeight={500}
                        borderBottom="1px solid #C0C0C0"
                        pt="8px"
                        pb="7px"
                        fontSize="14px"
                        lineHeight={1.7142857143}
                        display="block"
                      >
                        コメント
                      </Box>
                      <Box
                        color="#fff"
                        backgroundColor="#007BC2"
                        flexBasis="120px"
                        pl="12px"
                        pr="22px"
                        flexGrow={0}
                        flexShrink={0}
                        fontWeight={500}
                        borderBottom="1px solid #C0C0C0"
                        pt="8px"
                        pb="7px"
                        fontSize="14px"
                        lineHeight={1.7142857143}
                        display="block"
                      >
                        権限
                      </Box>
                      <Box
                        color="#fff"
                        backgroundColor="#007BC2"
                        flexBasis="24px"
                        pl="76px"
                        pr="16px"
                        flexGrow={0}
                        flexShrink={0}
                        fontWeight={500}
                        borderBottom="1px solid #C0C0C0"
                        pt="8px"
                        pb="7px"
                        fontSize="14px"
                        lineHeight={1.7142857143}
                        display="block"
                      />
                    </Flex>
                  </Box>
                  <Box overflowY="auto">
                    {displayUsers.map((user) => {
                      return (
                        <Flex key={user.name}>
                          <Box
                            color="#333"
                            fontSize="14px"
                            lineHeight={1.7142857143}
                            backgroundColor="#fff"
                            borderBottom="1px solid #C0C0C0"
                            pt="16px"
                            pb="15px"
                            flexShrink={0}
                            flexGrow={0}
                            flexBasis="160px"
                            pl="16px"
                            pr="12px"
                          >
                            {user.name}
                          </Box>
                          <Box
                            color="#333"
                            fontSize="14px"
                            lineHeight={1.7142857143}
                            backgroundColor="#fff"
                            borderBottom="1px solid #C0C0C0"
                            pt="16px"
                            pb="15px"
                            flexShrink={0}
                            flexGrow={0}
                            flexBasis="200px"
                            pl="12px"
                            pr="12px"
                          >
                            {user.email}
                          </Box>
                          <Box
                            color="#333"
                            fontSize="14px"
                            lineHeight={1.7142857143}
                            flex="1 1 auto"
                            backgroundColor="#fff"
                            borderBottom="1px solid #C0C0C0"
                            pt="16px"
                            pb="15px"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            width="428px"
                            pl="12px"
                            pr="12px"
                          >
                            {user.comment}
                          </Box>
                          <Box
                            color="#333"
                            fontSize="14px"
                            lineHeight={1.7142857143}
                            backgroundColor="#fff"
                            borderBottom="1px solid #C0C0C0"
                            pt="16px"
                            pb="15px"
                            flexGrow={0}
                            flexShrink={0}
                            flexBasis="120px"
                            pl="12px"
                            pr="20px"
                          >
                            <Box
                              pr="8px"
                              backgroundColor="#A6DDFF"
                              fontSize="14px"
                              fontWeight={400}
                              lineHeight={1.7142857143}
                              borderRadius="24px"
                              boxSizing="border-box"
                              width="120px"
                              height="24px"
                              position="relative"
                              alignItems="center"
                              textAlign="center"
                            >
                              MEMBER
                            </Box>
                          </Box>
                          <Box
                            color="#333"
                            fontSize="14px"
                            lineHeight={1.7142857143}
                            pr="16px"
                            backgroundColor="#fff"
                            borderBottom="1px solid #C0C0C0"
                            pt="16px"
                            pb="15px"
                            flexGrow={0}
                            flexShrink={0}
                            flexBasis="24px"
                            pl="20px"
                          >
                            <Box
                              onClick={() => handleOpenConfirmModal(user.id)}
                            >
                              <MdAddCircle
                                size="24px"
                                color="#3C8FCB"
                                cursor="pointer"
                              />
                            </Box>
                          </Box>
                        </Flex>
                      )
                    })}
                  </Box>
                </Box>
              </Box>
            </Flex>
          </ModalBody>
          <ModalCloseButton right={3} left="auto" size="lg" color="#fff" />
        </ModalContent>
      </Modal>
      <ConfirmModal
        isOpen={isOpenConfirmModal}
        onClose={handleCloseConfirmModal}
        handleAddUser={HandleAddUser}
      />
    </>
  )
}
