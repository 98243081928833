import React, { useMemo } from 'react'
import { useNavigate } from '@reach/router'
import dayjs from 'dayjs'
import {
  Box,
  Center,
  Stack,
  VStack,
  HStack,
  Image,
  Text,
  Spacer,
  useColorModeValue,
} from '@chakra-ui/react'
import { CalendarIcon, RepeatClockIcon } from '@chakra-ui/icons'
import truncate from 'lodash/truncate'
import type { Playlist } from 'types/generated/graphql'

export interface QuizPlaylistCardProps {
  playlist: Playlist
  isEditableQuiz: boolean
}

// クイズ一覧ページで使うクイズカードコンポーネント
export const PlaylistCard: React.FC<QuizPlaylistCardProps> = ({
  playlist,
  isEditableQuiz,
}) => {
  const navigate = useNavigate()
  const publishedColor = useColorModeValue('primaryLight', 'secondaryLight')
  const unpublishedColor = useColorModeValue('primaryDark', 'secondaryDark')
  const listDesignLabel = playlist.design

  const currentDesign = useMemo(() => {
    switch (listDesignLabel) {
      case 'SIMPLE':
        return 'シンプル'
      case 'SERIOUS':
        return 'シリアス'
      case 'ORIGINAL':
        return 'オリジナル'
      default:
        return '未選択'
    }
  }, [listDesignLabel])

  return (
    <Box
      overflow="hidden"
      bg="white"
      _hover={{ boxShadow: '2xl', bg: 'rgba(0,0,0,0.2)' }}
      onClick={() =>
        isEditableQuiz
          ? navigate(`/quizplaylist/${playlist.id}/check`)
          : navigate(`/quizplaylist/${playlist.id}/play`)
      }
    >
      {playlist.published ? (
        <Center w="100%" bg={publishedColor}>
          <Text fontWeight="bold">公開中</Text>
        </Center>
      ) : (
        <Center w="100%" bg={unpublishedColor}>
          <Text fontWeight="bold">非公開</Text>
        </Center>
      )}
      {playlist.imageUrl ? (
        <Image
          w="100%"
          h="150px"
          objectFit="cover"
          src={playlist.imageUrl}
          fallbackSrc={'images/quiz_bg--card.png'}
          alt="クイズプレイリスト画像"
        />
      ) : (
        <Center w="100%" h="150px">
          <Text>no image</Text>
        </Center>
      )}
      <Stack p="2">
        <HStack w="100%">
          <Text fontSize="sm" mr={2}>
            <CalendarIcon boxSize="16px" mr={1} mb={1} />
            {dayjs(playlist.createdAt).format('YYYY-MM-DD')}
          </Text>
          <Text fontSize="sm" mr={2}>
            <RepeatClockIcon boxSize="16px" mr={1} mb={1} />
            {dayjs(playlist.updatedAt).format('YYYY-MM-DD')}
          </Text>
        </HStack>
        <Text
          mt={1}
          display="block"
          fontSize="lg"
          lineHeight="normal"
          fontWeight="semibold"
        >
          {playlist.name}
        </Text>
        <Text mt={2}>
          {playlist.description
            ? truncate(playlist.description, {
                length: 90,
                omission: '…',
              })
            : '説明文なし'}
        </Text>
        <Text fontSize="md" mt={2} fontWeight="bold">
          デザイン：{currentDesign}
        </Text>
        <Spacer />
        <VStack w="100%">
          <Text fontSize="2xl" fontWeight="bold">
            全{playlist.quizzes.length}問
          </Text>
          <Text fontSize="sm" as="sub" pb="1">
            うちクイズ
          </Text>
          <HStack>
            <Text fontSize="sm" fontWeight="bold">
              公開
              {playlist.quizzes.filter(({ published }) => published).length}問
            </Text>
            <Text fontSize="sm" fontWeight="bold">
              非公開
              {playlist.quizzes.filter(({ published }) => !published).length}問
            </Text>
          </HStack>
        </VStack>
      </Stack>
    </Box>
  )
}
