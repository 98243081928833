import React from 'react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Button,
  Box,
} from '@chakra-ui/react'
import { navigate } from '@reach/router'
import { ToolButtons } from 'v2components/Common/ToolButtons'

interface Props {
  modalBody: string
  isOpenCompletedModal: boolean
  handleCloseCompletedModal: () => void
  itemId?: string
  contentType: 'quiz' | 'quizlist' | 'enquete'
  handleOpenPreviewModal?: (itemId: string) => void
}

export const CompletedModal: React.FC<Props> = ({
  modalBody,
  isOpenCompletedModal,
  handleCloseCompletedModal,
  itemId,
  contentType,
  handleOpenPreviewModal,
}) => {
  let naviPath = ''
  if (contentType === 'quiz') {
    naviPath = 'quiz'
  } else if (contentType === 'quizlist') {
    naviPath = 'quizlist'
  } else if (contentType === 'enquete') {
    naviPath = 'enquetes'
  }

  return (
    <Modal
      isOpen={isOpenCompletedModal}
      onClose={handleCloseCompletedModal}
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent w="600px" maxW="auto">
        <ModalCloseButton />
        <ModalBody
          textAlign="center"
          pt="60px"
          pb="40px"
          fontSize="20"
          fontWeight="500"
        >
          {modalBody}
        </ModalBody>
        {itemId && handleOpenPreviewModal && (
          <Box mb="40px">
            <ToolButtons
              itemId={itemId}
              contentType={contentType}
              buttonType="comp-modal"
              onCloseCm={handleCloseCompletedModal}
              handleOpenPreviewModal={() => {
                handleOpenPreviewModal(itemId)
              }}
            />
          </Box>
        )}
        <ModalFooter pt="0" pb="40px" justifyContent="center">
          <Button
            bg="buttonPrimary"
            width="160px"
            height="40px"
            borderRadius="40px"
            color="#fff"
            fontWeight="500"
            onClick={() => {
              navigate(`/v2/${naviPath}`)
            }}
            _hover={{
              bg: 'buttonPrimaryHover',
            }}
          >
            一覧へ戻る
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
