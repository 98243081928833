import React from 'react'
import { Box } from '@chakra-ui/react'

export const EmbedLayoutOpenArea: React.FC = ({ children }) => {
  return (
    <Box h="100vh" maxW="92%" m="auto">
      <Box borderRadius="16px" boxShadow="0 0 0 1px #ccc">
        {children}
      </Box>
    </Box>
  )
}
