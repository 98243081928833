import { useMemo } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Center, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { GROUP, GroupData, GroupVars } from 'graphql/apollo/query/group'
import { useUserProfileContext } from 'state'
import { Layout } from 'v2components/Common/Layout'
import { SettingLayout } from 'v2components/Common/SettingLayout'
import { GroupForm } from 'v2components/EditGroup/GroupForm'
import { isOwnerUser } from 'utils/getGroupRole'

type Props = RouteComponentProps<{
  groupId: string
}>

export const EditGroup: React.FC<Props> = () => {
  const { userProfile, currentGroup } = useUserProfileContext()
  const isEditableGroup = useMemo(() => {
    // 選択グループがあり、選択グループのオーナーであるとき
    if (!currentGroup) return false
    if (!userProfile) return false
    if (!isOwnerUser(userProfile.user.id, currentGroup)) return false
    return true
  }, [currentGroup, userProfile])
  const groupId = currentGroup ? currentGroup.id : ''
  const {
    loading: groupLoading,
    error: groupError,
    data: groupData,
  } = useQuery<GroupData, GroupVars>(GROUP, {
    variables: {
      id: groupId,
    },
    skip: !currentGroup,
  })

  if (!userProfile || groupLoading)
    return (
      <Layout pageTitle="グループ">
        <SettingLayout>
          <Center h="100%">
            <Text fontWeight={600} fontSize={20}>
              Loading
            </Text>
          </Center>
        </SettingLayout>
      </Layout>
    )
  if (groupError)
    return (
      <Layout pageTitle="グループ">
        <div>グループデータが取得できません。</div>
      </Layout>
    )
  if (groupData === undefined)
    return (
      <Layout pageTitle="グループ">
        <div>現在グループ未設定状態です。</div>
      </Layout>
    )
  if (!isEditableGroup)
    return (
      <Layout pageTitle="グループ">
        <SettingLayout>
          <Center h="100%">
            <Text fontWeight={600} fontSize={20}>
              グループの編集権限がありません。
            </Text>
          </Center>
        </SettingLayout>
      </Layout>
    )
  if (!currentGroup || !userProfile) {
    return (
      <Layout pageTitle="NotFound Seledted Group">
        <SettingLayout>グループが選択されていません。</SettingLayout>
      </Layout>
    )
  }
  return (
    <Layout pageTitle="グループ設定">
      <SettingLayout>
        <GroupForm group={currentGroup} />
      </SettingLayout>
    </Layout>
  )
}
