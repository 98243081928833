import React from 'react'
import { Text, Flex, IconButton, HStack, Tooltip } from '@chakra-ui/react'
import {
  MdInfoOutline,
  MdOutlineViewList,
  MdDescription,
  MdViewList,
  MdGridView,
} from 'react-icons/md'
import { QuizIcon } from 'assets/icons'

interface Props {
  contentTypeTitle: string
  contentType: 'enquete' | 'quiz' | 'quizlist'
  isListPage: boolean
  closeInfoFlag?: boolean
  handleClickToggleInfo?: (flg: boolean) => void
  isListView?: boolean
  handleClickSetListView?: (flg: boolean) => void
}

export const ContentsHeader: React.FC<Props> = ({
  contentTypeTitle,
  contentType,
  isListPage,
  closeInfoFlag,
  handleClickToggleInfo,
  isListView,
  handleClickSetListView,
}) => {
  return (
    <>
      <Flex align="flex-start" justify="space-between" marginBottom={'1rem'}>
        <Text
          as="h1"
          fontSize="1.5rem"
          color="#2B5080"
          fontWeight="500"
          letterSpacing="0.04em"
          display="inline-flex"
          lineHeight="1"
          alignItems="center"
        >
          {contentType === 'enquete' && (
            <MdDescription color="#2B5080" size="32px" />
          )}
          {contentType === 'quiz' && ( // 後で変更
            <QuizIcon h="32px" w="32px" fill="#2B5080" />
          )}
          {contentType === 'quizlist' && (
            <MdViewList color="#2B5080" size="32px" />
          )}
          <Text display="inline-block" ml="8px" fontWeight="bold">
            {contentTypeTitle}
          </Text>
        </Text>
        {isListPage && (
          <HStack gap="0.5rem">
            <Tooltip
              label={isListView ? 'グリッド表示にする' : 'リスト表示にする'}
              placement="top"
              bgColor="#CCEAFC"
              color="#222"
              borderRadius="4px"
              fontSize="11px"
              hasArrow
            >
              <IconButton
                isRound={true}
                variant="solid"
                bg="none"
                aria-label="Done"
                w="40px"
                icon={
                  isListView ? (
                    <MdGridView size="24px" color="#222" />
                  ) : (
                    <MdOutlineViewList size="24px" color="#222" />
                  )
                }
                _hover={{
                  bgColor: '#C4C7CC',
                }}
                onClick={() => {
                  handleClickSetListView?.(!isListView)
                }}
              />
            </Tooltip>
            <Tooltip
              label="右メニューを表示する"
              placement="top"
              bgColor="#CCEAFC"
              color="#222"
              borderRadius="4px"
              fontSize="11px"
              hasArrow
            >
              <IconButton
                isRound={true}
                variant="solid"
                bg={'none'}
                aria-label="Done"
                w="40px"
                icon={<MdInfoOutline size="24px" color="#222" />}
                onClick={() => {
                  handleClickToggleInfo?.(!closeInfoFlag)
                }}
                _hover={{
                  bg: '#C4C7CC',
                }}
              />
            </Tooltip>
          </HStack>
        )}
      </Flex>
    </>
  )
}
