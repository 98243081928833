import { gql } from '@apollo/client'
import { Group, CreateGroupInput } from 'types/generated/graphql'

export type CreateGroupData = {
  createGroup: Group
}

export type CreateGroupVars = {
  input: CreateGroupInput
}

export const CREATE_GROUP = gql`
  mutation CreateGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      id
      name
      description
      icon
      members {
        user {
          id
          name
          email
        }
        role
      }
    }
  }
`
