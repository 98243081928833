import { gql } from '@apollo/client'
import { Playlist, CreatePlaylistInput } from 'types/generated/graphql'

export type CreatePlaylistData = {
  createPlaylist: Playlist
}

export type CreatePlaylistVars = {
  input: CreatePlaylistInput
}

export const CREATE_PLAYLIST = gql`
  mutation CreatePlaylist($input: CreatePlaylistInput!) {
    createPlaylist(input: $input) {
      id
      name
      quizzes {
        id
        title
        published
      }
      design
    }
  }
`
