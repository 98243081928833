import React from 'react'
import { css } from '@emotion/react'
import { VStack, Text, Button, Box, Image, Heading } from '@chakra-ui/react'
import type { Playlist, QuizDesignType } from 'types/generated/graphql'

const ImageWrapper = css`
  padding-top: 100%;
  position: relative;
  height: 100%;
`
const ImageBox = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #f3b0ae;
`

type Props = {
  isLoading: boolean
  playlist?: Playlist
  quizzesCount: number
  design?: QuizDesignType
  onStart: () => void
}

export const Start: React.FC<Props> = ({
  isLoading,
  playlist,
  quizzesCount,
  design,
  onStart,
}) => {
  return (
    <VStack w="100%">
      <Box w={{ base: '100%', sm: '60%', lg: '50%' }} mb="0.5rem">
        <Box css={ImageWrapper}>
          <Image
            css={ImageBox}
            src={playlist?.imageUrl || '/images/whiteImage.png'}
            alt="リスト画像"
          />
        </Box>
      </Box>
      <Heading fontSize="xl" mb="1rem" color={`${design}.common.mainText`}>
        {playlist?.name}
      </Heading>
      <Text fontSize="lg" mb="1rem" color={`${design}.common.mainText`}>
        {playlist?.description}
      </Text>

      {!isLoading && quizzesCount && (
        <Heading fontSize="xl" color={`${design}.common.mainText`}>
          全{quizzesCount}問
        </Heading>
      )}

      <Button
        isLoading={isLoading}
        _hover={{
          color: `${design}.start.button.afterLabel`,
          borderColor: `${design}.start.button.afterBorder`,
          bg: `${design}.start.button.afterBackground`,
        }}
        border={design === 'SIMPLE' ? '1px' : ''}
        borderColor={`${design}.start.button.borderColor`}
        color={`${design}.start.button.label`}
        bg={`${design}.start.button.background`}
        w="200px"
        mb={3}
        onClick={onStart}
      >
        スタート
      </Button>
    </VStack>
  )
}
