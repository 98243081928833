import { useMutation } from '@apollo/client'
import {
  UpdateMemberData,
  UpdateMemberVar,
  UPDATE_MEMBER,
} from 'graphql/apollo/mutation/group/updateMember'
import { USER } from 'graphql/apollo/query/user'
import { GROUP } from 'graphql/apollo/query/group'

import { User, Group, Role } from 'types/generated/graphql'

export const useUpdateGroupMember = () => {
  const [updateMember, { loading }] = useMutation<
    UpdateMemberData,
    UpdateMemberVar
  >(UPDATE_MEMBER)
  const handleUpdateGroupMember = async (
    group: Group,
    user: User,
    role: Role
  ) => {
    const variables = {
      groupId: group.id,
      userId: user.id,
      role: role!,
    }
    await updateMember({
      variables: variables,
      refetchQueries: [USER, GROUP],
    })
  }

  return { handleUpdateGroupMember, loading }
}
