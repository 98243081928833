import React, { useState, useCallback, useMemo } from 'react'
import { Formik, Form, Field, FieldProps } from 'formik'
import {
  Container,
  Box,
  Center,
  Stack,
  FormControl,
  Checkbox,
  Button,
  IconButton,
  Text,
  HStack,
  Flex,
} from '@chakra-ui/react'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import { Quiz, Stats } from 'types/generated/graphql'
import {
  quizFormSchema,
  QuizFormValues,
} from 'services/validators/QuizFormSchema'
import { QuizImageForm } from './QuizImageForm'
import { FormInput } from 'v2components/Common/InputPageComponent/FormInput'
import { FormTextarea } from 'v2components/Common/InputPageComponent/FormTextarea'
import { navigate } from '@reach/router'
import { ContentsHeader } from 'v2components/Common/ContentsHeader'
import { EditPreviewLayout } from 'v2components/Common/EditPreviewLayout'
import { LivePreviewQuiz } from 'v2components/Common/LivePreview/LivePreviewQuiz'

type Props = {
  initialValues?: Quiz
  quizStats?: Stats
  submitButtonLabel: string
  headerTitle: string
  imageUrl?: string
  onSubmit: (values: QuizFormValues) => void
}

export const QuizForm: React.FC<Props> = ({
  initialValues,
  quizStats,
  submitButtonLabel,
  headerTitle,
  onSubmit,
}) => {
  const getStatLabel = (index: number) => {
    if (!quizStats?.stats[index]) {
      return ''
    }
    return `　回答数${quizStats?.stats[index].count}件(${quizStats?.stats[index].percent})`
  }
  const [wrongs, setWrongs] = useState(() => {
    if (initialValues === undefined) {
      return [{ name: 'wrongs.0', label: '不正解1', placeholder: '不正解1' }]
    }
    return initialValues.content.wrongs.map((_, i) => ({
      name: `wrongs.${i}`,
      label: `不正解${i + 1}${getStatLabel(i + 1)}`,
      placeholder: `不正解${i + 1}`,
    }))
  })

  const delWrong = useCallback(
    (values: QuizFormValues) => {
      wrongs.pop()
      setWrongs([...wrongs])
      values.wrongs.pop()
    },
    [wrongs, setWrongs]
  )

  const addWrong = useCallback(
    (values: QuizFormValues) => {
      setWrongs([
        ...wrongs,
        {
          name: `wrongs.${wrongs.length}`,
          label: `不正解${wrongs.length + 1}`,
          placeholder: `不正解${wrongs.length + 1}`,
        },
      ])
      values.wrongs.push('')
    },
    [wrongs, setWrongs]
  )

  const content = useMemo<QuizFormValues>(() => {
    if (!initialValues) {
      return quizFormSchema.getDefault()
    }

    return {
      question: initialValues.content.question,
      correct: initialValues.content.correct,
      wrongs: initialValues.content.wrongs.filter(Boolean),
      description: initialValues.content.description ?? '',
      snsShareText: initialValues.snsInfo.shareText ?? '',
      design: initialValues.design ?? '',
      destinationLink: initialValues.snsInfo.destinationLink ?? '',
      destinationLinkText: initialValues.snsInfo.destinationLinkText ?? '',
      published: initialValues.published,
      imageUrl: initialValues.content.imageUrl ?? '',
      answerImageUrl: initialValues.content.answerImageUrl ?? '',
    }
  }, [initialValues])

  return (
    <Formik
      initialValues={quizFormSchema.cast(content, {
        stripUnknown: true,
      })}
      validationSchema={quizFormSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, isSubmitting, isValid, validateForm }) => {
        return (
          <>
            <Flex
              bgColor={'#fff'}
              flex={'1 1 auto'}
              flexDirection={'column'}
              p={'24px 20px'}
              borderRadius={'24px'}
            >
              <ContentsHeader
                contentTypeTitle={headerTitle}
                contentType="quiz"
                isListPage={false}
              />
              <Box overflowY="scroll">
                <Form>
                  <Container maxW="100%">
                    <Stack spacing="24px">
                      <QuizImageForm />

                      <FormTextarea name="question" label="問題文" isRequired />

                      <Stack spacing="16px">
                        {quizStats && (
                          <Text fontSize="sm" mb="1rem">
                            総回答数:{quizStats.total}件
                          </Text>
                        )}
                        <Box>
                          <Stack spacing="16px">
                            <FormInput
                              name="correct"
                              label={'正解' + getStatLabel(0)}
                              placeholder="正解"
                              isRequired
                            />
                            {wrongs.map((wrong) => (
                              <FormInput
                                key={wrong.name}
                                name={wrong.name}
                                label={wrong.label}
                                placeholder={wrong.placeholder}
                                isRequired
                              />
                            ))}
                          </Stack>
                          {touched.wrongs && errors.wrongs && (
                            <Text color="v2Attention" fontSize="12px" mt="8px">
                              {errors.wrongs}
                            </Text>
                          )}
                        </Box>
                        <Center>
                          {wrongs.length <= 2 && (
                            <IconButton
                              bgColor="v2Primary"
                              color="#fff"
                              _hover={{
                                bgColor: 'v2Primary',
                                opacity: '0.85',
                              }}
                              aria-label="不正解欄追加"
                              isRound
                              icon={<AddIcon />}
                              onClick={() => addWrong(values)}
                              mr={2}
                            />
                          )}
                          {wrongs.length >= 2 && (
                            <IconButton
                              bgColor="v2Attention"
                              color="#fff"
                              _hover={{
                                bgColor: 'v2Attention',
                                opacity: '0.85',
                              }}
                              aria-label="不正解欄削除"
                              isRound
                              icon={<MinusIcon />}
                              onClick={() => {
                                delWrong(values)
                                validateForm()
                              }}
                            />
                          )}
                        </Center>
                      </Stack>

                      <FormTextarea
                        name="description"
                        label="解説文(オプション)"
                      />

                      <FormInput name="snsShareText" label="SNS投稿文" />
                      <FormInput name="destinationLink" label="遷移先リンク" />
                      <FormInput
                        name="destinationLinkText"
                        label="遷移先リンクの説明"
                      />
                      <Stack
                        direction="column"
                        spacing="24px"
                        bgColor="#EAF7FF"
                        p="24px"
                        borderRadius="10px"
                        w="100%"
                      >
                        <Field name="published">
                          {({ form }: FieldProps) => (
                            <FormControl
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Checkbox
                                name="published"
                                id="published"
                                colorScheme="blue"
                                defaultChecked={values.published}
                                onChange={() => {
                                  form.setFieldValue(
                                    'published',
                                    !values.published
                                  )
                                }}
                              >
                                公開する
                              </Checkbox>
                            </FormControl>
                          )}
                        </Field>

                        <HStack
                          direction="row"
                          spacing={4}
                          justifyContent="center"
                        >
                          <Button
                            variant="basic-btn"
                            onClick={() => {
                              navigate('/v2/quiz')
                            }}
                          >
                            キャンセル
                          </Button>
                          <Button
                            type="submit"
                            variant="primary-btn"
                            isLoading={isSubmitting}
                            disabled={!isValid}
                          >
                            {submitButtonLabel}
                          </Button>
                        </HStack>
                      </Stack>
                    </Stack>
                  </Container>
                </Form>
              </Box>
            </Flex>
            <EditPreviewLayout>
              <LivePreviewQuiz />
            </EditPreviewLayout>
          </>
        )
      }}
    </Formik>
  )
}
