import { gql } from '@apollo/client'
import { Scalars } from 'types/generated/graphql'

export type DeleteMemberData = null

export type DeleteMemberVar = {
  groupId: Scalars['ID']
  userId: Scalars['ID']
}

export const DELETE_MEMBER = gql`
  mutation DeleteMember($groupId: ID!, $userId: ID!) {
    deleteMember(groupId: $groupId, userId: $userId)
  }
`
