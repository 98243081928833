import React from 'react'
import {
  Textarea,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react'
import { Field, FieldProps } from 'formik'

export interface FormTextareaProps {
  name: string
  label: string
  placeholder?: string
  isRequired?: boolean
}

export const FormTextarea: React.FC<FormTextareaProps> = ({
  name,
  label,
  placeholder = label,
  isRequired = false,
}) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        return (
          <FormControl
            isInvalid={!!(form.errors[name] && form.touched[name])}
            isRequired={isRequired}
          >
            <FormLabel htmlFor={name} fontSize="14px">
              {label}
            </FormLabel>
            <Textarea
              {...field}
              placeholder={placeholder}
              errorBorderColor="v2Attention"
            />
            <FormErrorMessage color="v2Attention" fontSize="12px" mt="8px">
              {form.errors[name]}
            </FormErrorMessage>
          </FormControl>
        )
      }}
    </Field>
  )
}
