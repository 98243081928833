import { boolean, object, string, array, TypeOf, mixed } from 'yup'
import { parseISO } from 'date-fns'
import { DisplayUnit } from 'types/generated/graphql'

export const EnqueteFormSchema = object({
  title: string()
    .required('タイトルは必須です')
    .max(
      40,
      'タイトルにセットできる文字数は40字が上限です。文字数を40字以内にしてください'
    )
    .default(''),
  question: string()
    .required('質問文は必須です')
    .max(
      120,
      '質問文にセットできる文字数は120字が上限です。文字数を120字以内にしてください'
    )
    .default(''),
  periodDisplayFlag: boolean()
    .test(
      'periodDispalyFlag',
      '回答期限を表示する場合は、回答終了日を設定してください',
      (value, { parent }) => {
        if (value && !parent.deadline) {
          return false
        }
        return true
      }
    )
    .default(false),
  numberOfVoteDisplayFlag: boolean().default(false),
  showResult: boolean().default(true),
  resultText: string().ensure().default(''),
  allowDuplicateAnswer: boolean().default(false),
  startDate: string().test(
    'start_date',
    '回答開始日は回答終了日より前の日時で設定してください',
    (value, { parent }) => {
      const startDate = value ? parseISO(value) : new Date()
      const deadline = parseISO(parent.deadline)

      if (
        value &&
        parent.deadline &&
        startDate.getTime() >= deadline.getTime()
      ) {
        return false
      }
      return true
    }
  ),
  deadline: string(),
  optionsCsv: array()
    .required()
    .test(
      'required',
      '未入力の選択肢があります。入力するか未入力の選択肢を削除してください。',
      (values) => {
        if (
          Array.isArray(values) &&
          values.some((optionsCsv) => optionsCsv === undefined)
        ) {
          return false
        }
        return true
      }
    )
    .test(
      'max_length',
      '選択肢にセットできる文字数は20字が上限です。文字数を20字以内にしてください',
      (values) =>
        Array.isArray(values) &&
        values.every((optionsCsv) => String(optionsCsv).length <= 20)
    )
    .test(
      'required_two',
      '選択肢は2つ以上必要です',
      (values) => Array.isArray(values) && values.length >= 2
    )
    .default(['']),
  imageUrl: string().ensure().default(''),
  published: boolean().default(false),
  displayUnit: mixed()
    .oneOf(
      [DisplayUnit.Percentage, DisplayUnit.Vote, DisplayUnit.Both],
      '「％」か「票数」か「％・票数」を選択してください'
    )
    .default(DisplayUnit.Percentage),
}).defined()

export type EnqueteFormValues = TypeOf<typeof EnqueteFormSchema>
