import React from 'react'
import { Box, VStack, Text, useColorModeValue } from '@chakra-ui/react'
import { FormUploadImage } from 'components/Common/InputPageComponent/FormUploadImage'

export const EnqueteImageForm: React.FC = () => {
  const bg = useColorModeValue('primary', 'secondary')
  return (
    <Box bg={bg} borderRadius={10} p={3} mb={5}>
      <VStack>
        <Text fontWeight="bold" fontSize="sm">
          アンケート画像
        </Text>
        <FormUploadImage name="imageUrl" />
      </VStack>
    </Box>
  )
}
