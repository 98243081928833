import { Link as ReachLink, useNavigate } from '@reach/router'
import {
  Box,
  Button,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerBody,
  DrawerContent,
  VStack,
  Center,
  Image,
  List,
  ListItem,
  Link,
  Spacer,
  useColorModeValue,
} from '@chakra-ui/react'
import { BiListPlus } from 'react-icons/bi'
import { useAuthContext } from 'state'
import { ChooseCreateContents } from './ChooseCreateContents'
import { useUserProfileContext } from 'state'
import logo from 'assets/images/quzilla_logo_1c_black.png'

export interface Props {
  onClose: () => void
  isOpen: boolean
  variant: string
}

const SidebarContent = ({ onClick }: { onClick: () => void }) => {
  const auth = useAuthContext()
  const { currentGroup } = useUserProfileContext()
  const navigate = useNavigate()
  const button2Color = useColorModeValue('button2Light', 'button2Dark')

  return (
    <VStack h="100%" pt={5}>
      <Center>
        <Image src={logo} fallbackSrc={logo} w="200px" h="50px" alt="logo" />
      </Center>
      <List w="100%">
        <ListItem p={3}>
          <Center>
            <Link as={ReachLink} onClick={onClick} to="/">
              クイズ
            </Link>
          </Center>
        </ListItem>
        <ListItem p={3}>
          <Center>
            <Link as={ReachLink} onClick={onClick} to="/quizplaylist">
              クイズリスト
            </Link>
          </Center>
        </ListItem>
        <ListItem p={3}>
          <Center>
            <Link as={ReachLink} onClick={onClick} to="/enquetes">
              アンケート
            </Link>
          </Center>
        </ListItem>
        <ListItem p={3}>
          <Center>
            <Link as={ReachLink} onClick={onClick} to={`/mypage`}>
              マイページ
            </Link>
          </Center>
        </ListItem>
        {currentGroup && (
          <ListItem p={3}>
            <Center>
              <Link
                as={ReachLink}
                onClick={onClick}
                to={`/group/${currentGroup.id}`}
              >
                グループ
              </Link>
            </Center>
          </ListItem>
        )}
        <ListItem pt={30}>
          <Center>
            <ChooseCreateContents />
          </Center>
        </ListItem>
        <ListItem pt={3} pb={30}>
          <Center>
            <IconButton
              color="#382121"
              colorScheme="red"
              aria-label="プレイリスト作成ボタン"
              size="lg"
              fontSize="4xl"
              icon={<BiListPlus />}
              backgroundColor={button2Color}
              onClick={() => navigate('/quizplaylist/create')}
            />
          </Center>
        </ListItem>
      </List>
      <Spacer />
      <Button
        backgroundColor={button2Color}
        size="xs"
        w="100%"
        bottom="0"
        mb={3}
        onClick={auth.signOut}
      >
        サインアウト
      </Button>
    </VStack>
  )
}

export const Sidebar: React.FC<Props> = ({
  isOpen,
  variant,
  onClose,
}: Props) => {
  const bg = useColorModeValue('primary', 'secondary')
  const button2Color = useColorModeValue('button2Light', 'button2Dark')
  return variant === 'sidebar' ? (
    <Box position="fixed" left={0} p={5} w="200px" top={0} h="100%" bg={bg}>
      <SidebarContent onClick={onClose} />
    </Box>
  ) : (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent backgroundColor={bg}>
          <DrawerCloseButton backgroundColor={button2Color} />
          <DrawerBody>
            <SidebarContent onClick={onClose} />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}
