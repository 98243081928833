import { gql } from '@apollo/client'
import { User, UpdateUserInput } from 'types/generated/graphql'

export type UpdateUserData = {
  createUser: User
}

export type UpdateUserVars = {
  input: UpdateUserInput
}

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      name
      aliasId
      comment
      icon
      coverImage
    }
  }
`
