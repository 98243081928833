import React, { useMemo } from 'react'
import { Box } from '@chakra-ui/react'
import { RouteComponentProps } from '@reach/router'
import { useQuery } from '@apollo/client'

import { EditRoot } from 'components/EditEnquete/EditRoot'
import { Error } from 'components/Common/Error'
import { Layout } from 'components/Common/Layout'
import { Loading } from 'components/Common/Loading'
import { NoData } from 'components/Common/NoData'
import { Unauthorized } from 'components/Common/Unauthorized'
import { ENQUETE, EnqueteData, EnqueteVars } from 'graphql/apollo/query/enquete'
import { useUserProfileContext } from 'state'
import { isOwned } from 'utils/isOwned'

type Props = RouteComponentProps<{
  enqueteId: string
}>

export const EditEnquete: React.FC<Props> = (props) => {
  const enqueteId = props.enqueteId!
  const { userProfile, currentGroup } = useUserProfileContext()
  const { loading, error, data } = useQuery<EnqueteData, EnqueteVars>(ENQUETE, {
    variables: {
      id: enqueteId,
    },
  })

  const enquete = data?.enquete
  const isOwnedEnquete = useMemo(() => {
    return isOwned(enquete, currentGroup, userProfile)
  }, [enquete, currentGroup, userProfile])

  if (loading)
    return (
      <Layout pageTitle="アンケート編集">
        <Loading />
      </Layout>
    )
  if (error)
    return (
      <Layout pageTitle="アンケート編集">
        <Error />
      </Layout>
    )
  if (!data || !enquete || !props.enqueteId)
    return (
      <Layout pageTitle="アンケート編集">
        <NoData />
      </Layout>
    )

  return (
    <Layout pageTitle="アンケート編集">
      <Box w="100%" h="100%" p={3}>
        {isOwnedEnquete ? <EditRoot enquete={enquete} /> : <Unauthorized />}
      </Box>
    </Layout>
  )
}
