import React, { useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Grid,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { Group } from 'types/generated/graphql'
import { UserList } from './UsersList'
import { useEditGroup } from 'v2hooks/useEditGroup'
import { GroupFormValues, groupSchema } from 'services/validators/groupSchema'
import { MdGroup } from 'react-icons/md'
import { FormInput } from 'v2components/Common/InputPageComponents/FormInput'
import { FormTextarea } from 'v2components/Common/InputPageComponents/FormTextarea'
import { InfoModal } from 'v2components/Modal/InfoModal'
import { AlertModal } from 'v2components/Modal/AlertModal'
import { GroupImageForm } from './GroupImageForm'

type Props = {
  group: Group
}

export const GroupForm: React.FC<Props> = ({ group }) => {
  const {
    isOpen: isInfoModalOpen,
    onOpen: onInfoModalOpen,
    onClose: onInfoModalClose,
  } = useDisclosure()
  const {
    isOpen: isAlertModalOpen,
    onOpen: onAlertModalOpen,
    onClose: onAlertModalClose,
  } = useDisclosure()
  const members = group.members
  const { handleEditGroup } = useEditGroup({ group: group })
  const [isUpdating, setIsUpdating] = useState(false)
  const initialValues = {
    name: group.name,
    icon: group.icon,
    description: group.description,
  }
  return (
    <Formik
      initialValues={groupSchema.cast(initialValues, { stripUnknown: true })}
      validationSchema={groupSchema}
      onSubmit={async (values: GroupFormValues) => {
        try {
          setIsUpdating(true)
          await handleEditGroup(values)
          onInfoModalOpen()
        } catch (e) {
          console.error(e)
          onAlertModalOpen()
        } finally {
          setIsUpdating(false)
        }
      }}
    >
      {({ isValid }) => {
        return (
          <Form>
            <Flex flexDir="column" p="24px 20px">
              <Flex flexWrap="wrap" alignItems="flex-start" mb="16px">
                <MdGroup size="32px" color="#2B507F" />
                <Flex
                  color="#2B5080"
                  fontSize="24px"
                  fontWeight="500"
                  letterSpacing="0.04em"
                  display="inline-flex"
                  ml="8px"
                  alignItems="center"
                >
                  グループ設定
                </Flex>
              </Flex>
              <Flex overflowY="auto" flexDirection="column" maxH="99999rem">
                <Box display="block">
                  <Flex display="flex" gridGap="24px">
                    <GroupImageForm />
                    <Box flexShrink={1} flexGrow={1} flexBasis="539px">
                      <Box>
                        <FormInput
                          name="name"
                          label="グループネーム"
                          isRequired={true}
                        />
                      </Box>
                      <Box mt="20px">
                        <FormTextarea
                          name="description"
                          label="グループの説明"
                        />
                      </Box>
                    </Box>
                  </Flex>
                </Box>
                <Grid
                  display={['none', '-ms-grid', 'grid']}
                  justifyContent="center"
                  gap="0 14px"
                  gridTemplateColumns="repeat(auto-fit, 160px)"
                  marginTop="40px"
                >
                  <Button
                    color="#fff"
                    fontSize="14px"
                    fontWeight={500}
                    lineHeight={1.7142857143}
                    letterSpacing="0.08em"
                    textAlign="center"
                    backgroundColor="#0086D2"
                    borderRadius="20px"
                    cursor="pointer"
                    display={[
                      '-webkit-box',
                      '-webkit-flex',
                      '-ms-flexbox',
                      'flex',
                    ]}
                    justifyContent="center"
                    alignItems="center"
                    boxSizing="border-box"
                    padding="8px 16px"
                    type="submit"
                    _hover={{
                      bg: 'buttonPrimaryHover',
                    }}
                    disabled={!isValid}
                  >
                    {!isUpdating ? '更新する' : <Spinner />}
                  </Button>
                </Grid>
                <Box flexGrow={1} mt="32px">
                  <Flex
                    color="#222"
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="1.7242857143"
                    letterSpacing="0.04em"
                    alignItems="center"
                    mb="8px"
                  >
                    追加済みユーザー
                  </Flex>
                  <UserList members={members} currentGroup={group} />
                </Box>
              </Flex>
              <InfoModal
                isOpen={isInfoModalOpen}
                onClose={onInfoModalClose}
                modalBody="更新が完了しました"
              />
              <AlertModal
                isOpen={isAlertModalOpen}
                onClose={onAlertModalClose}
                modalBody="グループの更新に失敗しました"
              />
            </Flex>
          </Form>
        )
      }}
    </Formik>
  )
}
