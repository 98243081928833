import { gql } from '@apollo/client'
import { AnswerQuizInput } from 'types/generated/graphql'

export type AnswerQuizData = null

export type AnswerQuizVars = {
  input: AnswerQuizInput
}

export const ANSWER_QUIZ = gql`
  mutation AnswerQuiz($input: AnswerQuizInput!) {
    answerQuiz(input: $input)
  }
`
