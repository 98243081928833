import React from 'react'
import {
  Center,
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react'
import { User, Group } from 'types/generated/graphql'
import { useUpdateGroupMember } from 'hooks/useUpdateGroupMember'

interface Props {
  group: Group
  user: User
  modalBody: string
  isOpen: boolean
  onClose: () => void
}

export const RoleEditedModal: React.FC<Props> = ({
  group,
  user,
  modalBody,
  isOpen,
  onClose,
}) => {
  const { handleUpdateGroupMember } = useUpdateGroupMember()

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>　</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Center>
            <Text fontSize="lg" m={10}>
              {modalBody}
            </Text>
          </Center>
        </ModalBody>
        <ModalFooter>
          <Button
            size="md"
            backgroundColor="#F3B0AE"
            color="#4D3B3E"
            mr={3}
            onClick={async () => {
              try {
                await handleUpdateGroupMember(group, user, 'MEMBER')
                onClose()
              } catch (error) {
                throw new Error('ユーザーロール変更に失敗しました。')
              }
            }}
          >
            変更
          </Button>
          <Button
            size="md"
            backgroundColor="#F3B0AE"
            color="#4D3B3E"
            mr={3}
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
