import { createCtx } from './contextUtils'

export type SignInStatus = 'unconfirmed' | 'authenticated' | 'unauthenticated'

export type AuthContextType = {
  user: Record<string, any>
  signInStatus: SignInStatus
  signIn: () => void
  signOut: () => void
}

export const [useAuthContext, AuthProvider] = createCtx<AuthContextType>()
