import React, { useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Layout } from 'v2components/Common/Layout'
import { TopMainLayout } from 'v2components/Common/TopMainLayout'
import { InfoQuizzes } from 'v2components/InfoArea/InfoQuizzes'
import { ContentsList } from 'v2components/Common/ContentsList'
import { ContentsHeader } from 'v2components/Common/ContentsHeader'
import { useUserProfileContext } from 'state'
import { useQuery } from '@apollo/client'
import { QUIZZES, QuizzesData, QuizzesVars } from 'graphql/apollo/query/quizzes'
import { Loading } from 'v2components/Common/Loading'
import { Error } from 'components/Common/Error'

type Props = RouteComponentProps

export const Quizzes: React.FC<Props> = () => {
  const { currentGroup } = useUserProfileContext()
  const {
    loading: quizzesLoading,
    error: quizzesError,
    data: quizzesData,
  } = useQuery<QuizzesData, QuizzesVars>(QUIZZES, {
    variables: {
      filter: {
        ownerGroupId: currentGroup?.id,
      },
    },
  })
  const [activeItemId, setActiveItemId] = useState('')
  const [closeInfoFlag, setCloseInfoFlag] = useState(false)
  const [isListView, setIsListView] = useState(false)

  const setItemId = (itemId: string) => {
    setActiveItemId(itemId)
  }

  const handleClickToggleInfo = (flg: boolean) => {
    setCloseInfoFlag(flg)
  }

  const handleClickSetListView = (flg: boolean) => {
    setIsListView(flg)
  }

  return (
    <Layout pageTitle="クイズページ" setItemId={setItemId} showSearchBox={true}>
      <TopMainLayout>
        <ContentsHeader
          contentTypeTitle="クイズ"
          contentType="quiz"
          isListPage={true}
          closeInfoFlag={closeInfoFlag}
          handleClickToggleInfo={handleClickToggleInfo}
          isListView={isListView}
          handleClickSetListView={handleClickSetListView}
        />
        {(() => {
          if (quizzesLoading) return <Loading />
          if (quizzesError) return <Error />
          if (quizzesData?.quizzes)
            return (
              <ContentsList
                quizzes={quizzesData?.quizzes}
                isEditable={true}
                setItemId={setItemId}
                activeItemId={activeItemId}
                closeInfoFlag={closeInfoFlag}
                handleClickToggleInfo={handleClickToggleInfo}
                isListView={isListView}
              />
            )
        })()}
      </TopMainLayout>
      <InfoQuizzes
        activeItemId={activeItemId}
        closeInfoFlag={closeInfoFlag}
        handleClickToggleInfo={handleClickToggleInfo}
      />
    </Layout>
  )
}
