import React, { useCallback, useMemo, useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import { Box, Image, Text, VStack, Link, HStack } from '@chakra-ui/react'

import { Loading } from 'components/Common/Loading'
import { NoData } from 'components/Common/NoData'
import { Private } from 'components/Common/Private'

import { useQuery, useMutation } from '@apollo/client'
import { ENQUETE, EnqueteData, EnqueteVars } from 'graphql/apollo/query/enquete'
import {
  ENQUETE_STATS,
  EnqueteStatsData,
  EnqueteStatsVars,
} from 'graphql/apollo/query/enqueteStats'
import {
  ANSWER_ENQUETE,
  AnswerEnqueteData,
  AnswerEnqueteVars,
} from 'graphql/apollo/mutation/answer/answerEnquete'
import {
  getAnsweredEnquete,
  isAnsweredEnquete,
  saveAnsweredEnquete,
} from 'utils/answeredEnquete'
import { HorizontalLine } from '../components/AnswerEnquete/HorizontalLine'

type Props = RouteComponentProps<{
  enqueteId: string
  isPreview?: boolean
}>

export const AnswerEnquete: React.FC<Props> = ({
  enqueteId,
  isPreview = false,
}) => {
  const [answered, setAnswered] = useState<string>(
    useMemo(() => {
      if (!isPreview && enqueteId && isAnsweredEnquete(enqueteId)) {
        return getAnsweredEnquete(enqueteId)
      }
      return ''
    }, [isPreview, enqueteId])
  )
  const [isSkippedAnswer, setIsSkippedAnswer] = useState(false)
  const [answerEnquete] = useMutation<AnswerEnqueteData, AnswerEnqueteVars>(
    ANSWER_ENQUETE
  )
  const {
    loading: enqueteLoading,
    error: enqueteError,
    data: enqueteData,
  } = useQuery<EnqueteData, EnqueteVars>(ENQUETE, {
    variables: {
      id: enqueteId!,
    },
    context: {
      noAuthorizationHeader: !isPreview,
    },
  })
  const {
    loading: enqueteStatsLoading,
    data: enqueteStatsData,
    refetch,
  } = useQuery<EnqueteStatsData, EnqueteStatsVars>(ENQUETE_STATS, {
    variables: {
      enqueteId: enqueteId!,
    },
  })
  const handleSelectedChoice = useCallback(
    async (selectedChoice) => {
      if (!isPreview && enqueteId && !isAnsweredEnquete(enqueteId)) {
        await answerEnquete({
          variables: {
            input: {
              answer: selectedChoice,
              enqueteId: enqueteId,
            },
          },
        })
        saveAnsweredEnquete(enqueteId, selectedChoice)
        await refetch()
      }
      setAnswered(selectedChoice)
    },
    [answerEnquete, enqueteId, isPreview, refetch]
  )
  const enquete = enqueteData?.enquete
  const isExpired = useMemo(() => {
    if (!enquete?.deadline) {
      return false
    }
    return new Date() >= new Date(enquete.deadline)
  }, [enquete])
  const canAnswer = !isExpired && !answered && !isSkippedAnswer

  if (enqueteLoading || enqueteStatsLoading) return <Loading />
  if (enqueteError) navigate('/404NotFound')
  if (!enqueteData || !enquete || !enqueteStatsData) return <NoData />
  if (!isPreview && !enqueteData.enquete.published) {
    return <Private category="アンケート" />
  }
  return (
    <VStack w="100%" h="100%" p={5} borderWidth="1px" borderRadius="sm">
      <VStack w={{ base: '100%', md: '60%' }}>
        <Box w={{ base: '100%', md: '60%' }} mb="0.5rem">
          <Box h="100%" paddingTop="100%" position="relative">
            <Image
              w="100%"
              h="100%"
              position="absolute"
              top="0"
              objectFit="contain"
              bg="#f3b0ae"
              src={enquete.imageUrl || `/images/whiteImage.png`}
              alt={enquete.question}
            />
          </Box>
        </Box>

        <Box
          w={{ base: '100%', md: '60%' }}
          fontSize={{ base: 'md', sm: 'lg' }}
        >
          <Text mb="1rem" textAlign="center">
            {enquete.question}
          </Text>

          {enqueteStatsData.enqueteStats.stats.map((stats, idx) => (
            <HorizontalLine
              key={idx}
              enquete={enquete}
              stats={stats}
              onSelectedChoice={handleSelectedChoice}
              answered={answered}
              canAnswer={canAnswer}
            />
          ))}
          <HStack w="100%" h="20px" justify="right" pt={4}>
            <Text
              fontSize="sm"
              onClick={() => {
                setIsSkippedAnswer(true)
              }}
              textDecoration="underline"
              cursor="pointer"
              display={canAnswer ? 'block' : 'none'}
            >
              投票せずに結果を見る
            </Text>
            {!canAnswer && enquete.numberOfVoteDisplayFlag && (
              <Text textAlign="left" fontSize="sm">
                総投票数: {enqueteStatsData.enqueteStats.total}
              </Text>
            )}
          </HStack>
        </Box>
      </VStack>
      <Link
        onClick={() => {
          window.open('/terms', '_blank')
        }}
        pt="3"
        fontSize="xs"
        color="blackAlpha.700"
      >
        利用規約
      </Link>
    </VStack>
  )
}
