import { gql } from '@apollo/client'
import { Scalars } from 'types/generated/graphql'

export type AddMemberData = null

export type AddMemberVar = {
  groupId: Scalars['ID']
  userId: Scalars['ID']
}

export const ADD_MEMBER = gql`
  mutation AddMember($groupId: ID!, $userId: ID!) {
    addMember(groupId: $groupId, userId: $userId)
  }
`
