import React, { useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Box, Button, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import { groupSchema } from 'services/validators/groupSchema'
import { useUserProfileContext } from 'state'
import { Layout } from 'components/Common/Layout'
import { FormInput } from 'components/Common/InputPageComponent/FormInput'
import { useCreateGroup } from 'hooks/useCreateGroup'
import { GroupResultModal } from 'components/Modal/GroupResultModal'
import { Scalars } from 'types/generated/graphql'

type Props = RouteComponentProps

export const CreateGroup: React.FC<Props> = () => {
  const [modalBody, setModalBody] = useState<string>('')
  const [groupId, setGroupId] = useState<Scalars['ID']>('')
  const [isMutationSucceeded, setIsMutationSucceeded] = useState<boolean>(false)
  const { handleCurrentGroup } = useUserProfileContext()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const buttonNextColor = useColorModeValue('buttonNextLight', 'buttonNextDark')
  const { handleCreateGroup } = useCreateGroup()

  return (
    <Layout pageTitle="グループ作成">
      <Box w="100%" h="100%" p={3}>
        <GroupResultModal
          modalBody={modalBody}
          isOpen={isOpen}
          onClose={onClose}
          isMutationSucceeded={isMutationSucceeded}
          groupId={groupId}
        />
        <Formik
          initialValues={groupSchema.cast(groupSchema.getDefault(), {
            stripUnknown: true,
          })}
          validationSchema={groupSchema}
          onSubmit={async (values) => {
            try {
              const data = await handleCreateGroup(values)
              setIsMutationSucceeded(true)
              setModalBody(`グループ「${values.name}」を作成しました。`)
              handleCurrentGroup(data.data?.createGroup)
              setGroupId(data.data!.createGroup.id)
              onOpen()
            } catch (e) {
              setIsMutationSucceeded(false)
              setModalBody('登録に失敗しました。')
              onOpen()
            }
          }}
        >
          {({ values, errors, touched, isSubmitting, isValid }) => {
            const isUserName = values.name === ''
            const isRegisterStatus = isUserName || !isValid
            return (
              <Form>
                <FormInput
                  name="name"
                  label="グループネーム"
                  isRequired={true}
                />
                <Button
                  bg={buttonNextColor}
                  w="100%"
                  variant="outline"
                  aria-label="add group"
                  type="submit"
                  disabled={isRegisterStatus}
                  isLoading={isSubmitting}
                >
                  登録
                </Button>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </Layout>
  )
}
