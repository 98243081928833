import React from 'react'
import { Formik, Form } from 'formik'
import {
  playlistFormSchema,
  PlaylistFormValues,
} from 'services/validators/playlistFormSchema'
import { Box, Flex, Stack } from '@chakra-ui/react'
import type { Playlist, Quiz } from 'types/generated/graphql'
import { CommonSetting } from 'v2components/QuizPlaylistForm/CommonSetting'
import { SelectQuizzes } from 'v2components/QuizPlaylistForm/SelectQuizzes'
import { BorderSetting } from 'v2components/QuizPlaylistForm/BorderSetting'
import { SnsSetting } from 'v2components/QuizPlaylistForm/SnsSetting'
import { SaveButtons } from 'v2components/QuizPlaylistForm/SaveButtons'
import { LivePreviewQuizList } from 'v2components/Common/LivePreview/LivePreviewQuizList'
import { ContentsHeader } from 'v2components/Common/ContentsHeader'
import { EditPreviewLayout } from 'v2components/Common/EditPreviewLayout'

type Props = {
  initialValues?: Playlist
  submitButtonLabel: string
  headerTitle: string
  selectedQuizzes: Quiz[]
  unselectedQuizzes: Quiz[]
  selectedQuizIds: string[]
  setSelectedQuizIds: (quizIds: string[]) => void
  handleSubmit: (values: PlaylistFormValues) => Promise<void>
}

export const QuizPlaylistForm: React.FC<Props> = ({
  initialValues,
  submitButtonLabel,
  headerTitle,
  selectedQuizzes,
  unselectedQuizzes,
  selectedQuizIds,
  setSelectedQuizIds,
  handleSubmit,
}) => {
  return (
    <Formik
      initialValues={{
        ...playlistFormSchema.cast(initialValues, {
          stripUnknown: true,
        }),
        quizIds: initialValues?.quizzes.map(({ id }) => id) || ([] as string[]),
      }}
      onSubmit={handleSubmit}
      validationSchema={playlistFormSchema}
    >
      {({
        isValid,
        errors,
        isSubmitting,
        values,
        setFieldValue,
        validateForm,
      }) => (
        <>
          {(() => {
            values.quizIds = selectedQuizIds
          })()}
          <Flex
            maxW="60%"
            bgColor={'#fff'}
            flex={'1 1 auto'}
            flexDirection={'column'}
            p={'24px 20px'}
            borderRadius={'24px'}
          >
            <ContentsHeader
              contentTypeTitle={headerTitle}
              contentType="quizlist"
              isListPage={false}
            />
            <Box overflowY="scroll">
              <Form>
                <Stack spacing="24px">
                  <CommonSetting />
                  <SelectQuizzes
                    selectedQuizzes={selectedQuizzes}
                    unselectedQuizzes={unselectedQuizzes}
                    setSelectedQuizIds={setSelectedQuizIds}
                    validateForm={validateForm}
                    selectedQuizIds={selectedQuizIds}
                    errors={errors}
                  />
                  <BorderSetting
                    selectedQuizzes={selectedQuizzes}
                    passingScore={values.passingScore || 0}
                    onSlidePassingScore={(passingScore: number) =>
                      setFieldValue('passingScore', passingScore)
                    }
                  />
                  <SnsSetting />
                  <SaveButtons
                    submitButtonLabel={submitButtonLabel}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                  />
                </Stack>
              </Form>
            </Box>
          </Flex>
          <EditPreviewLayout>
            <LivePreviewQuizList selectedQuizzes={selectedQuizzes} />
          </EditPreviewLayout>
        </>
      )}
    </Formik>
  )
}
