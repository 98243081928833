import React from 'react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react'

interface Props {
  modalBody: string
  modalDescription?: string
  isOpen: boolean
  onClose: () => void
}

export const AlertModal: React.FC<Props> = ({
  modalBody,
  modalDescription,
  isOpen,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Text textAlign="center" pt="60px" pb="0px" fontSize="20">
            {modalBody}
          </Text>
          {modalDescription && (
            <Text
              textAlign="center"
              mt="12px"
              pb="0px"
              fontSize="14px"
              color="#666"
            >
              {modalDescription}
            </Text>
          )}
        </ModalBody>
        <ModalFooter pt="40px" pb="40px" justifyContent="center">
          <Button
            bg="buttonBasic"
            width="140px"
            height="40px"
            borderRadius="40px"
            onClick={onClose}
            _hover={{
              bg: 'buttonBasicHover',
            }}
          >
            閉じる
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
