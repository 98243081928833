import React from 'react'
import {
  Center,
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react'
import { User, Group } from 'types/generated/graphql'
import { useDeleteGroupMember } from 'hooks/useDeleteGroupMember'
import { useNavigate } from '@reach/router'
import { useUserProfileContext } from 'state'

interface Props {
  group: Group
  user: User
  modalBody: string
  isOpen: boolean
  onClose: () => void
}

export const DeleteMyselfModal: React.FC<Props> = ({
  group,
  user,
  modalBody,
  isOpen,
  onClose,
}) => {
  const { handleDeleteGroupMember } = useDeleteGroupMember()
  const navigate = useNavigate()
  const { handleCurrentGroup } = useUserProfileContext()

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>　</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Center>
            <Text fontSize="lg" m={10}>
              {modalBody}
            </Text>
          </Center>
        </ModalBody>
        <ModalFooter>
          <Button
            size="md"
            backgroundColor="#F3B0AE"
            color="#4D3B3E"
            mr={3}
            onClick={async () => {
              try {
                await handleDeleteGroupMember(group, user)
                onClose()
                handleCurrentGroup(undefined)
                navigate('/')
              } catch (error) {
                throw new Error('メンバーの削除に失敗しました。')
              }
            }}
          >
            削除
          </Button>
          <Button
            size="md"
            backgroundColor="#F3B0AE"
            color="#4D3B3E"
            mr={3}
            onClick={onClose}
          >
            キャンセル
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
