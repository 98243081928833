import React from 'react'
import { Box, HStack } from '@chakra-ui/react'
import {
  Enquete,
  StatsPerSelection,
  DisplayUnit,
} from 'types/generated/graphql'
import { css } from '@emotion/react'

interface Props {
  stats: StatsPerSelection
  enquete: Enquete
  onSelectedChoice: (
    selectedChoice: string,
    allowDuplicateAnswer: boolean
  ) => void
  canAnswer: boolean
  answered?: string
}

const animation = css`
  animation-name: graphAnime;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
  @keyframes graphAnime {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
`

export const EmbedHorizontalLine: React.FC<Props> = ({
  stats,
  enquete,
  onSelectedChoice,
  canAnswer,
  answered,
}) => {
  const percent = canAnswer ? `0%` : `${stats.percent}`

  return (
    <Box
      w="100%"
      p=".25em .5em"
      cursor={canAnswer ? 'pointer' : 'default'}
      position="relative"
      zIndex="0"
      border="1px"
      borderColor="white"
      borderRadius="8px"
      bg="rgba(0, 0, 0, 0.5)"
      overflow="hidden"
      fontWeight="700"
      onClick={() => {
        if (canAnswer) {
          onSelectedChoice(stats.selection, enquete.allowDuplicateAnswer)
        }
      }}
    >
      <Box
        css={!canAnswer && animation}
        top="0"
        left="0"
        h="100%"
        position="absolute"
        zIndex="-1"
        _before={{
          content: '""',
          position: 'absolute',
          h: '100%',
          w: percent,
          bg:
            answered === stats.selection
              ? '#ffaf00'
              : 'rgba(255, 255, 255, 0.4)',
        }}
      ></Box>
      <HStack
        justifyContent="space-between"
        fontSize={{ base: '15px', sm: '18px' }}
        letterSpacing=".05em"
      >
        <Box>{stats.selection}</Box>
        <Box>
          {!canAnswer &&
            (enquete.displayUnit === DisplayUnit.Percentage
              ? stats.percent
              : enquete.displayUnit === DisplayUnit.Vote
              ? `${stats.count}票`
              : `${stats.percent}・${stats.count}票`)}
        </Box>
      </HStack>
    </Box>
  )
}
