import { useState, useEffect, useMemo } from 'react'
import Auth, { CognitoUser } from '@aws-amplify/auth'
import { SignInStatus } from 'state/auth'
import { ICredentials } from '@aws-amplify/core'

const authConfig = {
  customProvider: process.env.REACT_APP_AUTH_CUSTOM_PROVIDER,
  options: {
    userPoolId: process.env.REACT_APP_AUTH_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USERPOOL_WEBCLIENTID,
    identityPoolId: process.env.REACT_APP_AUTH_IDENTITYPOOL_ID,
    oauth: {
      domain: process.env.REACT_APP_AUTH_OAUTH_DOMAIN,
      scope: process.env.REACT_APP_AUTH_OAUTH_SCOPE.split(','),
      redirectSignIn: process.env.REACT_APP_AUTH_OAUTH_REDIRECT_SIGNIN,
      redirectSignOut: process.env.REACT_APP_AUTH_OAUTH_REDIRECT_SIGNOUT,
      region: process.env.REACT_APP_AUTH_OAUTH_REGION,
      responseType: process.env.REACT_APP_AUTH_OAUTH_RESPONSETYPE,
    },
  },
} as const

export const useAuth = () => {
  const [state, setState] = useState<{
    user: CognitoUser | any
    jwtToken: string
    credentials?: ICredentials
    signInStatus: SignInStatus
  }>({
    user: {},
    jwtToken: '',
    signInStatus: 'unconfirmed',
  })

  const auth = useMemo(() => {
    Auth.configure(authConfig.options)
    return Auth
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const cognitoUser: CognitoUser = await auth.currentAuthenticatedUser()
        const idToken = cognitoUser.getSignInUserSession()?.getIdToken()
        const credentials = await auth.currentCredentials()
        setState({
          user: idToken?.payload,
          jwtToken: idToken?.getJwtToken() ?? '',
          credentials,
          signInStatus: 'authenticated',
        })
      } catch (e) {
        setState({
          user: {},
          jwtToken: '',
          signInStatus: 'unauthenticated',
        })
        console.error(e)
      }
    })()
  }, [auth])

  const signIn = () =>
    auth.federatedSignIn({ customProvider: authConfig.customProvider })

  const signOut = () => auth.signOut()
  return {
    ...state,
    signIn,
    signOut,
  }
}
