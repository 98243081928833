import React from 'react'
import {
  VStack,
  Heading,
  Text,
  Stack,
  Flex,
  Box,
  Button,
} from '@chakra-ui/react'
import { BsCircle } from 'react-icons/bs'
import { ImCross } from 'react-icons/im'
import type { Quiz, Stats } from 'types/generated/graphql'
import { LayoutEmbedQuizPlaylist } from '../Common/LayoutEmbedQuizPlaylist'

type Props = {
  quiz: Quiz & { answerCandidates: string[] }
  quizStats?: Stats
  answer: string
  onNext: () => void
}

export const EmbedAnswered: React.FC<Props> = ({
  quiz,
  quizStats,
  answer,
  onNext,
}) => {
  // 非埋め込みの場合は問題文と解説両方表示できるが、
  // 埋め込みの場合は、解説があれば解説、なければ問題文を表示
  const displayText = () => {
    if (quiz.content.description) {
      return (
        <Heading
          fontSize="18px"
          fontWeight="800"
          lineHeight="1.6em"
          m="0 0 0.4em"
          minW="100%"
          textShadow="0 0 5px rgba(0, 0, 0, 0.5)"
          display="flex"
          _before={{
            content: '"解説"',
            display: 'inline-block',
            textShadow: 'none',
            w: '3em',
            h: '1.8em',
            lineHeight: '1.8em',
            borderRadius: '6px',
            fontSize: '70%',
            fontWeight: '800',
            px: '3px',
            mr: '.3em',
            mt: '.25em',
            bg: '#ffaf00',
            color: '#fff',
            textAlign: 'center',
          }}
        >
          <Text>{quiz.content.description}</Text>
        </Heading>
      )
    }
    const stats = quizStats ? `(正答率${quizStats.stats[0].percent})` : ''
    return (
      <Heading
        fontSize="18px"
        display="flex"
        fontWeight="800"
        textShadow="0 0 5px rgba(0, 0, 0, 0.5)"
        lineHeight="1.6em"
        m="0 0 0.3em"
        minWidth="100%"
        _before={{
          content: '"Q"',
          display: 'inline-block',
          textShadow: 'none',
          w: '1.8em',
          minWidth: '1.8em',
          h: '1.8em',
          lineHeight: '1.5em',
          borderRadius: '6px',
          fontSize: '70%',
          fontWeight: '800',
          mr: '.3em',
          mt: '.25em',
          bg: '#ffaf00',
          color: '#fff',
          textAlign: 'center',
        }}
      >
        {quiz.content.question}
        {stats}
      </Heading>
    )
  }

  return (
    <LayoutEmbedQuizPlaylist
      imageUrl={quiz.content.answerImageUrl!}
      alt={quiz.content.question}
    >
      <VStack w="100%" p="25px 0 15px 0" spacing={3}>
        <Text
          fontSize="24px"
          fontWeight="800"
          h="35px"
          px="2.5"
          w="4em"
          mb=".5em"
          color="#fff"
          lineHeight="1.3em"
          border="1px #fff solid"
          textShadow="0 0 5px rgba(0, 0, 0, 0.5)"
          borderRadius="6px"
          textAlign="center"
        >
          {answer === quiz.content.correct ? '正解' : '不正解'}
        </Text>
        <Box
          fontSize={{ base: 'md', sm: 'lg' }}
          color="white"
          w="100%"
          alignItems="left"
          display="flex"
        >
          {displayText()}
        </Box>
        <Stack w="100%" spacing={3} direction="row" align="center">
          <Box w="100%">
            {quiz.answerCandidates.map((candidate, index) => {
              // 未選択かつ不正解の選択肢は表示しない
              if (candidate !== quiz.content.correct && answer !== candidate) {
                return null
              }
              return (
                <Box w="100%" key={'quizPlaylistAnswer-' + index}>
                  <Box
                    p="15px"
                    bg="rgba(255, 255, 255, 0.9)"
                    w="100%"
                    mb="10px"
                    borderRadius="8px"
                    lineHeight="1.6em"
                    fontWeight="500"
                  >
                    {(() => {
                      // 正解
                      if (candidate === quiz.content.correct) {
                        return (
                          <Flex color="#333" position="relative">
                            <Box as="span" position="absolute">
                              <BsCircle size="30px" color="#ffaf00" />
                            </Box>
                            <Text fontWeight="bold" pl="3" ml="7">
                              {candidate}
                            </Text>
                          </Flex>
                        )
                      }

                      // 間違い
                      if (answer === candidate) {
                        return (
                          <Flex color="#333" position="relative">
                            <Box as="span" position="absolute">
                              <ImCross size="30px" color="#ffaf00" />
                            </Box>
                            <Text pl="3" ml="7">
                              {candidate}
                            </Text>
                          </Flex>
                        )
                      }
                    })()}
                  </Box>
                </Box>
              )
            })}
          </Box>
        </Stack>

        <Button
          bg="#ffaf00"
          _hover={{ bg: '#eb7917' }}
          color="#fff"
          fontSize="18px"
          fontWeight="700"
          p="10px"
          textDecoration="none"
          borderRadius="6px"
          w="8em"
          maxWidth="100%"
          boxSizing="border-box"
          onClick={onNext}
        >
          次へ
        </Button>
      </VStack>
    </LayoutEmbedQuizPlaylist>
  )
}
