import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useDisclosure } from '@chakra-ui/react'
import { QuizForm } from 'v2components/QuizForm'
import { AlertModal } from 'v2components/Modal/AlertModal'
import { Loading } from 'v2components/Common/Loading'
import { NoData } from 'v2components/Common/NoData'
import { Error } from 'v2components/Common/Error'
import { useUpdateQuiz } from 'hooks/useUpdateQuiz'
import { Quiz } from 'types/generated/graphql'
import {
  QUIZSTATS,
  QuizStatsData,
  QuizStatsVars,
} from 'graphql/apollo/query/quizStats'
import { CompletedModal } from 'v2components/Modal/CompletedModal'
import { PreviewModal } from 'v2components/Modal/PreviewModal'

export interface QuizCardProps {
  quiz: Quiz
}

export const EditRoot: React.FC<QuizCardProps> = ({ quiz }) => {
  const alertDisclosure = useDisclosure()
  const completeDisclosure = useDisclosure()
  const previewDisclosure = useDisclosure()
  const [previewItemId, setPreviewItemId] = useState('')

  const { handleUpdateQuiz } = useUpdateQuiz({
    id: quiz?.id,
  })
  const {
    loading: quizStatsLoading,
    error: quizStatsError,
    data: quizStatsData,
  } = useQuery<QuizStatsData, QuizStatsVars>(QUIZSTATS, {
    variables: {
      quizId: quiz?.id!,
    },
    fetchPolicy: 'network-only',
  })

  const handleOpenPreviewModal = (itemId: string) => {
    setPreviewItemId(itemId)
    previewDisclosure.onOpen()
  }
  const handleClosePreviewModal = () => {
    setPreviewItemId('')
    previewDisclosure.onClose()
  }

  if (quizStatsLoading) return <Loading />
  if (quizStatsError) return <Error />
  if (!quizStatsData) return <NoData />

  return (
    <>
      <QuizForm
        initialValues={quiz}
        quizStats={quizStatsData.quizStats}
        submitButtonLabel="更新"
        headerTitle="クイズ編集"
        onSubmit={async (values) => {
          try {
            await handleUpdateQuiz(values, quiz)
            completeDisclosure.onOpen()
          } catch (error) {
            alertDisclosure.onOpen()
          }
        }}
      />
      <CompletedModal
        modalBody={'クイズを更新しました'}
        isOpenCompletedModal={completeDisclosure.isOpen}
        handleCloseCompletedModal={completeDisclosure.onClose}
        itemId={quiz?.id}
        contentType="quiz"
        handleOpenPreviewModal={handleOpenPreviewModal}
      />
      <PreviewModal
        pmIsOpen={previewDisclosure.isOpen}
        onClosePm={handleClosePreviewModal}
        itemId={previewItemId}
        contentType="quiz"
      />
      <AlertModal
        modalBody={'選択クイズの登録に失敗しました。'}
        isOpen={alertDisclosure.isOpen}
        onClose={alertDisclosure.onClose}
      />
    </>
  )
}
