import { Box } from '@chakra-ui/react'

export interface SettingLayoutProps {
  pageTitle?: string
}

export const SettingLayout: React.FC<SettingLayoutProps> = ({ children }) => {
  /* ここにLayoutを構築する
     HeaderComponent, SideNaviComponentを組み合わせる */
  return (
    <Box
      bgColor="#fff"
      flex="1 1 860px"
      flexDirection="column"
      borderRadius="24px"
      overflowX="auto"
      h="100%"
    >
      {children}
    </Box>
  )
}
