import { useMutation } from '@apollo/client'
import {
  DELETE_PLAYLIST,
  DeletePlaylistData,
  DeletePlaylistVars,
} from 'graphql/apollo/mutation/playlist/deletePlaylist'

export const useDeleteQuizPlaylist = (
  quizPlaylistId: string,
  onClose: () => void
) => {
  const [deletePlaylist, { loading, error }] = useMutation<
    DeletePlaylistData,
    DeletePlaylistVars
  >(DELETE_PLAYLIST)

  const handleDeleteQuiz = async () => {
    await deletePlaylist({
      variables: {
        id: quizPlaylistId,
      },
      refetchQueries: ['Playlists'],
      awaitRefetchQueries: true,
    })

    if (loading) console.log('loading now')
    if (error)
      alert(`${quizPlaylistId}の削除において、エラー発生が発生しました`)

    onClose()
  }
  return handleDeleteQuiz
}
