import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react'
import React from 'react'

type Props = {
  isOpen: boolean
  onClose: () => void
  handleAddUser: () => void
}

export const ConfirmModal: React.FC<Props> = ({
  isOpen,
  onClose,
  handleAddUser,
}) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody textAlign="center" pt="60px" pb="40px" fontSize="20">
            グループに追加しますか？
          </ModalBody>
          <ModalCloseButton right={3} left="auto" size="lg" />
          <ModalFooter m="auto" pt="0" pb="60px">
            <Stack direction="row" spacing={4}>
              <Button
                bg="buttonBasic"
                width="140px"
                height="40px"
                borderRadius="40px"
                onClick={onClose}
                _hover={{
                  bg: 'buttonBasicHover',
                }}
              >
                キャンセル
              </Button>
              <Button
                bg="buttonPrimary"
                width="140px"
                height="40px"
                borderRadius="40px"
                color="#fff"
                _hover={{
                  bg: 'buttonPrimaryHover',
                }}
                onClick={async () => {
                  try {
                    await handleAddUser()
                  } catch (e) {
                    console.error('fail remove', e)
                  } finally {
                    onClose()
                  }
                }}
              >
                追加
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
