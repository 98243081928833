import React from 'react'
import { Image, Center } from '@chakra-ui/react'
import { StatusIcon } from 'components/ImageUpload/StatusIcon'
import { DropzoneRootProps } from 'react-dropzone'

interface Props {
  imageUrl: string
  style: DropzoneRootProps
  isDragAccept: boolean
  isDragReject: boolean
}

export const IconComponent: React.FC<Props> = ({
  imageUrl,
  style,
  isDragAccept,
  isDragReject,
}) => {
  return (
    <Center
      {...style}
      position="relative"
      w="10rem"
      h="10rem"
      borderRadius="full"
      border="1px"
      borderColor="gray.200"
    >
      <Image
        position={'absolute'}
        w="100%"
        h="100%"
        objectFit="cover"
        borderRadius="full"
        src={imageUrl}
        fallbackSrc={'/images/quiz_bg.png'}
        alt="prifile-icon"
      />
      <Center
        w="100%"
        h="100%"
        position={'absolute'}
        borderRadius="full"
        bg="blackAlpha.300"
        pr={1}
        pb={2}
        _hover={{ bg: 'blackAlpha.600' }}
      >
        <StatusIcon
          imageUrl={imageUrl}
          isDragAccept={isDragAccept}
          isDragReject={isDragReject}
        />
      </Center>
    </Center>
  )
}
