import React from 'react'
import { Box, useDisclosure } from '@chakra-ui/react'
import { RouteComponentProps, useNavigate } from '@reach/router'

import { EnqueteForm } from 'components/EnqueteForm/index'

import { Layout } from 'components/Common/Layout'
import { AlertModal } from 'components/Modal/AlertModal'
import { useCreateEnquete } from 'hooks/useCreateEnquete'

type Props = RouteComponentProps

export const CreateEnquete: React.FC<Props> = () => {
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { handleCreateEnquete } = useCreateEnquete()

  return (
    <Layout pageTitle="アンケート作成">
      <Box w="100%" h="100%" p={3}>
        <EnqueteForm
          submitButtonLabel="登録"
          onSubmit={async (values) => {
            try {
              await handleCreateEnquete(values)
              navigate('/enquetes')
            } catch (error) {
              onOpen()
            }
          }}
        />
        <AlertModal
          modalBody={'アンケートの登録に失敗しました。'}
          isOpen={isOpen}
          onClose={onClose}
        />
      </Box>
    </Layout>
  )
}
