import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useCookies } from 'react-cookie'
import { USER, UserData, UserVars } from 'graphql/apollo/query/user'
import { GROUP, GroupData, GroupVars } from 'graphql/apollo/query/group'
import { Group } from 'types/generated/graphql'

export const useUserProfile = (user: Record<string, any>) => {
  const [cookies, setCookie, removeCookie] = useCookies()
  const [userProfile, setUserProfile] = useState<UserData>()
  const [currentGroup, setCurrentGroup] = useState<Group | undefined>(
    cookies.currentGroup
  )

  const {
    loading: userLoading,
    error: userError,
    data: userData,
  } = useQuery<UserData, UserVars>(USER, {
    variables: {
      id: user['sub'],
    },
    fetchPolicy: 'cache-first',
    skip: !user['sub'],
  })
  const {
    loading: groupLoading,
    error: groupError,
    data: groupData,
  } = useQuery<GroupData, GroupVars>(GROUP, {
    variables: {
      id: currentGroup?.id!,
    },
    skip: !currentGroup,
  })

  const handleCurrentGroup = (group?: Group) => setCurrentGroup(group)

  useEffect(() => {
    const blankUser = {
      user: {
        id: user['sub'],
        aliasId: '',
        email: user['email'],
        name: '',
        organizationName: user['cognito:groups']
          ? user['cognito:groups'][0]
          : '',
      },
    }
    if (!user['sub']) return
    if (userLoading) return
    if (userError) setUserProfile(blankUser)
    if (userData && user['cognito:groups']) {
      // userData.user.groupsが何故かnullになることがあるので、null以外のときにデータ更新するようにした
      if (userData.user.groups !== null) setUserProfile(userData)
    } else {
      setUserProfile(blankUser)
    }
  }, [userData, userError, userLoading, user])

  useEffect(() => {
    if (groupLoading) return
    if (groupError) setCurrentGroup(undefined)
    if (groupData) {
      setCurrentGroup(groupData.group)
      setCookie('currentGroup', groupData.group, { path: '/' })
    }
    if (!currentGroup) {
      removeCookie('currentGroup')
    }
  }, [
    currentGroup,
    groupData,
    groupError,
    groupLoading,
    setCookie,
    removeCookie,
  ])

  return {
    userProfile,
    currentGroup,
    handleCurrentGroup,
  }
}
