import { Image, Center, VStack, Text } from '@chakra-ui/react'
import logo from 'assets/images/logo512.png'

type props = {
  category: 'クイズ' | 'アンケート'
}

export const Private: React.FC<props> = (props) => {
  return (
    <Center w="100%" h="90vh">
      <VStack>
        <Image src={logo} w="50px" h="50px" alt="logo" />
        <Text fontSize="3xl">{props.category}非公開</Text>
      </VStack>
    </Center>
  )
}
