import { useCallback, useMemo } from 'react'
import S3, { ManagedUpload } from 'aws-sdk/clients/s3'
import { useAuth } from './useAuth'

import { dataUrlToFile } from 'utils/dataUrlToFile'
import { isDataURL } from 'utils/isDataURL'

type Visibility = 'protected' | 'public' | 'private'

export const useUploadFile = () => {
  const { credentials } = useAuth()
  const s3client = useMemo(
    () =>
      new S3({
        region: 'ap-northeast-1',
        credentials,
      }),
    [credentials]
  )

  const handleUploadFile = useCallback(
    async (
      file: File,
      visibility: Visibility = 'protected'
    ): Promise<ManagedUpload.SendData> => {
      const Key =
        visibility === 'public'
          ? `${visibility}/${file.name}`
          : `${visibility}/${credentials?.identityId}/${file.name}`

      const sendData = await s3client
        .upload({
          Bucket: process.env.REACT_APP_UPLOAD_BUCKET_NAME,
          Key,
          Body: file,
          ContentType: file.type,
        })
        .promise()

      return {
        ...sendData,
        Location: `https://${process.env.REACT_APP_UPLOAD_BUCKET_NAME}/${Key}`,
      }
    },
    [credentials, s3client]
  )

  const handleUploadDataUrlImage = useCallback(
    async (dataUrl: string, fileName: string) => {
      if (isDataURL(dataUrl)) {
        const imgFile = await dataUrlToFile(
          dataUrl,
          fileName + '-' + Date.now().toString()
        )
        const s3ImageData = await handleUploadFile(imgFile)
        return s3ImageData.Location
      } else {
        return dataUrl
      }
    },
    [handleUploadFile]
  )

  return {
    handleUploadFile,
    handleUploadDataUrlImage,
  }
}
