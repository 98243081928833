import React, { useState, useMemo } from 'react'
import {
  Button,
  Box,
  Text,
  Icon,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react'
import { MdPersonAdd } from 'react-icons/md'
import { User } from 'types/generated/graphql'
import { useQuery } from '@apollo/client'
import { USERS, UsersData, UsersVars } from 'graphql/apollo/query/users'

import { UserCard } from 'components/MemberDrawer/AddMemberModal/UserCard'
import { Loading } from 'components/Common/Loading'
import { Error } from 'components/Common/Error'

interface Props {
  isEditable: boolean
}

export const AddMemberModal: React.FC<Props> = ({ isEditable }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const buttonNextColor = useColorModeValue('buttonNextLight', 'buttonNextDark')
  const [searchText, setSearchText] = useState<string>('')
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    return setSearchText(e.target.value)
  }

  const { loading, error, data } = useQuery<UsersData, UsersVars>(USERS)

  const users = useMemo(() => {
    if (!data || searchText === '') return []

    return data.users.filter((user: User) => {
      return (
        user.email.includes(searchText) ||
        user.name.includes(searchText) ||
        user.aliasId.includes(searchText)
      )
    })
  }, [data, searchText])

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <>
      <Button w="100%" bg={buttonNextColor} onClick={onOpen}>
        <Text fontSize="sm" mr={2}>
          <Icon as={MdPersonAdd} mt={0.3} mr={1} mb={1} />
          ユーザー追加
        </Text>
      </Button>
      <Modal onClose={onClose} isOpen={isOpen} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>ユーザー追加</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              value={searchText}
              onChange={handleChange}
              id="searchText"
              placeholder="ユーザー名、ユーザーID、メールアドレスで検索"
            />
            {users.map((user: User) => {
              return (
                <Box key={user.id} pt={2}>
                  <UserCard user={user} isEditable={isEditable} />
                </Box>
              )
            })}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
