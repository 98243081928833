import React from 'react'
import { SimpleGrid } from '@chakra-ui/react'
import { EnqueteCard } from 'components/Common/EnqueteCard'
import { NoData } from 'components/Common/NoData'
import { Enquete } from 'types/generated/graphql'

interface Props {
  enquetes?: Enquete[]
  isEditable: boolean
}

export const EnqueteCardList: React.FC<Props> = ({ enquetes, isEditable }) => {
  if (!enquetes || enquetes.length === 0) return <NoData />

  return (
    <SimpleGrid w="100%" columns={[1, 2, 3, 4]} spacing="10px">
      {enquetes.map((enquete) => (
        <EnqueteCard
          key={enquete.id}
          enquete={enquete}
          isEditable={isEditable}
        />
      ))}
    </SimpleGrid>
  )
}
