import React from 'react'
import {
  Box,
  Button,
  Flex,
  Spacer,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react'
import type { Quiz } from 'types/generated/graphql'
import { IoReturnDownBackOutline } from 'react-icons/io5'
import { CardDndRoot } from './CardDndComponent'

type Props = {
  selectedQuizzes: Quiz[]
  unselectedQuizzes: Quiz[]
  isValid: boolean
  setSelectedQuizIds: (quizIds: string[]) => void
  onBack: () => void
  onNext: () => void
}

export const SecondInputPage: React.FC<Props> = ({
  selectedQuizzes,
  unselectedQuizzes,
  isValid,
  setSelectedQuizIds,
  onBack,
  onNext,
}) => {
  const buttonNextColor = useColorModeValue('buttonNextLight', 'buttonNextDark')
  const buttonBackColor = useColorModeValue('buttonBackLight', 'buttonBackDark')

  return (
    <Box w="100%" h="100%">
      <Flex w="100%" pb="5">
        <Button
          variant="outline"
          aria-label="Delete Quiz"
          backgroundColor={buttonBackColor}
          onClick={onBack}
          //   icon={<IoReturnDownBackOutline />}
        >
          戻る
        </Button>
        <Spacer />
        <VStack>
          <Text fontSize="xs">
            ※リストに入れたいクイズをドラックアンドドロップ
          </Text>
          <Text fontSize="xs">※クイズ順はドラックアンドドロップで設定</Text>
        </VStack>
        <Spacer />
        <Button
          variant="outline"
          aria-label="Delete Quiz"
          backgroundColor={buttonNextColor}
          onClick={onNext}
          disabled={!isValid}
        >
          次へ
        </Button>
      </Flex>
      <CardDndRoot
        selectedQuizzes={selectedQuizzes}
        unselectedQuizzes={unselectedQuizzes}
        setSelectedQuizIds={setSelectedQuizIds}
      />
    </Box>
  )
}
