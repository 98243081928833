import React, { useState, useCallback, useMemo } from 'react'
import dayjs from 'dayjs'
import { useQuery } from '@apollo/client'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { css } from '@emotion/react'
import {
  Box,
  Container,
  VStack,
  HStack,
  Center,
  Text,
  Image,
  IconButton,
  Flex,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useColorModeValue,
  Tooltip,
} from '@chakra-ui/react'
import { CalendarIcon, RepeatClockIcon } from '@chakra-ui/icons'
import { IoArrowBack } from 'react-icons/io5'
import { MdContentCopy, MdRemoveRedEye, MdModeEdit } from 'react-icons/md'
import { ImEmbed } from 'react-icons/im'
import { VscPreview } from 'react-icons/vsc'
import { Error } from 'components/Common/Error'
import { Layout } from 'components/Common/Layout'
import { Loading } from 'components/Common/Loading'
import { NoData } from 'components/Common/NoData'
import { QuizCardRoot } from 'components/Common/QuizCardComponent/QuizCardRoot'
import { Unauthorized } from 'components/Common/Unauthorized'
import { DeleteModal } from 'components/Modal/DeleteModal'
import { QUIZ, QuizData, QuizVars } from 'graphql/apollo/query/quiz'
import { useDeleteQuiz } from 'hooks/useDeleteQuiz'
import { useUserProfileContext } from 'state'
import { isOwned } from 'utils/isOwned'
import { getEmbedHeight } from 'utils/answeredQuiz'

const ImageWrapper = css`
  padding-top: 100%;
  position: relative;
  height: 100%;
`
const ImageBox = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #f3b0ae;
`

type Props = RouteComponentProps<{ quizId: string }>

export const QuizCheck: React.FC<Props> = (props) => {
  const navigate = useNavigate()
  const [copySuccess, setCopySuccess] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const publishedColor = useColorModeValue('primaryLight', 'secondaryLight')
  const unpublishedColor = useColorModeValue('primaryDark', 'secondaryDark')
  const { userProfile, currentGroup } = useUserProfileContext()
  const { loading, error, data } = useQuery<QuizData, QuizVars>(QUIZ, {
    variables: {
      id: props.quizId!,
    },
  })

  const quiz = data?.quiz
  const isOwnedQuiz = useMemo(() => {
    return isOwned(quiz, currentGroup, userProfile)
  }, [quiz, currentGroup, userProfile])

  const handleCopy = useCallback(() => {
    const { origin } = new URL(window.location.href)
    const shareUrl = `${origin}/quiz/${props.quizId}/play`
    onOpen()
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        setCopySuccess('クイズURLがコピーされました😆')
      })
      .catch(() => {
        setCopySuccess('クイズURLのコピーに失敗しました…😢')
      })
  }, [onOpen, props.quizId])

  const handleEmbedCodeCopy = useCallback(() => {
    if (!quiz) {
      return
    }
    const { origin } = new URL(window.location.href)
    const height = getEmbedHeight(quiz) + 25
    const code = `<script>window.addEventListener('message', function (e) {if (e.origin !== '${origin}') {return}; const iframe = document.getElementById('${quiz.id}'); iframe.height = e.data[0] + 'px'}, false);</script><iframe id="${quiz.id}" src="${origin}/quiz/${quiz.id}/embed/play" height="${height}px" width="100%" scrolling="no" frameBorder="no"></iframe>`
    onOpen()
    navigator.clipboard
      .writeText(code)
      .then(() => {
        setCopySuccess('埋め込み用コードがコピーされました😆')
      })
      .catch(() => {
        setCopySuccess('埋め込み用コードのコピーに失敗しました…😢')
      })
  }, [onOpen, quiz])

  const handleSubmit = useDeleteQuiz(props.quizId!, onClose)

  if (loading) return <Loading />
  if (error) return <Error />
  if (!data || !quiz) return <NoData />

  if (!isOwnedQuiz) {
    return (
      <Layout pageTitle="チェックページ">
        <Unauthorized />
      </Layout>
    )
  }

  return (
    <Layout pageTitle="チェックページ">
      <Container maxW="container.md" p={0}>
        <VStack py={2}>
          <Flex w="100%">
            <IconButton
              _focus={{ boxShadow: 'none' }}
              variant="outline"
              aria-label="Return Quiz"
              color="#4D3B3E"
              onClick={() => navigate(`/quiz`)}
              icon={<IoArrowBack />}
            />
            <Spacer />
            <DeleteModal handleSubmit={handleSubmit} />
          </Flex>
          <VStack w="100%" borderWidth="1px" spacing="0">
            {quiz?.published ? (
              <Center w="100%" bg={publishedColor}>
                <Text fontWeight="bold">公開中</Text>
              </Center>
            ) : (
              <Center w="100%" bg={unpublishedColor}>
                <Text fontWeight="bold">非公開</Text>
              </Center>
            )}
            <Box display={{ sm: 'flex' }} w="100%">
              {quiz.content.imageUrl ? (
                <Box flexShrink={0} w={{ base: '100%', sm: '30%' }}>
                  <Box css={ImageWrapper}>
                    <Image
                      css={ImageBox}
                      src={quiz.content.imageUrl}
                      alt="リスト画像"
                    />
                  </Box>
                </Box>
              ) : (
                <Center w={{ base: '100%', sm: '30%' }} h="200px">
                  <Text>no image</Text>
                </Center>
              )}
              <VStack p={{ base: 1, sm: 2 }} w="100%">
                <HStack w="100%">
                  <Text fontSize="md" mr={2}>
                    <CalendarIcon boxSize="16px" mr={1} mb={1} />
                    {dayjs(quiz.createdAt).format('YYYY-MM-DD')}
                  </Text>
                  <Text fontSize="md" mr={2}>
                    <RepeatClockIcon boxSize="16px" mr={1} mb={1} />
                    {dayjs(quiz.updatedAt).format('YYYY-MM-DD')}
                  </Text>
                </HStack>
                <QuizCardRoot quiz={quiz} />
              </VStack>
            </Box>
          </VStack>
          <HStack pt={3}>
            <Tooltip hasArrow label="URLコピー" bg="red.600">
              <IconButton
                colorScheme="red"
                aria-label="URLコピー"
                isRound
                icon={<MdContentCopy />}
                onClick={handleCopy}
              />
            </Tooltip>
            <Spacer />
            <Tooltip
              hasArrow
              label={
                quiz.published
                  ? '埋め込み用コードコピー'
                  : '非公開のためコピーできません'
              }
              bg="red.600"
              shouldWrapChildren
            >
              <IconButton
                colorScheme="red"
                aria-label="埋め込み用コードコピー"
                isRound
                icon={<ImEmbed />}
                onClick={handleEmbedCodeCopy}
                disabled={!quiz.published}
              />
            </Tooltip>
            <Spacer />
            <Tooltip hasArrow label="プレビュー" bg="red.600">
              <IconButton
                colorScheme="red"
                aria-label="プレビュー"
                isRound
                icon={<MdRemoveRedEye />}
                onClick={() => navigate(`/quiz/${props.quizId}/preview`)}
              />
            </Tooltip>
            <Spacer />
            <Tooltip hasArrow label="埋め込み用プレビュー" bg="red.600">
              <IconButton
                colorScheme="red"
                aria-label="埋め込み用プレビュー"
                isRound
                icon={<VscPreview />}
                onClick={() => navigate(`/quiz/${props.quizId}/embed/preview`)}
              />
            </Tooltip>
            <Spacer />
            <Tooltip hasArrow label="編集" bg="red.600">
              <IconButton
                colorScheme="red"
                aria-label="編集"
                isRound
                icon={<MdModeEdit />}
                onClick={() => navigate(`/quiz/${props.quizId}/edit`)}
              />
            </Tooltip>
          </HStack>
          <Text fontSize="xs" color="blackAlpha.700" pt={3}>
            ID : {props.quizId}
          </Text>
        </VStack>

        <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton left={3} right="auto" size="lg" />
            <ModalBody textAlign="center" p="3rem" fontSize="20">
              {copySuccess}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Container>
    </Layout>
  )
}
