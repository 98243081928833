import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { FormUploadImage } from 'v2components/Common/InputPageComponent/FormUploadImage'

export const GroupImageForm: React.FC = () => {
  return (
    <Box flexShrink={1} flexGrow={1} flexBasis="539px">
      <Flex
        color="#222"
        fontSize="14px"
        fontWeight="500"
        lineHeight="1.7142857143"
        letterSpacing="0.04em"
        alignItems="center"
      >
        グループ画像
      </Flex>
      <FormUploadImage name="icon" />
    </Box>
  )
}
