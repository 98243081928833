import { gql } from '@apollo/client'
import { Scalars } from 'types/generated/graphql'

export type DeleteEnqueteData = null

export type DeleteEnqueteVars = {
  id: Scalars['ID']
}

export const DELETE_ENQUETE = gql`
  mutation DeleteEnquete($id: ID!) {
    deleteEnquete(id: $id)
  }
`
