import {
  useDisclosure,
  Button,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Stack,
} from '@chakra-ui/react'
import { DeleteIcon } from '@chakra-ui/icons'
import { AlertModal } from 'components/Modal/AlertModal'

type Props = {
  handleSubmit: () => void
}

export const DeleteModal: React.FC<Props> = ({ handleSubmit }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const alertDisclosure = useDisclosure()
  return (
    <>
      <IconButton
        colorScheme="red"
        aria-label="Delete Modal Button"
        isRound
        icon={<DeleteIcon />}
        onClick={onOpen}
      />
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody textAlign="center" pt="3rem" fontSize="20">
            本当に削除しますか？
          </ModalBody>
          <ModalCloseButton left={3} right="auto" size="lg" />
          <ModalFooter m="auto" pb={10}>
            <Stack direction="row" spacing={4}>
              <Button
                color="#4D3B3E"
                bg="#DCDCDC"
                width="8rem"
                height="3rem"
                onClick={onClose}
              >
                キャンセル
              </Button>
              <Button
                color="#4D3B3E"
                bg="#F3B0AE"
                width="8rem"
                height="3rem"
                onClick={async () => {
                  try {
                    await handleSubmit()
                  } catch (error) {
                    onClose()
                    alertDisclosure.onOpen()
                  }
                }}
              >
                削除
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertModal
        modalBody={'削除に失敗しました。'}
        isOpen={alertDisclosure.isOpen}
        onClose={alertDisclosure.onClose}
      />
    </>
  )
}
