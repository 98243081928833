import {
  Flex,
  Heading,
  Box,
  Text,
  Center,
  Image,
  HStack,
} from '@chakra-ui/react'
import { RouteComponentProps } from '@reach/router'
import logo from 'assets/images/logo192.png'

type Props = RouteComponentProps

export const Terms: React.FC<Props> = () => {
  return (
    <Flex direction="column">
      <Box bg="primary">
        <HStack spacing="0" pt="20px" pl="20px">
          <Image src={logo} w="30px" h="30px" alt="logo" />
          <Heading size="sm" p="8px">
            QuzillA
          </Heading>
        </HStack>  
        <Center>
          <Heading size="2xl" pb="50px">QuzillA 利用規約</Heading>
        </Center>
      </Box>
      <Box py="20px" px="5%">
        <Text my="45px" fontSize="18px" lineHeight="1.7">
          「QuzillA 利用規約」（以下「本規約」といいます）は、株式会社朝日新聞社が運営するQuzillA（以下、総称して「本サービス」といいます）の利用規約です。本サービスの閲覧・利用を行う利用者は、本規約の内容に同意の上、ご利用いただくものとします。未成年者の方は、事前に保護者の同意を得て本サービスをご利用ください（未成年者が本サービスを利用した場合、当社は保護者の同意があったものとみなします）。
        </Text>
        <Box>
          <Box>
            <Heading size="lg" py="30px">
              第１条（適用）
            </Heading>
            <Text fontSize="md">
              本規約が適用される「利用者」には、本サービスを利用する方全てが含まれます。
            </Text>
          </Box>
          <Box>
            <Heading size="lg" py="30px">
              第２条（外部コンテンツ）
            </Heading>
            <Text fontSize="md">
              当社以外の事業者が管理運営するリンク先のホームページ等で利用可能となっているコンテンツ及び当社以外の事業者が管理運営するソーシャルネットワーキングサービス（Facebook、Twitter 等）の機能または動画埋め込み機能を使って本サービス内に表示するコンテンツ、本サービス外から引用、転載され本サービス内に表示されるコンテンツ（以下、総称して「外部コンテンツ」といいます）並びに外部コンテンツにおける広告、商品、サービスなどについて当社は責任を負いかねますので、利用者の判断及び責任においてご利用ください。
            </Text>
          </Box>
          <Box>
            <Heading size="lg" py="30px">
              第３条（利用者の責任・費用）
            </Heading>
            <Box>
              <Text fontSize="md" mb="15px">
                1.利用者は、自己の責任で本サービスを利用するものとし、本サービスを利用した結果、損害や不利益を被ったとしても、利用者自身が責任を負うものとします。ただし、当社の責に帰する場合はこの限りではありません。 
              </Text>
              <Text fontSize="md">
                2.本サービスの利用のために必要な通信料や、コンピューター・通信機器等に関する費用は、利用者の負担とします。
              </Text>
            </Box>
          </Box>
          <Box>
            <Heading size="lg" py="30px">
              第４条（著作権）
            </Heading>
            <Text fontSize="md">
              本サービス及び本サービス内の全てのコンテンツに関する著作権その他の権利は、当社及び正当な著作権者に帰属します。利用者は、本サービスの内容について、当社の事前の書面による許可なく転載、複製、転用、転送、販売等の二次利用を行うことはできません。
            </Text>
          </Box>
          <Box>
            <Heading size="lg" py="30px">
              第５条（リンクについて）
            </Heading>
            <Box fontSize="md">
              <Text>
                本サービスのリンクは、営利を目的とせず、本サービスへのリンクである旨を明記し、フレームなしで行うなど一定の条件を満たしている限り、原則として自由です。ただし、本サービスの趣旨に合わない場合や情報発信源を誤認させるような形でリンクを張るなど、当社の事業等を害する恐れがある場合は、リンク自体をお断りすることがあります。詳しくは、次項の注意点をご参照ください。なお、編集・更新の都合により、本サービス内の各ページは予告なく変更・削除されることがあります。リンクを設定いただいても、短期間でリンク切れとなる可能性もありますので、あらかじめご了承ください。
              </Text>
              <Text>
                いわゆるフレームリンク、直リンク、エンベッドなど、本サービスの内容を他サイトのコンテンツと組み合わせて表示するリンクや、本サービスの内容が単独で表示される場合であっても、リンク先のホームページの一部として表示される形のリンク、その他、一般に当社の信用にかかわるようなリンクの仕方は、営利・非営利を問わず禁止します。
              </Text>
              <Text>
                本サービスからリンクされた第三者のサイトは、当社の管理管轄下にあるものではなく、利用者がそれら第三者のサイトの内容を見たことや、そのサイト内の広告、商品、サービスなどを利用したことによって生じた直接的・間接的な損害について、当社は責任を負いかねます。
              </Text>
              <Text>
                当社がSNS でのシェアのために本サービスに設定したOGP（Open Graph protocol、設定されるタイトル・テキスト・画像等の一切のコンテンツを含みます）については、当該OGP が設定されたページで当社がシェアボタンを設置したSNS のためにのみ使用いただけます。それ以外での使用については営利・非営利を問わず禁止します。
              </Text>
            </Box>
          </Box>
          <Box>
            <Heading size="lg" py="30px">
              第６条（免責事項）
            </Heading>
            <Box>
              <Text fontSize="md" mb="15px">
                1.当社は、本サービスの内容及び機能について、いかなる保証（その完全性、正確性、確実性、有用性、安全性、正常動作性、機能性を含みますが、これに限りません）も行いません。また、当社は、本サービスの内容及び機能を、編集、管理、削除等し、またはこれに必要な技術的な手段を利用者に提供する義務を負わないものとします。 
              </Text>
              <Box fontSize="md">
                <Text>
                  2.利用者は、以下の事項を了承の上、本サービスを利用するものとします。
                </Text>
                <Text>
                  (1)本サービスの変更、中断、遅延、停止、終了または不具合が生じる場合があること
                </Text> 
                <Text>
                  (2)第三者または他の利用者とのトラブルが生じた場合に、利用者の責任で対応すること 
                </Text>
                <Text>
                  (3)本サービスを通じて第三者から取得した情報等または第三者との取引等により生じたトラブルは利用者の責任で対応すること
                </Text>
                <Text>
                  (4)上記各号について、当社がその責を免れること 
                </Text>
                <Text>
                  (5)本サービスに起因して発生した一切の損害（（1）~（3）に関連して生じた損害を含みますがこれに限りません）について当社が責任を負わないこと（ただし、当社の責に帰する場合はこの限りではありません）
                </Text>
              </Box>
            </Box>
          </Box>
          <Box>
            <Heading size="lg" py="30px">
              第７条（禁止事項）
            </Heading>
            <Box fontSize="md">
              <Text>
                利用者は、本サービスに関して、以下の行為を行わないものとします。
              </Text>
              <Text>
                (1)本サービスのコンテンツ等一切を第三者へ提供・再配信する行為
              </Text>
              <Text>
                (2)本サービスの正規機能によるものを除き、本サービスのコンテンツ等の複製（閲覧の際に端末上に一時的に発生する電子的蓄積は除く）、編集、加工、翻訳、翻案、出版、転載、頒布、放送、口述、展示、販売、公衆送信（送信可能化、インターネット上のウェブサイトやイントラネット等への掲載を含む）もしくは改変をするなど、当社もしくは第三者の権利を侵害する一切の行為、またはこれらを行ったコンテンツ等一切を第三者へ提供・再配信する行為
              </Text>
              <Text>
                (3)本サービスを、営利を目的として利用する行為、またはその準備を目的とした行為 
              </Text>
              <Text>
                (4)データマイニング、ロボット等によるデータ収集・抽出ツールを使用する行為
              </Text>
              <Text>
                (5)本サービスの全部または一部のリバースエンジニアリング、逆コンパイル、または逆アセンブルする等の行為
              </Text>
              <Text>
                (6)第三者または当社の財産、名誉・信用・肖像・プライバシー・パブリシティーに係る権利、著作権・特許権・商標権等の知的財産権その他の権利を侵害する行為、または侵害を助長する行為
              </Text>
              <Text>
                (7)上記各号に定めるほか、法令、公序良俗もしくは第三者との契約に違反する行為またはそれを助長するおそれがある行為 
              </Text>
              <Text>
                (8)本サービスの運営または他の利用者による本サービスの利用を妨害する行為 
              </Text>
              <Text>
                (9)その他、当社が合理的な理由に基づき不適当と判断する行為
              </Text>
            </Box>
          </Box>
          <Box>
            <Heading size="lg" py="30px">
              第８条（規約・サービスの変更等）
            </Heading>
            <Box>
              <Box fontSize="md" mb="15px">
                <Text>
                  1.当社は、以下の各号のいずれかに該当する場合に、利用者の事前の同意を得ることなく、本規約を変更できるものとします。当該変更にあたり、当社は変更の効力発生日の前までに、本規約を変更する旨及び変更後の本規約の内容と効力発生日を本サービス上に掲載します。
                </Text>
                <Text>
                  (1)本規約の変更が、利用者の一般の利益に適合するとき
                </Text>
                <Text>
                  (2)本規約の変更が、利用者による本サービスの利用という目的に反せず、かつ変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき
                </Text>
              </Box>
              <Text fontSize="md" mb="15px">
                2.本規約のいずれかの規定が法令に違反していると判断された場合や無効または実施できないと判断された場合も、当該規定以外の各規定は、いずれも引き続き有効とします。 
              </Text>
              <Text fontSize="md">
                3.当社は、利用者の事前の同意を得ることなく、予告なしにいつでも本サービスの全部または一部を変更し、本サービスの提供を中断または終了できるものとします。
              </Text>
            </Box>
          </Box>
          <Box>
            <Heading size="lg" py="30px">
              第９条（準拠法、裁判管轄）
            </Heading>
            <Box>
              <Text fontSize="md" mb="15px">
                1.本規約は日本国法に準拠し、日本の法令に従って解釈されるものとします。 
              </Text>
              <Text fontSize="md">
                2.利用者と当社の間で本規約及び本サービスに関する紛争が生じた場合、両者で誠意をもって協議をするものとしますが、それでも解決しない場合、東京簡易裁判所または東京地方裁判所をもって第一審の専属的合意管轄裁判所とします。
              </Text>
            </Box>
          </Box>
          <Box>
            <Text py="40px" textAlign="right">
              以上
            </Text>
          </Box>
        </Box>
      </Box>
    </Flex>
  )
}