import { useState } from 'react'
import {
  Text,
  Box,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react'
import { useUserProfileContext } from 'state'
import { SelectGroupModal } from 'v2components/Modal/SelectGroupModal'
import logo from 'assets/images/logo.svg'
import grouplogo from 'assets/images/group_logo.svg'
import headericon from 'assets/images/header__group-icon.png'
import { useQueryStore } from 'v2state/SearchQueryStore'
import { MdSearch } from 'react-icons/md'

export interface HeaderProps {
  onShowSidebar?: () => void
  showSidebarButton?: boolean
  showSearchBox?: boolean
  pageTitle: string
  setItemId?: (itemId: string) => void
}

export const Header: React.FC<HeaderProps> = ({
  setItemId,
  showSearchBox = false,
}) => {
  const { currentGroup } = useUserProfileContext()
  const [isOpenGroupModal, setIsOpenGroupModal] = useState(false)
  const query = useQueryStore((state) => state.query)
  const updateQuery = useQueryStore((state) => state.updateQuery)
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateQuery(event.target.value)
  }
  return (
    <Box w="100%" display="block">
      <Flex justifyContent="center" alignItems="center" p="16px">
        <Box flex="0 0 auto" mr="16px">
          <Image src={logo} />
        </Box>
        <Box flex="0 1 auto" mr="auto" w="720px" position="relative">
          {showSearchBox ? (
            <InputGroup>
              <InputLeftElement pointerEvents="none" pl="20px">
                <MdSearch color="#2B5080" size="24px" />
              </InputLeftElement>
              <Input
                value={query}
                placeholder="検索"
                border="none"
                color="#2B5080"
                fontSize="16px"
                fontWeight="500"
                lineHeight="1.5"
                borderRadius="23px"
                boxSizing="border-box"
                p="11px 16px 11px 48px"
                w="100%"
                backgroundColor="field"
                onChange={handleOnChange}
              />
            </InputGroup>
          ) : (
            <></>
          )}
        </Box>
        <Flex flex="0 0 auto" alignItems="center" ml="16px">
          <Flex
            color="#fff"
            fontSize="14px"
            fontWeight="700"
            lineHeight="1.7142857143"
            letterSpacing="0.04em"
            alignItems="center"
            mr="16px"
            _before={{
              bgImage: grouplogo,
              bgPos: 'center center',
              bgRepeat: 'no-repeat',
              content: '""',
              bgSize: 'contain',
              display: 'block',
              mr: '8px',
              w: '24px',
              aspectRatio: '1/1',
            }}
          >
            グループ
          </Flex>
          <Flex
            bgImage="linear-gradient(180deg, #FFF 63.07%, #D2EBFA 100%)"
            borderRadius="10px"
            alignItems="center"
            boxSizing="border-box"
            p="8px 12px"
            w="240px"
            cursor="pointer"
            onClick={() => {
              setIsOpenGroupModal(true)
              setItemId?.('')
            }}
          >
            <Box mr="8px" w="30px">
              <Image src={headericon} />
            </Box>
            <Text
              color="#2B5080"
              fontSize="14px"
              fontWeight="500"
              lineHeight="1.7142857143"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
            >
              {currentGroup ? currentGroup.name : 'グループなし'}
            </Text>
          </Flex>
        </Flex>
        <SelectGroupModal
          isOpen={isOpenGroupModal}
          onClose={() => {
            setIsOpenGroupModal(false)
          }}
        />
      </Flex>
    </Box>
  )
}
