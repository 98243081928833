import React, { useState } from 'react'
import { useQuery } from '@apollo/client'

import { RouteComponentProps } from '@reach/router'
import { Box, Button, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import { groupSchema } from 'services/validators/groupSchema'
import { useUserProfileContext } from 'state'
import { GROUP, GroupData, GroupVars } from 'graphql/apollo/query/group'

import { Layout } from 'components/Common/Layout'
import { FormInput } from 'components/Common/InputPageComponent/FormInput'
import { useEditGroup } from 'hooks/useEditGroup'
import { GroupResultModal } from 'components/Modal/GroupResultModal'
import { Loading } from 'components/Common/Loading'
import { NoData } from 'components/Common/NoData'
import { Error } from 'components/Common/Error'

type Props = RouteComponentProps

export const EditGroup: React.FC<Props> = () => {
  const [modalBody, setModalBody] = useState<string>('')
  const [isMutationSucceeded, setIsMutationSucceeded] = useState<boolean>(false)
  const { currentGroup } = useUserProfileContext()
  const { handleEditGroup } = useEditGroup({ group: currentGroup })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const buttonNextColor = useColorModeValue('buttonNextLight', 'buttonNextDark')
  const groupId = currentGroup?.id!
  const { loading, error, data } = useQuery<GroupData, GroupVars>(GROUP, {
    variables: {
      id: groupId,
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />
  if (!data) return <NoData />

  const content = {
    name: data.group.name,
  }

  return (
    <Layout pageTitle="グループ編集">
      <Box w="100%" h="100%" p={3}>
        <GroupResultModal
          modalBody={modalBody}
          isOpen={isOpen}
          onClose={onClose}
          isMutationSucceeded={isMutationSucceeded}
          groupId={currentGroup!.id}
        />
        <Formik
          initialValues={groupSchema.cast(content, {
            stripUnknown: true,
          })}
          validationSchema={groupSchema}
          onSubmit={async (values) => {
            try {
              await handleEditGroup(values)
              setIsMutationSucceeded(true)
              setModalBody(`グループ名を「${values.name}」に変更しました。`)
              onOpen()
            } catch (e) {
              setIsMutationSucceeded(false)
              setModalBody('変更に失敗しました。')
              onOpen()
            }
          }}
        >
          {({ values, errors, touched, isSubmitting, isValid }) => {
            const isUserName = values.name === ''
            const isRegisterStatus = isUserName || !isValid
            return (
              <Form>
                <FormInput
                  name="name"
                  label="グループネーム"
                  isRequired={true}
                />
                <Button
                  bg={buttonNextColor}
                  w="100%"
                  variant="outline"
                  aria-label="add group"
                  type="submit"
                  disabled={isRegisterStatus}
                  isLoading={isSubmitting}
                >
                  変更
                </Button>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </Layout>
  )
}
