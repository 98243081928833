import { object, string } from 'yup'

export const snsInfoSchema = object({
  shareText: string().ensure().default(''),
  destinationLink: string()
    .url('「遷移先リンク」はURL形式で入力してください。')
    .ensure()
    .default(''),
  destinationLinkText: string().ensure().default(''),
})
