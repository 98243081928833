import { gql } from '@apollo/client'
import { Quiz } from 'types/generated/graphql'

export type QuizzesData = {
  quizzes: Quiz[]
}

export type QuizzesVars = {
  filter: {
    ownerGroupId?: string
  }
}

export const QUIZZES = gql`
  query Quizzes($filter: QuizzesFilter) {
    quizzes(filter: $filter) {
      id
      title
      createdAt
      updatedAt
      published
      ownerUser {
        id
        name
      }
      updateUser {
        id
        name
        icon
      }
      ownerGroup {
        id
        name
      }
      design
      content {
        question
        correct
        wrongs
        description
        imageUrl
        answerImageUrl
      }
      answers {
        quizId
        answer
        count
      }
    }
  }
`
