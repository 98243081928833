import { gql } from '@apollo/client'
import { EnquetePub, UpdateEnqueteInputPub } from 'types/generated/graphql'

export type UpdateEnqueteDataPub = {
  updateEnquete: EnquetePub
}

export type UpdateEnqueteVarsPub = {
  input: UpdateEnqueteInputPub
}

export const UPDATE_ENQUETE_PUB = gql`
  mutation UpdateEnquetePub($input: UpdateEnqueteInputPub!) {
    updateEnquetePub(input: $input) {
      id
      published
    }
  }
`
