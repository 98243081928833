import React from 'react'
import { Button, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Role } from 'types/generated/graphql'

interface Props {
  role: Role
}

export const MemberMenu: React.FC<Props> = ({ role }) => {
  return (
    <Menu>
      <MenuButton as={Button} rightIcon={<ChevronDownIcon />} disabled>
        {role}
      </MenuButton>
      <MenuList>
        <MenuItem>Member</MenuItem>
      </MenuList>
    </Menu>
  )
}
