import { gql } from '@apollo/client'
import { Playlist } from 'types/generated/graphql'
import { Quiz } from 'types/generated/graphql'

export type PlaylistsData = {
  playlists: Array<Playlist & { quizzes: Quiz[] }>
}

export type PlaylistsVars = {
  filter: {
    ownerGroupId?: string
  }
}

export const PLAYLISTS = gql`
  query Playlists($filter: PlaylistsFilter) {
    playlists(filter: $filter) {
      id
      name
      description
      published
      design
      quizzes {
        id
        title
        published
        content {
          question
          correct
          wrongs
        }
      }
      imageUrl
      updateUser {
        id
        name
        icon
      }
    }
  }
`
