import React from 'react'
import { Box } from '@chakra-ui/react'
import { Pie } from 'react-chartjs-2'
import 'chartjs-plugin-datalabels'
import { CHART_BG_COLOR } from 'v2utils/constants'
import { Chart as ChartJS, Tooltip, PieController, ArcElement } from 'chart.js'
ChartJS.register(Tooltip, PieController, ArcElement)

const graphOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: true,
      color: 'white',
      formatter: (value: any, context: any) => {
        return value ? value : ''
      },
    },
    tooltip: {
      callbacks: {
        label: (context: any): string => {
          return ''
        },
        title: (tooltipItems: any): string => {
          return ''
        },
      },
    },
    title: {
      display: false,
    },
  },
}
const graphData = {
  labels: [] as number[],
  datasets: [
    {
      label: '',
      data: [] as number[],
      backgroundColor: CHART_BG_COLOR,
    },
  ],
}

export interface Props {
  datas: {
    labels: string[]
    labelsNumber: number[]
    count: number[]
    percent: string[]
  }
}

export const SummaryGraphPie: React.FC<Props> = ({ datas }) => {
  // graphOptionsにツールチップラベルの情報追加
  const tooltipLabels = (context: any): string => {
    const ttLabel = datas.percent[context.dataIndex] || ''
    return ttLabel
  }
  graphOptions.plugins.tooltip.callbacks.label = tooltipLabels
  const tooltipTitles = (tooltipItems: any): string => {
    const ttTitle = datas.labels[tooltipItems[0].dataIndex] || ''
    return ttTitle
  }
  graphOptions.plugins.tooltip.callbacks.title = tooltipTitles

  // graphDataに情報追加
  graphData.labels = datas.labelsNumber
  graphData.datasets[0].data = datas.count

  if (!graphData.labels.length) return <></>
  return (
    <Box mt="24px">
      <Pie data={graphData} options={graphOptions} />
    </Box>
  )
}
