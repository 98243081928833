import { gql } from '@apollo/client'
import { Scalars } from 'types/generated/graphql'

export type DeletePlaylistData = null

export type DeletePlaylistVars = {
  id: Scalars['ID']
}

export const DELETE_PLAYLIST = gql`
  mutation DeletePlaylist($id: ID!) {
    deletePlaylist(id: $id)
  }
`
