import { useMutation } from '@apollo/client'
import {
  DELETE_MEMBER,
  DeleteMemberData,
  DeleteMemberVar,
} from 'graphql/apollo/mutation/group/deleteMember'
import { GROUP } from 'graphql/apollo/query/group'
import { Group } from 'types/generated/graphql'
import { getOwnerList, isOwnerUser } from 'utils/getGroupRole'

export const useRemoveUserFromGroup = (
  group: Group,
  userId: string,
  onClose: () => void
) => {
  const [removeUser, { loading, error }] = useMutation<
    DeleteMemberData,
    DeleteMemberVar
  >(DELETE_MEMBER)
  const handleRemoveMember = async () => {
    if (group.members.length <= 1) {
      window.alert('削除できません。')
      onClose()
      return handleRemoveMember
    }
    if (getOwnerList(group).length <= 1 && isOwnerUser(userId, group)) {
      window.alert('削除できません。')
      onClose()
      return handleRemoveMember
    }
    await removeUser({
      variables: {
        groupId: group.id,
        userId: userId,
      },
      refetchQueries: [{ query: GROUP }, 'Group'],
    })
    if (loading) console.log('loading now')
    if (error) alert(`${userId}の削除において、エラー発生が発生しました`)
    onClose()
  }
  return handleRemoveMember
}
