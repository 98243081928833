import React, { useMemo } from 'react'
import { RouteComponentProps } from '@reach/router'
import { VStack, Flex, Button, Spacer } from '@chakra-ui/react'
import { useNavigate } from '@reach/router'
import { useQuery } from '@apollo/client'

import { Error } from 'components/Common/Error'
import { Layout } from 'components/Common/Layout'
import { Loading } from 'components/Common/Loading'
import { NoData } from 'components/Common/NoData'
import { Unauthorized } from 'components/Common/Unauthorized'
import { useUserProfileContext } from 'state'
import { isOwned } from 'utils/isOwned'
import { ENQUETE, EnqueteData, EnqueteVars } from 'graphql/apollo/query/enquete'
import { AnswerEnquete } from './AnswerEnquete'

type Props = RouteComponentProps<{
  enqueteId: string
}>

export const AnswerEnquetePreview: React.FC<Props> = (props) => {
  const navigate = useNavigate()
  const { userProfile, currentGroup } = useUserProfileContext()
  const { loading, error, data } = useQuery<EnqueteData, EnqueteVars>(ENQUETE, {
    variables: {
      id: props.enqueteId!,
    },
  })

  const enquete = data?.enquete
  const isOwnedEnquete = useMemo(() => {
    return isOwned(enquete, currentGroup, userProfile)
  }, [enquete, currentGroup, userProfile])

  return (
    <Layout pageTitle="アンケート">
      {(() => {
        if (loading) {
          return <Loading />
        }
        if (error) {
          return <Error />
        }
        if (!data || !props.enqueteId) {
          return <NoData />
        }
        if (!isOwnedEnquete) {
          return <Unauthorized />
        }

        return (
          <VStack p={3}>
            <Flex w="100%">
              <Button
                onClick={() => navigate(`/enquete/${props.enqueteId}/check`)}
              >
                プレビューをやめる
              </Button>
              <Spacer />
            </Flex>
            <AnswerEnquete enqueteId={props.enqueteId} isPreview={true} />
          </VStack>
        )
      })()}
    </Layout>
  )
}
