import { Box, useDisclosure } from '@chakra-ui/react'
import { navigate, RouteComponentProps } from '@reach/router'

import { useCreateQuiz } from 'hooks/useCreateQuiz'

import { Layout } from 'components/Common/Layout'
import { QuizForm } from 'components/QuizForm/index'
import { AlertModal } from 'components/Modal/AlertModal'

type Props = RouteComponentProps

export const CreateQuiz: React.FC<Props> = () => {
  const { handleCreateQuiz } = useCreateQuiz()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Layout pageTitle="選択クイズ作成">
      <Box w="100%" h="100%" p={3}>
        <QuizForm
          submitButtonLabel="登録"
          onSubmit={async (values) => {
            try {
              await handleCreateQuiz(values)
              navigate('/')
            } catch (error) {
              onOpen()
            }
          }}
        />
        <AlertModal
          modalBody={'クイズの登録に失敗しました。'}
          isOpen={isOpen}
          onClose={onClose}
        />
      </Box>
    </Layout>
  )
}
