import React, { useEffect, useState } from 'react'
import { useLocation, Link as ReachLink, navigate } from '@reach/router'
import {
  Box,
  Flex,
  Button,
  Image,
  Text,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react'
import { useUserProfileContext } from 'state'
import asideUserBgImage from 'assets/images/aside__user-bg.png'
import userIcon from 'assets/images/quzilla-icon.png'
import gnavActiveMark from 'assets/images/gnav-active-mark.png'
import {
  MdDescription,
  MdViewList,
  MdAddCircleOutline,
  MdLogout,
  MdOutlinePeopleAlt,
} from 'react-icons/md'
import { QuizIcon } from 'assets/icons'
import { AddContentModal } from 'v2components/Modal/AddContentModal'
import { useAuthContext } from 'state'

export interface SidebarProps {
  onShowSidebar?: () => void
  showSidebarButton?: boolean
  pageTitle?: string
}

const navigationItems = [
  {
    name: 'アンケート',
    path: '/v2/enquetes',
    addContentPath: '/v2/create/enquete',
    canCreate: true,
    mdIcon: <MdDescription className="icon" />,
    active: false,
    type: 'enquete',
  },
  {
    name: 'クイズ',
    path: '/v2/quiz',
    addContentPath: '/v2/create/quiz',
    canCreate: true,
    mdIcon: <QuizIcon className="icon" />,
    active: false,
    type: 'quiz',
  },
  {
    name: 'クイズリスト',
    path: '/v2/quizlist',
    addContentPath: '/v2/create/quizlist',
    canCreate: true,
    mdIcon: <MdViewList className="icon" />,
    active: false,
    type: 'quizlist',
  },
  {
    name: 'グループ設定',
    path: '/v2/edit/group',
    addContentPath: '',
    canCreate: false,
    mdIcon: <MdOutlinePeopleAlt className="icon" />,
    active: false,
    type: 'group',
  },
]

export const Sidebar: React.FC<SidebarProps> = () => {
  const { userProfile, currentGroup } = useUserProfileContext()
  const [navItems, setNavItem] = useState(navigationItems)
  const location = useLocation()
  const lastPath = location.pathname
  const setActivatedNavItems = () =>
    setNavItem((prevNavItems) => {
      return prevNavItems.map((item) => ({
        ...item,
        active:
          item.path === lastPath ||
          (lastPath.indexOf(`/create/${item.type}`) !== -1 &&
            lastPath.endsWith(`/create/${item.type}`)) ||
          lastPath.indexOf(`/edit/${item.type}/`) !== -1,
      }))
    })
  useEffect(() => {
    setActivatedNavItems()
  }, [])
  const addContentDisclosure = useDisclosure()
  const auth = useAuthContext()

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        boxSizing="border-box"
        pr="8px"
        width="220px"
      >
        <Box pt="16px" pb="8px">
          <Button
            color="#2B5080"
            fontSize="14px"
            fontWeight="700"
            lineHeight="1.7142857143"
            letterSpacing="0.04em"
            textAlign="center"
            background="linear-gradient(180deg, #FFF 63.07%, #D2EBFA 100%)"
            cursor="pointer"
            filter="drop-shadow(10px 10px 20px rgba(0, 21, 48, 0.2))"
            display="flex"
            alignItems="center"
            borderRadius="25px"
            boxSizing="border-box"
            ml="auto"
            mr="auto"
            p="13px 30px"
            w="160px"
            h="50px"
            _hover={{
              opacity: '0.75',
              background: 'linear-gradient(180deg, #FFF 63.07%, #D2EBFA 100%)',
            }}
            onClick={() => {
              addContentDisclosure.onOpen()
            }}
          >
            <MdAddCircleOutline size="24px" />
            <Text ml="8px">新規作成</Text>
          </Button>
        </Box>
        <Box mt="24px" mb="24px">
          {navItems.map((navigationItem) => {
            if (navigationItem.name === 'グループ設定' && !currentGroup) {
              return <React.Fragment key="hidden" />
            }
            return (
              <Box display="block" key={navigationItem.name} mt="2px">
                <Flex
                  backgroundColor={navigationItem.active ? '#fff' : ''}
                  className={
                    navigationItem.active
                      ? 'sidebar-nav-menu active'
                      : 'sidebar-nav-menu'
                  }
                  fontSize="14px"
                  fontWeight="700"
                  lineHeight="1.7142857143"
                  letterSpacing="0.04em"
                  borderRadius="12px 0px 0px 12px"
                  alignItems="center"
                  p="4px 8px"
                  position="relative"
                  justifyContent="space-between"
                  _after={
                    navigationItem.active
                      ? {
                          content: '""',
                          backgroundImage: gnavActiveMark,
                          display: 'block',
                          mr: '-8px',
                          w: '16px',
                          aspectRatio: '16/80',
                          position: 'absolute',
                          top: '50%',
                          right: '0',
                          transform: 'translate(0, -50%)',
                        }
                      : {}
                  }
                >
                  <Flex
                    as={ReachLink}
                    to={navigationItem.path}
                    cursor="pointer"
                    flexGrow={1}
                    h="40px"
                    alignItems="center"
                  >
                    {navigationItem.mdIcon}
                    <Text ml="8px">{navigationItem.name}</Text>
                  </Flex>

                  {navigationItem.canCreate && (
                    <IconButton
                      isRound={true}
                      variant="solid"
                      aria-label="ADD"
                      as={ReachLink}
                      to={navigationItem.addContentPath}
                      icon={<MdAddCircleOutline size="24px" />}
                      bg={'none'}
                      w="40px"
                      color={navigationItem.active ? 'v2Primary' : '#fff'}
                      onClick={(e) => {
                        e.stopPropagation() // クリックイベントの伝播を停止
                        return
                      }}
                      _hover={
                        navigationItem.active
                          ? {
                              color: '#fff',
                              bg: 'v2Primary',
                            }
                          : {
                              color: 'v2Primary',
                              bg: '#fff',
                            }
                      }
                    />
                  )}
                </Flex>
              </Box>
            )
          })}
        </Box>
        <Box
          backgroundImage={asideUserBgImage}
          backgroundPosition="0px 0px"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          backgroundAttachment="initial"
          backgroundClip="initial"
          backgroundColor="initial"
          filter="drop-shadow(5px 5px 20px rgba(0, 21, 48, 0.3))"
          boxSizing="border-box"
          mt="auto"
          paddingTop="67px"
          pb="13px"
          pl="8px"
          pr="41px"
          width="213px"
          h="191px"
        >
          <Box w="164px">
            <Box
              borderRadius="64px"
              border="2px solid #fff"
              overflow="hidden"
              boxSizing="border-box"
              mb="3px"
              ml="auto"
              mr="auto"
              w="64px"
              h="64px"
              onClick={() => {
                navigate('/v2/mypage')
              }}
              cursor="pointer"
            >
              <Image
                src={userProfile?.user.icon ? userProfile?.user.icon : userIcon}
              />
            </Box>
            <Box
              color="#222"
              fontSize="14px"
              fontWeight="700"
              lineHeight="1.7142857143"
              letterSpacing="0.04em"
              textAlign="center"
              textDecorationLine="underline"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              onClick={() => {
                navigate('/v2/mypage')
              }}
              cursor="pointer"
            >
              {userProfile?.user.name}
            </Box>
            <Box
              color="#222"
              fontSize="12px"
              fontWeight="500"
              lineHeight="1.6666666667"
              textAlign="center"
              textDecorationLine="underline"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              onClick={() => {
                navigate('/v2/mypage')
              }}
              cursor="pointer"
            >
              {userProfile?.user.email}
            </Box>
          </Box>
        </Box>
        <Box mt="16px" ml="8px">
          <Button
            color="#2B5080"
            fontSize="14px"
            fontWeight="700"
            lineHeight="1.7142857143"
            letterSpacing="0.04em"
            textAlign="center"
            background="linear-gradient(180deg, #FFF 63.07%, #D2EBFA 100%)"
            cursor="pointer"
            display="flex"
            alignItems="center"
            borderRadius="20px"
            boxSizing="border-box"
            ml="0px"
            mr="auto"
            p="8px 22px"
            w="160px"
            h="40px"
            _hover={{
              opacity: '0.75',
              background: 'linear-gradient(180deg, #FFF 63.07%, #D2EBFA 100%)',
            }}
            onClick={auth.signOut}
          >
            <MdLogout size="24px" />
            <Text ml="6px">サインアウト</Text>
          </Button>
        </Box>
      </Flex>
      <AddContentModal
        isOpen={addContentDisclosure.isOpen}
        onClose={addContentDisclosure.onClose}
      />
    </>
  )
}
