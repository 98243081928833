import React from 'react'
import {
  Center,
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react'

interface Props {
  modalBody: string
  isOpen: boolean
  onClose: () => void
}

export const AlertModal: React.FC<Props> = ({ modalBody, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>　</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Center>
            <Text fontSize="lg" m={10}>
              {modalBody}
            </Text>
          </Center>
        </ModalBody>
        <ModalFooter>
          <Button
            size="md"
            backgroundColor="#F3B0AE"
            color="#4D3B3E"
            mr={3}
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
