import {
  Circle,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react'
import { CreateQuiz } from './CreateQuiz'
import { CreateEnquete } from './CreateEnquete'

export const ChooseCreateContents: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const button2Color = useColorModeValue('button2Light', 'button2Dark')
  return (
    <>
      <IconButton
        _focus={{ boxShadow: 'none' }}
        colorScheme="red"
        aria-label="クイズ作成ボタン"
        size="lg"
        onClick={onOpen}
        backgroundColor={button2Color}
        icon={
          <Circle size="25px">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 96.993 98.821"
            >
              <g
                id="グループ_133"
                data-name="グループ 133"
                transform="translate(0 26.195)"
              >
                <line
                  id="線_25"
                  data-name="線 25"
                  x2="27.03"
                  transform="translate(13.515 0) rotate(90)"
                  fill="none"
                  stroke="#382121"
                  strokeWidth="8"
                />
                <line
                  id="線_26"
                  data-name="線 26"
                  x2="27.03"
                  transform="translate(0 13.515)"
                  fill="none"
                  stroke="#382121"
                  strokeWidth="8"
                />
              </g>
              <g
                id="クエスチョンマーク"
                transform="translate(43.87) rotate(19)"
              >
                <path
                  id="パス_67"
                  data-name="パス 67"
                  d="M53.612,15.533A28.413,28.413,0,0,0,42.805,4.419,32.174,32.174,0,0,0,26.449,0,36.669,36.669,0,0,0,7.991,4.648a20.633,20.633,0,0,0-6.766,5.843,3.534,3.534,0,0,0,.1,5.434l6.544,5.241a3.535,3.535,0,0,0,4.53-.094,8.883,8.883,0,0,1,3.323-2.892,21,21,0,0,1,10.728-2.6,16.283,16.283,0,0,1,10.565,3.773,11.837,11.837,0,0,1,2.791,3.4,6.212,6.212,0,0,1,.795,2.836,11.778,11.778,0,0,1-1.2,5.544A11.3,11.3,0,0,1,37.26,34a23.958,23.958,0,0,1-5.214,3.729c-2.048,1.156-4.255,2.173-6.485,3.4a20.063,20.063,0,0,0-7.23,6.473,17.518,17.518,0,0,0-2.261,5.034,21.306,21.306,0,0,0-.725,5.658v3.771a3.535,3.535,0,0,0,3.535,3.535H27.4a3.535,3.535,0,0,0,3.535-3.535V58.3a5.918,5.918,0,0,1,.167-1.536,1.909,1.909,0,0,1,.45-.85,6.075,6.075,0,0,1,1.584-1.153c1.278-.719,3.332-1.689,5.658-2.946a37.649,37.649,0,0,0,11.286-8.752A25.86,25.86,0,0,0,54.5,35.421a28.224,28.224,0,0,0,1.68-9.826A21.721,21.721,0,0,0,53.612,15.533Z"
                  transform="translate(0 0)"
                  fill="#382121"
                />
                <path
                  id="パス_68"
                  data-name="パス 68"
                  d="M9.613,0a9.612,9.612,0,1,0,9.609,9.613A9.614,9.614,0,0,0,9.613,0Z"
                  transform="translate(13.527 74.015)"
                  fill="#382121"
                />
              </g>
            </svg>
          </Circle>
        }
      />

      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size="4xl"
        id="ChooseQuizTypeModal"
      >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent>
          <ModalHeader ml="4rem" color="#4D3B3E">
            作成するクイズ、アンケートを選択
          </ModalHeader>
          <ModalCloseButton left={3} top={3} right="auto" size="lg" />
          <ModalBody>
            <SimpleGrid minChildWidth={160} spacing="30px" mb={4}>
              <CreateQuiz />
              <CreateEnquete />
            </SimpleGrid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
