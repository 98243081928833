import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { CHART_BG_COLOR } from 'v2utils/constants'

export interface Props {
  datas: {
    labels: string[]
    labelsNumber: number[]
    count: number[]
    percent: string[]
  }
}

export const SummaryLabels: React.FC<Props> = ({ datas }) => {
  if (!datas) return <Box mt="24px">データが取得できませんでした</Box>

  const labels = datas.labels
  const percents = datas.percent

  return (
    <Box mt="24px">
      {labels.map((label: string, index: number) => (
        <Text
          key={index}
          display="inline-flex"
          alignItems="center"
          fontSize="11px"
          lineHeight="1.6"
          mr="5px"
          _before={{
            h: '11px',
            w: '11px',
            borderRadius: '50%',
            bgColor: CHART_BG_COLOR[index],
            mr: '4px',
            display: 'inline-block',
            content: '""',
          }}
        >
          {label}
          {`（${percents[index]}）`}
        </Text>
      ))}
    </Box>
  )
}
