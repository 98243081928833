import { Enquete, Group, Playlist, Quiz } from 'types/generated/graphql'
import { UserData } from 'graphql/apollo/query/user'

export const isOwned = (
  content: Quiz | Playlist | Enquete | undefined,
  currentGroup: Group | undefined,
  userProfile: UserData | undefined
) => {
  // クイズの読み込みが終わっていて、そのクイズがグループのものならそこにユーザが属しているか、個人のものならユーザが一致しているか
  if (content?.ownerGroup) {
    return content.ownerGroup.id === currentGroup?.id
  }

  if (content?.ownerUser) {
    return content.ownerUser.id === userProfile?.user.id
  }

  return false
}
