import { IconButton, Text, Center, VStack } from '@chakra-ui/react'
import { FaMicrosoft } from 'react-icons/fa'
import { RouteComponentProps } from '@reach/router'

type Props = {
  onSignIn: () => void
} & RouteComponentProps

export const SignIn: React.FC<Props> = ({ onSignIn }) => {
  document.title = 'ログイン'

  return (
    <Center bg="red.100" w="100vw" h="100vh">
      <Center
        position="absolute"
        width="50%"
        height="50%"
        background="#FFFFFF"
        borderRadius="10px"
      >
        <VStack>
          <Text fontSize="5xl">QuzillA</Text>
          <Text fontSize="sm">ログインはこちら</Text>
          <IconButton
            _focus={{ boxShadow: 'none' }}
            width="200px"
            aria-label="Search database"
            icon={<FaMicrosoft />}
            backgroundColor="#F3B0AE"
            onClick={onSignIn}
          />
        </VStack>
      </Center>
    </Center>
  )
}
