import React from 'react'
import { css } from '@emotion/react'

import {
  VStack,
  Text,
  Stack,
  Button,
  Box,
  Image,
  Grid,
  GridItem,
} from '@chakra-ui/react'

import { Quiz, Stats } from 'types/generated/graphql'

const ImageWrapper = css`
  padding-top: 100%;
  position: relative;
  height: 100%;
`
const ImageBox = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #f3b0ae;
`
const ImageWrapperChoice = css`
  padding-top: 40%;
  position: relative;
  height: 100%;
`
const ImageBoxChoice = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export interface QuizProps {
  quiz: Quiz
  quizOptions: string[]
  quizStats?: Stats
  onNext: () => void
  onSelectedChoice: (selectedChoice: string) => void
}
// http://localhost:3000/quiz/hoge
export const MultipleChoicePlayQuestion: React.FC<QuizProps> = ({
  quiz,
  quizOptions,
  quizStats,
  onNext,
  onSelectedChoice,
}) => {
  const design = quiz.design

  return (
    <VStack w={{ base: '90%', md: '60%' }} p={4}>
      {design === 'SIMPLE' || design === 'SERIOUS' ? (
        <Box w={{ base: '100%' }}>
          <Box css={ImageWrapperChoice}>
            <Image
              css={ImageBoxChoice}
              src={quiz.content.imageUrl || '/images/whiteImage.png'}
              alt="リスト画像"
            />
          </Box>
        </Box>
      ) : (
        <Box w={{ base: '100%', md: '60%' }}>
          <Box css={ImageWrapper}>
            <Image
              css={ImageBox}
              src={quiz.content.imageUrl || '/images/whiteImage.png'}
              alt="リスト画像"
            />
          </Box>
        </Box>
      )}
      <Text
        fontSize={{ base: 'md', sm: 'lg' }}
        mb="1rem"
        py="1rem"
        color={`${design}.common.mainText`}
      >
        Q：{quiz.content.question}
        {quizStats &&
          // statsには[正解、不正解1、不正解2…]の順番で入る
          `(正答率${quizStats.stats[0].percent})`}
      </Text>
      <Stack w="100%" mb="2rem" spacing={4} direction="row" align="center">
        <Grid
          w="100%"
          templateRows={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }}
          gap={4}
        >
          {quizOptions.map((quiz, index) => {
            if (!quiz) {
              return null
            }
            return (
              <GridItem
                w="100%"
                rowSpan={{ base: 1, md: 1 }}
                colSpan={{ base: 1, md: 2 }}
                key={quiz + index}
              >
                <Button
                  size="lg"
                  _hover={{
                    bg: `${design}.question.choiceQuestion.afterBackground`,
                  }}
                  border={design === 'SIMPLE' ? '1px' : ''}
                  borderColor={`${design}.question.choiceQuestion.borderColor`}
                  color={`${design}.question.choiceQuestion.label`}
                  bg={`${design}.question.choiceQuestion.background`}
                  w="100%"
                  onClick={() => {
                    onNext()
                    onSelectedChoice(quiz)
                  }}
                >
                  {quiz}
                </Button>
              </GridItem>
            )
          })}
        </Grid>
      </Stack>
    </VStack>
  )
}
