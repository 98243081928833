import React from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { navigate, RouteComponentProps } from '@reach/router'
import { Layout } from 'v2components/Common/Layout'
import { EnqueteForm } from 'v2components/EnqueteForm/index'
import { AlertModal } from 'v2components/Modal/AlertModal'
import { useCreateEnquete } from 'hooks/useCreateEnquete'
import { CompletedModal } from 'v2components/Modal/CompletedModal'

type Props = RouteComponentProps

export const CreateEnquete: React.FC<Props> = () => {
  const completeDisclosure = useDisclosure()
  const alertDisclosure = useDisclosure()
  const { handleCreateEnquete } = useCreateEnquete()

  const handleCloseCompletedModal = () => {
    completeDisclosure.onClose()
    navigate(`/v2/enquetes`)
  }

  return (
    <Layout pageTitle="アンケート作成">
      <EnqueteForm
        submitButtonLabel="登録"
        headerTitle="アンケート作成"
        onSubmit={async (values) => {
          try {
            await handleCreateEnquete(values)
            completeDisclosure.onOpen()
          } catch (error) {
            alertDisclosure.onOpen()
          }
        }}
      />
      <CompletedModal
        modalBody={'アンケートを作成しました'}
        isOpenCompletedModal={completeDisclosure.isOpen}
        handleCloseCompletedModal={handleCloseCompletedModal}
        contentType="enquete"
      />
      <AlertModal
        modalBody={'アンケートの登録に失敗しました。'}
        isOpen={alertDisclosure.isOpen}
        onClose={alertDisclosure.onClose}
      />
    </Layout>
  )
}
