import React from 'react'
import {
  VStack,
  HStack,
  Text,
  Button,
  Stack,
  Box,
  Flex,
  Heading,
} from '@chakra-ui/react'
import { BsCircle } from 'react-icons/bs'
import { ImCross } from 'react-icons/im'

import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
} from 'react-share'
import truncate from 'lodash/truncate'

import { Quiz, Stats } from 'types/generated/graphql'
import { LayoutEmbedQuiz } from 'components/Common/LayoutEmbedQuiz'

export interface QuizProps {
  quiz: Quiz
  quizOptions: string[]
  quizStats?: Stats
  selectedChoice: string | undefined
}

export const MultipleChoiceEmbedPlayAnswer: React.FC<QuizProps> = ({
  quiz,
  quizOptions,
  quizStats,
  selectedChoice,
}) => {
  const correct = quiz.content.correct
  const { origin } = new URL(window.location.href)
  const shareUrl = `${origin}/quiz/${quiz.id}/play`
  const sendOnLink = quiz.snsInfo.destinationLink ?? ''
  const sendOnLinkText = quiz.snsInfo.destinationLinkText
    ? truncate(quiz.snsInfo.destinationLinkText, {
        length: 25,
      })
    : 'リンク'

  const deafultSnsShareText = quiz.snsInfo.shareText
    ? quiz.snsInfo.shareText
    : `「${quiz.content.question}」を解きました！`

  const description = () => {
    let stats = ''
    if (quiz.content.description) {
      return (
        <Heading
          fontSize="18px"
          fontWeight="800"
          lineHeight="1.6em"
          m="0 0 0.4em"
          minW="100%"
          textShadow="0 0 5px rgba(0, 0, 0, 0.5)"
          display="flex"
          _before={{
            content: '"解説"',
            display: 'inline-block',
            textShadow: 'none',
            w: '3em',
            h: '1.8em',
            lineHeight: '1.8em',
            borderRadius: '6px',
            fontSize: '70%',
            fontWeight: '800',
            px: '3px',
            mr: '.3em',
            mt: '.25em',
            bg: '#ffaf00',
            color: '#fff',
            textAlign: 'center',
          }}
        >
          <Text>{quiz.content.description}</Text>
        </Heading>
      )
    }
    if (quizStats) {
      stats = `(正答率${quizStats.stats[0].percent})`
    }
    return (
      <Heading
        fontSize="18px"
        display="flex"
        fontWeight="800"
        textShadow="0 0 5px rgba(0, 0, 0, 0.5)"
        lineHeight="1.6em"
        m="0 0 0.3em"
        minWidth="100%"
        _before={{
          content: '"Q"',
          display: 'inline-block',
          textShadow: 'none',
          w: '1.8em',
          minWidth: '1.8em',
          h: '1.8em',
          lineHeight: '1.5em',
          borderRadius: '6px',
          fontSize: '70%',
          fontWeight: '800',
          mr: '.3em',
          mt: '.25em',
          bg: '#ffaf00',
          color: '#fff',
          textAlign: 'center',
        }}
      >
        {quiz.content.question}
        {stats}
      </Heading>
    )
  }

  return (
    <LayoutEmbedQuiz quiz={quiz} imageUrl={quiz.content.answerImageUrl!}>
      <VStack w="100%" p="25px 0 15px 0" spacing={3}>
        <Text
          fontSize="24px"
          fontWeight="800"
          h="35px"
          px="2.5"
          w="4em"
          mb=".5em"
          color="#fff"
          lineHeight="1.3em"
          border="1px #fff solid"
          textShadow="0 0 5px rgba(0, 0, 0, 0.5)"
          borderRadius="6px"
          textAlign="center"
        >
          {selectedChoice === correct ? '正解' : '不正解'}
        </Text>

        <Box
          fontSize={{ base: 'md', sm: 'lg' }}
          color="white"
          w="100%"
          alignItems="left"
          display="flex"
        >
          {description()}
        </Box>

        <Stack w="100%" spacing={3} direction="row" align="center">
          <Box w="100%">
            {quizOptions.map((quiz, index) => {
              // 選択肢の順番を保持するためループ処理を行う
              // 正解の場合は、正解のみを表示する
              // 不正解の場合は、正解と選択した値を表示する
              // 正解の場合
              if (quiz !== correct && selectedChoice !== quiz) {
                return null
              }
              return (
                <Box w="100%" key={'quizAnswer-' + index}>
                  <Box
                    p="15px"
                    bg="rgba(255, 255, 255, 0.9)"
                    w="100%"
                    mb="10px"
                    borderRadius="8px"
                    lineHeight="1.6em"
                    fontWeight="500"
                  >
                    {(() => {
                      if (quiz === correct) {
                        return (
                          <Flex color="#333" position="relative">
                            <Box as="span" position="absolute">
                              <BsCircle size="30px" color="#ffaf00" />
                            </Box>
                            <Text fontWeight="bold" pl="3" ml="7">
                              {quiz}
                            </Text>
                          </Flex>
                        )
                      }

                      if (quiz === selectedChoice) {
                        // 不正解の場合
                        return (
                          <Flex color="#333" position="relative">
                            <Box as="span" position="absolute">
                              <ImCross size="30px" color="#ffaf00" />
                            </Box>
                            <Text pl="3" ml="7">
                              {quiz}
                            </Text>
                          </Flex>
                        )
                      }
                    })()}
                  </Box>
                </Box>
              )
            })}
          </Box>
        </Stack>

        <HStack>
          <TwitterShareButton
            url={shareUrl}
            title={deafultSnsShareText}
            hashtags={['QuzillA']}
          >
            <TwitterIcon size={40} borderRadius={10} />
          </TwitterShareButton>
          <Box as="span" mr="10px" />
          <FacebookShareButton
            url={shareUrl}
            quote={deafultSnsShareText}
            hashtag={'QuzillA'}
          >
            <FacebookIcon size={40} borderRadius={10} />
          </FacebookShareButton>
        </HStack>

        {sendOnLink && (
          <Button
            textAlign="center"
            w="16em"
            as="a"
            target="_blank"
            h="2.5em"
            bg="#ffaf00"
            color="#fff"
            fontSize="18px"
            fontWeight="800"
            href={sendOnLink}
          >
            {sendOnLinkText}
          </Button>
        )}
      </VStack>
    </LayoutEmbedQuiz>
  )
}
