import React from 'react'
import { Icon } from '@chakra-ui/react'
import { MdAddAPhoto, MdCloudUpload, MdWarning } from 'react-icons/md'
import { IconType } from 'react-icons'

interface Props {
  imageUrl: string
  isDragAccept: boolean
  isDragReject: boolean
}

export const StatusIcon: React.FC<Props> = ({
  imageUrl,
  isDragAccept,
  isDragReject,
}) => {
  const getIconType = (): IconType => {
    if (isDragAccept) return MdCloudUpload
    if (isDragReject) return MdWarning

    return MdAddAPhoto
  }

  return <Icon as={getIconType()} w={'12'} h={'12'} color="whiteAlpha.800" />
}
