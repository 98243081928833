import { Text } from '@chakra-ui/react'
import { RouteComponentProps } from '@reach/router'
import { Layout } from 'v2components/Common/Layout'
import { SettingLayout } from 'v2components/Common/SettingLayout'

type Props = RouteComponentProps

export const CreateGroup: React.FC<Props> = () => {
  return (
    <Layout pageTitle="Create Group">
      <SettingLayout>
        <Text>Create Group</Text>
      </SettingLayout>
    </Layout>
  )
}
