import React from 'react'
import { Button, Center, Flex, Text } from '@chakra-ui/react'
import { useNavigate } from '@reach/router'
import { RiQuestionnaireLine } from 'react-icons/ri'

export const CreateEnquete: React.FC = () => {
  const navigate = useNavigate()
  return (
    <Button
      colorScheme="red"
      backgroundColor="#F3B0AE"
      height={160}
      onClick={() => navigate('/enquete/create')}
    >
      <Flex flexDirection="column">
        <Center mb={3}>
          <RiQuestionnaireLine color="#4D3B3E" size={80} />
        </Center>
        <Center>
          <Text color="#4D3B3E">アンケート</Text>
        </Center>
      </Flex>
    </Button>
  )
}
