import React, { useState, useCallback } from 'react'
import { useMutation } from '@apollo/client'
import {
  Spacer,
  IconButton,
  Stack,
  HStack,
  Image,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { AddIcon, CheckIcon } from '@chakra-ui/icons'

import {
  AddMemberData,
  AddMemberVar,
  ADD_MEMBER,
} from 'graphql/apollo/mutation/group/addMember'
import { USER } from 'graphql/apollo/query/user'

import { User, Group, Member } from 'types/generated/graphql'
import { useUserProfileContext } from 'state'

import { AlertModal } from 'components/Modal/AlertModal'

export interface Props {
  user: User
  isEditable: boolean
}

// クイズ一覧ページで使うクイズカードコンポーネント
export const UserCard: React.FC<Props> = ({ user, isEditable }) => {
  const { userProfile, currentGroup } = useUserProfileContext()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [modalBody, setModalBody] = useState<string>('')

  const [addMember, { loading }] = useMutation<AddMemberData, AddMemberVar>(
    ADD_MEMBER
  )

  const handleAddMember = useCallback(
    async (group: Group, user: User) => {
      const variables = {
        groupId: group.id,
        userId: user.id,
      }
      return await addMember({
        variables: variables,
        refetchQueries: [USER],
        awaitRefetchQueries: true,
      })
    },
    [addMember]
  )
  if (!currentGroup || !userProfile) return <></>
  const members = currentGroup.members.map((member: Member) => member.user.id)
  const isMember = members.includes(user.id)
  return (
    <HStack borderWidth="1px" borderRadius={5} p={1}>
      <Image
        boxSize={10}
        borderRadius="full"
        src={user.icon || '/images/quiz_bg.png'}
        fallbackSrc={'/images/quiz_bg.png'}
        alt="リスト画像"
      />
      <Stack>
        <Text fontSize={'sm'}>{user.name}</Text>
        <Text fontSize={'sm'}>@{user.aliasId}</Text>
      </Stack>
      <Spacer />
      <AlertModal modalBody={modalBody} isOpen={isOpen} onClose={onClose} />
      {isEditable ? (
        isMember ? (
          <IconButton
            icon={<CheckIcon />}
            aria-label="Member Minus Button"
            disabled
          />
        ) : (
          <IconButton
            isLoading={loading}
            icon={<AddIcon />}
            aria-label="Member Add Button"
            onClick={async () => {
              try {
                await handleAddMember(currentGroup, user)
              } catch (e) {
                setModalBody('ユーザー追加に失敗しました。')
                onOpen()
              }
            }}
          />
        )
      ) : (
        <IconButton
          icon={<AddIcon />}
          aria-label="Member Add Button"
          disabled
        />
      )}
    </HStack>
  )
}
