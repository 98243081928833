import { Box, Text, Image, Icon, GridItem } from '@chakra-ui/react'
import { CheckCircleIcon } from '@chakra-ui/icons'
import { QuizDesignType, ListDesignType } from 'types/generated/graphql'
import { FormDesignRadio } from 'components/Common/InputPageComponent/FormDesignRadio'

type Props = {
  design: QuizDesignType | ListDesignType
  displayName: string
  isSelected: boolean
  sampleImgUrl: string
  sampleImgAlt: string
  onSelect: () => void
}

export const DesignTemplate = ({
  design,
  displayName,
  isSelected,
  sampleImgUrl,
  sampleImgAlt,
  onSelect,
}: Props) => {
  return (
    <GridItem
      pos={'relative'}
      borderWidth="1px"
      borderRadius={10}
      rowSpan={{ base: 1, sm: 1 }}
      colSpan={{ base: 1, sm: 1 }}
      onClick={onSelect}
    >
      {isSelected && (
        <Icon
          as={CheckCircleIcon}
          pos={'absolute'}
          top={2}
          left={2}
          boxSize={5}
          color="green.300"
        />
      )}
      <label>
        <Image
          objectFit="fill"
          w="100%"
          h="150px"
          src={sampleImgUrl}
          alt={sampleImgAlt}
        />
        <Box p="2">
          <Box display="flex" alignItems="baseline">
            <Box
              color="gray.500"
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="xs"
              textTransform="uppercase"
              ml="2"
            />
          </Box>
          <Box
            mt="1"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            textAlign="center"
          >
            <FormDesignRadio name="design" value={design} />
            <Text>{displayName}</Text>
          </Box>
        </Box>
      </label>
    </GridItem>
  )
}
