import { Box, Image, VStack, Text } from '@chakra-ui/react'

export const NoQuizInfo: React.FC = () => {
  return (
    <Box
      w="100%"
      h="100%"
      position="relative"
      borderRadius="16px"
      overflow="hidden"
    >
      <Image
        position="absolute"
        w="100%"
        h="100%"
        objectFit="cover"
        src="/images/whiteImage.png"
        filter="brightness(65%)"
      />
      <VStack
        w="100%"
        h="100%"
        minHeight="480px"
        justify="center"
        position="relative"
        color="white"
        p="15px 30px"
        fontSize={{ base: 'md', sm: 'lg' }}
      >
        <Text fontWeight={600}>
          クイズリストにクイズが存在しません。プレビューを表示するにはクイズリストにクイズを追加してください。
        </Text>
      </VStack>
    </Box>
  )
}
