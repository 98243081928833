import React, { useMemo } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'

import { ImageComponent } from 'components/ImageUpload/ImageComponent'
import { IconComponent } from 'components/ImageUpload/IconComponent'
import { CoverComponent } from 'components/ImageUpload/CoverComponent'

const activeStyle = {
  border: '3px',
  borderColor: '#2196f3',
  borderStyle: 'dashed',
}

const acceptStyle = {
  border: '3px',
  borderColor: '#00e676',
  borderStyle: 'dashed',
}

const rejectStyle = {
  border: '3px',
  borderColor: '#ff1744',
  borderStyle: 'dashed',
}

export type ImageType = 'NOMAL' | 'ICON' | 'COVER'
type ContainerProps = {
  onDrop: DropzoneOptions['onDrop']
  imageUrl: string
  type?: ImageType
}

export const ImageUpload: React.FC<ContainerProps> = ({
  onDrop,
  imageUrl,
  type = 'NOMAL',
}) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: 'image/*', onDrop, maxFiles: 1 })

  const style = useMemo(() => {
    return {
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }
  }, [isDragActive, isDragReject, isDragAccept])

  const imageArea = () => {
    switch (type) {
      case 'ICON':
        return (
          <IconComponent
            imageUrl={imageUrl}
            style={getRootProps({ style })}
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
          />
        )
      case 'COVER':
        return (
          <CoverComponent
            imageUrl={imageUrl}
            style={getRootProps({ style })}
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
          />
        )
      case 'NOMAL':
        return (
          <ImageComponent
            imageUrl={imageUrl}
            style={getRootProps({ style })}
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
          />
        )
    }
  }

  return (
    <>
      <input {...getInputProps()} />
      {imageArea()}
    </>
  )
}
