import { Layout } from 'v2components/Common/Layout'
import React, { useState, useMemo } from 'react'
import { RouteComponentProps } from '@reach/router'
import { useQuery } from '@apollo/client'
import { useDisclosure } from '@chakra-ui/react'
import { Quiz } from 'types/generated/graphql'
import { QUIZZES, QuizzesData, QuizzesVars } from 'graphql/apollo/query/quizzes'
import { useUserProfileContext } from 'state'
import { QuizPlaylistForm } from 'v2components/QuizPlaylistForm'
import { AlertModal } from 'v2components/Modal/AlertModal'
import { CompletedModal } from 'v2components/Modal/CompletedModal'
import { Error } from 'v2components/Common/Error'
import { Loading } from 'v2components/Common/Loading'
import { navigate } from '@reach/router'
import { useCreateQuizlist } from 'v2hooks/useCreateQuizlist'

type Props = RouteComponentProps

export const CreateQuizList: React.FC<Props> = () => {
  const { currentGroup } = useUserProfileContext()
  const [selectedQuizIds, setSelectedQuizIds] = useState<string[]>([])
  const completeDisclosure = useDisclosure()
  const alertDisclosure = useDisclosure()

  const quizzesResult = useQuery<QuizzesData, QuizzesVars>(QUIZZES, {
    variables: {
      filter: {
        ownerGroupId: currentGroup?.id,
      },
    },
  })

  const allQuizzes = useMemo<Quiz[]>(() => {
    return quizzesResult?.data?.quizzes || []
  }, [quizzesResult])

  const selectedQuizzes = useMemo<Quiz[]>(() => {
    return allQuizzes.filter(({ id }) => selectedQuizIds.includes(id))
  }, [allQuizzes, selectedQuizIds])

  const unselectedQuizzes = useMemo<Quiz[]>(() => {
    return allQuizzes.filter(({ id }) => !selectedQuizIds.includes(id))
  }, [allQuizzes, selectedQuizIds])

  const handleCloseCompletedModal = () => {
    completeDisclosure.onClose()
    navigate(`/v2/quizlist`)
  }

  const { handleCreateQuizlist } = useCreateQuizlist()

  return (
    <Layout pageTitle="クイズリスト作成">
      {(() => {
        if (quizzesResult.loading) {
          return <Loading />
        }

        if (quizzesResult.error || !quizzesResult.data) {
          return <Error />
        }

        return (
          <QuizPlaylistForm
            submitButtonLabel="登録"
            headerTitle="クイズリスト作成"
            selectedQuizzes={selectedQuizzes}
            unselectedQuizzes={unselectedQuizzes}
            selectedQuizIds={selectedQuizIds}
            setSelectedQuizIds={setSelectedQuizIds}
            handleSubmit={async (values) => {
              try {
                await handleCreateQuizlist(values)
                completeDisclosure.onOpen()
              } catch (error) {
                alertDisclosure.onOpen()
              }
            }}
          />
        )
      })()}
      <CompletedModal
        modalBody={'クイズリストを登録しました'}
        isOpenCompletedModal={completeDisclosure.isOpen}
        handleCloseCompletedModal={handleCloseCompletedModal}
        contentType="quizlist"
      />
      <AlertModal
        modalBody={'クイズリストの登録に失敗しました。'}
        isOpen={alertDisclosure.isOpen}
        onClose={alertDisclosure.onClose}
      />
    </Layout>
  )
}
