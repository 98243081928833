import React from 'react'
import { navigate } from '@reach/router'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react'
import { useUserProfileContext } from 'state'

type Props = {
  isOpen: boolean
  isMe: boolean
  onClose: () => void
  handleRemoveUser: () => void
}

export const RemoveUserFormGroupModal: React.FC<Props> = ({
  isOpen,
  isMe,
  onClose,
  handleRemoveUser,
}) => {
  const { handleCurrentGroup } = useUserProfileContext()
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody textAlign="center" pt="60px" pb="40px" fontSize="20">
            グループから削除しますか？
          </ModalBody>
          <ModalCloseButton right={3} left="auto" size="lg" />
          <ModalFooter m="auto" pt="0" pb="60px">
            <Stack direction="row" spacing={4}>
              <Button
                bg="buttonBasic"
                width="140px"
                height="40px"
                borderRadius="40px"
                onClick={onClose}
                _hover={{
                  bg: 'buttonBasicHover',
                }}
              >
                キャンセル
              </Button>
              <Button
                bg="buttonPrimary"
                width="140px"
                height="40px"
                borderRadius="40px"
                color="#fff"
                _hover={{
                  bg: 'buttonPrimaryHover',
                }}
                onClick={async () => {
                  try {
                    await handleRemoveUser()
                    if (isMe) {
                      handleCurrentGroup(undefined)
                      navigate('/v2/enquetes')
                    }
                  } catch (error) {
                    console.log('fail remove')
                    onClose()
                  }
                }}
              >
                削除
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
