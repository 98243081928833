import React, { useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
} from '@chakra-ui/react'
import { DeleteIcon, SelectIcon } from 'assets/icons'
import { Group, Member } from 'types/generated/graphql'
import { RemoveUserFormGroupModal } from 'v2components/Modal/RemoveUserFromGroupModal'
import { AddUserModal } from 'v2components/Modal/AddUserModal'
import { useRemoveUserFromGroup } from 'v2hooks/useRemoveUserFromGroup'
import { useUpdateGroupMember } from 'v2hooks/useUpdateGroupMember'
import { useUserProfileContext } from 'state'
import { getOwnerList, isOwnerUser } from 'utils/getGroupRole'

type Props = {
  members: Member[]
  currentGroup: Group
}

export const UserList: React.FC<Props> = ({ members, currentGroup }) => {
  const { userProfile } = useUserProfileContext()
  const [removeUserId, setRemoveUserId] = useState('')
  const [isMe, setIsMe] = useState(false)
  const [updateTarget, setUpdateTarget] = useState('')
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false)
  const [isOpenAddUserModal, setIsOpenAddUserModal] = useState(false)
  const onOpenRemoveModal = () => setIsOpenRemoveModal(true)
  const onCloseRemoveModal = () => setIsOpenRemoveModal(false)
  const handleOpenRemoveModal = (userId: string) => {
    if (userId === userProfile?.user.id) {
      setIsMe(true)
    }
    setRemoveUserId(userId)
    onOpenRemoveModal()
  }
  const handleCloseRemoveModal = () => {
    setIsMe(false)
    setRemoveUserId('')
    onCloseRemoveModal()
  }
  const onOpenAddUserModal = () => setIsOpenAddUserModal(true)
  const onCloseAddUserModal = () => setIsOpenAddUserModal(false)
  const handleOpenAddUserModal = () => {
    onOpenAddUserModal()
  }
  const handleCloseAddUserModal = () => {
    onCloseAddUserModal()
  }
  const removeUser = useRemoveUserFromGroup(
    currentGroup,
    removeUserId,
    onCloseRemoveModal
  )
  const HandleRemoveUser = async () => {
    await removeUser()
  }
  const isOnlyOwner = !(getOwnerList(currentGroup).length > 1)
  const canRemoveUser = (member: Member) =>
    !(isOwnerUser(member.user.id, currentGroup) && isOnlyOwner)
  const { handleUpdateGroupMember, loading: updateGroupLoading } =
    useUpdateGroupMember()
  return (
    <Flex
      backgroundColor="#EAF7FF"
      borderRadius="10px"
      flexDirection="column"
      p="20px"
    >
      <Box>
        <Button
          bg="buttonPrimary"
          marginLeft="auto"
          marginRight="auto"
          width="480px"
          color="#fff"
          fontSize="14px"
          fontWeight={500}
          lineHeight={1.7142857143}
          letterSpacing="0.08em"
          textAlign="center"
          backgroundColor="#0086D2"
          borderRadius="20px"
          cursor="pointer"
          display="flex"
          justifyContent="center"
          alignItems="center"
          boxSizing="border-box"
          padding="8px 16px"
          onClick={handleOpenAddUserModal}
          _hover={{
            bg: 'buttonPrimaryHover',
          }}
        >
          ユーザーを追加する
        </Button>
      </Box>
      <Box mt="16px" w="100%" position="relative">
        <Box
          position={{
            base: 'sticky',
            md: '-webkit-sticky',
            lg: '-webkit-sticky',
          }}
          top={0}
          left={0}
          zIndex={1}
        >
          <Flex>
            <Box
              flex={0}
              flexGrow={0}
              flexShrink={0}
              flexBasis="160px"
              pl="16px"
              pr="12px"
              fontWeight={500}
              backgroundColor="#EAF7FF"
              borderBottom="1px solid #C0C0C0"
              pt="8px"
              pb="7px"
              color="#333"
              fontSize="14px"
              lineHeight={1.7142857143}
            >
              NAME
            </Box>
            <Box
              flex={0}
              flexGrow={0}
              flexShrink={0}
              flexBasis="240px"
              pl="12px"
              pr="12px"
              fontWeight={500}
              backgroundColor="#EAF7FF"
              borderBottom="1px solid #C0C0C0"
              pt="8px"
              pb="7px"
              color="#333"
              fontSize="14px"
              lineHeight={1.7142857143}
            >
              EMAIL
            </Box>
            <Box
              color="#333"
              fontSize="14px"
              lineHeight={1.7142857143}
              flex="1 1 100%"
              pl="12px"
              pr="12px"
              fontWeight={500}
              backgroundColor="#EAF7FF"
              borderBottom="1px solid #C0C0C0"
              pt="8px"
              pb="7px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              width="428px"
            >
              コメント
            </Box>
            <Box
              color="#333"
              fontSize="14px"
              lineHeight={1.7142857143}
              flexGrow={0}
              flexShrink={0}
              fontWeight={500}
              backgroundColor="#EAF7FF"
              borderBottom="1px solid #C0C0C0"
              pt="8px"
              pb="7px"
              flexBasis="120px"
              pl="12px"
              pr="20px"
            >
              権限
            </Box>
            <Box
              color="#333"
              fontSize="14px"
              lineHeight={1.7142857143}
              flexGrow={0}
              flexShrink={0}
              pr="76px"
              fontWeight={500}
              backgroundColor="#EAF7FF"
              borderBottom="1px solid #C0C0C0"
              pt="8px"
              pb="7px"
              flexBasis="24px"
              pl="20px"
            />
          </Flex>
        </Box>
      </Box>
      <Box overflowY="auto">
        {members.map((member) => {
          return (
            <Flex key={member.user.id}>
              <Box
                color="#333"
                fontSize="14px"
                lineHeight={1.7142857143}
                backgroundColor="#fff"
                borderBottom="1px solid #C0C0C0"
                pt="16px"
                pb="15px"
                flexShrink={0}
                flexGrow={0}
                flexBasis="160px"
                pl="16px"
                pr="12px"
              >
                {member.user.name}
              </Box>
              <Box
                color="#333"
                fontSize="14px"
                lineHeight={1.7142857143}
                backgroundColor="#fff"
                borderBottom="1px solid #C0C0C0"
                pt="16px"
                pb="15px"
                flexShrink={0}
                flexGrow={0}
                flexBasis="240px"
                pl="12px"
                pr="12px"
              >
                {member.user.email}
              </Box>
              <Box
                color="#333"
                fontSize="14px"
                lineHeight={1.7142857143}
                flex="1 1 auto"
                backgroundColor="#fff"
                borderBottom="1px solid #C0C0C0"
                pt="16px"
                pb="15px"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                width="428px"
                pl="12px"
                pr="12px"
              >
                {member.user.comment}
              </Box>
              <Box
                color="#333"
                fontSize="14px"
                lineHeight={1.7142857143}
                backgroundColor="#fff"
                borderBottom="1px solid #C0C0C0"
                pt="16px"
                pb="15px"
                flexGrow={0}
                flexShrink={0}
                flexBasis="120px"
                pl="12px"
                pr="20px"
              >
                {/* OWNERがひとりの場合はOWNERユーザーの権限を変更できないようにする */}
                {isOnlyOwner && member.role === 'OWNER' ? (
                  <Box
                    backgroundColor={
                      member.role === 'OWNER' ? '#FFD000' : '#A6DEFF'
                    }
                    fontSize="14px"
                    fontWeight={400}
                    lineHeight={1.7142857143}
                    borderRadius="24px"
                    boxSizing="border-box"
                    width="120px"
                    height="24px"
                    position="relative"
                    textAlign="center"
                  >
                    {member.role}
                  </Box>
                ) : (
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<SelectIcon w="8px" h="5px" />}
                      pr="8px"
                      backgroundColor={
                        member.role === 'OWNER' ? '#FFD000' : '#A6DEFF'
                      }
                      fontSize="14px"
                      fontWeight={400}
                      lineHeight={1.7142857143}
                      borderRadius="24px"
                      cursor="pointer"
                      boxSizing="border-box"
                      width="120px"
                      height="24px"
                      position="relative"
                      alignItems="center"
                      disabled={
                        updateGroupLoading && updateTarget === member.user.id
                      }
                    >
                      {updateGroupLoading && updateTarget === member.user.id ? (
                        <Spinner size="xs" />
                      ) : (
                        member.role
                      )}
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        onClick={async () => {
                          try {
                            setUpdateTarget(member.user.id)
                            await handleUpdateGroupMember(
                              currentGroup,
                              member.user,
                              'OWNER'
                            )
                          } catch (e) {
                            console.error(e)
                          } finally {
                            setUpdateTarget('')
                          }
                        }}
                      >
                        Owner
                      </MenuItem>
                      <MenuItem
                        onClick={async () => {
                          try {
                            setUpdateTarget(member.user.id)
                            await handleUpdateGroupMember(
                              currentGroup,
                              member.user,
                              'MEMBER'
                            )
                          } catch (e) {
                            console.error(e)
                          } finally {
                            setUpdateTarget('')
                          }
                        }}
                      >
                        Member
                      </MenuItem>
                    </MenuList>
                  </Menu>
                )}
              </Box>
              <Box
                color="#333"
                fontSize="14px"
                lineHeight={1.7142857143}
                pr="16px"
                backgroundColor="#fff"
                borderBottom="1px solid #C0C0C0"
                pt="16px"
                pb="15px"
                flexGrow={0}
                flexShrink={0}
                flexBasis="24px"
                pl="20px"
              >
                {canRemoveUser(member) ? (
                  <Box onClick={() => handleOpenRemoveModal(member.user.id)}>
                    <DeleteIcon
                      w="24px"
                      h="24px"
                      color="#EB5757"
                      cursor="pointer"
                    />
                  </Box>
                ) : (
                  <Box w="24px" h="24px" />
                )}
              </Box>
            </Flex>
          )
        })}
      </Box>
      <RemoveUserFormGroupModal
        isOpen={isOpenRemoveModal}
        isMe={isMe}
        onClose={handleCloseRemoveModal}
        handleRemoveUser={HandleRemoveUser}
      />
      <AddUserModal
        isOpen={isOpenAddUserModal}
        onClose={handleCloseAddUserModal}
        group={currentGroup}
        groupMembers={members}
      />
    </Flex>
  )
}
