import React from 'react'
import { Box, Badge, Text } from '@chakra-ui/react'

import { Group, Member } from 'types/generated/graphql'
import { UserData } from 'graphql/apollo/query/user'

import { getOwnerList, isOwnerUser } from 'utils/getGroupRole'

interface Props {
  group: Group
  userProfile: UserData
}

export const GroupBox: React.FC<Props> = ({ group, userProfile }) => {
  return (
    <Box
      maxW="sm"
      maxH="sm"
      height="11rem"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      _hover={{ bg: 'primaryLight' }}
    >
      <Box p="6">
        <Box display="flex" alignItems="baseline">
          {isOwnerUser(userProfile.user.id, group) && (
            <Badge borderRadius="full" px="2" bg="primary" color="borderLight">
              Owner
            </Badge>
          )}
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            ml="2"
          >
            {group.members?.length} 人のメンバー
          </Box>
        </Box>
        <Box
          mt="1"
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          noOfLines={1}
        >
          {group.name}
        </Box>
        <Box mt="2">
          <Box as="span" color="gray.600" fontSize="sm">
            オーナー
          </Box>
          <Box as="span" ml="2" color="gray.600" fontSize="sm">
            {getOwnerList(group).map((adminUser: Member) => {
              return (
                <Text key={adminUser.user.id}>@{adminUser.user.aliasId}</Text>
              )
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
