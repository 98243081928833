import React from 'react'
import { Spacer, Stack, HStack, Image, Text } from '@chakra-ui/react'

import { Member } from 'types/generated/graphql'
import { UserData } from 'graphql/apollo/query/user'
import { useUserProfileContext } from 'state'

import { RoleMenu } from 'components/MemberDrawer/RoleMenu'
import { DeleteIconButton } from 'components/MemberDrawer/DeleteIconButton'

export interface Props {
  userProfile: UserData
  member: Member
  isEditable: boolean
}

// クイズ一覧ページで使うクイズカードコンポーネント
export const MemberCard: React.FC<Props> = ({
  userProfile,
  member,
  isEditable,
}) => {
  const { currentGroup } = useUserProfileContext()

  return (
    <HStack borderWidth="1px" borderRadius={5} p={1}>
      <Image
        boxSize={10}
        borderRadius="full"
        src={member.user.icon || '/images/quiz_bg.png'}
        fallbackSrc={'/images/quiz_bg.png'}
        alt="リスト画像"
      />
      <Stack>
        <Text fontSize={'sm'}>{member.user.name}</Text>
        <Text fontSize={'sm'}>@{member.user.aliasId}</Text>
      </Stack>
      <Spacer />
      <RoleMenu
        isEditable={isEditable}
        userProfile={userProfile}
        currentGroup={currentGroup}
        member={member}
        role={member.role}
      />
      {currentGroup && (
        <DeleteIconButton
          isEditable={isEditable}
          userProfile={userProfile}
          member={member}
          currentGroup={currentGroup}
        />
      )}
    </HStack>
  )
}
