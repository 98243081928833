import React, { useState, useMemo } from 'react'
import { RouteComponentProps } from '@reach/router'
import { useQuery } from '@apollo/client'
import { useDisclosure } from '@chakra-ui/react'

import { Layout } from 'v2components/Common/Layout'
import { Unauthorized } from 'components/Common/Unauthorized'
import { QuizPlaylistForm } from 'v2components/QuizPlaylistForm'
import {
  PLAYLIST,
  PlaylistData,
  PlaylistVars,
} from 'graphql/apollo/query/playlist'
import { QUIZZES, QuizzesData, QuizzesVars } from 'graphql/apollo/query/quizzes'
import { useUserProfileContext } from 'state'
import { Quiz } from 'types/generated/graphql'
import { isOwned } from 'utils/isOwned'
import { AlertModal } from 'v2components/Modal/AlertModal'
import { Loading } from 'v2components/Common/Loading'
import { CompletedModal } from 'v2components/Modal/CompletedModal'
import { Error } from 'v2components/Common/Error'
import { PreviewModal } from 'v2components/Modal/PreviewModal'
import { useUpdateQuizlist } from 'v2hooks/useUpdateQuizlist'

type Props = RouteComponentProps<{
  quizlistId: string
}>

export const EditQuizList: React.FC<Props> = ({ quizlistId }) => {
  const [selectedQuizIds, setSelectedQuizIds] = useState<string[]>([])
  const { currentGroup, userProfile } = useUserProfileContext()
  const alertDisclosure = useDisclosure()
  const completeDisclosure = useDisclosure()
  const previewDisclosure = useDisclosure()
  const [previewItemId, setPreviewItemId] = useState('')

  const playlistResult = useQuery<PlaylistData, PlaylistVars>(PLAYLIST, {
    variables: {
      id: quizlistId!,
    },
    skip: !quizlistId,
    onCompleted: (data) => {
      const quizIds = data.playlist.quizzes.map(({ id }) => id)
      setSelectedQuizIds(quizIds)
    },
  })

  const isOwnedQuizPlaylist = useMemo(() => {
    return isOwned(playlistResult.data?.playlist, currentGroup, userProfile)
  }, [currentGroup, playlistResult, userProfile])

  const quizzesResult = useQuery<QuizzesData, QuizzesVars>(QUIZZES, {
    variables: {
      filter: {
        ownerGroupId: currentGroup?.id,
      },
    },
  })

  const allQuizzes = useMemo<Quiz[]>(() => {
    return quizzesResult?.data?.quizzes || []
  }, [quizzesResult])

  const selectedQuizzes = useMemo<Quiz[]>(() => {
    return allQuizzes
      .filter(({ id }) => selectedQuizIds.includes(id))
      .sort(({ id: aId }, { id: bId }) => {
        return selectedQuizIds.indexOf(aId) - selectedQuizIds.indexOf(bId)
      })
  }, [allQuizzes, selectedQuizIds])

  const unselectedQuizzes = useMemo<Quiz[]>(() => {
    return allQuizzes.filter(({ id }) => !selectedQuizIds.includes(id))
  }, [allQuizzes, selectedQuizIds])

  const handleOpenPreviewModal = (itemId: string) => {
    setPreviewItemId(itemId)
    previewDisclosure.onOpen()
  }

  const handleClosePreviewModal = () => {
    setPreviewItemId('')
    previewDisclosure.onClose()
  }

  const { handleUpdateQuizlist } = useUpdateQuizlist({ id: quizlistId! })

  return (
    <Layout pageTitle="クイズリスト編集">
      {(() => {
        if (playlistResult.loading || quizzesResult.loading) {
          return <Loading />
        }

        if (
          playlistResult.error ||
          quizzesResult.error ||
          !playlistResult.data ||
          !quizzesResult.data
        ) {
          return <Error />
        }

        if (!isOwnedQuizPlaylist) {
          return <Unauthorized />
        }

        return (
          <QuizPlaylistForm
            initialValues={playlistResult.data.playlist}
            submitButtonLabel="更新"
            headerTitle="クイズリスト編集"
            selectedQuizzes={selectedQuizzes}
            unselectedQuizzes={unselectedQuizzes}
            selectedQuizIds={selectedQuizIds}
            setSelectedQuizIds={setSelectedQuizIds}
            handleSubmit={async (values) => {
              try {
                await handleUpdateQuizlist(values)
                completeDisclosure.onOpen()
              } catch (error) {
                alertDisclosure.onOpen()
              }
            }}
          />
        )
      })()}
      <CompletedModal
        modalBody={'クイズリストを更新しました'}
        isOpenCompletedModal={completeDisclosure.isOpen}
        handleCloseCompletedModal={completeDisclosure.onClose}
        itemId={quizlistId}
        contentType="quizlist"
        handleOpenPreviewModal={handleOpenPreviewModal}
      />
      <PreviewModal
        pmIsOpen={previewDisclosure.isOpen}
        onClosePm={handleClosePreviewModal}
        itemId={previewItemId}
        contentType="quizlist"
      />
      <AlertModal
        modalBody={'クイズリストの更新に失敗しました。'}
        isOpen={alertDisclosure.isOpen}
        onClose={alertDisclosure.onClose}
      />
    </Layout>
  )
}
