import React from 'react'
import { Box } from '@chakra-ui/react'
import { Bar } from 'react-chartjs-2'
import 'chartjs-plugin-datalabels'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { CHART_BG_COLOR } from 'v2utils/constants'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
)

const graphOptions = {
  indexAxis: 'y' as const,
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      ticks: {
        font: {
          size: 11,
        },
      },
      grid: {
        display: false,
      },
    },
    x: {
      show: false,
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: true,
      color: 'white',
      formatter: (value: any, context: any) => {
        return value ? value : ''
      },
    },
    tooltip: {
      callbacks: {
        label: (context: any): string => {
          return ''
        },
        title: (tooltipItems: any): string => {
          return ''
        },
      },
    },
    title: {
      display: false,
    },
  },
  elements: {
    bar: {
      borderWidth: 0,
      //   barThickness: 10,
    },
  },
}
const graphData = {
  labels: [] as number[],
  datasets: [
    {
      label: '',
      data: [] as number[],
      backgroundColor: CHART_BG_COLOR,
    },
  ],
}

export interface Props {
  datas: {
    labels: string[]
    labelsNumber: number[]
    count: number[]
    percent: string[]
  }
}

export const SummaryGraphBar: React.FC<Props> = ({ datas }) => {
  // graphOptionsにツールチップラベルの情報追加
  const tooltipLabels = (context: any): string => {
    const ttLabel = datas.percent[context.dataIndex] || ''
    return ttLabel
  }
  graphOptions.plugins.tooltip.callbacks.label = tooltipLabels
  const tooltipTitles = (tooltipItems: any): string => {
    const ttTitle = datas.labels[tooltipItems[0].dataIndex] || ''
    return ttTitle
  }
  graphOptions.plugins.tooltip.callbacks.title = tooltipTitles

  // graphDataに情報追加
  graphData.labels = datas.labelsNumber
  graphData.datasets[0].data = datas.count

  const boxHeight = graphData.labels.length * 24 + 'px'

  if (!graphData.labels.length) return <></>
  return (
    <Box h={boxHeight} mt="24px">
      <Bar data={graphData} options={graphOptions} />
    </Box>
  )
}
