import { gql } from '@apollo/client'
import { PlaylistPub, UpdatePlaylistInputPub } from 'types/generated/graphql'

export type UpdatePlaylistDataPub = {
  updatePlaylist: PlaylistPub
}

export type UpdatePlaylistVarsPub = {
  input: UpdatePlaylistInputPub
}

export const UPDATE_PLAYLIST_PUB = gql`
  mutation UpdatePlaylistPub($input: UpdatePlaylistInputPub!) {
    updatePlaylistPub(input: $input) {
      id
      published
    }
  }
`
