import { gql } from '@apollo/client'
import { Scalars, Role } from 'types/generated/graphql'

export type UpdateMemberData = null

export type UpdateMemberVar = {
  groupId: Scalars['ID']
  userId: Scalars['ID']
  role: Role
}

export const UPDATE_MEMBER = gql`
  mutation UpdateMember($groupId: ID!, $userId: ID!, $role: Role!) {
    updateMember(groupId: $groupId, userId: $userId, role: $role)
  }
`
