import React, { useState, useCallback, useMemo } from 'react'
import dayjs from 'dayjs'
import { useQuery } from '@apollo/client'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { css } from '@emotion/react'
import {
  Box,
  Container,
  VStack,
  HStack,
  Center,
  Text,
  Image,
  IconButton,
  Flex,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Tooltip,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react'
import { CalendarIcon, RepeatClockIcon } from '@chakra-ui/icons'
import { IoArrowBack } from 'react-icons/io5'
import { MdContentCopy, MdRemoveRedEye, MdModeEdit } from 'react-icons/md'

import { Error } from 'components/Common/Error'
import { Layout } from 'components/Common/Layout'
import { Loading } from 'components/Common/Loading'
import { NoData } from 'components/Common/NoData'
import { EnqueteCardRoot } from 'components/Common/EnqueteCardRoot'
import { Unauthorized } from 'components/Common/Unauthorized'
import { DeleteModal } from 'components/Modal/DeleteModal'
import { ENQUETE, EnqueteData, EnqueteVars } from 'graphql/apollo/query/enquete'
import { useDeleteEnquete } from 'hooks/useDeleteEnquete'
import { useUserProfileContext } from 'state'
import { isOwned } from 'utils/isOwned'
import { getEmbedHeight } from 'utils/answeredEnquete'
import { ImEmbed } from 'react-icons/im'
import { VscPreview } from 'react-icons/vsc'

const ImageWrapper = css`
  padding-top: 100%;
  position: relative;
  height: 100%;
`
const ImageBox = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #f3b0ae;
`

type Props = RouteComponentProps<{ enqueteId: string }>

export const EnqueteCheck: React.FC<Props> = (props) => {
  const navigate = useNavigate()
  const [copySuccess, setCopySuccess] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const publishedColor = useColorModeValue('primaryLight', 'secondaryLight')
  const unpublishedColor = useColorModeValue('primaryDark', 'secondaryDark')
  const { userProfile, currentGroup } = useUserProfileContext()
  const { loading, error, data } = useQuery<EnqueteData, EnqueteVars>(ENQUETE, {
    variables: {
      id: props.enqueteId!,
    },
  })

  const enquete = data?.enquete
  const isOwnedEnquete = useMemo(() => {
    return isOwned(enquete, currentGroup, userProfile)
  }, [enquete, currentGroup, userProfile])

  const handleCopy = useCallback(() => {
    const { origin } = new URL(window.location.href)
    const shareUrl = `${origin}/enquete/${props.enqueteId}/answer`
    onOpen()
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        setCopySuccess('回答URLがコピーされました😆')
      })
      .catch(() => {
        setCopySuccess('回答URLのコピーに失敗しました…😢')
      })
  }, [onOpen, props.enqueteId])

  const handleEmbedCodeCopy = useCallback(() => {
    if (!enquete) {
      return
    }
    const { origin } = new URL(window.location.href)
    // iframe埋込時に表示される枠の高さを最後にプラスする
    const height = getEmbedHeight(enquete) + 25
    const code = `<script>window.addEventListener('message', function (e) {if (e.origin !== '${origin}') {return}; const iframe = document.getElementById('${enquete.id}'); iframe.height = e.data[0] + 'px'}, false);</script><iframe id="${enquete.id}" src="${origin}/enquete/${enquete.id}/embed/answer" height="${height}px" width="100%" scrolling="no" frameBorder="no"></iframe>`
    onOpen()
    navigator.clipboard
      .writeText(code)
      .then(() => {
        setCopySuccess('埋め込み用コードがコピーされました😆')
      })
      .catch(() => {
        setCopySuccess('埋め込み用コードのコピーに失敗しました…😢')
      })
  }, [onOpen, enquete])

  const handleSubmit = useDeleteEnquete(props.enqueteId!, onClose)

  return (
    <Layout pageTitle="アンケートチェックページ">
      {(() => {
        if (loading) {
          return <Loading />
        }
        if (error) {
          return <Error />
        }
        if (!data || !enquete) {
          return <NoData />
        }
        if (!isOwnedEnquete) {
          return <Unauthorized />
        }

        return (
          <Container maxW="container.md" p={0}>
            <VStack py={2}>
              <Flex w="100%">
                <IconButton
                  _focus={{ boxShadow: 'none' }}
                  variant="outline"
                  aria-label="Return Enquete"
                  color="#4D3B3E"
                  onClick={() => navigate(`/enquetes`)}
                  icon={<IoArrowBack />}
                />
                <Spacer />
                <DeleteModal handleSubmit={handleSubmit} />
              </Flex>
              <VStack w="100%" borderWidth="1px" spacing="0">
                {enquete?.published ? (
                  <Center w="100%" bg={publishedColor}>
                    <Text fontWeight="bold">公開中</Text>
                  </Center>
                ) : (
                  <Center w="100%" bg={unpublishedColor}>
                    <Text fontWeight="bold">非公開</Text>
                  </Center>
                )}
                <Box display={{ sm: 'flex' }} w="100%">
                  {enquete.imageUrl ? (
                    <Box flexShrink={0} w={{ base: '100%', sm: '30%' }}>
                      <Box css={ImageWrapper}>
                        <Image
                          css={ImageBox}
                          src={enquete.imageUrl}
                          alt="リスト画像"
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Center w={{ base: '100%', sm: '30%' }} h="200px">
                      <Text>no image</Text>
                    </Center>
                  )}
                  <VStack p={{ base: 1, sm: 2 }} w="100%">
                    <HStack w="100%">
                      <Text fontSize="md" mr={2}>
                        <CalendarIcon boxSize="16px" mr={1} mb={1} />
                        {dayjs(enquete.createdAt).format('YYYY-MM-DD')}
                      </Text>
                      <Text fontSize="md" mr={2}>
                        <RepeatClockIcon boxSize="16px" mr={1} mb={1} />
                        {dayjs(enquete.updatedAt).format('YYYY-MM-DD')}
                      </Text>
                    </HStack>
                    <EnqueteCardRoot enquete={enquete} />
                  </VStack>
                </Box>
              </VStack>
              <HStack pt={3}>
                <Tooltip hasArrow label="回答URLコピー" bg="red.600">
                  <IconButton
                    colorScheme="red"
                    aria-label="回答URLコピー"
                    isRound
                    icon={<MdContentCopy />}
                    onClick={handleCopy}
                  />
                </Tooltip>
                <Spacer />
                <Tooltip
                  hasArrow
                  label={
                    enquete.published
                      ? '埋め込み用コードコピー'
                      : '非公開のためコピーできません'
                  }
                  bg="red.600"
                  shouldWrapChildren
                >
                  <IconButton
                    colorScheme="red"
                    aria-label="埋め込み用コードコピー"
                    isRound
                    icon={<ImEmbed />}
                    onClick={handleEmbedCodeCopy}
                    disabled={!enquete.published}
                  />
                </Tooltip>
                <Spacer />
                <Tooltip hasArrow label="回答プレビュー" bg="red.600">
                  <IconButton
                    colorScheme="red"
                    aria-label="回答プレビュー"
                    isRound
                    icon={<MdRemoveRedEye />}
                    onClick={() =>
                      navigate(`/enquete/${props.enqueteId}/preview`)
                    }
                  />
                </Tooltip>
                <Spacer />
                <Tooltip hasArrow label="埋め込み用回答プレビュー" bg="red.600">
                  <IconButton
                    colorScheme="red"
                    aria-label="埋め込み用回答プレビュー"
                    isRound
                    icon={<VscPreview />}
                    onClick={() =>
                      navigate(`/enquete/${props.enqueteId}/embed/preview`)
                    }
                  />
                </Tooltip>
                <Spacer />
                <Tooltip hasArrow label="編集" bg="red.600">
                  <IconButton
                    colorScheme="red"
                    aria-label="編集"
                    isRound
                    icon={<MdModeEdit />}
                    onClick={() => navigate(`/enquete/${props.enqueteId}/edit`)}
                  />
                </Tooltip>
              </HStack>
              <Text fontSize="xs" color="blackAlpha.700" pt={3}>
                ID : {props.enqueteId}
              </Text>
            </VStack>

            <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
              <ModalOverlay />
              <ModalContent>
                <ModalCloseButton left={3} right="auto" size="lg" />
                <ModalBody textAlign="center" p="3rem" fontSize="20">
                  {copySuccess}
                </ModalBody>
              </ModalContent>
            </Modal>
          </Container>
        )
      })()}
    </Layout>
  )
}
