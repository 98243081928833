import React, { useState, useCallback, CSSProperties } from 'react'
import { Center, Grid, GridItem, Text } from '@chakra-ui/react'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableLocation,
  DraggableProvidedDraggableProps,
} from 'react-beautiful-dnd'
import type { Quiz } from 'types/generated/graphql'
import { SelectedQuizCard } from './SelectedQuizCard'
import { UnselectedQuizCard } from './UnselectedQuizCard'

type Props = {
  selectedQuizzes: Quiz[]
  unselectedQuizzes: Quiz[]
  setSelectedQuizIds: (quizIds: string[]) => void
}

// クイズ一覧ページで使うクイズカードコンポーネント
export const CardDndRoot: React.FC<Props> = ({
  selectedQuizzes,
  unselectedQuizzes,
  setSelectedQuizIds,
}) => {
  const [selected, _setSelected] = useState<Quiz[]>(selectedQuizzes)
  const setSelected = useCallback(
    (quizzes: Quiz[]) => {
      _setSelected(quizzes)
      setSelectedQuizIds(quizzes.map(({ id }) => id))
    },
    [setSelectedQuizIds]
  )

  const [unselected, setUnselected] = useState<Quiz[]>(unselectedQuizzes)

  const reorder = (list: Quiz[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  // 未選択<->選択済み の移動
  const move = (
    source: Quiz[],
    destination: Quiz[],
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation
  ) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)
    return {
      [droppableSource.droppableId]: sourceClone,
      [droppableDestination.droppableId]: destClone,
    }
  }

  const grid = 8
  const getItemStyle = (
    isDragging: Boolean,
    draggableStyle: DraggableProvidedDraggableProps['style']
  ): CSSProperties => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    position: 'relative',
    width: '100%',
    // padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',
    // styles we need to apply on draggables
    ...draggableStyle,
  })

  const getListStyle = (isDraggingOver: Boolean) => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: grid,
    height: '100%',
  })

  const getList = (id: string): Quiz[] => {
    return id === 'unselected' ? unselected : selected
  }

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result
    // dropped outside the list
    if (!destination) {
      return
    }
    if (source.droppableId === destination.droppableId) {
      // 移動しないとき
      const items: Quiz[] = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      )
      if (source.droppableId === 'selected') {
        setSelected(items)
      } else {
        setUnselected(items)
      }
    } else {
      // 移動するとき
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      )
      setUnselected(result.unselected)
      setSelected(result.selected)
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid templateColumns="repeat(2, 1fr)" gap={2}>
        <GridItem colSpan={1}>
          <Center>
            <Text>クイズ一覧</Text>
          </Center>
          <Droppable droppableId="unselected">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {unselected.map((item: Quiz, index: number) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        key={item.id + 'div'}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <UnselectedQuizCard quiz={item} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </GridItem>

        <GridItem colSpan={1}>
          <Center>
            <Text>選択済みクイズ</Text>
          </Center>
          <Droppable droppableId="selected">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {selected.map((item: Quiz, index: number) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        key={item.id + 'div'}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <SelectedQuizCard quiz={item} index={index} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </GridItem>
      </Grid>
    </DragDropContext>
  )
}
