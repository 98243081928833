import React, { useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import {
  Center,
  Box,
  Button,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import { userProfileFormSchema } from 'services/validators/UserProfileFormSchema'
import { useUserProfileContext } from 'state'

import { useQuery } from '@apollo/client'
import { USER, UserData, UserVars } from 'graphql/apollo/query/user'

import { Layout } from 'components/Common/Layout'
import { FormInput } from 'components/Common/InputPageComponent/FormInput'
import { FormTextarea } from 'components/Common/InputPageComponent/FormTextarea'
import { useEditUser } from 'hooks/useEditUser'
import { FormUploadImage } from 'components/Common/InputPageComponent/FormUploadImage'
import { UserEditedModal } from 'components/Modal/UserEditedModal'
import { Loading } from 'components/Common/Loading'
import { NoData } from 'components/Common/NoData'
import { Error } from 'components/Common/Error'

type Props = RouteComponentProps

export const EditUser: React.FC<Props> = () => {
  const [modalBody, setModalBody] = useState<string>('')
  const [isMutationSucceeded, setIsMutationSucceeded] = useState<boolean>(false)
  const { userProfile } = useUserProfileContext()
  const { handleEditUser } = useEditUser({ userProfile: userProfile })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const buttonNextColor = useColorModeValue('buttonNextLight', 'buttonNextDark')
  const userId = userProfile?.user.id!
  const { loading, error, data } = useQuery<UserData, UserVars>(USER, {
    variables: {
      id: userId,
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />
  if (!data) return <NoData />

  const content = {
    aliasId: data.user.aliasId,
    name: data.user.name,
    comment: data.user.comment,
    icon: data.user.icon,
    coverImage: data.user.coverImage,
  }
  return (
    <Layout pageTitle="ユーザー編集">
      <Box p="3" w="100%" h="100%">
        <UserEditedModal
          modalBody={modalBody}
          isOpen={isOpen}
          onClose={onClose}
          isMutationSucceeded={isMutationSucceeded}
          userId={userProfile!.user.id}
        />
        <Formik
          initialValues={userProfileFormSchema.cast(content, {
            stripUnknown: true,
          })}
          validationSchema={userProfileFormSchema}
          onSubmit={async (values) => {
            try {
              console.log('values:', values)
              await handleEditUser(values)
              setIsMutationSucceeded(true)
              setModalBody(`ユーザー情報を変更しました。`)
              onOpen()
            } catch (e) {
              console.log('e:', e)
              setIsMutationSucceeded(false)
              setModalBody('変更に失敗しました。')
              onOpen()
            }
          }}
        >
          {({ values, errors, touched, isSubmitting, isValid }) => {
            return (
              <Form>
                <Box w="100%" position="relative" pb={'7rem'}>
                  <FormUploadImage name="coverImage" type="COVER" />
                  <Center
                    position="absolute"
                    top="10rem"
                    left={{ base: 'calc(50% - 5rem)', sm: '1rem' }}
                    p="2px"
                    bg="white"
                    border="2rem"
                    borderRadius="full"
                  >
                    <FormUploadImage name="icon" type="ICON" />
                  </Center>
                </Box>
                <FormInput
                  name="aliasId"
                  label="ユーザーID"
                  isRequired={true}
                />
                <FormInput
                  name="name"
                  label="ユーザーネーム"
                  isRequired={true}
                />
                <FormTextarea name="comment" label="コメント" />
                <Button
                  bg={buttonNextColor}
                  w="100%"
                  variant="outline"
                  aria-label="add group"
                  type="submit"
                  disabled={!values.name || !values.aliasId || !isValid}
                  isLoading={isSubmitting}
                >
                  変更
                </Button>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </Layout>
  )
}
