import { Image, Center, VStack, Text } from '@chakra-ui/react'
import logo from 'assets/images/logo512.png'

export const NoData: React.FC = () => {
  return (
    <Center w="100%" h="90vh">
      <VStack>
        <Image src={logo} w="50px" h="50px" alt="logo" />
        <Text fontSize="3xl">データがありません...</Text>
      </VStack>
    </Center>
  )
}
