import { useState, useCallback } from 'react'
import { FormikValues } from 'formik'

import { Confirm } from 'components/UserProfileForm/Create/Confirm'
import { Input } from 'components/UserProfileForm/Create/Input'

interface Props {
  isValid: boolean
  isSubmitting: boolean
  values: FormikValues
}
const ProgressStatus = {
  INPUT_DATA: 'INPUT_DATA',
  CONFIRM_DATA: 'CONFIRM_DATA',
} as const

export const CreateForm: React.FC<Props> = ({
  isValid,
  isSubmitting,
  values,
}) => {
  const [progressStatus, setProgressStatus] = useState<
    keyof typeof ProgressStatus
  >(ProgressStatus.INPUT_DATA)
  const handleNext = useCallback(() => {
    setProgressStatus(ProgressStatus.CONFIRM_DATA)
  }, [])
  const handleBack = useCallback(() => {
    setProgressStatus(ProgressStatus.INPUT_DATA)
  }, [])

  switch (progressStatus) {
    case ProgressStatus.CONFIRM_DATA:
      return (
        <Confirm
          name={values.name}
          aliasId={values.aliasId}
          handleBack={handleBack}
          handleNext={handleNext}
          isSubmitting={isSubmitting}
        />
      )
    default:
      return (
        <Input
          name={values.name}
          aliasId={values.aliasId}
          handleNext={handleNext}
          isValid={isValid}
        />
      )
  }
}
