import React from 'react'
import { Stack, Text, Box } from '@chakra-ui/react'
import { FormInput } from 'v2components/Common/InputPageComponent/FormInput'
import { FormTextarea } from 'v2components/Common/InputPageComponent/FormTextarea'
import { FormUploadImage } from 'v2components/Common/InputPageComponent/FormUploadImage'

export const CommonSetting: React.FC = () => {
  return (
    <Stack spacing="24px">
      <Box>
        <Text fontWeight="500" mb="8px">
          背景画像
        </Text>
        <FormUploadImage name="imageUrl" />
      </Box>
      <FormInput name="name" label="リストの名前" isRequired={true} />
      <FormTextarea name="description" label="リストの説明" />
    </Stack>
  )
}
