import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  createContext,
} from 'react'
import { Formik, Form, Field, FieldProps } from 'formik'
import dayjs from 'dayjs'
import {
  Container,
  Box,
  Center,
  Flex,
  FormControl,
  Checkbox,
  Button,
  IconButton,
  Text,
} from '@chakra-ui/react'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'

import { Enquete, EnqueteStats, DisplayUnit } from 'types/generated/graphql'
import {
  EnqueteFormSchema,
  EnqueteFormValues,
} from 'services/validators/EnqueteFormSchema'
import { FormInput } from 'components/Common/InputPageComponent/FormInput'
import { FormInputOptions } from 'components/Common/InputPageComponent/FormInputOptions'
import { FormTextarea } from 'components/Common/InputPageComponent/FormTextarea'
import { EnqueteImageForm } from './EnqueteImageForm'
import { FormDateTime } from '../Common/InputPageComponent/FormDateTime'
import { FormRadio } from '../Common/InputPageComponent/FormRadio'

type Props = {
  initialValues?: Enquete
  enqueteStats?: EnqueteStats
  submitButtonLabel: string
  onSubmit: (values: EnqueteFormValues) => void
}

type FormDateProps = {
  setDeadlineFlg: React.Dispatch<React.SetStateAction<boolean>>
  deadlineFlg: boolean
  answerPeriodFlg: boolean
}
export const FormDateContext = createContext<FormDateProps>({
  setDeadlineFlg: () => {},
  deadlineFlg: false,
  answerPeriodFlg: false,
})

type FormOptionProps = {
  setInvalidFlg: React.Dispatch<React.SetStateAction<boolean>>
}
export const FormOptionContext = createContext<FormOptionProps>({
  setInvalidFlg: () => {},
})

export const EnqueteForm: React.FC<Props> = ({
  initialValues,
  enqueteStats,
  submitButtonLabel,
  onSubmit,
}) => {
  const getStatLabel = (index: number) => {
    if (!enqueteStats?.stats[index]) {
      return ''
    }
    return `　${enqueteStats?.stats[index].count}票(${enqueteStats?.stats[index].percent})`
  }
  const [optionsCsv, setOptionsCsv] = useState(() => {
    if (!initialValues) {
      return [
        { name: 'optionsCsv.0', label: '選択肢1', placeholder: '選択肢1' },
        { name: 'optionsCsv.1', label: '選択肢2', placeholder: '選択肢2' },
      ]
    }

    return initialValues.optionsCsv.split(',').map((_, i) => ({
      name: `optionsCsv.${i}`,
      label: `選択肢${i + 1}${getStatLabel(i)}`,
      placeholder: `選択肢${i + 1}`,
    }))
  })

  const delOptionsCsv = useCallback(
    (values: EnqueteFormValues) => {
      optionsCsv.pop()
      setOptionsCsv([...optionsCsv])
      values.optionsCsv.pop()
    },
    [optionsCsv, setOptionsCsv]
  )

  const addOptionsCsv = useCallback(
    (values: EnqueteFormValues) => {
      setOptionsCsv([
        ...optionsCsv,
        {
          name: `optionsCsv.${optionsCsv.length}`,
          label: `選択肢${optionsCsv.length + 1}`,
          placeholder: `選択肢${optionsCsv.length + 1}`,
        },
      ])
      values.optionsCsv.push('')
    },
    [optionsCsv, setOptionsCsv]
  )

  const content = useMemo<EnqueteFormValues>(() => {
    if (!initialValues) {
      return EnqueteFormSchema.getDefault()
    }

    return {
      title: initialValues.title,
      question: initialValues.question,
      periodDisplayFlag: initialValues.periodDisplayFlag,
      numberOfVoteDisplayFlag: initialValues.numberOfVoteDisplayFlag,
      showResult: initialValues.showResult,
      resultText: initialValues.resultText ?? '',
      allowDuplicateAnswer: initialValues.allowDuplicateAnswer,
      deadline: dayjs(initialValues.deadline).format('YYYY-MM-DD HH:mm'),
      startDate: dayjs(initialValues.startDate).format('YYYY-MM-DD HH:mm'),
      optionsCsv: initialValues.optionsCsv.split(','),
      published: initialValues.published,
      imageUrl: initialValues.imageUrl ?? '',
      displayUnit: initialValues.displayUnit,
    }
  }, [initialValues])

  const [answerPeriodFlg, setAnswerPeriodFlg] = useState<boolean>(
    content.periodDisplayFlag
  )
  const [deadlineFlg, setDeadlineFlg] = useState<boolean>(false)
  const [invalidFlg, setInvalidFlg] = useState<boolean>(true)
  const enqueteUrl = window.location.href

  useEffect(() => {
    if (enqueteUrl.includes('edit')) {
      setInvalidFlg(false)
    }
  }, [enqueteUrl])

  return (
    <Formik
      initialValues={EnqueteFormSchema.cast(content, {
        stripUnknown: true,
      })}
      validationSchema={EnqueteFormSchema}
      onSubmit={onSubmit}
      validateOnBlur
    >
      {({ values, errors, touched, isSubmitting, isValid, validateForm }) => {
        return (
          <Form>
            <Container maxW="container.md">
              <Flex direction="column">
                <EnqueteImageForm />
                <Box mb="1rem">
                  <FormInput
                    name="title"
                    label="タイトル"
                    placeholder="タイトル: 40文字制限です"
                    annotation="*「タイトル」はQuzillA管理画面上でのみ表示されます"
                    isRequired
                  />
                  <FormTextarea
                    name="question"
                    label="質問文"
                    placeholder="質問文: 120文字制限です"
                    isRequired
                  />

                  <Field name="periodDisplayFlag">
                    {({ form }: FieldProps) => (
                      <FormControl
                        // name="periodDisplayFlag"
                        display="flex"
                        alignItems="center"
                        mb={4}
                      >
                        <Checkbox
                          name="periodDisplayFlag"
                          colorScheme="red"
                          defaultChecked={values.periodDisplayFlag}
                          onChange={() => {
                            form.setFieldValue(
                              'periodDisplayFlag',
                              !values.periodDisplayFlag
                            )
                            setAnswerPeriodFlg(!values.periodDisplayFlag)
                          }}
                        >
                          回答期限を表示する
                        </Checkbox>
                      </FormControl>
                    )}
                  </Field>
                  <FormDateContext.Provider
                    value={{
                      deadlineFlg,
                      answerPeriodFlg,
                      setDeadlineFlg,
                    }}
                  >
                    <Flex direction={{ base: 'column', md: 'row' }}>
                      <FormDateTime name="startDate" label="回答開始日" />
                      <FormDateTime
                        name="deadline"
                        label="回答終了日"
                        isRequired={answerPeriodFlg}
                      />
                    </Flex>
                    {answerPeriodFlg && deadlineFlg ? (
                      <Text fontSize="sm" color="#E53E3E" pb={2}>
                        「回答期限を表示する」をチェックしている場合、回答終了日の入力が必要です
                      </Text>
                    ) : (
                      <Text fontSize="sm" color="#E53E3E" pb={2}>
                        {errors.startDate}
                        {errors.deadline}
                      </Text>
                    )}
                  </FormDateContext.Provider>
                  <FormRadio
                    name="displayUnit"
                    label="結果画面で表示する単位"
                    values={[
                      DisplayUnit.Percentage,
                      DisplayUnit.Vote,
                      DisplayUnit.Both,
                    ]}
                    radioBtnLabels={['％', '票数', '％・票数']}
                    isRequired
                  />
                </Box>
                <Field name="numberOfVoteDisplayFlag">
                  {({ form }: FieldProps) => (
                    <FormControl
                      //   name="numberOfVoteDisplayFlag"
                      display="flex"
                      alignItems="center"
                      mb={4}
                    >
                      <Checkbox
                        name="numberOfVoteDisplayFlag"
                        colorScheme="red"
                        defaultChecked={values.numberOfVoteDisplayFlag}
                        onChange={() => {
                          form.setFieldValue(
                            'numberOfVoteDisplayFlag',
                            !values.numberOfVoteDisplayFlag
                          )
                        }}
                      >
                        結果画面に総投票数を表示する
                      </Checkbox>
                    </FormControl>
                  )}
                </Field>
                <Box mb="1rem">
                  {enqueteStats && (
                    <Text fontSize="sm" mb="1rem">
                      総投票数:{enqueteStats.total}件
                    </Text>
                  )}
                  <FormOptionContext.Provider
                    value={{
                      setInvalidFlg,
                    }}
                  >
                    {optionsCsv.map((option) => (
                      <FormInputOptions
                        key={option.name}
                        name={option.name}
                        label={option.label}
                        placeholder={`${option.placeholder} : 20文字制限です`}
                      />
                    ))}
                  </FormOptionContext.Provider>
                  {touched.optionsCsv &&
                    errors.optionsCsv &&
                    errors.optionsCsv !== '選択肢は2つ以上必要です' && (
                      <Text fontSize="sm" color="#E53E3E" pb={2}>
                        {errors.optionsCsv}
                      </Text>
                    )}
                  <Text fontSize="1px" color="blackAlpha.500">
                    ※ 選択肢の上限数は20個です
                  </Text>
                  <Center>
                    {optionsCsv.length < 20 && (
                      <IconButton
                        colorScheme="red"
                        aria-label="選択肢欄追加"
                        isRound
                        icon={<AddIcon />}
                        onClick={() => addOptionsCsv(values)}
                        mr={2}
                      />
                    )}
                    {optionsCsv.length > 2 && (
                      <IconButton
                        colorScheme="blue"
                        aria-label="選択肢欄削除"
                        isRound
                        icon={<MinusIcon />}
                        onClick={() => {
                          delOptionsCsv(values)
                          validateForm()
                        }}
                      />
                    )}
                  </Center>
                </Box>
                <Field name="showResult">
                  {({ form }: FieldProps) => (
                    <FormControl display="flex" alignItems="center" mb={4}>
                      <Checkbox
                        name="showResult"
                        colorScheme="red"
                        defaultChecked={values.showResult}
                        onChange={() => {
                          form.setFieldValue('showResult', !values.showResult)
                        }}
                      >
                        結果を表示する
                      </Checkbox>
                    </FormControl>
                  )}
                </Field>
                <FormInput
                  name="resultText"
                  label="結果非表示時テキスト"
                  placeholder="結果非表示時に表示されるテキストです"
                />
                <Field name="allowDupulicateAnswer">
                  {({ form }: FieldProps) => (
                    <FormControl display="flex" alignItems="center" mb={4}>
                      <Checkbox
                        name="allowDuplicateAnswer"
                        colorScheme="red"
                        defaultChecked={values.allowDuplicateAnswer}
                        onChange={() => {
                          form.setFieldValue(
                            'allowDuplicateAnswer',
                            !values.allowDuplicateAnswer
                          )
                        }}
                      >
                        多重投票を許可する
                      </Checkbox>
                    </FormControl>
                  )}
                </Field>
                <Field name="published">
                  {({ form }: FieldProps) => (
                    <FormControl
                      //   name="published"
                      display="flex"
                      alignItems="center"
                      mb={4}
                    >
                      <Checkbox
                        name="published"
                        id="published"
                        colorScheme="red"
                        defaultChecked={values.published}
                        onChange={() => {
                          form.setFieldValue('published', !values.published)
                        }}
                      >
                        公開する
                      </Checkbox>
                    </FormControl>
                  )}
                </Field>
                <Button
                  type="submit"
                  m="auto"
                  bgColor="#F3B0AE"
                  width="10rem"
                  isLoading={isSubmitting}
                  disabled={
                    (answerPeriodFlg && deadlineFlg) || invalidFlg || !isValid
                  }
                >
                  {submitButtonLabel}
                </Button>
              </Flex>
            </Container>
          </Form>
        )
      }}
    </Formik>
  )
}
