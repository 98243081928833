import { useState, useCallback, useMemo } from 'react'
import { useMutation } from '@apollo/client'
import shuffle from 'lodash/shuffle'

import {
  ANSWER_QUIZ,
  AnswerQuizData,
  AnswerQuizVars,
} from 'graphql/apollo/mutation/answer/answerQuiz'
import { Playlist, Quiz } from 'types/generated/graphql'
import { isAnsweredQuiz, saveAnsweredQuiz } from 'utils/answeredQuiz'
import { PlaylistFormValues } from 'services/validators/playlistFormSchema'

export const ProgressStatus = {
  // スタート
  START: 'START',
  // 出題/回答
  QUESTION: 'QUESTION',
  // 回答結果
  ANSWERED: 'ANSWERED',
  // 総合成績
  RESULT: 'RESULT',
} as const

export const usePlayQuizPlaylist = (
  quizzes: Quiz[],
  quizListFormData: PlaylistFormValues,
  isPreview: boolean = true
) => {
  const [progressStatus, setProgressStatus] = useState<
    keyof typeof ProgressStatus
  >(ProgressStatus.START)

  const [quizNo, setQuizNo] = useState<number>(1)
  const [answers, setAnswers] = useState<string[]>([])
  const [score, setScore] = useState<number>(0)

  const [answerQuiz] = useMutation<AnswerQuizData, AnswerQuizVars>(ANSWER_QUIZ)

  const currentQuiz = useMemo(() => {
    const quiz = quizzes[quizNo - 1]
    if (!quiz) {
      return undefined
    }

    // 選択肢を順番ランダムで生成
    const answerCandidates = shuffle([
      quiz.content.correct,
      ...quiz.content.wrongs,
    ])

    return {
      ...quiz,
      answerCandidates,
    }
  }, [quizzes, quizNo])

  const quizzesCount = useMemo(() => {
    return quizzes.length || 0
  }, [quizzes])

  const handleStart = useCallback(() => {
    setProgressStatus(ProgressStatus.QUESTION)
  }, [])

  const handleAnswer = useCallback(
    (answer: string) => {
      setAnswers((answers) => [...answers, answer])

      // 正解なら1点加算
      if (currentQuiz?.content.correct === answer) {
        setScore((score) => score + 1)
      }
      setProgressStatus(ProgressStatus.ANSWERED)
      if (!isPreview && currentQuiz?.id && !isAnsweredQuiz(currentQuiz.id)) {
        answerQuiz({
          variables: {
            input: {
              answer: answer,
              quizId: currentQuiz.id,
            },
          },
        })
        saveAnsweredQuiz(currentQuiz.id)
      }
    },
    [currentQuiz, isPreview, answerQuiz]
  )

  const handleNext = useCallback(() => {
    const nextQuizNo = quizNo + 1
    if (nextQuizNo > quizzesCount) {
      setProgressStatus(ProgressStatus.RESULT)
      return
    }

    setProgressStatus(ProgressStatus.QUESTION)
    setQuizNo(nextQuizNo)
  }, [quizNo, quizzesCount])

  // プレビュー用のPlaylist型のデータを作成するために、Formに存在しないpropsを補完
  const generatedPlayListData: Playlist = {
    ...quizListFormData,
    id: 'dummyID',
    createdAt: 'dummy',
    updatedAt: 'dummy',
    quizzes: quizzes,
    design: 'NONE',
  }

  const resetPreviewStatus = () => {
    setQuizNo(1)
    setProgressStatus(ProgressStatus.START)
    setAnswers([])
    setScore(0)
  }

  return {
    progressStatus,
    playlist: generatedPlayListData,
    quizNo,
    answers,
    score,
    currentQuiz,
    quizzesCount,
    handleStart,
    handleAnswer,
    handleNext,
    resetPreviewStatus,
  }
}
