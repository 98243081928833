import React, { useState, useEffect, useCallback } from 'react'
import {
  Box,
  BoxProps,
  Button,
  Flex,
  Text,
  Tooltip,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import type { Quiz } from 'types/generated/graphql'
import {
  MdHelpOutline,
  MdOutlineAddCircleOutline,
  MdOutlineRemoveCircle,
  MdDragHandle,
  MdAddCircle,
  MdCheck,
} from 'react-icons/md'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd'
import dayjs from 'dayjs'
import { FormikErrors } from 'formik'
import { PlaylistFormValues } from 'services/validators/playlistFormSchema'

type Props = {
  selectedQuizzes: Quiz[]
  unselectedQuizzes: Quiz[]
  setSelectedQuizIds: (quizIds: string[]) => void
  validateForm: (values?: any) => void
  selectedQuizIds: string[]
  errors: FormikErrors<PlaylistFormValues>
}

const listContainerStyle: BoxProps = {
  width: '100%',
  position: 'relative',
}
const listContainerHeaderStyle: BoxProps = {
  position: 'sticky',
  top: '0',
  left: '0',
  zIndex: '1',
}

const headerListColumnStyle: BoxProps = {
  fontWeight: '500',
  backgroundColor: '#EAF7FF',
  borderBottom: '1px solid #C0C0C0',
  pt: '8px',
  pb: '7px',
  flex: '1 1 100%',
  py: '16px',
  boxSizing: 'content-box',
}

const bodyListColumnStyle: BoxProps = {
  backgroundColor: '#fff',
  borderBottom: '1px solid #C0C0C0',
  pt: '16px',
  pb: '15px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  flex: '1 1 100%',
  py: '16px',
  whiteSpace: 'nowrap',
  boxSizing: 'content-box',
}

const listColumn1Style = {
  borderBottom: 'none',
  flex: '0 0 17px',
  pl: '8px',
  pr: '8px',
}

const listColumn2Style = {
  flex: '0 0 20px',
  pl: '8px',
  pr: '8px',
}

const listColumn3Style = {
  flexBasis: '178px',
  pl: '8px',
  pr: '12px',
}

const listColumn4Style = {
  flexBasis: '98px',
  pl: '12px',
  pr: '12px',
}

const listColumn5Style = {
  flex: '0 0 24px',
  pl: '12px',
  pr: '16px',
}

const listColumn6Style = {
  flex: '0 0 36px',
  pl: '4px',
  pr: '4px',
}

const bodyListColumn1Style: BoxProps = {
  color: '#2B5080',
  fontSize: '14px',
  fontWeight: '700',
  lineHeight: '1',
  backgroundColor: '#EAF7FF',
  textAlign: 'right',
}

const modalHeaderListColumnStyle: BoxProps = {
  fontWeight: '500',
  color: '#fff',
  pt: '8px',
  pb: '7px',
  flex: '1 1 100%',
  px: '12px',
  boxSizing: 'content-box',
}
const modalBodyListColumnStyle: BoxProps = {
  pt: '16px',
  pb: '15px',
  flex: '1 1 100%',
  px: '12px',
  boxSizing: 'content-box',
  whiteSpace: 'nowrap',
  backgroundColor: '#fff',
  borderBottom: '1px solid #C0C0C0',
}
const modalListColumn1Style = {
  flexBasis: '307px',
  pl: '16px',
  pr: '12px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}
const modalListColumn2Style = {
  flexBasis: '227px',
  pl: '12px',
  pr: '12px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}
const modalListColumn3Style = {
  flexBasis: '88px',
  pl: '12px',
  pr: '13px',
}
const modalListColumn4Style = {
  flexBasis: '24px',
  pl: '13px',
  pr: '24px',
}
const modalListColumn5Style = {
  flexBasis: '36px',
  pl: '10px',
  pr: '10px',
}

export const SelectQuizzes: React.FC<Props> = ({
  selectedQuizzes,
  unselectedQuizzes,
  setSelectedQuizIds,
  validateForm,
  selectedQuizIds,
  errors,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedItems, setSelectedItems] = useState<Quiz[]>(selectedQuizzes)
  const [unselectedItems, setUnselectedItems] =
    useState<Quiz[]>(unselectedQuizzes)

  const handleSelectedQuizIds = useCallback(
    (quizzes: Quiz[]) => {
      setSelectedQuizIds(quizzes.map(({ id }) => id))
    },
    [setSelectedQuizIds]
  )

  const handleUnselectedItems = useCallback((quizzes: Quiz[]) => {
    setUnselectedItems(quizzes)
  }, [])

  useEffect(() => {
    // データが入ってない状態でレンダリングされる時があるため、EditQuizList.tsx > onCompletedの後（selectedQuizzesが取得された後）に再レンダリング
    if (selectedQuizzes.length !== selectedItems.length) {
      setSelectedItems(selectedQuizzes)
      handleSelectedQuizIds(selectedQuizzes)
      handleUnselectedItems(unselectedQuizzes)
    }
  }, [
    selectedQuizzes,
    selectedItems,
    handleSelectedQuizIds,
    unselectedQuizzes,
    handleUnselectedItems,
  ])

  useEffect(() => {
    // quizIds が変更されたときにバリデーションを発火
    validateForm()
  }, [selectedQuizIds, validateForm])

  const handleSetItems = (index: number, type: string) => {
    const selectedItemsClone = [...selectedItems]
    const unselectedItemsClone = [...unselectedItems]

    if (type === 'add') {
      const selectedItem = unselectedItemsClone[index]

      // 選択済みアイテムに追加し、未選択アイテムから削除
      selectedItemsClone.push(selectedItem)
      unselectedItemsClone.splice(index, 1)
    } else {
      const selectedItem = selectedItemsClone[index]

      // 選択済みアイテムに追加し、未選択アイテムから削除
      selectedItemsClone.splice(index, 1)
      unselectedItemsClone.push(selectedItem)
    }

    // 状態の更新
    setSelectedItems(selectedItemsClone)
    setSelectedQuizIds(selectedItemsClone.map(({ id }) => id))
    setUnselectedItems(unselectedItemsClone)
  }

  // ドラッグ&ドロップした要素を入れ替える
  const reorder = (list: Quiz[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = (result: DropResult) => {
    // ドロップ先がない
    if (!result.destination) {
      return
    }
    const movedItems = reorder(
      selectedItems, //　順序を入れ変えたい配列
      result.source.index, // 元の配列の位置
      result.destination.index // 移動先の配列の位置
    )
    setSelectedQuizIds(movedItems.map(({ id }) => id))
    setSelectedItems(movedItems)
  }

  return (
    <Box my="24px">
      <Text
        display="flex"
        fontSize="14px"
        fontWeight="500"
        alignItems="center"
        mb="8px"
      >
        クイズ選択
        <Text as="span" ml="4px" mr="8px" color="v2Attention">
          *
        </Text>
        <Tooltip
          label="クイズリストに含めるクイズを選択してください"
          placement="top"
          bgColor="#CCEAFC"
          color="#222"
          borderRadius="4px"
          fontSize="11px"
        >
          <Text as="span">
            <MdHelpOutline size="18px" color="#828282" />
          </Text>
        </Tooltip>
      </Text>

      {errors.quizIds && (
        <Text fontSize="12px" color="v2Attention" my="8px">
          {errors.quizIds}
        </Text>
      )}
      <Box bgColor="#EAF7FF" borderRadius="10px" pr="20px" py="24px">
        <Box display="flex" justifyContent="center">
          <Button
            bgColor="v2Primary"
            color="#fff"
            w="400px"
            borderRadius="100px"
            fontWeight="bold"
            _hover={{
              bgColor: 'buttonPrimaryHover',
            }}
            onClick={onOpen}
          >
            <MdOutlineAddCircleOutline size="24px" />
            <Text ml="8px">クイズを追加する</Text>
          </Button>
        </Box>

        {selectedItems.length > 0 && (
          <Box className="list-container" {...listContainerStyle} mt="16px">
            <Box
              className="list-container-header"
              {...listContainerHeaderStyle}
            >
              <Flex className="list-row">
                <Box
                  className="list-column"
                  {...headerListColumnStyle}
                  {...listColumn1Style}
                />
                <Box
                  className="list-column"
                  {...headerListColumnStyle}
                  {...listColumn2Style}
                />
                <Box
                  className="list-column"
                  {...headerListColumnStyle}
                  {...listColumn3Style}
                >
                  問題文
                </Box>
                <Box
                  className="list-column"
                  {...headerListColumnStyle}
                  {...listColumn4Style}
                >
                  正解
                </Box>
                <Box
                  className="list-column"
                  {...headerListColumnStyle}
                  {...listColumn6Style}
                >
                  公開
                </Box>
                <Box
                  className="list-column"
                  {...headerListColumnStyle}
                  {...listColumn5Style}
                />
              </Flex>
            </Box>
            <Box className="list-container-body" overflowY="auto">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="unselected">
                  {(provided, snapshot) => (
                    <Box
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      //   style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {selectedItems.map((selectedItem, index) => (
                        <Draggable
                          key={selectedItem.id}
                          draggableId={'q-' + selectedItem.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Flex
                              className="list-row"
                              key={selectedItem.id + 'div'}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Box
                                className="list-column"
                                {...bodyListColumnStyle}
                                {...bodyListColumn1Style}
                                {...listColumn1Style}
                              >
                                {index + 1}
                              </Box>
                              <Box
                                className="list-column"
                                {...bodyListColumnStyle}
                                {...listColumn2Style}
                              >
                                <MdDragHandle size="20px" />
                              </Box>
                              <Box
                                className="list-column"
                                {...bodyListColumnStyle}
                                {...listColumn3Style}
                              >
                                {selectedItem.content.question}
                              </Box>
                              <Box
                                className="list-column"
                                {...bodyListColumnStyle}
                                {...listColumn4Style}
                              >
                                {selectedItem.content.correct}
                              </Box>
                              <Box
                                className="list-column"
                                {...bodyListColumnStyle}
                                {...listColumn6Style}
                              >
                                {selectedItem.published && (
                                  <MdCheck size="20px" color="#27AE60" />
                                )}
                              </Box>
                              <Box
                                className="list-column"
                                {...bodyListColumnStyle}
                                {...listColumn5Style}
                              >
                                <IconButton
                                  variant="unstyled"
                                  aria-label="Delete item"
                                  w="24px"
                                  h="24px"
                                  minW="0"
                                  icon={
                                    <MdOutlineRemoveCircle
                                      color="#EB5757"
                                      size="24px"
                                    />
                                  }
                                  _hover={{
                                    opacity: 0.7,
                                  }}
                                  onClick={() =>
                                    handleSetItems(index, 'remove')
                                  }
                                />
                              </Box>
                            </Flex>
                          )}
                        </Draggable>
                      ))}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
          </Box>
        )}
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bgColor="v2Primary" maxW="900px">
          <ModalHeader color="#fff">クイズを追加</ModalHeader>
          <ModalCloseButton color="#fff" />
          <ModalBody pb="24px">
            <Box className="list-container" {...listContainerStyle}>
              <Box
                className="list-container-header"
                {...listContainerHeaderStyle}
              >
                <Flex className="list-row">
                  <Box
                    className="list-column"
                    {...modalHeaderListColumnStyle}
                    {...modalListColumn1Style}
                  >
                    問題文
                  </Box>
                  <Box
                    className="list-column"
                    {...modalHeaderListColumnStyle}
                    {...modalListColumn2Style}
                  >
                    正解
                  </Box>
                  <Box
                    className="list-column"
                    {...modalHeaderListColumnStyle}
                    {...modalListColumn3Style}
                  >
                    最終更新
                  </Box>
                  <Box
                    className="list-column"
                    {...modalHeaderListColumnStyle}
                    {...modalListColumn5Style}
                  >
                    公開
                  </Box>
                  <Box
                    className="list-column"
                    {...modalHeaderListColumnStyle}
                    {...modalListColumn4Style}
                  />
                </Flex>
              </Box>
              <Box className="list-container-body" overflowY="scroll">
                {unselectedItems.map((unselectedItem, index) => (
                  <Flex className="list-row" key={unselectedItem.id}>
                    <Box
                      className="list-column"
                      {...modalBodyListColumnStyle}
                      {...modalListColumn1Style}
                    >
                      {unselectedItem.content.question}
                    </Box>
                    <Box
                      className="list-column"
                      {...modalBodyListColumnStyle}
                      {...modalListColumn2Style}
                    >
                      {unselectedItem.content.correct}
                    </Box>
                    <Box
                      className="list-column"
                      {...modalBodyListColumnStyle}
                      {...modalListColumn3Style}
                    >
                      {dayjs(unselectedItem.updatedAt).format('YYYY/MM/DD')}
                    </Box>
                    <Box
                      className="list-column"
                      {...modalBodyListColumnStyle}
                      {...modalListColumn5Style}
                    >
                      {unselectedItem.published && (
                        <MdCheck size="20px" color="#27AE60" />
                      )}
                    </Box>
                    <Box
                      className="list-column"
                      {...modalBodyListColumnStyle}
                      {...modalListColumn4Style}
                    >
                      <IconButton
                        variant="unstyled"
                        aria-label="Delete item"
                        w="24px"
                        h="24px"
                        minW="0"
                        icon={<MdAddCircle color="#007BC2" size="24px" />}
                        onClick={() => handleSetItems(index, 'add')}
                        _hover={{
                          opacity: 0.7,
                        }}
                      />
                    </Box>
                  </Flex>
                ))}
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}
