import { useCallback, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { QuizStatsData } from 'graphql/apollo/query/quizStats'
import { Quiz } from 'types/generated/graphql'
import { quizFormSchema } from 'services/validators/QuizFormSchema'
import { MultipleChoiceEmbedPlayQuestion } from 'components/PlayQuiz/MultipleChoice/MultipleChoiceEmbedPlayQuestion'
import { MultipleChoiceEmbedPlayAnswer } from 'components/PlayQuiz/MultipleChoice/MultipleChoiceEmbedPlayAnswer'
import { ToolButtonsPre } from 'v2components/Common/ToolButtonsPre'

const ProgressStatus = {
  SELECT_ANSWER: 'SELECT_ANSWER',
  CHECK_ANSWER: 'CHECK_ANSWER',
} as const

export const LivePreviewQuiz: React.FC = () => {
  const [selectedChoice, setSelectedChoice] = useState<string>()
  const [progressStatus, setProgressStatus] = useState<
    keyof typeof ProgressStatus
  >(ProgressStatus.SELECT_ANSWER)
  const handleNext = useCallback(() => {
    setProgressStatus(ProgressStatus.CHECK_ANSWER)
  }, [])
  const { values } = useFormikContext()
  const castValues = quizFormSchema.cast(values, {
    stripUnknown: true,
  })
  const handleSelectedChoice = useCallback((selectedChoice) => {
    setSelectedChoice(selectedChoice)
  }, [])
  const generatedQuizData: Quiz = {
    content: { ...castValues },
    id: 'dummyID',
    published: true,
    createdAt: '',
    updatedAt: '',
    snsInfo: {
      destinationLink: castValues.destinationLink,
      destinationLinkText: castValues.destinationLinkText,
    },
  }

  const quizOptions = generatedQuizData.content.wrongs.concat(
    generatedQuizData.content.correct
  )

  const dummyStats: QuizStatsData = {
    quizStats: {
      quizId: '',
      stats: [{ count: 0, percent: '0%', selection: '' }],
      total: 0,
    },
  }
  const renderPageComponent = () => {
    switch (progressStatus) {
      case 'SELECT_ANSWER':
        return (
          <MultipleChoiceEmbedPlayQuestion
            quiz={generatedQuizData}
            quizOptions={quizOptions}
            quizStats={dummyStats.quizStats}
            onNext={handleNext}
            onSelectedChoice={handleSelectedChoice}
          />
        )
      case 'CHECK_ANSWER':
        return (
          <MultipleChoiceEmbedPlayAnswer
            quiz={generatedQuizData}
            quizOptions={quizOptions}
            quizStats={dummyStats.quizStats}
            selectedChoice={selectedChoice}
          />
        )
    }
  }

  const handleResetPreview = () => {
    setProgressStatus('SELECT_ANSWER')
  }

  return (
    <Box overflowY="auto" maxH="100%">
      <Box w="100%" marginBottom="20px">
        {renderPageComponent()}
      </Box>
      <ToolButtonsPre handleResetPreview={handleResetPreview} />
    </Box>
  )
}
