import { Quiz } from '../types/generated/graphql'

export const isAnsweredQuiz = (quizId: string) => {
  let answeredQuizzes = []
  let json = localStorage.getItem('answeredQuizzes')
  if (json) {
    answeredQuizzes = JSON.parse(json)
  }
  return answeredQuizzes.includes(quizId)
}

export const saveAnsweredQuiz = (quizId: string) => {
  let answeredQuizzes = []
  let json = localStorage.getItem('answeredQuizzes')
  if (json) {
    answeredQuizzes = JSON.parse(json)
  }
  answeredQuizzes.push(quizId)
  localStorage.setItem('answeredQuizzes', JSON.stringify(answeredQuizzes))
}

export const getEmbedHeight = (quiz: Quiz) => {
  let height = 300
  const wrongs = quiz.content.wrongs
  const wrongLength = [...wrongs]
  for (const longQuiz of wrongLength) {
    if (longQuiz.length >= 100) {
      height += 100
    }
  }
  return height
}
