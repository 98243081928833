import React from 'react'
import { Field, FieldProps } from 'formik'

export interface FormInputProps {
  name: string
  value: string
}

export const FormDesignRadio: React.FC<FormInputProps> = ({
  name,
  value,
}) => {
  return (
   <Field name={name}>
      {({ field }: FieldProps) => {
        return (
          <input type="radio" {...field} name={name} value={value} style={{display: 'none'}} />
        )
      }}
    </Field>
  )
}
