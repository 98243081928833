import { Center, VStack, Text } from '@chakra-ui/react'

export const Unauthorized: React.FC = () => {
  return (
    <Center w="100%" h="90vh">
      <VStack>
        <Text fontSize="3xl">アクセスする権限がありません</Text>
      </VStack>
    </Center>
  )
}
