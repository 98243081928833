import React from 'react'
import { HStack, IconButton, Tooltip } from '@chakra-ui/react'
import { MdOutlineRestartAlt } from 'react-icons/md'

interface Props {
  handleResetPreview: () => void
}

export const ToolButtonsPre: React.FC<Props> = ({ handleResetPreview }) => {
  return (
    <HStack spacing="12px" justifyContent="center">
      <Tooltip
        label="プレビューをリセット"
        placement="top"
        variant="quzilla-default"
      >
        <IconButton
          aria-label="reload"
          variant="tool-btns"
          icon={<MdOutlineRestartAlt size="24px" />}
          onClick={() => {
            handleResetPreview()
          }}
        />
      </Tooltip>
    </HStack>
  )
}
