import { gql } from '@apollo/client'
import { QuizPub, UpdateQuizInputPub } from 'types/generated/graphql'

export type UpdateQuizDataPub = {
  updateQuiz: QuizPub
}

export type UpdateQuizVarsPub = {
  input: UpdateQuizInputPub
}

export const UPDATE_QUIZ_PUB = gql`
  mutation UpdateQuizPub($input: UpdateQuizInputPub!) {
    updateQuizPub(input: $input) {
      id
      published
    }
  }
`
