import { createCtx } from './contextUtils'
import { UserData } from 'graphql/apollo/query/user'
import { Group } from 'types/generated/graphql'

export type UserProfileContextType = {
  userProfile?: UserData
  currentGroup?: Group
  handleCurrentGroup: (group?: Group) => void
}

export const [useUserProfileContext, UserProfileProvider] =
  createCtx<UserProfileContextType>()
