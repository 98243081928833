import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { PlayQuizPreview } from './PlayQuizPreview'

type Props = RouteComponentProps<{
  quizId: string
}>

export const EmbedPlayQuizPreview: React.FC<Props> = (props) => {
  return <PlayQuizPreview quizId={props.quizId} isEmbed={true} />
}
