import { boolean, object, string, InferType, array, number } from 'yup'
import { snsInfoSchema } from './snsInfoSchema'

export const playlistFormSchema = object({
  name: string()
    .required('リストの名前は必須です。')
    .max(40, '文字数を40字以内にしてください。')
    .default(''),
  published: boolean().default(false),
  snsInfo: snsInfoSchema,
  quizIds: array()
    .of(string())
    .default([])
    .test(
      'isNotEmpty',
      '少なくとも1つのクイズが必要です。',
      function (value: string[]) {
        return value && value.length > 0
      }
    ),
  imageUrl: string().default(''),
  description: string()
    .max(120, '文字数を120字以内にしてください。')
    .default(''),
  design: string().default('NONE'),
  passingScore: number().transform((currentValue, originalValue) =>
    originalValue === null ? 0 : currentValue
  ),
  passText: string().ensure().default(''),
  failText: string().ensure().default(''),
  passLink: string()
    .url('「合格リンク」はURL形式で入力してください。')
    .ensure()
    .default(''),
  failLink: string()
    .url('「不合格リンク」はURL形式で入力してください。')
    .ensure()
    .default(''),
  passLinkText: string().ensure().default(''),
  failLinkText: string().ensure().default(''),
}).defined()

export type PlaylistFormValues = InferType<typeof playlistFormSchema>
