import React, { useCallback, useMemo, useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import { VStack, Text, Link } from '@chakra-ui/react'
import shuffle from 'lodash/shuffle'
import { MultipleChoicePlayQuestion } from 'components/PlayQuiz/MultipleChoice/MultipleChoicePlayQuestion'
import { MultipleChoicePlayAnswer } from 'components/PlayQuiz/MultipleChoice/MultipleChoicePlayAnswer'
import { MultipleChoiceEmbedPlayAnswer } from 'components/PlayQuiz/MultipleChoice/MultipleChoiceEmbedPlayAnswer'
import { MultipleChoiceEmbedPlayQuestion } from 'components/PlayQuiz/MultipleChoice/MultipleChoiceEmbedPlayQuestion'
import { Loading } from 'components/Common/Loading'
import { NoData } from 'components/Common/NoData'
import { Private } from 'components/Common/Private'
import { useQuery, useMutation } from '@apollo/client'
import { QUIZ, QuizData, QuizVars } from 'graphql/apollo/query/quiz'
import {
  QUIZSTATS,
  QuizStatsData,
  QuizStatsVars,
} from 'graphql/apollo/query/quizStats'
import {
  AnswerQuizData,
  AnswerQuizVars,
  ANSWER_QUIZ,
} from 'graphql/apollo/mutation/answer/answerQuiz'
import { isAnsweredQuiz, saveAnsweredQuiz } from 'utils/answeredQuiz'

type urlProps = RouteComponentProps<{
  quizId: string
  isPreview?: boolean
  isEmbed: boolean
}>

const ProgressStatus = {
  SELECT_ANSWER: 'SELECT_ANSWER',
  CHECK_ANSWER: 'CHECK_ANSWER',
} as const

// http://localhost:3000/quiz/{quizId}
// http://localhost:3000/quiz/82781471-f8be-421d-91c7-6fcfb37218d2
export const PlayQuiz: React.FC<urlProps> = ({
  quizId,
  isPreview = false,
  isEmbed,
}) => {
  const [selectedChoice, setSelectedChoice] = useState<string>()
  const [progressStatus, setProgressStatus] = useState<
    keyof typeof ProgressStatus
  >(ProgressStatus.SELECT_ANSWER)
  const { loading, error, data } = useQuery<QuizData, QuizVars>(QUIZ, {
    variables: {
      id: quizId!,
    },
    context: {
      noAuthorizationHeader: !isPreview,
    },
  })
  const { loading: quizStatsLoading, data: quizStatsData } = useQuery<
    QuizStatsData,
    QuizStatsVars
  >(QUIZSTATS, {
    variables: {
      quizId: quizId!,
    },
    fetchPolicy: 'network-only',
  })

  const [answerQuiz] = useMutation<AnswerQuizData, AnswerQuizVars>(ANSWER_QUIZ)
  const handleNext = useCallback(() => {
    setProgressStatus(ProgressStatus.CHECK_ANSWER)
  }, [])
  const handleSelectedChoice = useCallback(
    (selectedChoice) => {
      if (!isPreview && quizId && !isAnsweredQuiz(quizId)) {
        answerQuiz({
          variables: {
            input: {
              answer: selectedChoice,
              quizId: quizId,
            },
          },
        })
        saveAnsweredQuiz(quizId)
      }
      setSelectedChoice(selectedChoice)
    },
    [answerQuiz, quizId, isPreview]
  )
  const quiz = data?.quiz
  const design = quiz?.design

  const quizOptions = useMemo(() => {
    return quiz ? shuffle(quiz.content.wrongs.concat(quiz.content.correct)) : []
  }, [quiz])

  if (loading || quizStatsLoading) return <Loading />
  if (error) navigate('/404NotFound')
  if (!data || !quiz) return <NoData />
  if (!isPreview && !data.quiz.published) return <Private category="クイズ" />

  const renderPageComponent = () => {
    switch (progressStatus) {
      case 'SELECT_ANSWER':
        if (isEmbed) {
          return (
            <MultipleChoiceEmbedPlayQuestion
              quiz={quiz}
              quizOptions={quizOptions}
              quizStats={quizStatsData?.quizStats}
              onNext={handleNext}
              onSelectedChoice={handleSelectedChoice}
            />
          )
        }
        return (
          <MultipleChoicePlayQuestion
            quiz={quiz}
            quizOptions={quizOptions}
            quizStats={quizStatsData?.quizStats}
            onNext={handleNext}
            onSelectedChoice={handleSelectedChoice}
          />
        )
      case 'CHECK_ANSWER':
        if (isEmbed) {
          return (
            <MultipleChoiceEmbedPlayAnswer
              quiz={quiz}
              quizOptions={quizOptions}
              quizStats={quizStatsData?.quizStats}
              selectedChoice={selectedChoice}
            />
          )
        }
        return (
          <MultipleChoicePlayAnswer
            quiz={quiz}
            quizOptions={quizOptions}
            quizStats={quizStatsData?.quizStats}
            selectedChoice={selectedChoice}
          />
        )
    }
  }

  if (isEmbed) {
    return <VStack w="100%">{renderPageComponent()}</VStack>
  }

  return (
    <VStack w="100%" h="100vh" p={5} bg={`${design}.common.screenBackground`}>
      {renderPageComponent()}
    </VStack>
  )
}
