import { createIcon } from '@chakra-ui/icons'

export const SelectIcon = createIcon({
  displayName: 'SelectIcon',
  viewBox: '0 0 8 5',
  d: 'M0.25 0.5L4 4.25L7.75 0.5H0.25Z',
})

export const DeleteIcon = createIcon({
  displayName: 'DeleteIcon',
  viewBox: '0 0 40 40',
  d: 'M11.3333 21.3333H28V18H11.3333V21.3333ZM19.6667 36.3333C17.3611 36.3333 15.1944 35.8958 13.1667 35.0208C11.1389 34.1458 9.375 32.9583 7.875 31.4583C6.375 29.9583 5.1875 28.1944 4.3125 26.1667C3.4375 24.1389 3 21.9722 3 19.6667C3 17.3611 3.4375 15.1944 4.3125 13.1667C5.1875 11.1389 6.375 9.375 7.875 7.875C9.375 6.375 11.1389 5.1875 13.1667 4.3125C15.1944 3.4375 17.3611 3 19.6667 3C21.9722 3 24.1389 3.4375 26.1667 4.3125C28.1944 5.1875 29.9583 6.375 31.4583 7.875C32.9583 9.375 34.1458 11.1389 35.0208 13.1667C35.8958 15.1944 36.3333 17.3611 36.3333 19.6667C36.3333 21.9722 35.8958 24.1389 35.0208 26.1667C34.1458 28.1944 32.9583 29.9583 31.4583 31.4583C29.9583 32.9583 28.1944 34.1458 26.1667 35.0208C24.1389 35.8958 21.9722 36.3333 19.6667 36.3333Z',
})

export const QuizIcon = createIcon({
  displayName: 'QuizIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.2998 3H17.5855V17.4286H20.7284V21H3.2998V3ZM8.2997 6.57131H12.5854V17.4285H8.2997V6.57131Z"
      />
    </g>
  ),
})
