import React, { useMemo } from 'react'
import { RouteComponentProps } from '@reach/router'
import { QUIZ, QuizData, QuizVars } from 'graphql/apollo/query/quiz'
import { useUserProfileContext } from 'state'
import { isOwned } from 'utils/isOwned'
import { useQuery } from '@apollo/client'
import { Layout } from 'v2components/Common/Layout'
import { Error } from 'v2components/Common/Error'
import { Loading } from 'v2components/Common/Loading'
import { NoData } from 'v2components/Common/NoData'
import { Unauthorized } from 'v2components/Common/Unauthorized'
import { EditRoot } from 'v2components/EditQuiz/EditRoot'

type Props = RouteComponentProps<{
  quizId: string
}>

export const EditQuiz: React.FC<Props> = (props) => {
  const quizId = props.quizId!
  const { userProfile, currentGroup } = useUserProfileContext()
  const { loading, error, data } = useQuery<QuizData, QuizVars>(QUIZ, {
    variables: {
      id: quizId,
    },
  })

  const quiz = data?.quiz
  const isOwnedQuiz = useMemo(() => {
    return isOwned(quiz, currentGroup, userProfile)
  }, [quiz, currentGroup, userProfile])

  return (
    <Layout pageTitle="クイズ編集">
      {(() => {
        if (loading) return <Loading />
        if (error) return <Error />
        if (!data || !quiz || !props.quizId) return <NoData />
        if (!isOwnedQuiz) return <Unauthorized />
        if (isOwnedQuiz && quiz) return <EditRoot quiz={quiz} />
      })()}
    </Layout>
  )
}
