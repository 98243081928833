import { Flex } from '@chakra-ui/react'

export interface TopMainLayoutProps {
  pageTitle?: string
}

export const TopMainLayout: React.FC<TopMainLayoutProps> = ({ children }) => {
  /* ここにLayoutを構築する
     HeaderComponent, SideNaviComponentを組み合わせる */
  return (
    <Flex
      bgColor={'#fff'}
      flex={'1 1 auto'}
      flexDirection={'column'}
      p={'24px 20px'}
      borderRadius={'24px'}
      overflowY="auto"
      overflowX="hidden"
    >
      {children}
    </Flex>
  )
}
