import React, { useMemo } from 'react'
import { Box } from '@chakra-ui/react'
import { RouteComponentProps } from '@reach/router'
import { useQuery } from '@apollo/client'

import { EditRoot } from 'components/EditQuiz/EditRoot'
import { Error } from 'components/Common/Error'
import { Layout } from 'components/Common/Layout'
import { Loading } from 'components/Common/Loading'
import { NoData } from 'components/Common/NoData'
import { Unauthorized } from 'components/Common/Unauthorized'
import { QUIZ, QuizData, QuizVars } from 'graphql/apollo/query/quiz'
import { useUserProfileContext } from 'state'
import { isOwned } from 'utils/isOwned'

type Props = RouteComponentProps<{
  quizId: string
}>

export const EditQuiz: React.FC<Props> = (props) => {
  const quizId = props.quizId!
  const { userProfile, currentGroup } = useUserProfileContext()
  const { loading, error, data } = useQuery<QuizData, QuizVars>(QUIZ, {
    variables: {
      id: quizId,
    },
  })

  const quiz = data?.quiz
  const isOwnedQuiz = useMemo(() => {
    return isOwned(quiz, currentGroup, userProfile)
  }, [quiz, currentGroup, userProfile])

  if (loading) return <Loading />
  if (error) return <Error />
  if (!data || !quiz || !props.quizId) return <NoData />

  return (
    <Layout pageTitle="クイズ編集">
      <Box w="100%" h="100%" p={3}>
        {isOwnedQuiz ? <EditRoot quiz={quiz} /> : <Unauthorized />}
      </Box>
    </Layout>
  )
}
