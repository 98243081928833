import React from 'react'
import { Image, Center, VStack, Text } from '@chakra-ui/react'
import { StatusIcon } from 'components/ImageUpload/StatusIcon'
import { DropzoneRootProps } from 'react-dropzone'

interface Props {
  imageUrl: string
  style: DropzoneRootProps
  isDragAccept: boolean
  isDragReject: boolean
}

export const ImageComponent: React.FC<Props> = ({
  imageUrl,
  style,
  isDragAccept,
  isDragReject,
}) => {
  return (
    <Center
      {...style}
      position="relative"
      w="100%"
      h="20rem"
      border="1px"
      borderColor="gray.200"
    >
      <Image
        position={'absolute'}
        objectFit="cover"
        w="100%"
        h="100%"
        src={imageUrl}
        fallbackSrc={'/images/whiteImage.png'}
        alt="prifile-icon"
      />
      <Center
        w="100%"
        h="100%"
        position={'absolute'}
        bg="blackAlpha.300"
        pr={1}
        pb={2}
        _hover={{ bg: 'blackAlpha.600' }}
      >
        <VStack>
          <StatusIcon
            imageUrl={imageUrl}
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
          />
          <Text fontWeight={'bold'} color="whiteAlpha.800">
            ここに画像をドラック&ドロップ
          </Text>
        </VStack>
      </Center>
    </Center>
  )
}
