import { useEffect } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { Header } from './Header'
import { Sidebar } from './Sidebar'
import bgwave from 'assets/images/bg-wave.png'
import { useUserProfileContext, useAuthContext } from 'state'
import { AlertModal } from 'v2components/Modal/AlertModal'
import { UserProfileForm } from 'v2components/UserCreateModal/index'

export interface LayoutProps {
  pageTitle: string
  showSearchBox?: boolean
  setItemId?: (itemId: string) => void
}

export const Layout: React.FC<LayoutProps> = ({
  pageTitle,
  setItemId,
  showSearchBox = false,
  children,
}) => {
  useEffect(() => {
    document.title = pageTitle
  })
  const { userProfile } = useUserProfileContext()
  const auth = useAuthContext()
  /* ここにLayoutを構築する
     HeaderComponent, SideNaviComponentを組み合わせる */
  return (
    <>
      {userProfile?.user.organizationName === '' ? (
        <AlertModal
          modalBody={
            '組織情報が取得できませんでした。管理者にお問い合わせください。'
          }
          isOpen={true}
          onClose={() => auth.signOut()}
        />
      ) : (
        <UserProfileForm userProfile={userProfile} />
      )}
      <Box
        bgImage={bgwave}
        bgPosition="center bottom"
        bgRepeat="no-repeat"
        bgSize="2880px"
        backgroundRepeat="repeat-x"
        backgroundAttachment="initial"
        backgroundClip="initial"
        bgColor="#007BC2"
        minH="100vh"
        minW="1000px"
        height="100%"
        fontFamily='"Noto Sans JP", sans-serif'
        lineHeight={1}
        verticalAlign="baseline"
        m={0}
        p={0}
        border="0"
      >
        <Flex flexDirection="column" minH="100vh" height="100%">
          <Header
            pageTitle={pageTitle}
            setItemId={setItemId}
            showSearchBox={showSearchBox}
          />
          <Flex
            p="16px 16px 24px 8px"
            flexGrow={1}
            overflow="hidden"
            boxSizing="border-box"
            h="100%"
          >
            <Sidebar />
            <Flex flex="1 1 auto" overflowX="auto" gridColumnGap="16px">
              {children}
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </>
  )
}
