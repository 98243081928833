import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { useQuery } from '@apollo/client'

import { LayoutOpenArea } from 'components/Common/LayoutOpenArea'
import { PlayQuiz } from 'components/PlayQuiz'
import { QUIZ, QuizData, QuizVars } from 'graphql/apollo/query/quiz'

type urlProps = RouteComponentProps<{
  quizId: string
}>

// http://localhost:3000/quiz/{quizId}
// http://localhost:3000/quiz/82781471-f8be-421d-91c7-6fcfb37218d2
export const PlayQuizOpenArea: React.FC<urlProps> = (props) => {
  const { data } = useQuery<QuizData, QuizVars>(QUIZ, {
    variables: {
      id: props.quizId!,
    },
  })
  const quiz = data?.quiz
  const design = quiz?.design || 'ORIGINAL'
  const quizId = props.quizId!
  
  return (
    <LayoutOpenArea design={design}>
      <PlayQuiz quizId={quizId} isEmbed={false} />
    </LayoutOpenArea>
  )
}
