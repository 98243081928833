import React from 'react'
import {
  VStack,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import { FormInput } from 'v2components/Common/InputPageComponent/FormInput'

export const SnsSetting: React.FC = () => {
  return (
    <Accordion allowToggle w="100%">
      <AccordionItem>
        <AccordionButton
          color="#fff"
          fontSize="16px"
          fontWeight="500"
          lineHeight="1.5"
          letterSpacing="0.04"
          backgroundColor="v2Primary"
          py="8px"
          px="20px"
          _hover={{
            backgroundColor: 'buttonPrimaryHover',
          }}
        >
          <Box flex="1" textAlign="left">
            詳細設定
          </Box>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel
          borderRadius="0px 0px 10px 10px"
          display="flex"
          flexDirection="column"
          py="24px"
          px="20px"
          bgColor="#F5F5F5"
        >
          <VStack spacing="24px">
            <FormInput name="snsInfo.shareText" label="SNS投稿文" />
            <FormInput
              name="snsInfo.destinationLink"
              label="遷移先リンク"
              placeholder="例：https://example.com"
            />
            <FormInput
              name="snsInfo.destinationLinkText"
              label="遷移先リンクの説明"
            />
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
