import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { Global } from '@emotion/react'

import { EmbedLayoutOpenArea } from 'components/Common/EmbedLayoutOpenArea'
import { EmbedAnswerEnquete } from './EmbedAnswerEnquete'

type props = RouteComponentProps<{
  enqueteId: string
}>

export const EmbedAnswerEnqueteOpenArea: React.FC<props> = (props) => {
  const enqueteId = props.enqueteId!
  return (
    <>
      <Global
        styles={{
          body: {
            backgroundColor: 'transparent',
          },
        }}
      />
      <EmbedLayoutOpenArea>
        <EmbedAnswerEnquete enqueteId={enqueteId} />
      </EmbedLayoutOpenArea>
    </>
  )
}
