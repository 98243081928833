import React from 'react'
import {
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import {
  MdOutlineMoreVert,
  MdOutlineCreate,
  MdRemoveRedEye,
  MdInsertLink,
  MdCode,
  MdDeleteForever,
} from 'react-icons/md'

export interface EnqueteCardProps {
  itemId: string
  handleNavigateEditPage: (itemId: string) => void
  handleOpenPreviewModal: (itemId: string) => void
  handleCopyUrl: (itemId: string) => void
  handleCopyEmbedCode: (itemId: string) => void
  handleOpenDeleteModal: (itemId: string) => void
}

export interface ItemData {
  id: string
  title: string
  imageUrl?: string
  description: string
  contentType: string
}

const menuItemStyles = {
  fontSize: '14px',
  lineHeight: '24px',
  px: '4px',
  pl: '16px',
  _hover: {
    bgColor: '#DAF1FF',
  },
}

const menuItemIconStyles = {
  size: '20px',
}

export const ContentsListMenu: React.FC<EnqueteCardProps> = ({
  itemId,
  handleNavigateEditPage,
  handleOpenPreviewModal,
  handleCopyUrl,
  handleCopyEmbedCode,
  handleOpenDeleteModal,
}) => {
  if (!itemId) return <></>

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        isRound={true}
        icon={<MdOutlineMoreVert />}
        variant="solid"
        bg="none"
        w="32px"
        h="32px"
        minW="32px"
        outline="none"
        _hover={{
          bgColor: '#C4C7CC',
        }}
        _focus={{
          boxShadow: 'none',
        }}
        _active={{
          border: 'none',
          bgColor: '#C4C7CC',
        }}
      />
      <MenuList
        color="black"
        pt="8px"
        w="240px"
        boxShadow="0px 0px 20px 0px rgba(0, 0, 0, 0.32)"
      >
        <MenuItem
          {...menuItemStyles}
          icon={<MdOutlineCreate {...menuItemIconStyles} />}
          onClick={() => handleNavigateEditPage(itemId)}
        >
          編集
        </MenuItem>
        <MenuItem
          {...menuItemStyles}
          icon={<MdRemoveRedEye {...menuItemIconStyles} />}
          onClick={() => handleOpenPreviewModal(itemId)}
        >
          プレビュー
        </MenuItem>
        <MenuItem
          {...menuItemStyles}
          icon={<MdInsertLink {...menuItemIconStyles} />}
          onClick={() => handleCopyUrl(itemId)}
        >
          URLをコピー
        </MenuItem>
        <MenuItem
          {...menuItemStyles}
          icon={<MdCode {...menuItemIconStyles} />}
          onClick={() => handleCopyEmbedCode(itemId)}
        >
          埋め込み用コードをコピー
        </MenuItem>
        <MenuItem
          {...menuItemStyles}
          icon={<MdDeleteForever {...menuItemIconStyles} />}
          onClick={() => handleOpenDeleteModal(itemId)}
        >
          削除
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
