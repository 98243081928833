import React, { useState, useCallback, useMemo } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import { useMutation, useQuery } from '@apollo/client'
import { Box, Text, useDisclosure } from '@chakra-ui/react'
import { Quiz, ListDesignType } from 'types/generated/graphql'
import { QUIZZES, QuizzesData, QuizzesVars } from 'graphql/apollo/query/quizzes'
import { useUserProfileContext } from 'state'
import {
  CREATE_PLAYLIST,
  CreatePlaylistData,
  CreatePlaylistVars,
} from 'graphql/apollo/mutation/playlist/createPlaylist'
import { PlaylistFormValues } from 'services/validators/playlistFormSchema'
import { useUploadFile } from 'hooks/useUploadFile'
import { createOgp } from 'utils/createOgp'
import { Layout } from 'components/Common/Layout'
import { QuizPlaylistForm } from 'components/QuizPlaylistForm'
import { AlertModal } from 'components/Modal/AlertModal'

type Props = RouteComponentProps

export const CreateQuizPlaylist: React.FC<Props> = () => {
  const { currentGroup } = useUserProfileContext()
  const [selectedQuizIds, setSelectedQuizIds] = useState<string[]>([])
  const { isOpen, onOpen, onClose } = useDisclosure()

  const quizzesResult = useQuery<QuizzesData, QuizzesVars>(QUIZZES, {
    variables: {
      filter: {
        ownerGroupId: currentGroup?.id,
      },
    },
  })

  const allQuizzes = useMemo<Quiz[]>(() => {
    return quizzesResult?.data?.quizzes || []
  }, [quizzesResult])

  const selectedQuizzes = useMemo<Quiz[]>(() => {
    return allQuizzes.filter(({ id }) => selectedQuizIds.includes(id))
  }, [allQuizzes, selectedQuizIds])

  const unselectedQuizzes = useMemo<Quiz[]>(() => {
    return allQuizzes.filter(({ id }) => !selectedQuizIds.includes(id))
  }, [allQuizzes, selectedQuizIds])

  const { handleUploadDataUrlImage } = useUploadFile()

  const [createPlaylist] = useMutation<CreatePlaylistData, CreatePlaylistVars>(
    CREATE_PLAYLIST
  )

  const handleSubmit = useCallback(
    async (values: PlaylistFormValues) => {
      const s3ImageUrl = await handleUploadDataUrlImage(
        values.imageUrl,
        'quizPlaylist'
      )
      const ogpImageUrl = await createOgp(values.name, values.imageUrl)
      const s3OgpImageUrl = await handleUploadDataUrlImage(
        ogpImageUrl,
        'quizPlaylist-ogp'
      )

      await createPlaylist({
        variables: {
          input: {
            name: values.name,
            published: values.published,
            quizIds: selectedQuizIds,
            snsInfo: {
              ...values.snsInfo,
              ogpImageUrl: s3OgpImageUrl,
            },
            design: values.design as ListDesignType,
            passingScore: values.passingScore,
            passText: values.passText,
            failText: values.failText,
            passLink: values.passLink,
            failLink: values.failLink,
            passLinkText: values.passLinkText,
            failLinkText: values.failLinkText,
            imageUrl: s3ImageUrl,
            description: values.description,
            ownerGroupId: currentGroup?.id,
          },
        },
        refetchQueries: ['Playlists'],
        awaitRefetchQueries: true,
      })

      navigate('/quizplaylist')
    },
    [
      currentGroup?.id,
      selectedQuizIds,
      handleUploadDataUrlImage,
      createPlaylist,
    ]
  )

  return (
    <Layout pageTitle="クイズプレイリスト作成">
      <Box w="100%" h="100%" p={3}>
        {(() => {
          if (quizzesResult.loading) {
            return <Text>ロード中...</Text>
          }

          if (quizzesResult.error || !quizzesResult.data) {
            return <Text>エラー</Text>
          }

          return (
            <QuizPlaylistForm
              submitButtonLabel="登録"
              selectedQuizzes={selectedQuizzes}
              unselectedQuizzes={unselectedQuizzes}
              design={'NONE'}
              setSelectedQuizIds={setSelectedQuizIds}
              handleSubmit={async (values) => {
                try {
                  await handleSubmit(values)
                } catch (error) {
                  onOpen()
                }
              }}
            />
          )
        })()}
        <AlertModal
          modalBody={'クイズリストの登録に失敗しました。'}
          isOpen={isOpen}
          onClose={onClose}
        />
      </Box>
    </Layout>
  )
}
