import React, { useMemo } from 'react'
import { Spacer, Text, VStack } from '@chakra-ui/react'
import { Quiz } from 'types/generated/graphql'

export interface QuizCardProps {
  quiz: Quiz
}

export const QuizCardRoot: React.FC<QuizCardProps> = ({ quiz }) => {
  const design = quiz.design

  const currentDesign = useMemo(() => {
    switch (design) {
      case 'SIMPLE':
        return 'シンプル'
      case 'SERIOUS':
        return 'シリアス'
      default:
        return 'オリジナル'
    }
  }, [])

  return (
    <VStack w="100%">
      <Text w="100%">
        {quiz.content.question.length <= 55
          ? quiz.content.question
          : quiz.content.question.slice(0, 55) + '…'}
      </Text>
      <Spacer />
      <VStack w="100%">
        <Text w="100%" fontSize="xs" text-align="left" pt={2}>
          {`正解：${quiz.content.correct}`}
        </Text>
        <Text w="100%" fontSize="xs" text-align="left">
          {`誤答：${quiz.content.wrongs.join(' , ')}`}
        </Text>
        <Text w="100%" fontSize="xs" text-align="left">
            デザイン：{currentDesign}
          </Text>
      </VStack>
    </VStack>
  )
}
