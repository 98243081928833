import React, { useState, useMemo } from 'react'
import {
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerContent,
  DrawerBody,
  DrawerFooter,
  Stack,
  HStack,
  Box,
  Link,
  Text,
  Heading,
  Spacer,
  Switch,
  Input,
  useDisclosure,
} from '@chakra-ui/react'

import { Member, Group } from 'types/generated/graphql'
import { UserData } from 'graphql/apollo/query/user'

import { MemberCard } from 'components/MemberDrawer/MemberCard'
import { AddMemberModal } from 'components/MemberDrawer/AddMemberModal'

interface Props {
  userProfile: UserData
  isEditableGroup: boolean
  selectedGroup: Group
}

export const MemberDrower: React.FC<Props> = ({
  userProfile,
  isEditableGroup,
  selectedGroup,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [searchText, setSearchText] = useState<string>('')
  const [enableEditMode, setEnableEditMode] = useState<boolean>(false)

  const members = useMemo(() => {
    if (!selectedGroup) return []
    if (!searchText) return selectedGroup.members

    return selectedGroup.members.filter((member: Member) => {
      if (!member.user.email || !member.user.name || !member.user.aliasId) {
        return false
      }

      return (
        member.user.email.includes(searchText) ||
        member.user.name.includes(searchText) ||
        member.user.aliasId.includes(searchText)
      )
    })
  }, [selectedGroup, searchText])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    return setSearchText(e.target.value)
  }

  return (
    <>
      <HStack>
        <Link onClick={onOpen}>
          <HStack spacing={'0'}>
            <Text fontWeight={'bold'}>{selectedGroup?.members.length}</Text>
            <Text>メンバー</Text>
          </HStack>
        </Link>
        <Spacer />
      </HStack>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={() => {
          setEnableEditMode(false)
          onClose()
        }}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            『{selectedGroup?.name}』メンバーリスト
          </DrawerHeader>
          <DrawerBody>
            <Stack spacing="5px">
              {isEditableGroup && (
                <HStack>
                  <Spacer />
                  {enableEditMode ? (
                    <Heading size="sm" pb={'4px'}>
                      編集モード
                    </Heading>
                  ) : (
                    <Heading size="sm" pb={'4px'}>
                      閲覧モード
                    </Heading>
                  )}
                  <HStack>
                    <Switch
                      id="enableEditMode"
                      defaultChecked={false}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setEnableEditMode(event.target.checked)
                      }}
                    />
                  </HStack>
                </HStack>
              )}
              <Box>
                <Input
                  value={searchText}
                  onChange={handleChange}
                  id="searchText"
                  placeholder="ユーザー名、ユーザーID、メールアドレスで検索"
                />
              </Box>
              {members.map((member: Member) => {
                return (
                  <Box key={member.user.id}>
                    <MemberCard
                      userProfile={userProfile}
                      member={member}
                      isEditable={enableEditMode}
                    />
                  </Box>
                )
              })}
            </Stack>
          </DrawerBody>
          {enableEditMode && (
            <DrawerFooter borderTopWidth="1px">
              <AddMemberModal isEditable={enableEditMode} />
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
    </>
  )
}
