import { gql } from '@apollo/client'
import { User, CreateUserInput } from 'types/generated/graphql'

export type CreateUserData = {
  createUser: User
}

export type CreateUserVars = {
  input: CreateUserInput
}

export const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      name
      email
      aliasId
    }
  }
`
