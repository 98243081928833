import React, { useState, useCallback, useMemo } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import { useMutation, useQuery } from '@apollo/client'
import { Box, Text, useDisclosure } from '@chakra-ui/react'

import { Layout } from 'components/Common/Layout'
import { Unauthorized } from 'components/Common/Unauthorized'
import { QuizPlaylistForm } from 'components/QuizPlaylistForm'
import {
  UPDATE_PLAYLIST,
  UpdatePlaylistData,
  UpdatePlaylistVars,
} from 'graphql/apollo/mutation/playlist/updatePlaylist'
import {
  PLAYLIST,
  PlaylistData,
  PlaylistVars,
} from 'graphql/apollo/query/playlist'
import { QUIZZES, QuizzesData, QuizzesVars } from 'graphql/apollo/query/quizzes'
import { useUploadFile } from 'hooks/useUploadFile'
import { PlaylistFormValues } from 'services/validators/playlistFormSchema'
import { useUserProfileContext } from 'state'
import { Quiz, ListDesignType } from 'types/generated/graphql'
import { createOgp } from 'utils/createOgp'
import { isOwned } from 'utils/isOwned'
import { AlertModal } from 'components/Modal/AlertModal'

type Props = RouteComponentProps<{
  quizPlaylistId: string
}>

export const EditQuizPlaylist: React.FC<Props> = ({ quizPlaylistId }) => {
  const [selectedQuizIds, setSelectedQuizIds] = useState<string[]>([])
  const { currentGroup, userProfile } = useUserProfileContext()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const playlistResult = useQuery<PlaylistData, PlaylistVars>(PLAYLIST, {
    variables: {
      id: quizPlaylistId!,
    },
    skip: !quizPlaylistId,
    onCompleted: (data) => {
      const quizIds = data.playlist.quizzes.map(({ id }) => id)
      setSelectedQuizIds(quizIds)
    },
  })

  const isOwnedQuizPlaylist = useMemo(() => {
    return isOwned(playlistResult.data?.playlist, currentGroup, userProfile)
  }, [currentGroup, playlistResult, userProfile])

  const quizzesResult = useQuery<QuizzesData, QuizzesVars>(QUIZZES, {
    variables: {
      filter: {
        ownerGroupId: currentGroup?.id,
      },
    },
  })

  const allQuizzes = useMemo<Quiz[]>(() => {
    return quizzesResult?.data?.quizzes || []
  }, [quizzesResult])

  const selectedQuizzes = useMemo<Quiz[]>(() => {
    return allQuizzes
      .filter(({ id }) => selectedQuizIds.includes(id))
      .sort(({ id: aId }, { id: bId }) => {
        return selectedQuizIds.indexOf(aId) - selectedQuizIds.indexOf(bId)
      })
  }, [allQuizzes, selectedQuizIds])

  const unselectedQuizzes = useMemo<Quiz[]>(() => {
    return allQuizzes.filter(({ id }) => !selectedQuizIds.includes(id))
  }, [allQuizzes, selectedQuizIds])

  const { handleUploadDataUrlImage } = useUploadFile()

  const [updatePlaylist] = useMutation<UpdatePlaylistData, UpdatePlaylistVars>(
    UPDATE_PLAYLIST
  )

  const handleSubmit = useCallback(
    async (values: PlaylistFormValues) => {
      if (!quizPlaylistId) {
        return
      }

      const s3ImageUrl = await handleUploadDataUrlImage(
        values.imageUrl,
        'quizPlaylist'
      )
      const ogpImageUrl = await createOgp(values.name, values.imageUrl)
      const s3OgpImageUrl = await handleUploadDataUrlImage(
        ogpImageUrl,
        'quizPlaylist-ogp'
      )

      await updatePlaylist({
        variables: {
          input: {
            id: quizPlaylistId,
            name: values.name,
            published: values.published,
            quizIds: selectedQuizIds,
            snsInfo: {
              ...values.snsInfo,
              ogpImageUrl: s3OgpImageUrl,
            },
            passingScore: values.passingScore,
            passText: values.passText,
            failText: values.failText,
            passLink: values.passLink,
            failLink: values.failLink,
            passLinkText: values.passLinkText,
            failLinkText: values.failLinkText,
            imageUrl: s3ImageUrl,
            description: values.description,
            design: values.design as ListDesignType,
          },
        },
        refetchQueries: [
          'Playlists',
          {
            query: PLAYLIST,
            variables: { id: quizPlaylistId },
          },
        ],
        awaitRefetchQueries: true,
      })

      navigate('/quizplaylist')
    },
    [selectedQuizIds, quizPlaylistId, handleUploadDataUrlImage, updatePlaylist]
  )

  return (
    <Layout pageTitle="クイズプレイリスト編集">
      <Box w="100%" h="100%" p={3}>
        {(() => {
          if (playlistResult.loading || quizzesResult.loading) {
            return <Text>ロード中...</Text>
          }

          if (
            playlistResult.error ||
            quizzesResult.error ||
            !playlistResult.data ||
            !quizzesResult.data
          ) {
            return <Text>エラー</Text>
          }

          if (!isOwnedQuizPlaylist) {
            return <Unauthorized />
          }

          return (
            <QuizPlaylistForm
              initialValues={playlistResult.data.playlist}
              design={playlistResult.data.playlist.design || 'ORIGINAL'}
              submitButtonLabel="更新"
              selectedQuizzes={selectedQuizzes}
              unselectedQuizzes={unselectedQuizzes}
              setSelectedQuizIds={setSelectedQuizIds}
              handleSubmit={async (values) => {
                try {
                  await handleSubmit(values)
                } catch (error) {
                  onOpen()
                }
              }}
            />
          )
        })()}
        <AlertModal
          modalBody={'クイズリストの更新に失敗しました。'}
          isOpen={isOpen}
          onClose={onClose}
        />
      </Box>
    </Layout>
  )
}
