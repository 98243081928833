import React from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { RouteComponentProps } from '@reach/router'
import { Layout } from 'v2components/Common/Layout'
import { QuizForm } from 'v2components/QuizForm/index'
import { AlertModal } from 'v2components/Modal/AlertModal'
import { useCreateQuiz } from 'hooks/useCreateQuiz'
import { CompletedModal } from 'v2components/Modal/CompletedModal'
import { navigate } from '@reach/router'

type Props = RouteComponentProps

export const CreateQuiz: React.FC<Props> = () => {
  const { handleCreateQuiz } = useCreateQuiz()
  const completeDisclosure = useDisclosure()
  const alertDisclosure = useDisclosure()
  const handleCloseCompletedModal = () => {
    completeDisclosure.onClose()
    navigate(`/v2/quiz`)
  }

  return (
    <Layout pageTitle="クイズ作成">
      <QuizForm
        submitButtonLabel="登録"
        headerTitle="クイズ作成"
        onSubmit={async (values) => {
          try {
            await handleCreateQuiz(values)
            completeDisclosure.onOpen()
          } catch (error) {
            alertDisclosure.onOpen()
          }
        }}
      />
      <CompletedModal
        modalBody={'クイズを作成しました'}
        isOpenCompletedModal={completeDisclosure.isOpen}
        handleCloseCompletedModal={handleCloseCompletedModal}
        contentType="quiz"
      />
      <AlertModal
        modalBody={'クイズの登録に失敗しました。'}
        isOpen={alertDisclosure.isOpen}
        onClose={alertDisclosure.onClose}
      />
    </Layout>
  )
}
