export const fileToDataUrl = (file: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      const dataUrl = e.target!.result
      resolve(dataUrl)
    }
    reader.readAsDataURL(file)
  })
}
