import React from 'react'
import { useNavigate } from '@reach/router'
import { Box, Image, Text, Flex } from '@chakra-ui/react'
import { MdDescription, MdViewList, MdCheck } from 'react-icons/md'
import { ContentsListMenu } from 'v2components/Common/ContentsListMenu'
import dayjs from 'dayjs'
import userIcon from 'assets/images/quzilla-icon.png'

export interface ItemData {
  id: string
  title: string
  imageUrl: string | undefined | null
  description: string | undefined | null
  published?: boolean
  updatedAt?: string
  userName?: string
  iconImg?: string | undefined | null
  totalCount?: number
}

export interface ContentsItemProps {
  itemId: string
  itemData: ItemData
  contentType: 'enquete' | 'quiz' | 'quizlist'
  isEditable: boolean
  activeItemId?: string
  onClick: (itemId: string) => void
  handleOpenPreviewModal: (itemId: string) => void
  handleCopyUrl: (itemId: string) => void
  handleCopyEmbedCode: (itemId: string) => void
  handleOpenDeleteModal: (itemId: string) => void
}

export const ContentsListBar: React.FC<ContentsItemProps> = ({
  itemId,
  itemData,
  contentType,
  activeItemId,
  onClick,
  handleOpenPreviewModal,
  handleCopyUrl,
  handleCopyEmbedCode,
  handleOpenDeleteModal,
}) => {
  const navigate = useNavigate()
  const handleClickListItem = (itemId: string) => {
    onClick(itemId)
  }

  const handleNavigateEditPage = (itemId: string) => {
    navigate(`/v2/edit/${contentType}/${itemId}`)
  }

  return (
    <Box
      bgColor={activeItemId === itemId ? '#0086D2' : '#fff'}
      color={activeItemId === itemId ? '#FFF' : ''}
      lineHeight="1.7"
      cursor="pointer"
      transition=".15s"
      display="flex"
      alignItems="center"
      borderBottom="1px solid #ccc"
      _hover={{
        bgColor: activeItemId === itemId ? '#0086D2' : '#CCEAFC',
      }}
      onClick={() => handleClickListItem(itemId)}
    >
      <Box
        flexDirection="column"
        pr="40px"
        flexGrow={1}
        flexShrink={1}
        flexBasis="268px"
        justifyContent="center"
        paddingLeft="8px"
        paddingRight="12px"
        paddingY="8px"
        overflow="hidden"
      >
        <Flex fontSize="14px" fontWeight="500" lineHeight="1.7" align="center">
          <Box as="span" flexShrink={0}>
            {contentType === 'quiz' && ( // クイズのアイコンは後で
              <MdDescription
                size="20px"
                color={activeItemId === itemId ? '#fff' : '#0086D2'}
              />
            )}
            {contentType === 'enquete' && (
              <MdDescription
                size="20px"
                color={activeItemId === itemId ? '#fff' : '#0086D2'}
              />
            )}
            {contentType === 'quizlist' && (
              <MdViewList
                size="20px"
                color={activeItemId === itemId ? '#fff' : '#0086D2'}
              />
            )}
          </Box>
          <Text
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            ml="8px"
          >
            {itemData.title}
          </Text>
        </Flex>
        <Text
          className="excerpt"
          fontSize="12px"
          lineHeight="1.6666666667"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          pl="28px"
        >
          {itemData.description}
        </Text>
      </Box>

      <Box
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
        flexBasis="132px"
        paddingLeft="12px"
        paddingRight="12px"
        paddingY="8px"
      >
        <Flex alignItems="center">
          <Box
            borderRadius="50%"
            overflow="hidden"
            flex="0 0 auto"
            marginRight="8px"
            w="24px"
            h="24px"
          >
            <Image
              src={itemData.iconImg ? itemData.iconImg : userIcon}
              alt={itemData.userName}
              width="24px"
              height="24px"
              objectFit="contain"
            />
          </Box>
          <Box>{itemData.userName}</Box>
        </Flex>
      </Box>

      <Box
        flexGrow={0}
        flexShrink={0}
        flexBasis="52px"
        paddingY="8px"
        paddingX="12px"
      >
        {itemData.published ? (
          <MdCheck size="20px" color="#27AE60" />
        ) : (
          <Box w="20px" />
        )}
      </Box>

      <Box
        flexGrow={0}
        flexShrink={0}
        flexBasis="102px"
        paddingY="8px"
        paddingX="12px"
      >
        {dayjs(itemData.updatedAt).format('YYYY/MM/DD')}
      </Box>

      {contentType !== 'quizlist' && (
        <Box
          whiteSpace="nowrap"
          flexBasis="82px"
          paddingY="8px"
          paddingX="12px"
          textAlign="center"
        >
          {itemData.totalCount}
        </Box>
      )}
      <Box flexBasis="42px" pl="4px" pr="8px" paddingY="8px">
        <ContentsListMenu
          itemId={itemData.id}
          handleNavigateEditPage={handleNavigateEditPage}
          handleOpenPreviewModal={handleOpenPreviewModal}
          handleCopyUrl={handleCopyUrl}
          handleCopyEmbedCode={handleCopyEmbedCode}
          handleOpenDeleteModal={handleOpenDeleteModal}
        />
      </Box>
    </Box>
  )
}
