import {
  Center,
  Stack,
  VStack,
  HStack,
  Heading,
  Image,
  Text,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useColorModeValue,
} from '@chakra-ui/react'
import logo from 'assets/images/logo192.png'

interface Props {
  name: string
  aliasId: string
  handleBack: () => void
  handleNext: () => void
  isSubmitting: boolean
}

export const Confirm: React.FC<Props> = ({
  name,
  aliasId,
  handleBack,
  handleNext,
  isSubmitting,
}) => {
  const buttonNextColor = useColorModeValue('buttonNextLight', 'buttonNextDark')
  const buttonBackColor = useColorModeValue('buttonBackLight', 'buttonBackDark')
  return (
    <Stack>
      <ModalHeader>
        <Center>
          <Image src={logo} w="3rem" h="3rem" alt="logo" />
          <Heading size="lg" pl="0.5rem">
            Step 2/2
          </Heading>
        </Center>
      </ModalHeader>
      <ModalBody>
        <VStack>
          <Center>
            <Heading size="lg" pt={5}>
              確認
            </Heading>
          </Center>
          <HStack>
            <Heading fontSize="4xl" pb="3rem">
              {name}
            </Heading>
            <Heading fontSize="xl" pb="3rem">
              @{aliasId}
            </Heading>
          </HStack>
          <Text>でよろしいですか？</Text>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <HStack w="100%">
          <Button
            bg={buttonBackColor}
            w="100%"
            variant="outline"
            aria-label="Delete Quiz"
            onClick={handleBack}
            type="submit"
          >
            戻る
          </Button>
          <Button
            bg={buttonNextColor}
            w="100%"
            variant="outline"
            aria-label="Delete Quiz"
            onClick={handleNext}
            type="submit"
            isLoading={isSubmitting}
          >
            登録
          </Button>
        </HStack>
      </ModalFooter>
    </Stack>
  )
}
