import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import { Spacer, Text, VStack } from '@chakra-ui/react'
import { DisplayUnit, Enquete } from 'types/generated/graphql'
import { useQuery } from '@apollo/client'
import {
  ENQUETE_STATS,
  EnqueteStatsData,
  EnqueteStatsVars,
} from 'graphql/apollo/query/enqueteStats'

export interface EnqueteCardProps {
  enquete: Enquete
}

export const EnqueteCardRoot: React.FC<EnqueteCardProps> = ({ enquete }) => {
  const { data, loading } = useQuery<EnqueteStatsData, EnqueteStatsVars>(
    ENQUETE_STATS,
    {
      variables: {
        enqueteId: enquete.id!,
      },
      fetchPolicy: 'network-only',
    }
  )

  const stats = useMemo(() => {
    return data?.enqueteStats.stats
      .map((stats) => `${stats.selection} (${stats.count}票)`)
      .join('、')
  }, [data])

  return (
    <VStack w="100%">
      <Text w="100%">
        {enquete.title.length <= 55
          ? enquete.title
          : enquete.title.slice(0, 55) + '…'}
      </Text>
      <Text w="100%">
        {enquete.question.length <= 55
          ? enquete.question
          : enquete.question.slice(0, 55) + '…'}
      </Text>
      <Spacer />
      <VStack w="100%">
        <Text w="100%" fontSize="xs" text-align="left" pt={2}>
          選択肢：{loading ? enquete.optionsCsv : stats}
        </Text>
        {enquete.deadline && (
          <Text w="100%" fontSize="xs" text-align="left" pt={2}>
            {`回答期限：${dayjs(enquete.deadline).format(
              'YYYY年M月D日 HH:mm'
            )}`}
          </Text>
        )}
        <Text w="100%" fontSize="xs" text-align="left" pt={2}>
          結果画面で表示する単位：
          {enquete.displayUnit === DisplayUnit.Percentage
            ? '％'
            : enquete.displayUnit === DisplayUnit.Vote
            ? `票数`
            : '％・票数'}
        </Text>
      </VStack>
    </VStack>
  )
}
