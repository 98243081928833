import React from 'react'
import {
  Textarea,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react'
import { Field, FieldProps } from 'formik'

export interface FormTextareaProps {
  name: string
  label: string
  placeholder?: string
  isRequired?: boolean
}

export const FormTextarea: React.FC<FormTextareaProps> = ({
  name,
  label,
  placeholder = label,
  isRequired = false,
}) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        return (
          <FormControl
            // name={name}
            isInvalid={!!(form.errors[name] && form.touched[name])}
            isRequired={isRequired}
            mb={3}
          >
            <FormLabel fontSize="xs" htmlFor={name}>
              {label}
            </FormLabel>
            <Textarea {...field} placeholder={placeholder} />
            <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
          </FormControl>
        )
      }}
    </Field>
  )
}
