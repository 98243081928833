import { Center, Text } from '@chakra-ui/react'

export const Error: React.FC = () => {
  return (
    <Center w="100%" px="16px" py="24px" flex="1 1 auto" flexFlow="column">
      <Text fontSize="xl" mb="20px" fontWeight="500">
        エラーが発生しました
      </Text>
      <Text>
        ブラウザをリロードするか、しばらく経ってからやり直してください
      </Text>
    </Center>
  )
}
