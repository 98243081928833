import React, { useState, useEffect } from 'react'
import {
  Box,
  Text,
  Flex,
  IconButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  Switch,
  useDisclosure,
} from '@chakra-ui/react'
import { MdViewList } from 'react-icons/md'
import { CloseIcon } from '@chakra-ui/icons'
import { useQuery } from '@apollo/client'
import {
  QUIZSTATS,
  QuizStatsData,
  QuizStatsVars,
} from 'graphql/apollo/query/quizStats'
import { QUIZ, QuizData, QuizVars } from 'graphql/apollo/query/quiz'
import { InfoLoading } from 'v2components/InfoArea/InfoLoading'
import { InfoError } from 'v2components/InfoArea/InfoError'
import { SummaryGraphBar } from 'v2components/Common/SummaryGraphBar'
import { SummaryGraphPie } from 'v2components/Common/SummaryGraphPie'
import { SummaryLabels } from 'v2components/Common/SummaryLabels'
import { ToolButtons } from 'v2components/Common/ToolButtons'
import { PreviewModal } from 'v2components/Modal/PreviewModal'
import { DeleteModal } from 'v2components/Modal/DeleteModal'
import { PlayQuiz } from 'components/PlayQuiz'
import { useDeleteQuiz } from 'v2hooks/useDeleteQuiz'
import { AlertModal } from 'v2components/Modal/AlertModal'
import { useUpdateQuiz } from 'hooks/useUpdateQuiz'

interface Props {
  activeItemId: string
  closeInfoFlag: boolean
  handleClickToggleInfo: (flg: boolean) => void
}

interface ItemDetailData {
  title: string
  description: string
  published: boolean
  total: number
  stateData: {
    labels: string[]
    labelsNumber: number[]
    count: number[]
    percent: string[]
  }
}

export const InfoQuizzes: React.FC<Props> = ({
  activeItemId,
  closeInfoFlag,
  handleClickToggleInfo,
}) => {
  const [isCheckedPub, setIsCheckedPub] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertDescription, setAlertDescription] = useState('')
  const previewDisclosure = useDisclosure()
  const deleteDisclosure = useDisclosure()
  const alertDisclosure = useDisclosure()

  const itemData: ItemDetailData = {
    title: 'クイズを選択してください',
    description: 'クイズをアクティブにすると詳細データを閲覧できます。',
    published: false,
    total: 0,
    stateData: {
      labels: [],
      labelsNumber: [],
      count: [],
      percent: [],
    },
  }

  const handleClosePreviewModal = () => {
    previewDisclosure.onClose()
  }
  const handleOpenPreviewModal = () => {
    previewDisclosure.onOpen()
  }

  const handleCloseInfo = () => {
    handleClickToggleInfo?.(true)
  }

  const deleteQuiz = useDeleteQuiz(activeItemId, deleteDisclosure.onClose)
  const handleDeleteItem = async () => {
    try {
      await deleteQuiz()
      handleClickToggleInfo?.(true)
    } catch (error) {
      setAlertMessage('削除に失敗しました。')
      setAlertDescription('※クイズリストで選択中の場合は削除できません。')
      deleteDisclosure.onClose()
      alertDisclosure.onOpen()
    }
  }

  const { handleUpdateQuizPub } = useUpdateQuiz({
    id: activeItemId,
  })

  const handleOpenDeleteModal = () => {
    deleteDisclosure.onOpen()
  }

  const {
    loading: quizLoading,
    error: quizError,
    data: quizData,
  } = useQuery<QuizData, QuizVars>(QUIZ, {
    variables: {
      id: activeItemId!,
    },
  })

  const {
    loading: quizStatsLoading,
    error: quizStatsError,
    data: quizStatsData,
  } = useQuery<QuizStatsData, QuizStatsVars>(QUIZSTATS, {
    variables: {
      quizId: activeItemId!,
    },
    fetchPolicy: 'network-only',
  })

  // itemDatsに取得したデータを入れていく
  const detailData = quizData?.quiz
  const stateData = quizStatsData?.quizStats
  if (detailData && stateData) {
    itemData.title = detailData.content.question
    itemData.description = ''
    itemData.published = detailData.published
    itemData.total = stateData.total
    const labels = []
    const labelsNumber = []
    const count = []
    const percent = []
    for (let i = 0; i < stateData.stats.length; i++) {
      labels.push(stateData.stats[i].selection)
      labelsNumber.push(i + 1)
      count.push(stateData.stats[i].count)
      percent.push(stateData.stats[i].percent)
    }

    itemData.stateData.labels = labels
    itemData.stateData.labelsNumber = labelsNumber
    itemData.stateData.count = count
    itemData.stateData.percent = percent
  }

  useEffect(() => {
    if (detailData) {
      setIsCheckedPub(detailData.published)
    }
  }, [detailData])

  return (
    <Box
      px="16px"
      py="24px"
      bgColor="#fff"
      borderRadius="24px"
      w="320px"
      maxW="320px"
      position="relative"
      display={closeInfoFlag ? 'none' : 'block'}
      overflowY="scroll"
      overflowX="hidden"
      flex="0 0 320px"
    >
      <IconButton
        isRound={true}
        variant="solid"
        bg="none"
        aria-label="Done"
        w="40px"
        h="40px"
        position="absolute"
        top="16px"
        right="8px"
        icon={<CloseIcon w="16px" h="16px" color="#222" />}
        _hover={{
          bgColor: '#C4C7CC',
        }}
        onClick={handleCloseInfo}
      />
      {itemData.title && (
        <Box fontWeight="bold" lineHeight="1.7" display="flex" pr="40px">
          <MdViewList color="#0086D2" size="24px" />
          <Text display="inline-block" ml="8px">
            {itemData.title}
          </Text>
        </Box>
      )}
      {itemData.description && (
        <Text fontSize="12px" lineHeight="1.66" mt="8px" pl="32px" pr="40px">
          {itemData.description}
        </Text>
      )}

      {(() => {
        if (quizLoading || quizStatsLoading) return <InfoLoading />
        if (activeItemId && (quizError || quizStatsError)) return <InfoError />
      })()}

      {activeItemId && detailData && stateData && (
        <>
          <Tabs variant="info-area" mt="24px" defaultIndex={tabIndex}>
            <TabList>
              <Tab
                onClick={() => {
                  setTabIndex(0)
                }}
              >
                詳細
              </Tab>
              <Tab
                onClick={() => {
                  setTabIndex(1)
                }}
              >
                回答サマリー
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <PlayQuiz
                  quizId={activeItemId}
                  isPreview={true}
                  isEmbed={true}
                />
                <Flex justifyContent="flex-end" mt="12px">
                  <FormControl display="flex" alignItems="center" w="auto">
                    <Switch
                      id="publish-switch"
                      size="sm"
                      colorScheme="green"
                      isChecked={isCheckedPub}
                      onChange={async (e) => {
                        setIsCheckedPub(!isCheckedPub)
                        try {
                          await handleUpdateQuizPub(!isCheckedPub)
                        } catch (error) {
                          setIsCheckedPub(isCheckedPub)
                          setAlertMessage('公開ステータス変更に失敗しました。')
                          setAlertDescription('')
                          alertDisclosure.onOpen()
                        }
                      }}
                    />
                    <FormLabel
                      htmlFor="publish-switch"
                      mb="0"
                      fontSize="14px"
                      ml="8px"
                    >
                      {isCheckedPub ? '公開' : '非公開'}
                    </FormLabel>
                  </FormControl>
                </Flex>
              </TabPanel>
              <TabPanel>
                <>
                  <Text fontSize="14px" textAlign="center">
                    <Text as="span" fontWeight="700" color="#2B5080">
                      総投票数：
                    </Text>
                    {itemData.total}件
                  </Text>
                  <SummaryGraphBar datas={itemData.stateData} />
                  <SummaryLabels datas={itemData.stateData} />
                  <SummaryGraphPie datas={itemData.stateData} />
                </>
              </TabPanel>
            </TabPanels>
          </Tabs>
          <Box mt="20px">
            <ToolButtons
              itemId={activeItemId}
              contentType="quiz"
              buttonType="item-info"
              handleOpenPreviewModal={() => {
                handleOpenPreviewModal()
              }}
              handleOpenDeleteModal={handleOpenDeleteModal}
            />
          </Box>
          <PreviewModal
            pmIsOpen={previewDisclosure.isOpen}
            onClosePm={handleClosePreviewModal}
            itemId={activeItemId}
            contentType="quiz"
          />
          <DeleteModal
            dmIsOpen={deleteDisclosure.isOpen}
            dmOnClose={deleteDisclosure.onClose}
            handleDeleteItem={handleDeleteItem}
          />
          <AlertModal
            modalBody={alertMessage}
            modalDescription={alertDescription}
            isOpen={alertDisclosure.isOpen}
            onClose={alertDisclosure.onClose}
          />
        </>
      )}
    </Box>
  )
}
