import { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import { UserProfileFormValues } from 'services/validators/UserProfileFormSchema'

import {
  UpdateUserData,
  UpdateUserVars,
  UPDATE_USER,
} from 'graphql/apollo/mutation/user/updateUser'
import { USER, UserData } from 'graphql/apollo/query/user'
import { useUploadFile } from 'hooks/useUploadFile'
import { GROUP } from 'graphql/apollo/query/group'

type HooksArgs = {
  userProfile: UserData | undefined
}
export const useEditUser = ({ userProfile }: HooksArgs) => {
  const { handleUploadDataUrlImage } = useUploadFile()
  const [updateUserData] = useMutation<UpdateUserData, UpdateUserVars>(
    UPDATE_USER
  )

  const handleEditUser = useCallback(
    async (values: UserProfileFormValues) => {
      if (!userProfile) return new Error('Missing userprofile ID.')
      const icon = await handleUploadDataUrlImage(values.icon, 'icon')
      const coverImage = await handleUploadDataUrlImage(
        values.coverImage,
        'cover-image'
      )

      const variables = {
        input: {
          id: userProfile.user.id,
          name: values.name,
          aliasId: values.aliasId,
          comment: values.comment,
          icon: icon,
          coverImage: coverImage,
        },
      }
      await updateUserData({
        variables: variables,
        refetchQueries: [USER, GROUP],
      })
    },
    [updateUserData, handleUploadDataUrlImage, userProfile]
  )

  return { handleEditUser }
}
