import { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import { UserProfileFormValues } from 'services/validators/UserProfileFormSchema'

import {
  CreateUserData,
  CreateUserVars,
  CREATE_USER,
} from 'graphql/apollo/mutation/user/createUser'
import { USER, UserData } from 'graphql/apollo/query/user'

type HooksArgs = {
  userProfile: UserData | undefined
}
export const useCreateUser = ({ userProfile }: HooksArgs) => {
  const [createUserData] = useMutation<CreateUserData, CreateUserVars>(
    CREATE_USER
  )

  const handleCreateUser = useCallback(
    async (values: UserProfileFormValues) => {
      const email = userProfile?.user.email!
      const variables = {
        input: {
          aliasId: values.aliasId,
          name: values.name,
          email: email,
        },
      }
      await createUserData({
        variables: variables,
        refetchQueries: [USER],
      })
    },
    [createUserData, userProfile?.user.email]
  )

  return { handleCreateUser }
}
