import React from 'react'
import {
  Box,
  Center,
  VStack,
  Image,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import truncate from 'lodash/truncate'
import { Quiz } from 'types/generated/graphql'

type Props = {
  quiz: Quiz
  index: number
}

// クイズ一覧ページで使うクイズカードコンポーネント
export const SelectedQuizCard: React.FC<Props> = ({ quiz, index }) => {
  const publishedColor = useColorModeValue('primaryLight', 'secondaryLight')
  const unpublishedColor = useColorModeValue('primaryDark', 'secondaryDark')

  const [size, setSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  window.addEventListener(
    'resize',
    function () {
      setSize({ width: window.innerWidth, height: window.innerHeight })
    },
    true
  )

  return (
    <Box h="120px">
      <Image
        position="absolute"
        top="0"
        left="0"
        w="100%"
        h="100%"
        objectFit="cover"
        src={quiz.content.imageUrl || '/images/whiteImage.png'}
      />
      <VStack
        position="absolute"
        bg="#FFFFFF"
        borderWidth="1px"
        borderColor="#00000"
        opacity="80%"
        w="100%"
        h="100%"
        p={1.5}
      >
        {quiz.published ? (
          <Center w="100%" bg={publishedColor}>
            <Text fontWeight="bold">公開中</Text>
          </Center>
        ) : (
          <Center w="100%" bg={unpublishedColor}>
            <Text fontWeight="bold">非公開</Text>
          </Center>
        )}
        <Text fontSize="xs">
          {size.width > 375 * 2
            ? truncate(quiz.content.question, {
                length: 74,
              })
            : truncate(quiz.content.question, {
                length: 32,
              })}
        </Text>
        <Text position="absolute" bottom="1.5" fontSize="xs">
          正解：{quiz.content.correct}
        </Text>
        <Text
          position="absolute"
          bottom="1.5"
          right="1.5"
          fontSize="sm"
          fontWeight="bold"
          color="red"
        >
          {index + 1}
        </Text>
      </VStack>
    </Box>
  )
}
