import React from 'react'
import { SimpleGrid } from '@chakra-ui/react'
import { PlaylistCard } from './PlaylistCard'
import { NoData } from 'components/Common/NoData'
import { Playlist } from 'types/generated/graphql'

interface Props {
  playlists?: Playlist[]
  isEditableQuiz: boolean
}

export const QuizPlaylistCardList: React.FC<Props> = ({
  playlists,
  isEditableQuiz,
}) => {
  if (!playlists || playlists.length === 0) return <NoData />

  return (
    <SimpleGrid columns={[1, 2, 3, 4]} spacing="10px">
      {playlists.map((playlist) => (
        <PlaylistCard
          key={playlist.id}
          playlist={playlist}
          isEditableQuiz={isEditableQuiz}
        />
      ))}
    </SimpleGrid>
  )
}
