import React from 'react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  SimpleGrid,
} from '@chakra-ui/react'
import { MdDescription, MdViewList } from 'react-icons/md'
import { QuizIcon } from 'assets/icons'
import { ButtonItem } from 'v2components/Modal/AddContentModal/ButtonItem'

interface Props {
  isOpen: boolean
  onClose: () => void
}

export const AddContentModal: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
      <ModalOverlay />
      <ModalContent bg="v2Primary" maxW="800px" w="800px" py="40px" px="24px">
        <ModalCloseButton color="#fff" />
        <ModalBody textAlign="center" fontSize="20" p="0">
          <Text
            fontSize="20px"
            fontWeight="500"
            mb="40px"
            color="#fff"
            lineHeight="1"
          >
            新規ファイル作成
          </Text>
          <SimpleGrid spacing="16px" columns={3}>
            <ButtonItem
              label="アンケートを作成する"
              icon={<MdDescription size="80px" fill="#2B5080" />}
              path="/v2/create/enquete"
            />
            <ButtonItem
              label="クイズを作成する"
              icon={<QuizIcon h="80px" w="80px" fill="#2B5080" />}
              path="/v2/create/quiz"
            />
            <ButtonItem
              label="クイズリストを作成する"
              icon={<MdViewList size="80px" fill="#2B5080" />}
              path="/v2/create/quizlist"
            />
          </SimpleGrid>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
