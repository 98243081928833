import { Center, Box } from '@chakra-ui/react'
import { QuizDesignType } from 'types/generated/graphql'

export interface LayoutProps {
  disableSidebar?: boolean
  design?: QuizDesignType
}

export const LayoutOpenArea: React.FC<LayoutProps> = ({ children, design }) => {
  return (
    <Box bg={`${design}.common.layerBackground`}>
      <Center>
        <Box h="100%" w="100%">
          {children}
        </Box>
      </Center>
    </Box>
  )
}
