import { Spinner, Center, VStack, Text } from '@chakra-ui/react'

export const InfoLoading: React.FC = () => {
  return (
    <Center
      px="16px"
      py="24px"
      bgColor="#fff"
      w="100%"
      h="100%"
      position="relative"
    >
      <VStack>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="v2Primary"
          size="xl"
        />
        <Text fontSize="xl">Loading...</Text>
      </VStack>
    </Center>
  )
}
