import React from 'react'
import { useNavigate } from '@reach/router'
import dayjs from 'dayjs'
import {
  Box,
  Center,
  Stack,
  HStack,
  Image,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { CalendarIcon, RepeatClockIcon } from '@chakra-ui/icons'
import { QuizCardRoot } from 'components/Common/QuizCardComponent/QuizCardRoot'
import type { Quiz } from 'types/generated/graphql'

export interface QuizCardProps {
  quiz: Quiz
  isEditableQuiz: boolean
}

// クイズ一覧ページで使うクイズカードコンポーネント
export const QuizCard: React.FC<QuizCardProps> = ({ quiz, isEditableQuiz }) => {
  const navigate = useNavigate()
  const publishedColor = useColorModeValue('primaryLight', 'secondaryLight')
  const unpublishedColor = useColorModeValue('primaryDark', 'secondaryDark')

  return (
    <Box
      overflow="hidden"
      bg="white"
      _hover={{ boxShadow: '2xl', bg: 'rgba(0,0,0,0.2)' }}
      onClick={() =>
        navigate(
          isEditableQuiz ? `/quiz/${quiz.id}/check` : `/quiz/${quiz.id}/play`
        )
      }
    >
      {quiz.published ? (
        <Center bg={publishedColor}>
          <Text fontWeight="bold">公開中</Text>
        </Center>
      ) : (
        <Center bg={unpublishedColor}>
          <Text fontWeight="bold">非公開</Text>
        </Center>
      )}
      {quiz.content.imageUrl ? (
        <Image
          w="100%"
          h="150px"
          objectFit="cover"
          src={quiz.content.imageUrl}
          fallbackSrc={'images/quiz_bg--card.png'}
          alt="クイズ画像"
        />
      ) : (
        <Center w="100%" h="150px">
          <Text>no image</Text>
        </Center>
      )}
      <Stack p="2">
        <HStack>
          <Text fontSize="sm" mr={2}>
            <CalendarIcon boxSize="16px" mr={1} mb={1} />
            {dayjs(quiz.createdAt).format('YYYY-MM-DD')}
          </Text>
          <Text fontSize="sm" mr={2}>
            <RepeatClockIcon boxSize="16px" mr={1} mb={1} />
            {dayjs(quiz.updatedAt).format('YYYY-MM-DD')}
          </Text>
        </HStack>
        <QuizCardRoot quiz={quiz} />
      </Stack>
    </Box>
  )
}
