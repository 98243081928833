import { RouteComponentProps } from '@reach/router'
import { Box } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { useQuery } from '@apollo/client'
import {
  QUIZSTATS,
  QuizStatsData,
  QuizStatsVars,
} from 'graphql/apollo/query/quizStats'
import { Quiz } from 'types/generated/graphql'
import {
  usePlayQuizPlaylist,
  ProgressStatus,
} from 'v2hooks/usePlayQuizListLivePreview'
import { EmbedStart } from 'components/PlayQuizPlaylist/EmbedStart'
import { EmbedQuestion } from 'components/PlayQuizPlaylist/EmbedQuestion'
import { EmbedAnswered } from 'components/PlayQuizPlaylist/EmbedAnswered'
import { EmbedResult } from 'components/PlayQuizPlaylist/EmbedResult'
import { NoQuizInfo } from './NoQuizInfo'
import { playlistFormSchema } from 'services/validators/playlistFormSchema'
import { ToolButtonsPre } from 'v2components/Common/ToolButtonsPre'

type Props = RouteComponentProps<{
  enqueteId: string
  isPreview?: boolean
  selectedQuizzes: Quiz[]
}>

export const LivePreviewQuizList: React.FC<Props> = (selectedQuizzes) => {
  const { values } = useFormikContext()
  const castValues = playlistFormSchema.cast(values, {
    stripUnknown: true,
  })
  const publishedQuizzes = selectedQuizzes.selectedQuizzes?.filter(
    (quiz) => quiz.published === true
  )
  const {
    progressStatus,
    playlist,
    quizNo,
    answers,
    score,
    currentQuiz,
    quizzesCount,
    handleStart,
    handleAnswer,
    handleNext,
    resetPreviewStatus,
  } = usePlayQuizPlaylist(publishedQuizzes!, castValues)
  const { data: quizStatsData } = useQuery<QuizStatsData, QuizStatsVars>(
    QUIZSTATS,
    {
      variables: {
        quizId: currentQuiz?.id!,
      },
      fetchPolicy: 'network-only',
    }
  )

  const handleResetPreview = () => {
    resetPreviewStatus()
  }

  const renderPageComponent = () => {
    switch (progressStatus) {
      case ProgressStatus.START:
        return (
          <EmbedStart
            isLoading={false}
            playlist={playlist}
            quizzesCount={quizzesCount}
            onStart={handleStart}
          />
        )
      case ProgressStatus.QUESTION:
        if (!currentQuiz) {
          return <NoQuizInfo />
        }
        return (
          <EmbedQuestion
            quiz={currentQuiz!}
            quizNo={quizNo}
            quizzesCount={quizzesCount}
            quizStats={quizStatsData?.quizStats}
            onAnswer={handleAnswer}
          />
        )
      case ProgressStatus.ANSWERED:
        if (!currentQuiz) {
          return <NoQuizInfo />
        }
        return (
          <EmbedAnswered
            quiz={currentQuiz!}
            quizStats={quizStatsData?.quizStats}
            answer={answers[answers.length - 1]}
            onNext={handleNext}
          />
        )

      case ProgressStatus.RESULT:
        return (
          <EmbedResult
            playlist={playlist!}
            quizzesCount={quizzesCount}
            answers={answers}
            score={score}
          />
        )
    }
  }

  return (
    <Box overflowY="auto" maxH="100%">
      <Box w="100%" marginBottom="20px">
        {renderPageComponent()}
      </Box>
      <ToolButtonsPre handleResetPreview={handleResetPreview} />
    </Box>
  )
}
