import React from 'react'
import {
  Box,
  Grid,
  GridItem,
  VStack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { FormUploadImage } from 'components/Common/InputPageComponent/FormUploadImage'

export const QuizImageForm: React.FC = () => {
  const bg = useColorModeValue('primary', 'secondary')
  return (
    <Box bg={bg} borderRadius={10} p={3} mb={5}>
      <Grid
        templateRows={{
          base: 'repeat(2, 1fr)',
          sm: 'repeat(1, 1fr)',
        }}
        templateColumns={{
          base: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
        gap={4}
      >
        <GridItem rowSpan={{ base: 1, sm: 1 }} colSpan={{ base: 1, sm: 1 }}>
          <VStack h="100%">
            <Text fontWeight="bold" fontSize="sm">
              問題画像
            </Text>
            <FormUploadImage name="imageUrl" />
          </VStack>
        </GridItem>
        <GridItem rowSpan={{ base: 1, sm: 1 }} colSpan={{ base: 1, sm: 1 }}>
          <VStack h="100%">
            <Text fontWeight="bold" fontSize="sm">
              解答画像
            </Text>
            <FormUploadImage name="answerImageUrl" />
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  )
}
