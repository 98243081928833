import { Box } from '@chakra-ui/react'
import { RouteComponentProps } from '@reach/router'
import { Layout } from 'components/Common/Layout'
import { QuizPlaylistCardList } from 'components/QuizPlaylistCardList'
import { useUserProfileContext } from 'state'
import {
  PLAYLISTS,
  PlaylistsData,
  PlaylistsVars,
} from 'graphql/apollo/query/playlists'
import { useQuery } from '@apollo/client'
import { Loading } from 'components/Common/Loading'
import { Error } from 'components/Common/Error'

type Props = RouteComponentProps

export const QuizPlaylists: React.FC<Props> = () => {
  const { currentGroup } = useUserProfileContext()
  const {
    loading: playlistsLoading,
    error: playlistsError,
    data: playlistsData,
  } = useQuery<PlaylistsData, PlaylistsVars>(PLAYLISTS, {
    variables: {
      filter: {
        ownerGroupId: currentGroup?.id,
      },
    },
  })

  if (playlistsLoading)
    return (
      <Layout pageTitle="クイズリストページ">
        <Loading />
      </Layout>
    )

  if (playlistsError)
    return (
      <Layout pageTitle="クイズリストページ">
        <Error />
      </Layout>
    )

  return (
    <Layout pageTitle="クイズリストページ">
      <Box p="3">
        <QuizPlaylistCardList
          playlists={playlistsData?.playlists}
          isEditableQuiz={true}
        />
      </Box>
    </Layout>
  )
}
