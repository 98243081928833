import { Enquete } from '../types/generated/graphql'

export const isAnsweredEnquete = (enqueteId: string) => {
  let answeredEnquetes = []
  let json = localStorage.getItem('answeredEnquetes')
  if (json) {
    answeredEnquetes = JSON.parse(json)
  }
  const enqueteIds = answeredEnquetes.map((answer: any) => answer.enqueteId)

  return enqueteIds.includes(enqueteId)
}

export const saveAnsweredEnquete = (
  enqueteId: string,
  selectedChoice: string
) => {
  let answeredEnquetes = []
  let json = localStorage.getItem('answeredEnquetes')
  if (json) {
    answeredEnquetes = JSON.parse(json)
  }
  answeredEnquetes.push({ enqueteId: enqueteId, answer: selectedChoice })
  localStorage.setItem('answeredEnquetes', JSON.stringify(answeredEnquetes))
}

export const getAnsweredEnquete = (enqueteId: string) => {
  let answeredEnquetes = []
  let json = localStorage.getItem('answeredEnquetes')
  if (json) {
    answeredEnquetes = JSON.parse(json)
  }
  const result = answeredEnquetes.find(
    (answeredEnquete: any) => answeredEnquete.enqueteId === enqueteId
  )
  return result['answer']
}

export const getEmbedHeight = (enquete: Enquete) => {
  let height = 300
  const options = enquete.optionsCsv.split(',')
  if (options.length <= 2) {
    return height
  }
  return height + 40 * (options.length - 2)
}
