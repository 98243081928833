import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useDisclosure } from '@chakra-ui/react'
import { EnqueteForm } from 'v2components/EnqueteForm'
import { AlertModal } from 'v2components/Modal/AlertModal'
import { Loading } from 'v2components/Common/Loading'
import { NoData } from 'components/Common/NoData'
import { Error } from 'v2components/Common/Error'
import { useUpdateEnquete } from 'hooks/useUpdateEnquete'
import { Enquete } from 'types/generated/graphql'
import {
  ENQUETE_STATS,
  EnqueteStatsData,
  EnqueteStatsVars,
} from 'graphql/apollo/query/enqueteStats'
import { CompletedModal } from 'v2components/Modal/CompletedModal'
import { PreviewModal } from 'v2components/Modal/PreviewModal'

export interface EnqueteCardProps {
  enquete: Enquete
}

export const EditRoot: React.FC<EnqueteCardProps> = ({ enquete }) => {
  const completeDisclosure = useDisclosure()
  const alertDisclosure = useDisclosure()
  const previewDisclosure = useDisclosure()
  const [previewItemId, setPreviewItemId] = useState('')
  const { handleUpdateEnquete } = useUpdateEnquete({
    id: enquete.id,
  })
  const {
    loading: enqueteStatsLoading,
    error: enqueteStatsError,
    data: enqueteStatsData,
  } = useQuery<EnqueteStatsData, EnqueteStatsVars>(ENQUETE_STATS, {
    variables: {
      enqueteId: enquete.id,
    },
    fetchPolicy: 'network-only',
  })
  const handleOpenPreviewModal = (itemId: string) => {
    setPreviewItemId(itemId)
    previewDisclosure.isOpen = true
  }
  const handleClosePreviewModal = () => {
    setPreviewItemId('')
    previewDisclosure.isOpen = false
  }

  if (enqueteStatsLoading) return <Loading />
  if (enqueteStatsError) return <Error />
  if (!enqueteStatsData) return <NoData />

  return (
    <>
      <EnqueteForm
        initialValues={enquete}
        enqueteStats={enqueteStatsData.enqueteStats}
        submitButtonLabel="更新"
        headerTitle="アンケート編集"
        onSubmit={async (values) => {
          try {
            await handleUpdateEnquete(values)
            completeDisclosure.onOpen()
          } catch (error) {
            alertDisclosure.onOpen()
          }
        }}
      />
      <CompletedModal
        modalBody={'アンケートを更新しました'}
        isOpenCompletedModal={completeDisclosure.isOpen}
        handleCloseCompletedModal={completeDisclosure.onClose}
        itemId={enquete?.id}
        contentType="enquete"
        handleOpenPreviewModal={handleOpenPreviewModal}
      />
      <PreviewModal
        pmIsOpen={previewDisclosure.isOpen}
        onClosePm={handleClosePreviewModal}
        itemId={previewItemId}
        contentType="enquete"
      />
      <AlertModal
        modalBody={'アンケートの更新に失敗しました。'}
        isOpen={alertDisclosure.isOpen}
        onClose={alertDisclosure.onClose}
      />
    </>
  )
}
