import React, { useState } from 'react'
import { IconButton, useDisclosure } from '@chakra-ui/react'
import { MinusIcon } from '@chakra-ui/icons'

import { Member, Group } from 'types/generated/graphql'
import { UserData } from 'graphql/apollo/query/user'
import { useDeleteGroupMember } from 'hooks/useDeleteGroupMember'
import { getOwnerList } from 'utils/getGroupRole'

import { DeleteMyselfModal } from 'components/MemberDrawer/DeleteMyselfModal'
import { AlertModal } from 'components/Modal/AlertModal'

interface Props {
  isEditable: boolean
  userProfile: UserData
  member: Member
  currentGroup: Group
}

export const DeleteIconButton: React.FC<Props> = ({
  isEditable,
  userProfile,
  member,
  currentGroup,
}) => {
  const alertDisclosure = useDisclosure()
  const deleteMemberDisclosure = useDisclosure()
  const [modalBody, setModalBody] = useState<string>('')
  const { handleDeleteGroupMember, loading } = useDeleteGroupMember()

  const DeleteIconButton = () => (
    <IconButton
      isLoading={loading}
      icon={<MinusIcon />}
      aria-label="Member Minus Button"
      onClick={async () => {
        try {
          if (member.user.id === userProfile.user.id) {
            setModalBody(
              'このグループにアクセスできなくなりますがよろしいですか？'
            )
            deleteMemberDisclosure.onOpen()
          } else {
            await handleDeleteGroupMember(currentGroup, member.user)
          }
        } catch (e) {
          setModalBody('ユーザーの削除に失敗しました。')
          alertDisclosure.onOpen()
        }
      }}
    />
  )

  return (
    <>
      <AlertModal
        modalBody={modalBody}
        isOpen={alertDisclosure.isOpen}
        onClose={alertDisclosure.onClose}
      />
      <DeleteMyselfModal
        group={currentGroup}
        user={member.user}
        modalBody={modalBody}
        isOpen={deleteMemberDisclosure.isOpen}
        onClose={deleteMemberDisclosure.onClose}
      />
      {/* 自分がオーナーであることが大前提 -> これはisEditableで保証されてる
      MEMBERに関しては常に消せていい
      消そうとしている対象がオーナーかつそれによってオーナーの数が０になるとき(現在のオーナー数が1以下の時)disabled*/}
      {isEditable &&
      (member.role === 'MEMBER' ||
        (member.role === 'OWNER' && getOwnerList(currentGroup).length > 1)) ? (
        <DeleteIconButton />
      ) : (
        <IconButton
          isLoading={loading}
          icon={<MinusIcon />}
          aria-label="Member Minus Button"
          disabled
        />
      )}
    </>
  )
}
