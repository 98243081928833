import { useMutation } from '@apollo/client'
import {
  ADD_MEMBER,
  AddMemberData,
  AddMemberVar,
} from 'graphql/apollo/mutation/group/addMember'
import { GROUP } from 'graphql/apollo/query/group'
import { Group } from 'types/generated/graphql'

export const useAddUserToGroup = (group: Group, userId: string) => {
  const [addUser, { loading, error }] = useMutation<
    AddMemberData,
    AddMemberVar
  >(ADD_MEMBER)
  const handleAddUserToGroup = async () => {
    await addUser({
      variables: {
        groupId: group.id,
        userId: userId,
      },
      refetchQueries: [{ query: GROUP }, 'Group'],
    })
    if (loading) console.log('loading now')
    if (error)
      alert(`${error}${userId}の追加において、エラー発生が発生しました`)
  }
  return handleAddUserToGroup
}
