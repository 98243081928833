import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { FormUploadImage } from 'v2components/Common/InputPageComponent/FormUploadImage'

export const EnqueteImageForm: React.FC = () => {
  return (
    <Box>
      <Text fontWeight="500" mb="8px">
        背景画像
      </Text>
      <FormUploadImage name="imageUrl" />
    </Box>
  )
}
