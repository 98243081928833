import { gql } from '@apollo/client'
import { Scalars, User } from 'types/generated/graphql'

export type UsersData = {
  users: [User]
}

export type UsersVars = {
  id: Scalars['ID']
}

export const USERS = gql`
  query Users {
    users {
      id
      name
      email
      aliasId
      comment
      icon
      coverImage
      groups {
        id
        members {
          role
          user {
            id
            name
            aliasId
          }
        }
        name
      }
    }
  }
`
