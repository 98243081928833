import React, { useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Layout } from 'v2components/Common/Layout'
import { TopMainLayout } from 'v2components/Common/TopMainLayout'
import { InfoEnquete } from 'v2components/InfoArea/InfoEnquete'
import { ContentsList } from 'v2components/Common/ContentsList'
import { ContentsHeader } from 'v2components/Common/ContentsHeader'
import { useUserProfileContext } from 'state'
import { useQuery } from '@apollo/client'
import {
  ENQUETES,
  EnquetesData,
  EnquetesVars,
} from 'graphql/apollo/query/enquetes'
import { Loading } from 'v2components/Common/Loading'
import { Error } from 'components/Common/Error'

type Props = RouteComponentProps

export const Enquetes: React.FC<Props> = () => {
  const { currentGroup } = useUserProfileContext()
  const {
    loading: enquetesLoading,
    error: enquetesError,
    data: enquetesData,
  } = useQuery<EnquetesData, EnquetesVars>(ENQUETES, {
    variables: {
      groupId: currentGroup?.id,
    },
  })
  const [activeItemId, setActiveItemId] = useState('')
  const [closeInfoFlag, setCloseInfoFlag] = useState(false)
  const [isListView, setIsListView] = useState(false)
  const setItemId = (itemId: string) => {
    setActiveItemId(itemId)
  }
  const handleClickToggleInfo = (flg: boolean) => {
    setCloseInfoFlag(flg)
  }

  const handleClickSetListView = (flg: boolean) => {
    setIsListView(flg)
  }

  return (
    <Layout
      pageTitle="アンケートページ"
      setItemId={setItemId}
      showSearchBox={true}
    >
      <TopMainLayout>
        <ContentsHeader
          contentTypeTitle="アンケート"
          contentType="enquete"
          isListPage={true}
          closeInfoFlag={closeInfoFlag}
          handleClickToggleInfo={handleClickToggleInfo}
          isListView={isListView}
          handleClickSetListView={handleClickSetListView}
        />
        {(() => {
          if (enquetesLoading) return <Loading />
          if (enquetesError) return <Error />
          if (enquetesData?.enquetes)
            return (
              <ContentsList
                enquetes={enquetesData?.enquetes}
                isEditable={true}
                setItemId={setItemId}
                activeItemId={activeItemId}
                closeInfoFlag={closeInfoFlag}
                handleClickToggleInfo={handleClickToggleInfo}
                isListView={isListView}
              />
            )
        })()}
      </TopMainLayout>
      <InfoEnquete
        activeItemId={activeItemId}
        closeInfoFlag={closeInfoFlag}
        handleClickToggleInfo={handleClickToggleInfo}
      />
    </Layout>
  )
}
