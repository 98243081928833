import React from 'react'
import {
  Textarea,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react'
import { Field, FieldProps } from 'formik'

export interface FormTextareaProps {
  name: string
  label: string
  placeholder?: string
  isRequired?: boolean
}

export const FormTextarea: React.FC<FormTextareaProps> = ({
  name,
  label,
  placeholder = label,
  isRequired = false,
}) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        return (
          <FormControl
            isInvalid={!!(form.errors[name] && form.touched[name])}
            isRequired={isRequired}
            mb={3}
          >
            <FormLabel
              color="#222"
              fontSize="14px"
              fontWeight="500"
              lineHeight="1.7142857143"
              letterSpacing="0.04em"
              alignItems="center"
              mb="8px"
              htmlFor={name}
            >
              {label}
            </FormLabel>
            <Textarea
              resize="none"
              minH="108px"
              w="100%"
              h="100%"
              borderColor="#C0C0C0"
              borderRadius="8px 8px"
              borderStyle="solid"
              borderWidth="1px"
              boxSizing="border-box"
              p="8px 16px"
              maxW="100%"
              {...field}
              placeholder={placeholder}
            />
            <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
          </FormControl>
        )
      }}
    </Field>
  )
}
