import { gql } from '@apollo/client'
import { Playlist, Quiz, Enquete } from 'types/generated/graphql'

export type MyPageData = {
  myPage: {
    enquetes: Enquete[]
    quizzes: Quiz[]
    playlists: Array<Playlist & { quizzes: Quiz[] }>
  }
}

export type MyPageVars = {
  groupId?: string
}

export const MYPAGE = gql`
  query Mypage($groupId: ID) {
    myPage(groupId: $groupId) {
      enquetes {
        id
        title
        question
        optionsCsv
        imageUrl
        createdAt
        updatedAt
        published
        selectMax
        selectMin
        periodDisplayFlag
        startDate
        deadline
        displayUnit
        ownerUser {
          id
          name
        }
        ownerGroup {
          id
          name
        }
      }
      quizzes {
        id
        title
        createdAt
        updatedAt
        published
        ownerUser {
          id
          name
        }
        ownerGroup {
          id
          name
        }
        content {
          question
          correct
          wrongs
          imageUrl
        }
      }
      playlists {
        id
        name
        description
        published
        quizzes {
          id
          title
          published
          content {
            question
            correct
            wrongs
          }
        }
        imageUrl
      }
    }
  }
`
