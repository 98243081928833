import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { Quiz } from 'types/generated/graphql'
import { QuizFormValues } from 'services/validators/QuizFormSchema'
import { createOgp } from 'utils/createOgp'
import { isDataURL } from 'utils/isDataURL'
import { useUploadFile } from 'hooks/useUploadFile'
import {
  UpdateQuizData,
  UpdateQuizVars,
  UPDATE_QUIZ,
} from 'graphql/apollo/mutation/quiz/updateQuiz'
import {
  UpdateQuizDataPub,
  UpdateQuizVarsPub,
  UPDATE_QUIZ_PUB,
} from 'graphql/apollo/mutation/quiz/updateQuizPub'
import { QUIZ } from 'graphql/apollo/query/quiz'
import { QUIZZES } from 'graphql/apollo/query/quizzes'

type HooksArgs = {
  id: string
}

export const useUpdateQuiz = ({ id }: HooksArgs) => {
  const { handleUploadDataUrlImage } = useUploadFile()

  const [updateQuiz] = useMutation<UpdateQuizData, UpdateQuizVars>(UPDATE_QUIZ)
  const [updateQuizPub] = useMutation<UpdateQuizDataPub, UpdateQuizVarsPub>(
    UPDATE_QUIZ_PUB
  )

  const handleUpdateQuiz = useCallback(
    async (values: QuizFormValues, quiz: Quiz) => {
      const s3ImageUrl = await handleUploadDataUrlImage(values.imageUrl, 'quiz')
      const s3AnswerImageUrl = await handleUploadDataUrlImage(
        values.answerImageUrl,
        'quiz-answer'
      )
      const ogpImageUrl =
        quiz.content.question !== values.question ||
        isDataURL(values.imageUrl) ||
        quiz.snsInfo.ogpImageUrl === undefined ||
        quiz.snsInfo.ogpImageUrl === null
          ? await createOgp(values.question, values.imageUrl)
          : quiz.snsInfo.ogpImageUrl
      const s3OgpImageUrl = await handleUploadDataUrlImage(
        ogpImageUrl,
        'quiz-ogp'
      )
      const variables = {
        input: {
          id,
          answerImageUrl: s3AnswerImageUrl,
          correct: values.correct,
          design: values.design as 'SERIOUS' | 'SIMPLE' | 'ORIGINAL',
          description: values.description,
          imageUrl: s3ImageUrl,
          published: values.published,
          question: values.question,
          snsInfo: {
            destinationLink: values.destinationLink,
            destinationLinkText: values.destinationLinkText,
            ogpImageUrl: s3OgpImageUrl,
            shareText: values.snsShareText,
          },
          wrongs: values.wrongs,
        },
      }
      await updateQuiz({
        variables: variables,
        refetchQueries: [QUIZ, QUIZZES],
      })
    },
    [handleUploadDataUrlImage, updateQuiz, id]
  )

  const handleUpdateQuizPub = useCallback(
    async (value: boolean) => {
      const variables = {
        input: {
          id,
          published: value,
        },
      }
      await updateQuizPub({
        variables: variables,
      })
    },
    [updateQuizPub, id]
  )

  return { handleUpdateQuiz, handleUpdateQuizPub }
}
