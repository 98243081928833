import { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import { GroupFormValues } from 'services/validators/groupSchema'

import {
  CreateGroupData,
  CreateGroupVars,
  CREATE_GROUP,
} from 'graphql/apollo/mutation/group/createGroup'
import { USER } from 'graphql/apollo/query/user'

export const useCreateGroup = () => {
  const [createGroupData] = useMutation<CreateGroupData, CreateGroupVars>(
    CREATE_GROUP
  )
  const handleCreateGroup = useCallback(
    async (values: GroupFormValues) => {
      const variables = {
        input: {
          name: values.name,
        },
      }
      return await createGroupData({
        variables: variables,
        refetchQueries: [USER],
      })
    },
    [createGroupData]
  )
  return { handleCreateGroup }
}
