import React, { useMemo } from 'react'
import { RouteComponentProps } from '@reach/router'
import { VStack, Flex, Button, Spacer } from '@chakra-ui/react'
import { useNavigate } from '@reach/router'
import { useQuery } from '@apollo/client'

import { Error } from 'components/Common/Error'
import { Layout } from 'components/Common/Layout'
import { Loading } from 'components/Common/Loading'
import { NoData } from 'components/Common/NoData'
import { Unauthorized } from 'components/Common/Unauthorized'
import { PlayQuiz } from 'components/PlayQuiz'
import { QUIZ, QuizData, QuizVars } from 'graphql/apollo/query/quiz'
import { useUserProfileContext } from 'state'
import { isOwned } from 'utils/isOwned'

type Props = RouteComponentProps<{
  quizId: string
  isEmbed: boolean
}>

export const PlayQuizPreview: React.FC<Props> = (props) => {
  const navigate = useNavigate()
  const { userProfile, currentGroup } = useUserProfileContext()
  const { loading, error, data } = useQuery<QuizData, QuizVars>(QUIZ, {
    variables: {
      id: props.quizId!,
    },
  })
  const quiz = data?.quiz
  const design = quiz?.design

  /**
   * TODO:
   * 埋め込みクイズのデザインが決まり次第削除予定
   */
  const designTheme = useMemo(() => {
    if (props.isEmbed) {
      return 'ORIGINAL'
    }
    if (design) {
      return design
    }
  }, [design])

  const isOwnedQuiz = useMemo(() => {
    return isOwned(quiz, currentGroup, userProfile)
  }, [quiz, currentGroup, userProfile])

  if (loading) return <Loading />
  if (error) return <Error />
  if (!data || !props.quizId) return <NoData />

  return (
    <Layout pageTitle="クイズ" design={designTheme}>
      <VStack p={3}>
        <Flex w="100%">
          <Button onClick={() => navigate(`/quiz/${props.quizId}/check`)}>
            プレビューをやめる
          </Button>
          <Spacer />
        </Flex>
        {isOwnedQuiz ? (
          <PlayQuiz
            quizId={props.quizId}
            isPreview={true}
            isEmbed={props.isEmbed}
          />
        ) : (
          <Unauthorized />
        )}
      </VStack>
    </Layout>
  )
}
