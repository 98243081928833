import { gql } from '@apollo/client'
import { AnswerEnqueteInput } from 'types/generated/graphql'

export type AnswerEnqueteData = null

export type AnswerEnqueteVars = {
  input: AnswerEnqueteInput
}

export const ANSWER_ENQUETE = gql`
  mutation AnswerEnquete($input: AnswerEnqueteInput!) {
    answerEnquete(input: $input)
  }
`
