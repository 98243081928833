import truncate from 'lodash/truncate'
import {
  Box,
  Center,
  VStack,
  HStack,
  Text,
  Heading,
  Button,
  Table,
  Tbody,
  Thead,
  Td,
  Tr,
  Th,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
} from 'react-share'
import { BsCircle } from 'react-icons/bs'
import { ImCross } from 'react-icons/im'
import { BiLinkExternal } from 'react-icons/bi'
import { Playlist, ListDesignType } from 'types/generated/graphql'

type Props = {
  playlist: Playlist
  quizzesCount: number
  score: number
  answers: string[]
  design?: ListDesignType
}

export const Result: React.FC<Props> = ({
  playlist,
  quizzesCount,
  score,
  answers,
  design,
}) => {
  const buttonNextColor = useColorModeValue('buttonNextLight', 'buttonNextDark')

  const { origin } = new URL(window.location.href)
  const shareUrl = `${origin}/quizplaylist/${playlist.id}/play`
  const sendOnLink = playlist.snsInfo.destinationLink || ''
  const sendOnLinkText =
    truncate(playlist.snsInfo.destinationLinkText || '', { length: 25 }) ||
    'リンク'

  const snsShareText = (() => {
    const resultMsg = `${quizzesCount}問中${score}問正解でした！`
    if (playlist.snsInfo.shareText) {
      return [playlist.snsInfo.shareText, resultMsg].join('\n')
    }
    return [playlist.name, resultMsg].join('\n')
  })()

  // 合格点設定によるテキスト表示の分岐
  const resultTextComponent = () => {
    // 合格点がクイズ数を上回った場合は満点を合格点とする
    let passingScore = playlist.passingScore || 0
    if (passingScore > quizzesCount) {
      passingScore = quizzesCount
    }

    // 得点が合格点を上回っているか否かを判定し、それぞれの場合に設定された表示文があればその文字列を表示、なければ何も表示しない
    const displayText =
      (score >= passingScore && playlist.passText) ||
      (score < passingScore && playlist.failText)

    if (displayText) {
      return (
        <Center borderWidth="1px" borderRadius="5" w="100%" p={5} marginY={10}>
          <Text color={`${design}.common.mainText`} fontSize="md">
            {displayText}
          </Text>
        </Center>
      )
    }
  }

  // 合格点設定によるテキスト表示の分岐
  const resultLinkComponent = () => {
    // 合格点がクイズ数を上回った場合は満点を合格点とする
    let passingScore = playlist.passingScore || 0
    if (passingScore > quizzesCount) {
      passingScore = quizzesCount
    }

    // 得点が合格点を上回っているか否かを判定し、それぞれの場合に設定された表示文があればその文字列を表示、なければ何も表示しない
    const displayLink =
      (score >= passingScore && playlist.passLink) ||
      (score < passingScore && playlist.failLink)

    const displayLinkText =
      (score >= passingScore && playlist.passLinkText) ||
      (score < passingScore && playlist.failLinkText)

    if (displayLink && displayLinkText) {
      return (
        <Button
          backgroundColor={buttonNextColor}
          mb={3}
          bottom="0"
          as="a"
          target="_blank"
          href={displayLink}
        >
          {displayLinkText}
        </Button>
      )
    }
  }

  return (
    <VStack w="100%" h="100%">
      <Heading
        as="h3"
        size="md"
        pb="0.5rem"
        m="0"
        color={`${design}.common.mainText`}
      >
        クイズ結果
      </Heading>
      <Heading as="h3" size="md" color={`${design}.result.result.correctLabel`}>
        {score}/{quizzesCount}正解！
      </Heading>

      <HStack spacing="30px" w="100%">
        {resultTextComponent()}
      </HStack>
      {resultLinkComponent()}

      <Table size="sm" mb={10}>
        <Thead>
          <Tr>
            <Th color={`${design}.common.mainText`}>正誤</Th>
            <Th color={`${design}.common.mainText`}>問題文</Th>
            <Th isNumeric color={`${design}.common.mainText`}>
              正答
            </Th>
            <Th w="52px"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {playlist.quizzes.map((quiz, index) => {
            const destinationLink = quiz.snsInfo.destinationLink || ''
            return (
              <Tr key={'quizPlaylistResult-' + index}>
                <Td>
                  {answers[index] === quiz.content.correct ? (
                    <Heading
                      as="h3"
                      size="md"
                      mb="1rem"
                      m="0"
                      color={`${design}.result.result.correctLabel`}
                    >
                      <BsCircle />
                    </Heading>
                  ) : (
                    <Heading
                      as="h3"
                      size="md"
                      mb="1rem"
                      m="0"
                      color={`${design}.result.result.incorrectLabel`}
                    >
                      <ImCross />
                    </Heading>
                  )}
                </Td>
                <Td>
                  <Text
                    fontSize={{ base: '10px', md: 'xs' }}
                    color={`${design}.common.mainText`}
                  >
                    {quiz.content.question}
                  </Text>
                </Td>
                <Td isNumeric>
                  <Text
                    fontSize="xs"
                    color={`${design}.result.result.correctLabel`}
                  >
                    {quiz.content.correct}
                  </Text>
                  {answers[index] === quiz.content.correct ? (
                    <Box />
                  ) : (
                    <Text
                      color={`${design}.result.result.incorrectLabel`}
                      fontSize="10px"
                    >
                      {answers[index]}
                    </Text>
                  )}
                </Td>
                <Td>
                  {destinationLink && (
                    <BiLinkExternal
                      size={20}
                      cursor="pointer"
                      onClick={() => window.open(destinationLink, '_blank')}
                    />
                  )}
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      <HStack>
        <TwitterShareButton
          url={shareUrl}
          title={snsShareText}
          hashtags={['qukker']}
        >
          <TwitterIcon size={32} borderRadius={10} />
        </TwitterShareButton>
        <FacebookShareButton
          url={shareUrl}
          quote={snsShareText}
          hashtag={'qukker'}
        >
          <FacebookIcon size={32} borderRadius={10} />
        </FacebookShareButton>
      </HStack>

      {sendOnLink && (
        <Button
          backgroundColor={buttonNextColor}
          mb={3}
          bottom="0"
          as="a"
          target="_blank"
          href={sendOnLink}
        >
          {sendOnLinkText}
        </Button>
      )}
    </VStack>
  )
}
