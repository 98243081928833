import React from 'react'
import { useNavigate } from '@reach/router'
import dayjs from 'dayjs'
import {
  Box,
  Center,
  Stack,
  HStack,
  Image,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { CalendarIcon, RepeatClockIcon } from '@chakra-ui/icons'
import type { Enquete } from 'types/generated/graphql'
import { EnqueteCardRoot } from './EnqueteCardRoot'

export interface EnqueteCardProps {
  enquete: Enquete
  isEditable: boolean
}

export const EnqueteCard: React.FC<EnqueteCardProps> = ({
  enquete,
  isEditable,
}) => {
  const navigate = useNavigate()
  const publishedColor = useColorModeValue('primaryLight', 'secondaryLight')
  const unpublishedColor = useColorModeValue('primaryDark', 'secondaryDark')

  return (
    <Box
      overflow="hidden"
      bg="white"
      _hover={{ boxShadow: '2xl', bg: 'rgba(0,0,0,0.2)' }}
      onClick={() =>
        navigate(
          isEditable
            ? `/enquete/${enquete.id}/check`
            : `/enquete/${enquete.id}/play`
        )
      }
    >
      {enquete.published ? (
        <Center bg={publishedColor}>
          <Text fontWeight="bold">公開中</Text>
        </Center>
      ) : (
        <Center bg={unpublishedColor}>
          <Text fontWeight="bold">非公開</Text>
        </Center>
      )}
      {enquete.imageUrl ? (
        <Image
          w="100%"
          h="150px"
          objectFit="cover"
          src={enquete.imageUrl}
          fallbackSrc={'images/quiz_bg--card.png'}
          alt="アンケート画像"
        />
      ) : (
        <Center w="100%" h="150px">
          <Text>no image</Text>
        </Center>
      )}
      <Stack p="2">
        <HStack>
          <Text fontSize="sm" mr={2}>
            <CalendarIcon boxSize="16px" mr={1} mb={1} />
            {dayjs(enquete.createdAt).format('YYYY-MM-DD')}
          </Text>
          <Text fontSize="sm" mr={2}>
            <RepeatClockIcon boxSize="16px" mr={1} mb={1} />
            {dayjs(enquete.updatedAt).format('YYYY-MM-DD')}
          </Text>
        </HStack>
        <EnqueteCardRoot enquete={enquete} />
      </Stack>
    </Box>
  )
}
