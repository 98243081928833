import React from 'react'
import { useQuery } from '@apollo/client'
import { useNavigate } from '@reach/router'
import {
  Center,
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Button,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { EnqueteForm } from 'components/EnqueteForm'
import { AlertModal } from 'components/Modal/AlertModal'
import { Loading } from 'components/Common/Loading'
import { NoData } from 'components/Common/NoData'
import { Error } from 'components/Common/Error'

import { useUpdateEnquete } from 'hooks/useUpdateEnquete'

import { Enquete } from 'types/generated/graphql'
import {
  ENQUETE_STATS,
  EnqueteStatsData,
  EnqueteStatsVars,
} from 'graphql/apollo/query/enqueteStats'

export interface EnqueteCardProps {
  enquete: Enquete
}

export const EditRoot: React.FC<EnqueteCardProps> = ({ enquete }) => {
  const navigate = useNavigate()
  const completeDisclosure = useDisclosure()
  const alertDisclosure = useDisclosure()
  const { handleUpdateEnquete } = useUpdateEnquete({
    id: enquete.id,
  })
  const {
    loading: enqueteStatsLoading,
    error: enqueteStatsError,
    data: enqueteStatsData,
  } = useQuery<EnqueteStatsData, EnqueteStatsVars>(ENQUETE_STATS, {
    variables: {
      enqueteId: enquete.id,
    },
    fetchPolicy: 'network-only',
  })
  if (enqueteStatsLoading) return <Loading />
  if (enqueteStatsError) return <Error />
  if (!enqueteStatsData) return <NoData />

  const completeModal = () => {
    return (
      <Modal
        isOpen={completeDisclosure.isOpen}
        onClose={completeDisclosure.onClose}
        isCentered={true}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>　</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Text fontSize="2xl" m={10}>
                アンケートを更新しました
              </Text>
            </Center>
          </ModalBody>
          <ModalFooter>
            <Button
              backgroundColor="#F3B0AE"
              color="#4D3B3E"
              mr={3}
              onClick={() => navigate(`/enquete/${enquete.id}/check`)}
            >
              戻る
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }
  const renderComponent = () => {
    return (
      <>
        <EnqueteForm
          initialValues={enquete}
          enqueteStats={enqueteStatsData.enqueteStats}
          submitButtonLabel="更新"
          onSubmit={async (values) => {
            try {
              await handleUpdateEnquete(values)
              completeDisclosure.onOpen()
            } catch (error) {
              alertDisclosure.onOpen()
            }
          }}
        />
        {completeModal()}
        <AlertModal
          modalBody={'アンケートの更新に失敗しました。'}
          isOpen={alertDisclosure.isOpen}
          onClose={alertDisclosure.onClose}
        />
      </>
    )
  }
  return <>{renderComponent()}</>
}
