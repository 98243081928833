import React from 'react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Button,
  Stack,
} from '@chakra-ui/react'

interface Props {
  modalBody: string
  isOpen: boolean
  onClose: () => void
  buttonText?: string
  handleButtonClick?: () => void
}

export const InfoModal: React.FC<Props> = ({
  modalBody,
  isOpen,
  onClose,
  buttonText = '確認',
  handleButtonClick = () => {},
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalBody textAlign="center" pt="60px" pb="40px" fontSize="20">
          {modalBody}
        </ModalBody>
        <ModalCloseButton right={3} left="auto" size="lg" />
        <ModalFooter m="auto" pt="0" pb="60px">
          <Stack direction="row" spacing={4}>
            <Button
              bg="buttonPrimary"
              width="140px"
              height="40px"
              borderRadius="40px"
              color="#fff"
              onClick={() => {
                onClose()
                handleButtonClick()
              }}
              _hover={{
                bg: 'buttonBasicHover',
              }}
            >
              {buttonText}
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
