import { Text, Box } from '@chakra-ui/react'

export const InfoError: React.FC = () => {
  return (
    <Box px="16px" py="24px" bgColor="#fff" borderRadius="24px" w="100%">
      <Text fontSize="md" mb="10px">
        エラーが発生しました
      </Text>
      <Text lineHeight="1.5">
        ブラウザをリロードするか、しばらく経ってからやり直してください。
      </Text>
    </Box>
  )
}
