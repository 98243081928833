import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { PlayQuizPlaylistPreview } from './PlayQuizPlaylistPreview'

type urlProps = RouteComponentProps<{
  quizPlaylistId: string
}>

export const EmbedPlayQuizPlaylistPreview: React.FC<urlProps> = ({
  quizPlaylistId = '',
}) => {
  return (
    <PlayQuizPlaylistPreview quizPlaylistId={quizPlaylistId} isEmbed={true} />
  )
}
