import React, { useState } from 'react'
import {
  Box,
  Text,
  Button,
  Flex,
  Spacer,
  Tooltip,
  IconButton,
  useColorModeValue,
} from '@chakra-ui/react'
import { ListDesignType } from 'types/generated/graphql'
import { InfoIcon } from '@chakra-ui/icons'
import { useNavigate } from '@reach/router'
import { DesignTemplate } from 'components/QuizForm/DesignTemplate'
import { FormInput } from 'components/Common/InputPageComponent/FormInput'
import { FormTextarea } from 'components/Common/InputPageComponent/FormTextarea'
import { FormUploadImage } from 'components/Common/InputPageComponent/FormUploadImage'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Scrollbar } from 'swiper'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

type Props = {
  onNext: () => void
  requiredValue: string
  isValid: boolean
  listDesign: ListDesignType
}

export const FirstInputPage: React.FC<Props> = ({
  onNext,
  requiredValue,
  isValid,
  listDesign,
}) => {
  const navigate = useNavigate()
  const buttonNextColor = useColorModeValue('buttonNextLight', 'buttonNextDark')
  const buttonBackColor = useColorModeValue('buttonBackLight', 'buttonBackDark')
  const [design, setDesign] = useState<ListDesignType>(listDesign)

  return (
    <Flex direction="column">
      <Flex w="100%" pb="5">
        <Button
          variant="outline"
          aria-label="Delete Quiz"
          backgroundColor={buttonBackColor}
          onClick={() => navigate('/quizplaylist')}
        >
          リスト一覧へ戻る
        </Button>
        <Spacer />
        <Button
          variant="outline"
          aria-label="Delete Quiz"
          backgroundColor={buttonNextColor}
          onClick={onNext}
          // isValidは初回ロード時isRequired未入力の時Trueでボタンのdisabledができない。valuesのnameを検知することで、初回ロード時にdisabledできるようにしている。
          disabled={!isValid || !requiredValue}
        >
          次へ
        </Button>
      </Flex>
      <FormUploadImage name="imageUrl" />
      <FormInput name="name" label="リストの名前" isRequired={true} />
      <FormTextarea name="description" label="リストの説明" />
      <Box mb="1rem">
        <Flex mb="0.5rem" alignItems="center">
          <Text fontSize="xs" fontWeight="500">
            デザインテンプレート選択
          </Text>
          <Tooltip
            hasArrow
            bg="red.600"
            p={1}
            whiteSpace={'pre-line'}
            label={
              '選択するとクイズのデザインが\nデザインテンプレートで統一されます'
            }
          >
            <IconButton
              colorScheme="white"
              color={'red.500'}
              aria-label="デザインテンプレート"
              size={'20px'}
              ml={1}
              isRound
              icon={<InfoIcon />}
            />
          </Tooltip>
        </Flex>
        <Swiper
          modules={[Scrollbar]}
          spaceBetween={10}
          slidesPerView={1}
          slideToClickedSlide={true}
          mousewheel={true}
          scrollbar={{
            draggable: true,
            hide: false,
          }}
          breakpoints={{
            600: { slidesPerView: 3.5 },
            400: { slidesPerView: 2.5 },
          }}
        >
          <SwiperSlide>
            <DesignTemplate
              design={design}
              displayName="未設定"
              isSelected={design === 'NONE'}
              sampleImgUrl="/images/listNoDesign.png"
              sampleImgAlt="deleteDesign"
              onSelect={() => setDesign('NONE')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <DesignTemplate
              design={design}
              displayName="シンプル"
              isSelected={design === 'SIMPLE'}
              sampleImgUrl="/images/simple.png"
              sampleImgAlt="simpleImage"
              onSelect={() => setDesign('SIMPLE')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <DesignTemplate
              design={design}
              displayName="シリアス"
              isSelected={design === 'SERIOUS'}
              sampleImgUrl="/images/serious.png"
              sampleImgAlt="seriousImage"
              onSelect={() => setDesign('SERIOUS')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <DesignTemplate
              design={design}
              displayName="オリジナル"
              isSelected={design === 'ORIGINAL'}
              sampleImgUrl="/images/default.png"
              sampleImgAlt="defaultImage"
              onSelect={() => setDesign('ORIGINAL')}
            />
          </SwiperSlide>
        </Swiper>
      </Box>
      <FormInput name="snsInfo.shareText" label="SNS投稿文" />
      <FormInput name="snsInfo.destinationLink" label="遷移先リンク" />
      <FormInput
        name="snsInfo.destinationLinkText"
        label="遷移先リンクの説明"
      />
    </Flex>
  )
}
