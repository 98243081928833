import {
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Box,
} from '@chakra-ui/react'
import { Field, FieldProps } from 'formik'

export interface FormInputProps {
  name: string
  label: string
  annotation?: string
  placeholder?: string
  isRequired?: boolean
}

export const FormInput: React.FC<FormInputProps> = ({
  name,
  label,
  annotation,
  placeholder = label,
  isRequired = false,
}) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        return (
          <FormControl
            // name={name}
            isInvalid={!!(form.errors[name] && form.touched[name])}
            isRequired={isRequired}
            mb={3}
          >
            {annotation ? (
              <Box>
                <FormLabel fontSize="xs" htmlFor={name} mb={0}>
                  {label}
                </FormLabel>
                <Text fontSize="1px" color="blackAlpha.500">
                  {annotation}
                </Text>
              </Box>
            ) : (
              <FormLabel fontSize="xs" htmlFor={name}>
                {label}
              </FormLabel>
            )}
            <Input {...field} placeholder={placeholder} />
            <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
          </FormControl>
        )
      }}
    </Field>
  )
}
