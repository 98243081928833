import React, { useMemo } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Layout } from 'v2components/Common/Layout'
import { useQuery } from '@apollo/client'
import { EditRoot } from 'v2components/EditEnquete/EditRoot'
import { Error } from 'v2components/Common/Error'
import { Loading } from 'v2components/Common/Loading'
import { NoData } from 'components/Common/NoData'
import { Unauthorized } from 'components/Common/Unauthorized'
import { ENQUETE, EnqueteData, EnqueteVars } from 'graphql/apollo/query/enquete'
import { useUserProfileContext } from 'state'
import { isOwned } from 'utils/isOwned'

type Props = RouteComponentProps<{
  enqueteId: string
}>

export const EditEnquete: React.FC<Props> = (props) => {
  const enqueteId = props.enqueteId!
  const { userProfile, currentGroup } = useUserProfileContext()
  const { loading, error, data } = useQuery<EnqueteData, EnqueteVars>(ENQUETE, {
    variables: {
      id: enqueteId,
    },
  })

  const enquete = data?.enquete
  const isOwnedEnquete = useMemo(() => {
    return isOwned(enquete, currentGroup, userProfile)
  }, [enquete, currentGroup, userProfile])

  return (
    <Layout pageTitle="アンケート編集">
      {(() => {
        if (loading) return <Loading />
        if (error) return <Error />
        if (!data || !enquete || !props.enqueteId) return <NoData />
        if (!isOwnedEnquete) return <Unauthorized />
        if (isOwnedEnquete && enquete) return <EditRoot enquete={enquete} />
      })()}
    </Layout>
  )
}
