import React from 'react'
import { Button, useDisclosure } from '@chakra-ui/react'
import { useUserProfileContext } from 'state'

import { GroupModal } from './GroupModal'

interface Props {}

export const SelectGroupModal: React.FC<Props> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { userProfile, currentGroup, handleCurrentGroup } =
    useUserProfileContext()
  const groupName = currentGroup?.name
  return (
    <>
      <Button mr={3} onClick={onOpen}>
        G：{groupName || '未選択'}
      </Button>
      <GroupModal
        userProfile={userProfile}
        isOpen={isOpen}
        onClose={onClose}
        handleCurrentGroup={handleCurrentGroup}
      />
    </>
  )
}
