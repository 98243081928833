import { gql } from '@apollo/client'
import { Quiz, UpdateQuizInput } from 'types/generated/graphql'

export type UpdateQuizData = {
  updateQuiz: Quiz
}

export type UpdateQuizVars = {
  input: UpdateQuizInput
}

export const UPDATE_QUIZ = gql`
  mutation UpdateQuiz($input: UpdateQuizInput!) {
    updateQuiz(input: $input) {
      id
      title
      ownerUser {
        name
      }
      ownerGroup {
        name
      }
      published
      snsInfo {
        destinationLink
      }
      design
      content {
        question
        correct
        wrongs
        description
        imageUrl
        answerImageUrl
      }
    }
  }
`
