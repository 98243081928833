import React from 'react'
import { Icon } from '@chakra-ui/react'
import { MdOutlineAddPhotoAlternate } from 'react-icons/md'
import { MdCloudUpload, MdWarning } from 'react-icons/md'
import { IconType } from 'react-icons'

interface Props {
  imageUrl: string
  isDragAccept: boolean
  isDragReject: boolean
}

export const StatusIcon: React.FC<Props> = ({
  imageUrl,
  isDragAccept,
  isDragReject,
}) => {
  const getIconType = (): IconType => {
    if (isDragAccept) return MdCloudUpload
    if (isDragReject) return MdWarning

    return MdOutlineAddPhotoAlternate
  }

  return (
    <Icon
      as={getIconType()}
      w="72px"
      h="72px"
      color={imageUrl ? 'whiteAlpha.700' : '#2B5080'}
    />
  )
}
