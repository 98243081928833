export const awsExports = {
  aws_project_region: 'ap-northeast-1',
  aws_appsync_graphqlEndpoint:
    'https://ya4bch6sejeoxfv6zvpfdqz3vm.appsync-api.ap-northeast-1.amazonaws.com/graphql',
  aws_appsync_region: 'ap-northeast-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-46rz4nmebbfcnbxtjnqt6awhxi',
  aws_cognito_identity_pool_id:
    'ap-northeast-1:18776068-7939-47e6-9ee7-e9c678a748ef',
  aws_cognito_region: 'ap-northeast-1',
  aws_user_pools_id: 'ap-northeast-1_3Cga9Vs8J',
  aws_user_pools_web_client_id: '71amag3le38d6lt4rvvsv5fs62',
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_user_files_s3_bucket: 'qukker-image-storage114722-dev',
  aws_user_files_s3_bucket_region: 'ap-northeast-1',
  aws_cognito_login_mechanisms: ['PREFERRED_USERNAME'],
}

// export default awsmobile
