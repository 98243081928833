import { gql } from '@apollo/client'
import { Scalars, Stats } from 'types/generated/graphql'

export type QuizStatsData = {
  quizStats: Stats
}

export type QuizStatsVars = {
  quizId: Scalars['ID']
}

export const QUIZSTATS = gql`
  query QuizStats($quizId: ID!) {
    quizStats(quizId: $quizId) {
      quizId
      total
      stats {
        selection
        count
        percent
      }
    }
  }
`
