import { useState, useContext } from 'react'
import { Box, Input, FormControl, FormLabel, Text } from '@chakra-ui/react'
import { Field, FieldProps } from 'formik'
import { FormOptionContext } from '../../EnqueteForm/index'

export interface FormInputProps {
  name: string
  label: string
  placeholder: string
}

export const FormInputOptions: React.FC<FormInputProps> = ({
  name,
  label,
  placeholder = label,
}) => {
  const [errorFlag, setErrorFlag] = useState<boolean>(false)
  const { setInvalidFlg } = useContext(FormOptionContext)
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const inputValue = event.target.value
          setInvalidFlg(false)

          if (inputValue.length > 20) {
            setErrorFlag(true)
          } else {
            setErrorFlag(false)
          }
          if (inputValue.includes(',')) {
            form.setFieldValue(field.name, inputValue.replace(/,/g, '，'))
          }
        }
        return (
          <FormControl
            // name={name}
            isInvalid={errorFlag}
            mb={3}
            onChange={handleChange}
          >
            <Box display="flex">
              <FormLabel fontSize="xs" htmlFor={name} mr={1}>
                {label}
              </FormLabel>
              <Text color="red" fontSize="12px">
                *
              </Text>
            </Box>
            <Input {...field} placeholder={placeholder} />
          </FormControl>
        )
      }}
    </Field>
  )
}
