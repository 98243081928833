import { gql } from '@apollo/client'
import { Scalars, User } from 'types/generated/graphql'

export type UserData = {
  user: User
}

export type UserVars = {
  id: Scalars['ID']
}

export const USER = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      name
      email
      aliasId
      comment
      icon
      coverImage
      organizationName
      groups {
        id
        members {
          role
          user {
            id
            name
            aliasId
          }
        }
        name
      }
    }
  }
`
