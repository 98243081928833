import React from 'react'
import { FormControl, FormErrorMessage } from '@chakra-ui/react'
import { Field, FieldProps } from 'formik'
import { fileToDataUrl } from 'utils/fileToDataUrl'
import { ImageUpload, ImageType } from 'v2components/ImageUpload'

interface Props {
  name: string
  type?: ImageType
}

export const FormUploadImage: React.FC<Props> = ({ name, type = 'NORMAL' }) => {
  return (
    <Field name={name}>
      {({ form }: FieldProps) => {
        return (
          <FormControl h="100%">
            <ImageUpload
              imageUrl={form.values[name]}
              onDrop={async (acceptedFiles) => {
                if (acceptedFiles) {
                  if (acceptedFiles.length === 0) return
                  const imageDataUrl = await fileToDataUrl(acceptedFiles[0])
                  form.setFieldValue(name, imageDataUrl)
                }
              }}
              type={type}
            />
            <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
          </FormControl>
        )
      }}
    </Field>
  )
}
