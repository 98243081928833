import { gql } from '@apollo/client'
import { Scalars, Group } from 'types/generated/graphql'

export type GroupData = {
  group: Group
}

export type GroupVars = {
  id: Scalars['ID']
}

export const GROUP = gql`
  query Group($id: ID!) {
    group(id: $id) {
      id
      name
      description
      icon
      members {
        user {
          id
          name
          email
          comment
          aliasId
        }
        role
      }
    }
  }
`
