import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { useUploadFile } from 'hooks/useUploadFile'
import { createOgp } from 'utils/createOgp'
import {
  UpdatePlaylistDataPub,
  UpdatePlaylistVarsPub,
  UPDATE_PLAYLIST_PUB,
} from 'graphql/apollo/mutation/playlist/updatePlaylistPub'
import { PLAYLIST } from 'graphql/apollo/query/playlist'
import {
  UPDATE_PLAYLIST,
  UpdatePlaylistData,
  UpdatePlaylistVars,
} from 'graphql/apollo/mutation/playlist/updatePlaylist'
import { PlaylistFormValues } from 'services/validators/playlistFormSchema'

type HooksArgs = {
  id: string
}

export const useUpdateQuizlist = ({ id }: HooksArgs) => {
  const [updateQuizlistPub] = useMutation<
    UpdatePlaylistDataPub,
    UpdatePlaylistVarsPub
  >(UPDATE_PLAYLIST_PUB)

  const { handleUploadDataUrlImage } = useUploadFile()

  const [updatePlaylist] = useMutation<UpdatePlaylistData, UpdatePlaylistVars>(
    UPDATE_PLAYLIST
  )

  const handleUpdateQuizlist = useCallback(
    async (values: PlaylistFormValues) => {
      if (!id) {
        return
      }

      const s3ImageUrl = await handleUploadDataUrlImage(
        values.imageUrl,
        'quizPlaylist'
      )
      const ogpImageUrl = await createOgp(values.name, values.imageUrl)
      const s3OgpImageUrl = await handleUploadDataUrlImage(
        ogpImageUrl,
        'quizPlaylist-ogp'
      )

      await updatePlaylist({
        variables: {
          input: {
            id: id,
            name: values.name,
            published: values.published,
            quizIds: values.quizIds,
            snsInfo: {
              ...values.snsInfo,
              ogpImageUrl: s3OgpImageUrl,
            },
            passingScore: values.passingScore,
            passText: values.passText,
            failText: values.failText,
            passLink: values.passLink,
            failLink: values.failLink,
            passLinkText: values.passLinkText,
            failLinkText: values.failLinkText,
            imageUrl: s3ImageUrl,
            description: values.description,
            design: 'NONE',
          },
        },
        refetchQueries: [
          'Playlists',
          {
            query: PLAYLIST,
            variables: { id: id },
          },
        ],
        awaitRefetchQueries: true,
      })
    },
    [id, handleUploadDataUrlImage, updatePlaylist]
  )

  const handleUpdateQuizlistPub = useCallback(
    async (value: boolean) => {
      const variables = {
        input: {
          id,
          published: value,
        },
      }
      await updateQuizlistPub({
        variables: variables,
      })
    },
    [updateQuizlistPub, id]
  )

  return { handleUpdateQuizlist, handleUpdateQuizlistPub }
}
