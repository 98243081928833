import React from 'react'
import { css } from '@emotion/react'
import {
  VStack,
  Text,
  Stack,
  Button,
  Box,
  Image,
  Grid,
  GridItem,
  Heading,
} from '@chakra-ui/react'
import type { Quiz, Stats, QuizDesignType } from 'types/generated/graphql'

const ImageWrapper = css`
  padding-top: 100%;
  position: relative;
  height: 100%;
`
const ImageBox = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #f3b0ae;
`

const ImageWrapperChoice = css`
  padding-top: 40%;
  position: relative;
  height: 100%;
`
const ImageBoxChoice = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`

type Props = {
  quiz: Quiz & { answerCandidates: string[] }
  quizNo: number
  quizzesCount: number
  quizStats?: Stats
  design?: QuizDesignType
  onAnswer: (answer: string) => void
}

export const Question: React.FC<Props> = ({
  quiz,
  quizNo,
  quizzesCount,
  quizStats,
  onAnswer,
  design,
}) => {
  return (
    <VStack w="100%">
      <Heading fontSize="xl" mb="1rem" color={`${design}.common.mainText`}>
        問題{quizNo}/{quizzesCount}
      </Heading>
      {design === 'SIMPLE' || design === 'SERIOUS' ? (
        <Box w={{ base: '100%' }}>
          <Box css={ImageWrapperChoice}>
            <Image
              css={ImageBoxChoice}
              src={quiz.content.imageUrl || '/images/whiteImage.png'}
              alt="リスト画像"
            />
          </Box>
        </Box>
      ) : (
        <Box w={{ base: '100%', sm: '60%', lg: '50%' }} mb="0.5rem">
          <Box css={ImageWrapper}>
            <Image
              css={ImageBox}
              src={quiz.content.imageUrl || '/images/whiteImage.png'}
              alt="リスト画像"
            />
          </Box>
        </Box>
      )}
      <Text
        fontSize={{ base: 'md', sm: 'lg' }}
        mb="1rem"
        py="1rem"
        color={`${design}.common.mainText`}
      >
        Q：{quiz.content.question}
        {quizStats &&
          // statsには[正解、不正解1、不正解2…]の順番で入る
          `(正答率${quizStats.stats[0].percent})`}
      </Text>
      <Stack w="100%" mb="2rem" spacing={4} direction="row" align="center">
        <Grid
          w="100%"
          templateRows={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }}
          gap={4}
        >
          {quiz.answerCandidates.map((candidate, index) => (
            <GridItem
              rowSpan={{ base: 1, md: 1 }}
              colSpan={{ base: 1, md: 2 }}
              key={'quizAnswerButton-' + index}
            >
              <Button
                size="lg"
                _hover={{
                  bg: `${design}.question.choiceQuestion.afterBackground`,
                }}
                border={design === 'SIMPLE' ? '1px' : ''}
                borderColor={`${design}.question.choiceQuestion.borderColor`}
                color={`${design}.question.choiceQuestion.label`}
                bg={`${design}.question.choiceQuestion.background`}
                w="100%"
                onClick={() => {
                  onAnswer(candidate)
                }}
              >
                {candidate}
              </Button>
            </GridItem>
          ))}
        </Grid>
      </Stack>
    </VStack>
  )
}
