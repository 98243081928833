import React from 'react'
import { useNavigate } from '@reach/router'
import { Box, Center, Image, Text, Flex } from '@chakra-ui/react'
import { MdDescription, MdViewList } from 'react-icons/md'
import { ContentsListMenu } from 'v2components/Common/ContentsListMenu'

export interface ItemData {
  id: string
  title: string
  imageUrl: string | undefined | null
  description: string | undefined | null
}

export interface ContentsItemProps {
  itemId: string
  itemData: ItemData
  contentType: 'enquete' | 'quiz' | 'quizlist'
  isEditable: boolean
  activeItemId?: string
  onClick: (itemId: string) => void
  handleOpenPreviewModal: (itemId: string) => void
  handleCopyUrl: (itemId: string) => void
  handleCopyEmbedCode: (itemId: string) => void
  handleOpenDeleteModal: (itemId: string) => void
}

export const ContentsListCard: React.FC<ContentsItemProps> = ({
  itemId,
  itemData,
  contentType,
  activeItemId,
  onClick,
  handleOpenPreviewModal,
  handleCopyUrl,
  handleCopyEmbedCode,
  handleOpenDeleteModal,
}) => {
  const navigate = useNavigate()
  const handleClickListItem = (itemId: string) => {
    onClick(itemId)
  }

  const handleNavigateEditPage = (itemId: string) => {
    navigate(`/v2/edit/${contentType}/${itemId}`)
  }

  if (!itemData || itemData.id === '') return <></>

  return (
    <Box
      bgColor={activeItemId === itemId ? '#0086D2' : '#F2F2F2'}
      color={activeItemId === itemId ? '#FFF' : ''}
      borderRadius="10px"
      boxSizing="border-box"
      pt="10px"
      pb="16px"
      px="10px"
      w="262px"
      position="relative"
      lineHeight="1"
      cursor="pointer"
      transition=".15s"
      ml="0px"
      marginInlineStart="0px"
      _hover={{
        bgColor: activeItemId === itemId ? '#0086D2' : '#CCEAFC',
      }}
      onClick={() => handleClickListItem(itemId)}
    >
      <Box className="">
        {itemData.imageUrl ? (
          <Image
            src={itemData.imageUrl}
            objectFit="cover"
            bgColor="#828282"
            borderRadius="10px"
            mb="8px"
            w="242px"
            h="144px"
          />
        ) : (
          <Center w="242px" h="144px" mb="8px" bgColor="#828282">
            <Text>no image</Text>
          </Center>
        )}
      </Box>
      <Box
        className="item-detail"
        flexDirection="column"
        alignItems="stretch"
        pr="40px"
      >
        <Flex
          className="title"
          fontSize="14px"
          fontWeight="500"
          lineHeight="1.7"
          align="center"
        >
          <Box as="span" flexShrink={0}>
            {contentType === 'quiz' && ( // クイズのアイコンは後で
              <MdDescription
                size="20px"
                color={activeItemId === itemId ? '#fff' : '#0086D2'}
              />
            )}
            {contentType === 'enquete' && (
              <MdDescription
                size="20px"
                color={activeItemId === itemId ? '#fff' : '#0086D2'}
              />
            )}
            {contentType === 'quizlist' && (
              <MdViewList
                size="20px"
                color={activeItemId === itemId ? '#fff' : '#0086D2'}
              />
            )}
          </Box>
          <Text
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            ml="8px"
          >
            {itemData.title}
          </Text>
        </Flex>
        <Text
          className="excerpt"
          fontSize="12px"
          lineHeight="1.6666666667"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          pl="28px"
        >
          {itemData.description}
        </Text>
      </Box>
      <Box mt="162px" mr="10px" position="absolute" top="0" right="0" w="32px">
        <ContentsListMenu
          itemId={itemData.id}
          handleNavigateEditPage={handleNavigateEditPage}
          handleOpenPreviewModal={handleOpenPreviewModal}
          handleCopyUrl={handleCopyUrl}
          handleCopyEmbedCode={handleCopyEmbedCode}
          handleOpenDeleteModal={handleOpenDeleteModal}
        />
      </Box>
    </Box>
  )
}
