import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/client'
import { CookiesProvider } from 'react-cookie'
import Amplify from 'aws-amplify'
import { awsExports } from './aws-exports'
import { client } from 'graphql/apollo/client'
import { App } from './App'
import 'dayjs/locale/ja'

Amplify.configure({
  ...awsExports,
  aws_appsync_graphqlEndpoint:
    process.env.REACT_APP_APPSYNC_GRAPHQL_API_ENDPOINT,
  aws_appsync_apiKey: process.env.REACT_APP_APPSYNC_API_KEY,
})

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
