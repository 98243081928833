import { gql } from '@apollo/client'
import { Playlist, UpdatePlaylistInput } from 'types/generated/graphql'

export type UpdatePlaylistData = {
  updatePlaylist: Playlist
}

export type UpdatePlaylistVars = {
  input: UpdatePlaylistInput
}

export const UPDATE_PLAYLIST = gql`
  mutation UpdatePlaylist($input: UpdatePlaylistInput!) {
    updatePlaylist(input: $input) {
      id
      name
      quizzes {
        id
        title
        published
      }
      design
    }
  }
`
