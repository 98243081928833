import { gql } from '@apollo/client'
import { Group, UpdateGroupInput } from 'types/generated/graphql'

export type UpdateGroupData = {
  updateGroup: Group
}

export type UpdateGroupVars = {
  input: UpdateGroupInput
}

export const UPDATE_GROUP = gql`
  mutation UpdateGroup($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      id
      name
      description
      icon
      members {
        user {
          id
          name
          email
        }
        role
      }
    }
  }
`
