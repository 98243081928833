import React, { useState, useEffect } from 'react'
import {
  Box,
  Text,
  Flex,
  IconButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  Switch,
  useDisclosure,
} from '@chakra-ui/react'
import { MdDescription, MdOutlineCalendarMonth } from 'react-icons/md'
import { CloseIcon } from '@chakra-ui/icons'
import { useQuery } from '@apollo/client'
import { ENQUETE, EnqueteData, EnqueteVars } from 'graphql/apollo/query/enquete'
import {
  ENQUETE_STATS,
  EnqueteStatsData,
  EnqueteStatsVars,
} from 'graphql/apollo/query/enqueteStats'
import { InfoLoading } from 'v2components/InfoArea/InfoLoading'
import { InfoError } from 'v2components/InfoArea/InfoError'
import { SummaryGraphBar } from 'v2components/Common/SummaryGraphBar'
import { SummaryGraphPie } from 'v2components/Common/SummaryGraphPie'
import { SummaryLabels } from 'v2components/Common/SummaryLabels'
import { ToolButtons } from 'v2components/Common/ToolButtons'
import { PreviewModal } from 'v2components/Modal/PreviewModal'
import { DeleteModal } from 'v2components/Modal/DeleteModal'
import { EmbedAnswerEnquete } from 'pages/EmbedAnswerEnquete'
import { useDeleteEnquete } from 'v2hooks/useDeleteEnquete'
import { AlertModal } from 'v2components/Modal/AlertModal'
import { useUpdateEnquete } from 'hooks/useUpdateEnquete'
import dayjs from 'dayjs'

interface Props {
  activeItemId: string
  closeInfoFlag: boolean
  handleClickToggleInfo: (flg: boolean) => void
}

interface ItemDetailData {
  title: string
  description: string
  published: boolean
  total: number
  deadline: string
  startDate: string
  stateData: {
    labels: string[]
    labelsNumber: number[]
    count: number[]
    percent: string[]
  }
}

export const InfoEnquete: React.FC<Props> = ({
  activeItemId,
  closeInfoFlag,
  handleClickToggleInfo,
}) => {
  const [isCheckedPub, setIsCheckedPub] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const [alertMessage, setAlertMessage] = useState('')
  const previewDisclosure = useDisclosure()
  const deleteDisclosure = useDisclosure()
  const alertDisclosure = useDisclosure()

  const itemData: ItemDetailData = {
    title: 'アンケートを選択してください',
    description: 'アンケートをアクティブにすると詳細データを閲覧できます。',
    published: false,
    total: 0,
    deadline: '',
    startDate: '',
    stateData: {
      labels: [],
      labelsNumber: [],
      count: [],
      percent: [],
    },
  }

  const handleClosePreviewModal = () => {
    previewDisclosure.onClose()
  }

  const handleOpenPreviewModal = () => {
    previewDisclosure.onOpen()
  }

  const handleCloseInfo = () => {
    handleClickToggleInfo?.(true)
  }

  const deleteEnquete = useDeleteEnquete(activeItemId, deleteDisclosure.onClose)
  const handleDeleteItem = async () => {
    try {
      await deleteEnquete()
      handleClickToggleInfo?.(true)
    } catch (error) {
      setAlertMessage('削除に失敗しました。')
      deleteDisclosure.onClose()
      alertDisclosure.onOpen()
    }
  }

  const { handleUpdateEnquetePub } = useUpdateEnquete({
    id: activeItemId,
  })

  const handleOpenDeleteModal = () => {
    deleteDisclosure.onOpen()
  }

  const {
    loading: enqueteLoading,
    error: enqueteError,
    data: enqueteData,
  } = useQuery<EnqueteData, EnqueteVars>(ENQUETE, {
    variables: {
      id: activeItemId!,
    },
    context: {
      noAuthorizationHeader: false,
    },
  })
  const {
    loading: enqueteStatsLoading,
    data: enqueteStatsData,
    // refetch,
  } = useQuery<EnqueteStatsData, EnqueteStatsVars>(ENQUETE_STATS, {
    variables: {
      enqueteId: activeItemId!,
    },
  })

  // itemDatsに取得したデータを入れていく
  const detailData = enqueteData?.enquete
  const stateData = enqueteStatsData?.enqueteStats
  if (detailData && stateData) {
    itemData.title = detailData.title
    itemData.description = detailData.question
    itemData.published = detailData.published
    itemData.total = stateData.total
    itemData.startDate = detailData.startDate
      ? dayjs(detailData.startDate).format('YYYY/MM/DD HH:mm')
      : ''
    itemData.deadline = detailData.deadline
      ? dayjs(detailData.deadline).format('YYYY/MM/DD HH:mm')
      : ''
    const labels = []
    const labelsNumber = []
    const count = []
    const percent = []
    for (let i = 0; i < stateData.stats.length; i++) {
      labels.push(stateData.stats[i].selection)
      labelsNumber.push(i + 1)
      count.push(stateData.stats[i].count)
      percent.push(stateData.stats[i].percent)
    }

    itemData.stateData.labels = labels
    itemData.stateData.labelsNumber = labelsNumber
    itemData.stateData.count = count
    itemData.stateData.percent = percent
  }

  useEffect(() => {
    if (detailData) {
      setIsCheckedPub(detailData.published)
    }
  }, [detailData])

  return (
    <Box
      px="16px"
      py="24px"
      bgColor="#fff"
      borderRadius="24px"
      position="relative"
      w="320px"
      display={closeInfoFlag ? 'none' : 'block'}
      overflowY="scroll"
      overflowX="hidden"
      flex="0 0 320px"
    >
      <IconButton
        isRound={true}
        variant="solid"
        bg="none"
        aria-label="Done"
        w="40px"
        h="40px"
        position="absolute"
        top="16px"
        right="8px"
        icon={<CloseIcon w="16px" h="16px" color="#222" />}
        _hover={{
          bgColor: '#C4C7CC',
        }}
        onClick={handleCloseInfo}
      />
      {itemData.title && (
        <Box fontWeight="bold" lineHeight="1.7" display="flex" pr="40px">
          <MdDescription color="#0086D2" size="24px" />
          <Text display="inline-block" ml="8px">
            {itemData.title}
          </Text>
        </Box>
      )}
      {itemData.description && (
        <Text fontSize="12px" lineHeight="1.66" mt="8px" pl="32px" pr="40px">
          {itemData.description}
        </Text>
      )}

      {(() => {
        if (enqueteLoading || enqueteStatsLoading) return <InfoLoading />
        if (activeItemId && enqueteError) return <InfoError />
      })()}

      {activeItemId && detailData && stateData && (
        <>
          <Tabs variant="info-area" mt="24px" defaultIndex={tabIndex}>
            <TabList>
              <Tab
                onClick={() => {
                  setTabIndex(0)
                }}
              >
                詳細
              </Tab>
              <Tab
                onClick={() => {
                  setTabIndex(1)
                }}
              >
                回答サマリー
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <EmbedAnswerEnquete enqueteId={activeItemId} isPreview={true} />
                <Flex justifyContent="space-between" mt="12px">
                  {itemData.startDate || itemData.deadline ? (
                    <Flex alignItems="center">
                      <MdOutlineCalendarMonth size="20px" />
                      <Text as="span" fontSize="12px" ml="4px" lineHeight="1.2">
                        {itemData.startDate} ~ {itemData.deadline}
                      </Text>
                    </Flex>
                  ) : null}
                  <FormControl display="flex" alignItems="center" w="auto">
                    <Switch
                      id="publish-switch"
                      size="sm"
                      colorScheme="green"
                      isChecked={isCheckedPub}
                      onChange={async (e) => {
                        setIsCheckedPub(!isCheckedPub)
                        try {
                          await handleUpdateEnquetePub(!isCheckedPub)
                        } catch (error) {
                          setIsCheckedPub(isCheckedPub)
                          setAlertMessage('公開ステータス変更に失敗しました。')
                          alertDisclosure.onOpen()
                        }
                      }}
                    />
                    <FormLabel
                      htmlFor="publish-switch"
                      mb="0"
                      fontSize="14px"
                      ml="8px"
                      whiteSpace="nowrap"
                    >
                      {isCheckedPub ? '公開' : '非公開'}
                    </FormLabel>
                  </FormControl>
                </Flex>
              </TabPanel>
              <TabPanel>
                <>
                  <Text fontSize="14px" textAlign="center">
                    <Text as="span" fontWeight="700" color="#2B5080">
                      総投票数：
                    </Text>
                    {itemData.total}件
                  </Text>
                  <SummaryGraphBar datas={itemData.stateData} />
                  <SummaryLabels datas={itemData.stateData} />
                  <SummaryGraphPie datas={itemData.stateData} />
                </>
              </TabPanel>
            </TabPanels>
          </Tabs>
          <Box mt="20px">
            <ToolButtons
              itemId={activeItemId}
              contentType="enquete"
              buttonType="item-info"
              handleOpenPreviewModal={() => {
                handleOpenPreviewModal()
              }}
              handleOpenDeleteModal={handleOpenDeleteModal}
            />
          </Box>
          <PreviewModal
            pmIsOpen={previewDisclosure.isOpen}
            onClosePm={handleClosePreviewModal}
            itemId={activeItemId}
            contentType="enquete"
          />
          <DeleteModal
            dmIsOpen={deleteDisclosure.isOpen}
            dmOnClose={deleteDisclosure.onClose}
            handleDeleteItem={handleDeleteItem}
          />
          <AlertModal
            modalBody={alertMessage}
            isOpen={alertDisclosure.isOpen}
            onClose={alertDisclosure.onClose}
          />
        </>
      )}
    </Box>
  )
}
