import { gql } from '@apollo/client'
import { Enquete } from 'types/generated/graphql'

export type EnquetesData = {
  enquetes: Enquete[]
}

export type EnquetesVars = {
  groupId?: string
}

export const ENQUETES = gql`
  query Enquetes($groupId: ID) {
    enquetes(groupId: $groupId) {
      id
      title
      question
      optionsCsv
      imageUrl
      createdAt
      updatedAt
      published
      selectMax
      selectMin
      periodDisplayFlag
      numberOfVoteDisplayFlag
      showResult
      resultText
      startDate
      deadline
      displayUnit
      answers {
        enqueteId
        answer
        count
      }
      ownerUser {
        id
        name
        icon
      }
      updateUser {
        id
        name
        icon
      }
      ownerGroup {
        id
        name
      }
    }
  }
`
