import {
  IconButton,
  Text,
  HStack,
  Spacer,
  useColorModeValue,
} from '@chakra-ui/react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { SelectGroupModal } from 'components/Modal/SelectGroupModal'

export interface HeaderProps {
  onShowSidebar?: () => void
  showSidebarButton?: boolean
  pageTitle: string
}

export const Header: React.FC<HeaderProps> = ({
  showSidebarButton = true,
  onShowSidebar,
  pageTitle = '',
}: HeaderProps) => {
  const bg = useColorModeValue('primary', 'secondary')
  const textColor = useColorModeValue('textLight', 'textDark')
  const button2Color = useColorModeValue('button2Light', 'button2Dark')
  return (
    <HStack bg={bg} p={2} color="#1A202C" justifyContent="center">
      {showSidebarButton && (
        <>
          <IconButton
            _focus={{ boxShadow: 'none' }}
            icon={<GiHamburgerMenu size={20} />}
            aria-label="サイドバーボタン"
            backgroundColor={button2Color}
            onClick={onShowSidebar}
          />
          <Spacer />
        </>
      )}
      <Spacer />
      <Text color={textColor} fontWeight="bold">
        {pageTitle}
      </Text>
      <Spacer />
      <SelectGroupModal />
    </HStack>
  )
}
