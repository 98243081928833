import React from 'react'
import { RouteComponentProps } from '@reach/router'

import { LayoutOpenArea } from 'components/Common/LayoutOpenArea'
import { AnswerEnquete } from './AnswerEnquete'

type props = RouteComponentProps<{
  enqueteId: string
}>

export const AnswerEnqueteOpenArea: React.FC<props> = (props) => {
  const enqueteId = props.enqueteId!
  return (
    <LayoutOpenArea>
      <AnswerEnquete enqueteId={enqueteId} />
    </LayoutOpenArea>
  )
}
