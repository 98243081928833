import { gql } from '@apollo/client'
import { Enquete, UpdateEnqueteInput } from 'types/generated/graphql'

export type UpdateEnqueteData = {
  updateEnquete: Enquete
}

export type UpdateEnqueteVars = {
  input: UpdateEnqueteInput
}

export const UPDATE_ENQUETE = gql`
  mutation UpdateEnquete($input: UpdateEnqueteInput!) {
    updateEnquete(input: $input) {
      id
      title
      question
      periodDisplayFlag
      numberOfVoteDisplayFlag
      showResult
      resultText
      allowDuplicateAnswer
      startDate
      deadline
      optionsCsv
      imageUrl
      published
      ownerUser {
        name
      }
      ownerGroup {
        name
      }
    }
  }
`
