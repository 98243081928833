import React from 'react'
import { VStack, Text, Stack, Button, Box, Heading } from '@chakra-ui/react'
import type { Quiz, Stats } from 'types/generated/graphql'
import { LayoutEmbedQuizPlaylist } from 'components/Common/LayoutEmbedQuizPlaylist'

type Props = {
  quiz: Quiz & { answerCandidates: string[] }
  quizNo: number
  quizzesCount: number
  quizStats?: Stats
  onAnswer: (answer: string) => void
}

export const EmbedQuestion: React.FC<Props> = ({
  quiz,
  quizNo,
  quizzesCount,
  quizStats,
  onAnswer,
}) => {
  return (
    <LayoutEmbedQuizPlaylist
      imageUrl={quiz.content.imageUrl!}
      alt={quiz.content.question!}
    >
      <VStack w="100%" spacing={3}>
        <Heading
          fontSize="22px"
          mb="1rem"
          color="white"
          fontWeight="700"
          lineHeight="1.6em"
          m="0 0 1em"
          textAlign="center"
          textShadow="0 0 5px rgba(0, 0, 0, 0.5)"
        >
          問題{quizNo}/{quizzesCount}
        </Heading>

        <Heading
          fontSize="18px"
          display="flex"
          fontWeight="800"
          textShadow="0 0 5px rgba(0, 0, 0, 0.5)"
          lineHeight="1.6em"
          m="0 0 0.3em"
          minWidth="100%"
          _before={{
            content: '"Q"',
            display: 'inline-block',
            textShadow: 'none',
            w: '1.8em',
            minWidth: '1.8em',
            h: '1.8em',
            lineHeight: '1.5em',
            borderRadius: '6px',
            fontSize: '70%',
            fontWeight: '800',
            mr: '.3em',
            mt: '.25em',
            bg: '#ffaf00',
            color: '#fff',
            textAlign: 'center',
          }}
        >
          {quiz.content.question}
          {quizStats &&
            // statsには[正解、不正解1、不正解2…]の順番で入る
            `(正答率${quizStats.stats[0].percent})`}
        </Heading>
        <Stack w="100%" spacing={4}>
          {quiz.answerCandidates.map((candidate, index) => (
            <Box w="100%" key={'quizAnswerButton-' + index}>
              <Button
                color="#333"
                bg="#fff"
                border="2px #333 solid"
                cursor="pointer"
                p="4px .6em"
                appearance="none"
                borderRadius="6px"
                fontSize="16px"
                fontWeight="700"
                lineHeight="1.6em"
                w="100%"
                h="100%"
                minH="40px"
                mb="8px"
                boxSizing="border-box"
                textAlign="left"
                justifyContent="initial"
                whiteSpace="pre-line"
                onClick={() => {
                  onAnswer(candidate)
                }}
              >
                <Text
                  display="inline-block"
                  w="2.2em"
                  minW="2.2em"
                  h="2.2em"
                  lineHeight="2.2em"
                  borderRadius="50%"
                  fontSize="60%"
                  mr=".4em"
                  mt=".1em"
                  color="#fff"
                  bg="#333"
                  boxSizing="border-box"
                  textShadow="none"
                  textAlign="center"
                  verticalAlign="top"
                >
                  {index + 1}
                </Text>
                {candidate}
              </Button>
            </Box>
          ))}
        </Stack>
      </VStack>
    </LayoutEmbedQuizPlaylist>
  )
}
