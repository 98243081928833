import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { createOgp } from 'utils/createOgp'
import { useUploadFile } from 'hooks/useUploadFile'
import { useUserProfileContext } from 'state'
import {
  CREATE_PLAYLIST,
  CreatePlaylistData,
  CreatePlaylistVars,
} from 'graphql/apollo/mutation/playlist/createPlaylist'
import { PlaylistFormValues } from 'services/validators/playlistFormSchema'

export const useCreateQuizlist = () => {
  const { currentGroup } = useUserProfileContext()
  const { handleUploadDataUrlImage } = useUploadFile()

  const [createPlaylist] = useMutation<CreatePlaylistData, CreatePlaylistVars>(
    CREATE_PLAYLIST
  )

  const handleCreateQuizlist = useCallback(
    async (values: PlaylistFormValues) => {
      const s3ImageUrl = await handleUploadDataUrlImage(
        values.imageUrl,
        'quizPlaylist'
      )
      const ogpImageUrl = await createOgp(values.name, values.imageUrl)
      const s3OgpImageUrl = await handleUploadDataUrlImage(
        ogpImageUrl,
        'quizPlaylist-ogp'
      )

      await createPlaylist({
        variables: {
          input: {
            name: values.name,
            published: values.published,
            quizIds: values.quizIds,
            snsInfo: {
              ...values.snsInfo,
              ogpImageUrl: s3OgpImageUrl,
            },
            design: 'NONE',
            passingScore: values.passingScore,
            passText: values.passText,
            failText: values.failText,
            passLink: values.passLink,
            failLink: values.failLink,
            passLinkText: values.passLinkText,
            failLinkText: values.failLinkText,
            imageUrl: s3ImageUrl,
            description: values.description,
            ownerGroupId: currentGroup?.id,
          },
        },
        refetchQueries: ['Playlists'],
        awaitRefetchQueries: true,
      })
    },
    [currentGroup?.id, handleUploadDataUrlImage, createPlaylist]
  )

  return { handleCreateQuizlist }
}
