import {
  Box,
  Button,
  Flex,
  Grid,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { MdGroup } from 'react-icons/md'
import { useCreateGroup } from 'v2hooks/useCreateGroup'
import { GroupFormValues, groupSchema } from 'services/validators/groupSchema'
import { useUserProfileContext } from 'state'
import { FormInput } from 'v2components/Common/InputPageComponents/FormInput'
import { FormTextarea } from 'v2components/Common/InputPageComponents/FormTextarea'
import { InfoModal } from 'v2components/Modal/InfoModal'
import { AlertModal } from 'v2components/Modal/AlertModal'
import { GroupImageForm } from '../EditGroup/GroupImageForm'

type Props = {
  onCloseCreateModal: () => void
}

export const GroupForm: React.FC<Props> = ({ onCloseCreateModal }) => {
  const {
    isOpen: isOpenInfoModal,
    onOpen: onOpenInfoModal,
    onClose: onCloseInfoModal,
  } = useDisclosure()
  const {
    isOpen: isOpenAlertModal,
    onOpen: onOpenAlertModal,
    onClose: onCloseAlertModal,
  } = useDisclosure()
  const { handleCurrentGroup } = useUserProfileContext()
  const { handleCreateGroup } = useCreateGroup()
  const initialValues = groupSchema.getDefault()
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={groupSchema}
        onSubmit={async (values: GroupFormValues) => {
          try {
            const groupData = await handleCreateGroup(values)
            handleCurrentGroup(groupData.data?.createGroup)
            onOpenInfoModal()
          } catch (e) {
            console.log(e)
            onOpenAlertModal()
          }
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <Flex flexDir="column" p="24px 20px">
              <Flex flexWrap="wrap" alignItems="flex-start" mb="16px">
                <MdGroup size="32px" color="#2B507F" />
                <Flex
                  color="#2B5080"
                  fontSize="24px"
                  fontWeight="500"
                  letterSpacing="0.04em"
                  display="inline-flex"
                  ml="8px"
                  alignItems="center"
                >
                  グループ設定
                </Flex>
              </Flex>
              <Flex flexDirection="column" maxH="99999rem">
                <Box display="block">
                  <Flex display="flex" gridGap="24px">
                    <GroupImageForm />
                    <Box flexShrink={1} flexGrow={1} flexBasis="539px">
                      <Box>
                        <FormInput
                          name="name"
                          label="グループネーム"
                          isRequired={true}
                        />
                      </Box>
                      <Box mt="20px">
                        <FormTextarea
                          name="description"
                          label="グループの説明"
                        />
                      </Box>
                    </Box>
                  </Flex>
                </Box>
              </Flex>
              <Grid
                display={['none', '-ms-grid', 'grid']}
                justifyContent="center"
                gap="0 14px"
                gridTemplateColumns="repeat(auto-fit, 160px)"
                marginTop="40px"
              >
                <Button
                  color="#fff"
                  fontSize="14px"
                  fontWeight={500}
                  lineHeight={1.7142857143}
                  letterSpacing="0.08em"
                  textAlign="center"
                  backgroundColor="#0086D2"
                  borderRadius="20px"
                  cursor="pointer"
                  display={[
                    '-webkit-box',
                    '-webkit-flex',
                    '-ms-flexbox',
                    'flex',
                  ]}
                  justifyContent="center"
                  alignItems="center"
                  boxSizing="border-box"
                  padding="8px 16px"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  _hover={{
                    bg: 'buttonPrimaryHover',
                  }}
                >
                  {!isSubmitting ? '登録する' : <Spinner />}
                </Button>
              </Grid>
            </Flex>
          </Form>
        )}
      </Formik>
      <InfoModal
        isOpen={isOpenInfoModal}
        onClose={onCloseInfoModal}
        buttonText="閉じる"
        handleButtonClick={onCloseCreateModal}
        modalBody="グループ作成が完了しました"
      />
      <AlertModal
        isOpen={isOpenAlertModal}
        onClose={onCloseAlertModal}
        modalBody="グループ作成処理が失敗しました"
      />
    </>
  )
}
