import React, { useState } from 'react'
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Role, Group, Member } from 'types/generated/graphql'
import { UserData } from 'graphql/apollo/query/user'
import { useUpdateGroupMember } from 'hooks/useUpdateGroupMember'
import { getOwnerList } from 'utils/getGroupRole'
import { RoleEditedModal } from 'components/MemberDrawer/RoleEditedModal'
import { AlertModal } from 'components/Modal/AlertModal'

interface Props {
  userProfile: UserData
  currentGroup?: Group
  member: Member
  role: Role
}

export const OwnerMenu: React.FC<Props> = ({
  userProfile,
  currentGroup,
  member,
  role,
}) => {
  const alertDisclosure = useDisclosure()
  const roleCheckDisclosure = useDisclosure()
  const [modalBody, setModalBody] = useState<string>('')

  const { handleUpdateGroupMember } = useUpdateGroupMember()

  if (!currentGroup) {
    return (
      <Menu>
        <MenuButton as={Button} disabled>
          {member.role}
        </MenuButton>
      </Menu>
    )
  }

  return (
    <>
      <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
          {role}
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={() =>
              handleUpdateGroupMember(currentGroup, member.user, 'OWNER')
            }
          >
            Owner
          </MenuItem>
          <MenuItem
            onClick={async () => {
              if (getOwnerList(currentGroup).length > 1) {
                try {
                  if (member.user.id === userProfile.user.id) {
                    setModalBody(
                      'このグループの編集ができなくなりますが、よろしいですか？'
                    )
                    roleCheckDisclosure.onOpen()
                  } else {
                    await handleUpdateGroupMember(
                      currentGroup,
                      member.user,
                      'MEMBER'
                    )
                  }
                } catch (e) {
                  setModalBody('ロールの変更に失敗しました。')
                  alertDisclosure.onOpen()
                }
              } else {
                if (role !== 'MEMBER') {
                  setModalBody('Ownerユーザーは1人以上である必要があります。')
                  alertDisclosure.onOpen()
                }
              }
            }}
          >
            Member
          </MenuItem>
        </MenuList>
      </Menu>
      <AlertModal
        modalBody={modalBody}
        isOpen={alertDisclosure.isOpen}
        onClose={alertDisclosure.onClose}
      />
      <RoleEditedModal
        group={currentGroup}
        user={member.user}
        modalBody={modalBody}
        isOpen={roleCheckDisclosure.isOpen}
        onClose={roleCheckDisclosure.onClose}
      />
    </>
  )
}
