import React from 'react'
import {
  Box,
  Center,
  Button,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useColorModeValue,
} from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { useNavigate } from '@reach/router'
import { UserData } from 'graphql/apollo/query/user'
import { Group } from 'types/generated/graphql'

import { GroupBox } from 'components/Modal/SelectGroupModal/GroupBox'

interface Props {
  userProfile?: UserData
  isOpen: boolean
  onClose: () => void
  handleCurrentGroup: (group?: Group) => void
}

export const GroupModal: React.FC<Props> = ({
  userProfile,
  isOpen,
  onClose,
  handleCurrentGroup,
}) => {
  const navigate = useNavigate()
  const buttonNextColor = useColorModeValue('buttonNextLight', 'buttonNextDark')

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      size={'4xl'}
      scrollBehavior={'inside'}
    >
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent>
        <ModalHeader>グループ選択</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleGrid minChildWidth={200} spacing="30px" mb={4}>
            <Center
              maxW="sm"
              maxH="sm"
              height="11rem"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              _hover={{ bg: 'primaryLight' }}
              onClick={() => {
                handleCurrentGroup(undefined)
                onClose()
                navigate(`/mypage`)
              }}
            >
              <Center
                mt="1"
                fontWeight="semibold"
                as="h4"
                lineHeight="tight"
                noOfLines={1}
              >
                グループなし
              </Center>
            </Center>
            {userProfile &&
              userProfile.user!.groups &&
              userProfile.user!.groups.map((group: Group) => (
                <Box
                  key={group.id}
                  onClick={() => {
                    handleCurrentGroup(group)
                    navigate(`/group/${group.id}`)
                    onClose()
                  }}
                >
                  <GroupBox group={group} userProfile={userProfile} />
                </Box>
              ))}
            <Center
              maxW="sm"
              height="11rem"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              onClick={() => {
                onClose()
                navigate('/group/create')
              }}
            >
              <Button w="100%" h="100%" bg={buttonNextColor}>
                Add
                <AddIcon ml={1} w="2" h="2" />
              </Button>
            </Center>
          </SimpleGrid>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
