import { gql } from '@apollo/client'
import { Scalars } from 'types/generated/graphql'

export type DeleteQuizData = null

export type DeleteQuizVars = {
  id: Scalars['ID']
}

export const DELETE_QUIZ = gql`
  mutation DeleteQuiz($id: ID!) {
    deleteQuiz(id: $id)
  }
`
