import React, { useRef } from 'react'
import { Box, Image, VStack } from '@chakra-ui/react'

export interface LayoutEmbedQuizProps {
  imageUrl?: string
  alt: string
}

export const LayoutEmbedQuizPlaylist: React.FC<LayoutEmbedQuizProps> = ({
  children,
  imageUrl = '',
  alt,
}) => {
  const elm = useRef<HTMLInputElement>(null)
  const setEmbedWindowHeight = () => {
    if (!elm.current) {
      return
    }
    const { clientHeight } = elm.current
    window.parent.postMessage([clientHeight], '*')
  }
  return (
    <Box
      w="100%"
      h="100%"
      position="relative"
      borderRadius="16px"
      overflow="hidden"
      onLoad={setEmbedWindowHeight}
      ref={elm}
    >
      <Image
        position="absolute"
        w="100%"
        h="100%"
        objectFit="cover"
        src={imageUrl || '/images/whiteImage.png'}
        alt={alt}
        filter="brightness(65%)"
      />
      <VStack
        w="100%"
        h="100%"
        minHeight="480px"
        justify="center"
        position="relative"
        color="white"
        p="15px 30px"
        fontSize={{ base: 'md', sm: 'lg' }}
      >
        {children}
      </VStack>
    </Box>
  )
}
