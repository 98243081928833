import React, { useState, useCallback } from 'react'
import { Formik, Form } from 'formik'
import {
  playlistFormSchema,
  PlaylistFormValues,
} from 'services/validators/playlistFormSchema'
import { Container } from '@chakra-ui/react'
import type { Playlist, Quiz, ListDesignType } from 'types/generated/graphql'
import { FirstInputPage } from 'components/QuizPlaylistForm/FirstInputPage'
import { SecondInputPage } from 'components/QuizPlaylistForm/SecondInputPage'
import { ThirdInputPage } from 'components/QuizPlaylistForm/ThirdInputPage'

const ProgressStatus = {
  CREATE_LIST: 'CREATE_LIST',
  SELECT_QUIZ: 'SELECT_QUIZ',
  SETTING_LIST: 'SETTING_LIST',
} as const

type Props = {
  initialValues?: Playlist
  submitButtonLabel: string
  selectedQuizzes: Quiz[]
  unselectedQuizzes: Quiz[]
  design: ListDesignType
  setSelectedQuizIds: (quizIds: string[]) => void
  handleSubmit: (values: PlaylistFormValues) => Promise<void>
}

export const QuizPlaylistForm: React.FC<Props> = ({
  initialValues,
  submitButtonLabel,
  selectedQuizzes,
  unselectedQuizzes,
  design,
  setSelectedQuizIds,
  handleSubmit,
}) => {
  const [progressStatus, setProgressStatus] = useState<
    keyof typeof ProgressStatus
  >(ProgressStatus.CREATE_LIST)
  const handleNextSelectQuiz = useCallback(() => {
    setProgressStatus(ProgressStatus.SELECT_QUIZ)
  }, [])
  const handleBackCreateList = useCallback(() => {
    setProgressStatus(ProgressStatus.CREATE_LIST)
  }, [])
  const handleNextSettingList = useCallback(() => {
    setProgressStatus(ProgressStatus.SETTING_LIST)
  }, [])
  const handleBackSelectQuiz = useCallback(() => {
    setProgressStatus(ProgressStatus.SELECT_QUIZ)
  }, [])

  return (
    <Formik
      initialValues={{
        ...playlistFormSchema.cast(initialValues, {
          stripUnknown: true,
        }),
        quizIds: initialValues?.quizzes.map(({ id }) => id) || [],
      }}
      onSubmit={handleSubmit}
      validationSchema={playlistFormSchema}
    >
      {({ isValid, isSubmitting, values, setFieldValue }) => (
        <Container maxW="container.md">
          <Form>
            {progressStatus === ProgressStatus.CREATE_LIST && (
              // 1ページ目: プレイリスト情報の設定
              <FirstInputPage
                onNext={handleNextSelectQuiz}
                requiredValue={values.name}
                isValid={isValid}
                listDesign={design}
              />
            )}

            {progressStatus === ProgressStatus.SELECT_QUIZ && (
              // 2ページ目: プレイリストへのクイズ紐付け(ドラッグアンドドロップ)
              <SecondInputPage
                selectedQuizzes={selectedQuizzes}
                unselectedQuizzes={unselectedQuizzes}
                isValid={isValid}
                setSelectedQuizIds={setSelectedQuizIds}
                onBack={handleBackCreateList}
                onNext={handleNextSettingList}
              />
            )}

            {progressStatus === ProgressStatus.SETTING_LIST && (
              // 3ぺージ目: 合格点の設定
              <ThirdInputPage
                submitButtonLabel={submitButtonLabel}
                selectedQuizzes={selectedQuizzes}
                isSubmitting={isSubmitting}
                isValid={isValid}
                passingScore={values.passingScore || 0}
                onSlidePassingScore={(passingScore: number) =>
                  setFieldValue('passingScore', passingScore)
                }
                onBack={handleBackSelectQuiz}
              />
            )}
          </Form>
        </Container>
      )}
    </Formik>
  )
}
