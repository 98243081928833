import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { PlayQuizPlaylistOpenArea } from './PlayQuizPlaylistOpenArea'

type urlProps = RouteComponentProps<{
  quizPlaylistId: string
}>

export const EmbedPlayQuizPlaylistOpenArea: React.FC<urlProps> = ({
  quizPlaylistId = '',
}) => {
  return (
    <PlayQuizPlaylistOpenArea quizPlaylistId={quizPlaylistId} isEmbed={true} />
  )
}
