import { gql } from '@apollo/client'
import { EnqueteStats, Scalars } from 'types/generated/graphql'

export type EnqueteStatsData = {
  enqueteStats: EnqueteStats
}

export type EnqueteStatsVars = {
  enqueteId: Scalars['ID']
}

export const ENQUETE_STATS = gql`
  query EnqueteStats($enqueteId: ID!) {
    enqueteStats(enqueteId: $enqueteId) {
      enqueteId
      total
      stats {
        selection
        count
        percent
      }
    }
  }
`
