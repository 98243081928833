import { Spinner, Center, VStack, Text } from '@chakra-ui/react'

export const Loading: React.FC = () => {
  return (
    <Center w="100%" h="90vh">
      <VStack>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="v2Primary"
          size="xl"
        />
        <Text fontSize="3xl">Loading...</Text>
      </VStack>
    </Center>
  )
}
