// 定数定義ファイル

// グラフ背景色
export const CHART_BG_COLOR = [
  '#2686C7',
  '#226DB6',
  '#1D52B7',
  '#3B46AC',
  '#5643A2',
  '#5F3295',
  '#852E87',
  '#A82393',
  '#B51F70',
  '#B03151',
  '#B63638',
  '#CD5B2F',
  '#EF9C21',
  '#FBB734',
  '#EFD727',
  '#C8D212',
  '#A8C724',
  '#6BB641',
  '#539759',
  '#5BA594',
]
