import { mode, Styles } from '@chakra-ui/theme-tools'

const styles: Styles = {
  global: (props) => ({
    body: {
      fontFamily: 'body',
      fontSize: '14px',
      color: mode('gray.800', 'white')(props),
      bg: mode('gray.50', 'gray.700')(props),
      linkColor: mode('gray.400', 'gray.700')(props),
      transitionProperty: 'background-color',
      transitionDuration: 'normal',
      lineHeight: 'base',
      height: '100%',
    },
    html: {
      height: '100%',
    },
    '#root': {
      height: '100%',
    },
    '.App': {
      height: '100%',
    },
    '.App>div': {
      height: '100%',
    },
    '*::placeholder': {
      color: mode('gray.400', 'whiteAlpha.400')(props),
    },
    '*, *::before, &::after': {
      borderColor: mode('gray.200', 'whiteAlpha.300')(props),
      wordWrap: 'break-word',
    },
    '.sidebar-nav-menu': {
      color: '#fff',
      '.icon': {
        w: '24px',
        h: '24px',
        fill: 'v2AccentY',
      },
      _hover: {
        color: 'v2AccentY',
      },
      '&.active': {
        color: 'v2Primary',
        '.icon': {
          fill: 'v2Primary',
        },
        _hover: {
          color: 'v2Primary',
          '.icon': {
            fill: 'v2Primary',
          },
        },
      },
    },
  }),
}

export default styles
