import React from 'react'
import { Text, Button, Box } from '@chakra-ui/react'
import { Link as ReachLink } from '@reach/router'

interface Props {
  label: string
  icon: React.ReactElement
  path: string
}

export const ButtonItem: React.FC<Props> = ({ label, icon, path }) => {
  return (
    <Button
      display="flex"
      flexDirection="column"
      w="240px"
      h="240px"
      borderRadius="16px"
      as={ReachLink}
      to={path}
    >
      <Box mb="16px">{icon}</Box>
      <Text fontSize="16px" lineHeight="2" color="#2B5080" fontWeight="700">
        {label}
      </Text>
    </Button>
  )
}
