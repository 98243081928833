import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { PlayQuiz } from 'components/PlayQuiz'
import { LayoutOpenArea } from 'components/Common/LayoutOpenArea'

type Props = RouteComponentProps<{
  quizId: string
}>

export const EmbedPlayQuizOpenArea: React.FC<Props> = (props) => {
  return (
    <LayoutOpenArea>
      <PlayQuiz quizId={props.quizId!} isEmbed={true} />
    </LayoutOpenArea>
  )
}
