import { FormikValues } from 'formik'

import {
  Center,
  Stack,
  Heading,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from '@chakra-ui/react'
import { FormInput } from 'v2components/Common/InputPageComponents/FormInput'

interface Props {
  isValid: boolean
  isSubmitting: boolean
  values: FormikValues
}
export const CreateForm: React.FC<Props> = ({
  isValid,
  isSubmitting,
  values,
}) => {
  return (
    <Stack>
      <ModalBody>
        <Center>
          <Heading size="lg" py={5}>
            ユーザー登録
          </Heading>
        </Center>
        <FormInput name="name" label="ユーザーネーム" isRequired={true} />
        <FormInput name="aliasId" label="ID" isRequired={true} />
      </ModalBody>
      <ModalFooter>
        <Button
          textColor="white"
          marginTop="auto"
          w="100%"
          variant="primary-btn"
          type="submit"
          disabled={!values.name || !values.aliasId || !isValid || isSubmitting}
        >
          {!isSubmitting ? '登録' : <Spinner></Spinner>}
        </Button>
      </ModalFooter>
    </Stack>
  )
}
