import React, { useMemo } from 'react'
import { Field, FieldProps } from 'formik'
import {
  Stack,
  HStack,
  VStack,
  Text,
  Box,
  Button,
  Flex,
  FormControl,
  Checkbox,
  Spacer,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useColorModeValue,
} from '@chakra-ui/react'
import { IoReturnDownBackOutline } from 'react-icons/io5'
import { MdGraphicEq } from 'react-icons/md'
import type { Quiz } from 'types/generated/graphql'
import { FormTextarea } from 'components/Common/InputPageComponent/FormTextarea'
import { FormInput } from 'components/Common/InputPageComponent/FormInput'

type Props = {
  submitButtonLabel: string
  selectedQuizzes: Quiz[]
  isSubmitting: boolean
  isValid: boolean
  passingScore: number
  onBack: () => void
  onSlidePassingScore: (passingScore: number) => void
}

export const ThirdInputPage: React.FC<Props> = ({
  submitButtonLabel,
  selectedQuizzes,
  isSubmitting,
  isValid,
  passingScore,
  onBack,
  onSlidePassingScore,
}) => {
  const buttonBackColor = useColorModeValue('buttonBackLight', 'buttonBackDark')

  const publishedQuizCount = useMemo(
    () => selectedQuizzes.filter((quiz) => quiz?.published).length,
    [selectedQuizzes]
  )

  return (
    <Flex direction="column">
      <Flex w="100%" pb="5">
        <Button
          variant="outline"
          aria-label="Delete Quiz"
          backgroundColor={buttonBackColor}
          onClick={onBack}
          //   icon={<IoReturnDownBackOutline />}
        >
          戻る
        </Button>
        <Spacer />
      </Flex>
      <VStack>
        {publishedQuizCount ? (
          <>
            <VStack
              w="100%"
              borderWidth="1px"
              flex="1"
              borderRadius="md"
              p="25px"
            >
              <Text fontSize="md">合格ラインの設定</Text>
              <HStack>
                <Text fontSize="6xl">{passingScore}</Text>
                <Text fontSize="4xl">/ {publishedQuizCount}</Text>
                <Text fontSize="3xl">問</Text>
              </HStack>
              <Slider
                aria-label="slider-ex-4"
                defaultValue={passingScore}
                min={0}
                max={publishedQuizCount}
                step={1}
                onChangeEnd={onSlidePassingScore}
              >
                <SliderTrack bg="red.100">
                  <SliderFilledTrack bg="tomato" />
                </SliderTrack>
                <SliderThumb boxSize={6}>
                  <Box color="tomato" as={MdGraphicEq} />
                </SliderThumb>
              </Slider>
            </VStack>
            <FormTextarea
              name="passText"
              label="合格ライン以上"
              placeholder="例：よくできました！合格です！"
            />
            <FormInput
              name="passLink"
              label="合格ライン以上リンク"
              placeholder="例：https://example.com"
            />
            <FormInput
              name="passLinkText"
              label="合格ライン以上リンク表示文字"
              placeholder="例：おめでとうございます！"
            />
            <FormTextarea
              name="failText"
              label="合格ライン以下"
              placeholder="例：もう少し頑張りましょう…"
            />
            <FormInput
              name="failLink"
              label="合格ライン以下リンク"
              placeholder="例：https://example.coom"
            />
            <FormInput
              name="failLinkText"
              label="合格ライン以下リンク表示文字"
              placeholder="例：もう一度解く…"
            />
            <Stack>
              <Text fontSize="xs">
                ※合格ラインに合わせてテキスト/リンクの出し分けが可能です。
              </Text>
              <Text fontSize="xs">
                ※常に同じテキストを表示させたい場合は合格ラインを0にして「合格ライン以上」にテキスト/リンクを入力してください。
              </Text>
            </Stack>
          </>
        ) : (
          <VStack
            w="100%"
            borderWidth="1px"
            flex="1"
            borderRadius="md"
            p="100px"
          >
            <Text fontSize="md">
              プレイリスト追加済のクイズに「公開」されているクイズがありません。
            </Text>
          </VStack>
        )}

        <Spacer />
        <Field name="published" pt={5}>
          {({ form }: FieldProps) => (
            <FormControl
              //   name="published"
              display="flex"
              alignItems="center"
              mb={4}
            >
              <Checkbox
                name="published"
                id="published"
                colorScheme="red"
                defaultChecked={form.values.published}
                onChange={() => {
                  form.setFieldValue('published', !form.values.published)
                }}
              >
                公開する
              </Checkbox>
            </FormControl>
          )}
        </Field>
        <Spacer />
        <Button
          type="submit"
          m="auto"
          bgColor="#F3B0AE"
          width="10rem"
          isLoading={isSubmitting}
          disabled={!isValid}
        >
          {submitButtonLabel}
        </Button>
      </VStack>
    </Flex>
  )
}
