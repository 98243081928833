import { useMemo } from 'react'
import { useNavigate, RouteComponentProps } from '@reach/router'
import {
  Center,
  VStack,
  Flex,
  Spacer,
  Image,
  Text,
  Button,
} from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { Layout } from 'components/Common/Layout'
import { ProgressStatus } from 'hooks/usePlayQuizPlaylist'
import { usePlayQuizPlaylistPreview } from 'hooks/usePlayQuizPlaylistPreview'
import { Start } from 'components/PlayQuizPlaylist/Start'
import { Question } from 'components/PlayQuizPlaylist/Question'
import { Answered } from 'components/PlayQuizPlaylist/Answered'
import { Result } from 'components/PlayQuizPlaylist/Result'
import { EmbedStart } from 'components/PlayQuizPlaylist/EmbedStart'
import { EmbedQuestion } from 'components/PlayQuizPlaylist/EmbedQuestion'
import { EmbedAnswered } from 'components/PlayQuizPlaylist/EmbedAnswered'
import { EmbedResult } from 'components/PlayQuizPlaylist/EmbedResult'
import { Loading } from 'components/Common/Loading'

import logo from 'assets/images/logo512.png'
import { Unauthorized } from 'components/Common/Unauthorized'
import {
  QUIZSTATS,
  QuizStatsData,
  QuizStatsVars,
} from 'graphql/apollo/query/quizStats'
import {
  PLAYLIST,
  PlaylistData,
  PlaylistVars,
} from 'graphql/apollo/query/playlist'

type urlProps = RouteComponentProps<{
  quizPlaylistId: string
  isEmbed: boolean
}>

// http://localhost:3000/quizplaylist/{quizPlaylistId} http://localhost:3000/quizplaylist/fad8a962-300f-4b00-a56c-8db7e3ee9838
export const PlayQuizPlaylistPreview: React.FC<urlProps> = ({
  quizPlaylistId = '',
  isEmbed = false,
}) => {
  const {
    loading,
    refetch,
    isOwnedQuizPlaylist,
    progressStatus,
    playlist,
    quizNo,
    answers,
    score,
    currentQuiz,
    quizzesCount,
    handleStart,
    handleAnswer,
    handleNext,
  } = usePlayQuizPlaylistPreview(quizPlaylistId)
  const navigate = useNavigate()

  refetch({ id: quizPlaylistId })

  const { loading: quizStatsLoading, data: quizStatsData } = useQuery<
    QuizStatsData,
    QuizStatsVars
  >(QUIZSTATS, {
    variables: {
      quizId: currentQuiz?.id!,
    },
    fetchPolicy: 'network-only',
  })

  const { data } = useQuery<PlaylistData, PlaylistVars>(PLAYLIST, {
    variables: {
      id: quizPlaylistId!,
    },
  })

  const listDesign = data?.playlist.design
  const design = currentQuiz?.design

  const designTheme = useMemo(() => {
    /**
     * TODO:
     * 埋め込みクイズのデザインが決まり次第削除予定
     */
    if (isEmbed) {
      return 'ORIGINAL'
    }

    if (
      listDesign === 'ORIGINAL' ||
      listDesign === 'SIMPLE' ||
      listDesign === 'SERIOUS'
    ) {
      return listDesign
    }

    if (design) {
      return design
    }
  }, [isEmbed, listDesign, design])

  if (quizStatsLoading) {
    return (
      <Layout pageTitle="クイズ">
        <Loading />
      </Layout>
    )
  }

  const mainComponent = (previewComponent: JSX.Element) => {
    return (
      <Layout pageTitle="クイズ" design={designTheme}>
        {isOwnedQuizPlaylist ? (
          <VStack p={3}>
            <Flex w="100%">
              <Button
                onClick={() =>
                  navigate(`/quizplaylist/${quizPlaylistId}/check`)
                }
              >
                プレビューをやめる
              </Button>
              <Spacer />
            </Flex>
            <VStack w="100%" bg={`${designTheme}.common.screenBackground`}>
              {previewComponent}
            </VStack>
          </VStack>
        ) : (
          <Unauthorized />
        )}
      </Layout>
    )
  }

  const privateComponent = (
    <Center w="100%" h="100vh">
      <VStack>
        <Image src={logo} w="50px" h="50px" alt="logo" />
        <Text fontSize="3xl">リスト内クイズがすべて非公開</Text>
      </VStack>
    </Center>
  )

  if (!currentQuiz) return mainComponent(privateComponent)

  const renderPageComponent = () => {
    switch (progressStatus) {
      case ProgressStatus.START:
        if (isEmbed) {
          return (
            <EmbedStart
              isLoading={loading}
              playlist={playlist}
              quizzesCount={quizzesCount}
              onStart={handleStart}
            />
          )
        }
        return (
          <Start
            isLoading={loading}
            playlist={playlist}
            quizzesCount={quizzesCount}
            design={designTheme}
            onStart={handleStart}
          />
        )
      case ProgressStatus.QUESTION:
        if (isEmbed) {
          return (
            <EmbedQuestion
              quiz={currentQuiz}
              quizNo={quizNo}
              quizzesCount={quizzesCount}
              onAnswer={handleAnswer}
              quizStats={quizStatsData?.quizStats}
            />
          )
        }
        return (
          <Question
            quiz={currentQuiz}
            quizNo={quizNo}
            quizzesCount={quizzesCount}
            design={designTheme}
            onAnswer={handleAnswer}
            quizStats={quizStatsData?.quizStats}
          />
        )
      case ProgressStatus.ANSWERD:
        if (isEmbed) {
          return (
            <EmbedAnswered
              quiz={currentQuiz}
              answer={answers[answers.length - 1]}
              onNext={handleNext}
              quizStats={quizStatsData?.quizStats}
            />
          )
        }
        return (
          <Answered
            quiz={currentQuiz}
            quizNo={quizNo}
            quizzesCount={quizzesCount}
            answer={answers[answers.length - 1]}
            design={designTheme}
            onNext={handleNext}
            quizStats={quizStatsData?.quizStats}
          />
        )
      case ProgressStatus.RESULT:
        if (isEmbed) {
          return (
            <EmbedResult
              playlist={playlist!}
              quizzesCount={quizzesCount}
              answers={answers}
              score={score}
            />
          )
        }
        return (
          <Result
            playlist={playlist!}
            quizzesCount={quizzesCount}
            answers={answers}
            score={score}
            design={designTheme}
          />
        )
    }
  }

  return mainComponent(loading ? <Loading /> : renderPageComponent())
}
