import React from 'react'
import { Role, Group, Member } from 'types/generated/graphql'
import { UserData } from 'graphql/apollo/query/user'
import { OwnerMenu } from 'components/MemberDrawer/OwnerMenu'
import { MemberMenu } from 'components/MemberDrawer/MemberMenu'

interface Props {
  isEditable: boolean
  userProfile: UserData
  currentGroup?: Group
  member: Member
  role: Role
}
export const RoleMenu: React.FC<Props> = ({
  isEditable,
  userProfile,
  currentGroup,
  member,
  role,
}) => {
  if (isEditable) {
    return (
      <OwnerMenu
        userProfile={userProfile}
        currentGroup={currentGroup}
        member={member}
        role={role}
      />
    )
  }
  return <MemberMenu role={role} />
}
