import { Box } from '@chakra-ui/react'
import { RouteComponentProps } from '@reach/router'
import { Layout } from 'components/Common/Layout'
import { QuizCardList } from 'components/QuizCardList'
import { useUserProfileContext } from 'state'
import { useQuery } from '@apollo/client'
import { QUIZZES, QuizzesData, QuizzesVars } from 'graphql/apollo/query/quizzes'
import { Loading } from 'components/Common/Loading'
import { Error } from 'components/Common/Error'

type Props = RouteComponentProps

export const Quizzes: React.FC<Props> = () => {
  const { currentGroup } = useUserProfileContext()
  const {
    loading: quizzesLoading,
    error: quizzesError,
    data: quizzesData,
  } = useQuery<QuizzesData, QuizzesVars>(QUIZZES, {
    variables: {
      filter: {
        ownerGroupId: currentGroup?.id,
      },
    },
  })

  if (quizzesLoading)
    return (
      <Layout pageTitle="クイズページ">
        <Loading />
      </Layout>
    )

  if (quizzesError)
    return (
      <Layout pageTitle="クイズページ">
        <Error />
      </Layout>
    )

  return (
    <Layout pageTitle="クイズページ">
      <Box p="3">
        <QuizCardList quizzes={quizzesData?.quizzes} isEditableQuiz={true} />
      </Box>
    </Layout>
  )
}
