import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { QuizFormValues } from 'services/validators/QuizFormSchema'
import { createOgp } from 'utils/createOgp'
import { useUploadFile } from 'hooks/useUploadFile'
import { useUserProfileContext } from 'state'
import {
  CreateQuizData,
  CreateQuizVars,
  CREATE_QUIZ,
} from 'graphql/apollo/mutation/quiz/createQuiz'
import { QUIZZES } from 'graphql/apollo/query/quizzes'

export const useCreateQuiz = () => {
  const { currentGroup } = useUserProfileContext()
  const { handleUploadDataUrlImage } = useUploadFile()

  const [createQuiz] = useMutation<CreateQuizData, CreateQuizVars>(CREATE_QUIZ)

  const handleCreateQuiz = useCallback(
    async (values: QuizFormValues) => {
      const s3ImageUrl = await handleUploadDataUrlImage(values.imageUrl, 'quiz')
      const s3AnswerImageUrl = await handleUploadDataUrlImage(
        values.answerImageUrl,
        'quiz-answer'
      )
      const ogpImageUrl = await createOgp(values.question, values.imageUrl)
      const s3OgpImageUrl = await handleUploadDataUrlImage(
        ogpImageUrl,
        'quiz-ogp'
      )

      await createQuiz({
        variables: {
          input: {
            answerImageUrl: s3AnswerImageUrl,
            correct: values.correct,
            design: values.design as 'SERIOUS' | 'SIMPLE' | 'ORIGINAL',
            description: values.description,
            imageUrl: s3ImageUrl,
            published: values.published,
            question: values.question,
            snsInfo: {
              destinationLink: values.destinationLink,
              destinationLinkText: values.destinationLinkText,
              ogpImageUrl: s3OgpImageUrl,
              shareText: values.snsShareText,
            },
            wrongs: values.wrongs,
            ownerGroupId: currentGroup?.id,
          },
        },
        refetchQueries: [{ query: QUIZZES }, 'Quizzes'],
      })
    },
    [currentGroup?.id, createQuiz, handleUploadDataUrlImage]
  )

  return { handleCreateQuiz }
}
