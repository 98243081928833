import { Center, VStack, Text } from '@chakra-ui/react'
import { WarningIcon } from '@chakra-ui/icons'

export const Error: React.FC = () => {
  return (
    <Center w="100%" h="90vh">
      <VStack>
        <WarningIcon w={8} h={8} color="red.500" />
        <Text fontSize="3xl">Error</Text>
      </VStack>
    </Center>
  )
}
