import {
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Box,
  Flex,
  Tooltip,
} from '@chakra-ui/react'
import { Field, FieldProps } from 'formik'
import { MdOutlineHelpOutline } from 'react-icons/md'

export interface FormInputProps {
  name: string
  label: string
  annotation?: string
  placeholder?: string
  isRequired?: boolean
}

export const FormInput: React.FC<FormInputProps> = ({
  name,
  label,
  annotation,
  placeholder = label,
  isRequired = false,
}) => {
  // snsInfoはデータが入れ子(snsInfo.****)になっているため、専用にデータ取得処理を行う
  // wrongsも設問によって数が増減し[name]そのままでは取れない（nameが動的に変わるため）ためデータ処理を行う
  const getValue = (obj: any, key: string) => {
    return key.split('.').reduce((acc, k) => acc && acc[k], obj)
  }

  const getErrorMessage = (errors: any, name: string) => {
    if ('snsInfo' in errors && errors.snsInfo) {
      return getValue(errors, name)
    }
    return errors[name]
  }

  const getTouchedFlag = (touched: any, name: string) => {
    if ('snsInfo' in touched && touched.snsInfo) {
      return getValue(touched, name)
    }
    return touched[name]
  }

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const errorMessage = getErrorMessage(form.errors, name)
        const touchedFlag = getTouchedFlag(form.touched, name)
        return (
          <FormControl
            isInvalid={!!(errorMessage && touchedFlag)}
            isRequired={isRequired}
          >
            <Flex alignItems="center" mb="8px">
              <FormLabel htmlFor={name} fontSize="14px" mb="0">
                {label}
              </FormLabel>
              {annotation && (
                <Tooltip
                  label={annotation}
                  placement="top"
                  bgColor="#CCEAFC"
                  color="#222"
                  borderRadius="4px"
                  fontSize="11px"
                  hasArrow
                >
                  <Box>
                    <MdOutlineHelpOutline color="#828282" size="20px" />
                  </Box>
                </Tooltip>
              )}
            </Flex>
            <Input
              {...field}
              placeholder={placeholder}
              bgColor="#fff"
              errorBorderColor="v2Attention"
            />
            <FormErrorMessage color="v2Attention" fontSize="12px" mt="8px">
              {errorMessage}
            </FormErrorMessage>
          </FormControl>
        )
      }}
    </Field>
  )
}
