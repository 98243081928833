import React from 'react'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  RadioGroup,
  Radio,
  HStack,
  Text,
} from '@chakra-ui/react'
import { Field, FieldProps } from 'formik'

export interface FormRadioProps {
  name: string
  label: string
  values: string[]
  radioBtnLabels: string[]
  isRequired?: boolean
}

export const FormRadio: React.FC<FormRadioProps> = ({
  name,
  label,
  values,
  radioBtnLabels,
  isRequired = false,
}) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        return (
          <FormControl
            isInvalid={!!(form.errors[name] && form.touched[name])}
            isRequired={isRequired}
            mb="12px"
          >
            <FormLabel fontSize="14px" mb="12px" htmlFor={name}>
              {label}
            </FormLabel>
            <RadioGroup {...field}>
              <HStack spacing="24px">
                {values.map((value, index) => (
                  <Radio {...field} key={value} name={name} value={value}>
                    <Text fontSize="14px">{radioBtnLabels[index]}</Text>
                  </Radio>
                ))}
              </HStack>
            </RadioGroup>
            <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
          </FormControl>
        )
      }}
    </Field>
  )
}
