// store
import { create } from 'zustand'

type State = {
  query: string
}

type Action = {
  updateQuery: (query: string) => void
}

export const useQueryStore = create<State & Action>()((set) => ({
  query: '',
  updateQuery: (query: string) => set((state) => ({ query: query })),
}))
