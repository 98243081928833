import { gql } from '@apollo/client'
import { Scalars, Quiz } from 'types/generated/graphql'

export type QuizData = {
  quiz: Quiz
}

export type QuizVars = {
  id: Scalars['ID']
}

export const QUIZ = gql`
  query Quiz($id: ID!) {
    quiz(id: $id) {
      id
      title
      published
      ownerGroup {
        id
        name
      }
      ownerUser {
        id
        name
      }
      snsInfo {
        ogpImageUrl
        shareText
        destinationLinkText
        destinationLink
      }
      design
      content {
        question
        correct
        wrongs
        description
        imageUrl
        answerImageUrl
      }
    }
  }
`
