import React from 'react'
import { SimpleGrid } from '@chakra-ui/react'
import { QuizCard } from 'components/Common/QuizCard'
import { NoData } from 'components/Common/NoData'
import { Quiz } from 'types/generated/graphql'

interface Props {
  quizzes?: Quiz[]
  isEditableQuiz: boolean
}

export const QuizCardList: React.FC<Props> = ({ quizzes, isEditableQuiz }) => {
  if (!quizzes || quizzes.length === 0) return <NoData />

  return (
    <SimpleGrid w="100%" columns={[1, 2, 3, 4]} spacing="10px">
      {quizzes.map((quiz) => (
        <QuizCard key={quiz.id} quiz={quiz} isEditableQuiz={isEditableQuiz} />
      ))}
    </SimpleGrid>
  )
}
