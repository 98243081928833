import fuse, { IFuseOptions } from 'fuse.js'

export class SearchItems<T> {
  items: T[]
  fuseObj: fuse<unknown>
  enqueteOptions: IFuseOptions<string> = {
    includeScore: true,
    ignoreLocation: true,
    keys: ['title', 'optionsCsv', 'question'],
    threshold: 0.1,
  }
  quizOptions: IFuseOptions<string> = {
    includeScore: true,
    ignoreLocation: true,
    keys: ['content.question', 'content.correct'],
    threshold: 0.1,
  }
  quizlistOptions: IFuseOptions<string> = {
    includeScore: true,
    ignoreLocation: true,
    keys: ['name', 'description'],
    threshold: 0.1,
  }

  constructor(items: any, type: string) {
    this.items = items
    if (type === 'enquete') {
      this.fuseObj = new fuse(items, this.enqueteOptions)
    } else if (type === 'quiz') {
      this.fuseObj = new fuse(items, this.quizOptions)
    } else if (type === 'quizlist') {
      this.fuseObj = new fuse(items, this.quizlistOptions)
    } else {
      this.fuseObj = new fuse([])
      console.error('Fuse construct Error')
    }
  }

  search(query: string): T[] {
    if (!query) {
      return this.items
    }
    const results = this.fuseObj.search(query)
    return results.map((item) => {
      return item.item as T
    })
  }
}
