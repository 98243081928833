import { useMutation } from '@apollo/client'
import {
  DELETE_ENQUETE,
  DeleteEnqueteData,
  DeleteEnqueteVars,
} from 'graphql/apollo/mutation/enquete/deleteEnquete'
import { ENQUETES } from 'graphql/apollo/query/enquetes'

export const useDeleteEnquete = (enqueteId: string, onClose: () => void) => {
  const [deleteEnquete, { loading, error }] = useMutation<
    DeleteEnqueteData,
    DeleteEnqueteVars
  >(DELETE_ENQUETE)

  const handleDeleteEnquete = async () => {
    await deleteEnquete({
      variables: {
        id: enqueteId,
      },
      refetchQueries: [{ query: ENQUETES }, 'Enquetes'],
    })
    if (loading) console.log('loading now')
    if (error) alert(`${enqueteId}の削除において、エラー発生が発生しました`)
    onClose()
  }
  return handleDeleteEnquete
}
